export const USER_GROUPS = {
  SUPER_ADMIN: 1,
  PMO_SUPPORT: 2,
  SUPPLIER_OPS: 3,
  CLIENT_HIRING_MANAGER: 4,
  CLIENT_HR_PROCUREMENT: 5,
};

export const REQUISITION_TYPES = {
  JOB_REQUEST: 1,
  EVALUATE_WORKER: 2,
  ADD_NEW_HM_USER: 3,
  DEACTIVATE_HM_USER: 4,
  EARLY_TERMINATION_OF_CONTRACT: 5,
  REQUEST_FOR_WORKER_EXTENSION: 6,
  OTHERS: 7,
};

export const REQUISITION_STATUSES = {
  PENDING_FOR_PMO_APPROVAL: 1,
  COMPLETED: 2,
  APPROVED_BY_PMO: 3,
  REJECTED_BY_PMO: 4,
  CANCELLED: 5,
};
