import { emsApi } from "..";
import { REQUISITION_SERVICE } from "../constants";

class Requisitions {
  async fnGetRequests(payload) {
    return await emsApi.post(REQUISITION_SERVICE.GET_REQUISITION_LIST, payload);
  }

  async fnGetRequestsFiltered(payload) {
    return await emsApi.post(
      REQUISITION_SERVICE.GET_REQUISITION_LIST_FILTERED,
      payload
    );
  }

  async fnGetTotal(payload) {
    return await emsApi.get(REQUISITION_SERVICE.GET_TOTAL, payload);
  }

  async fnGetOptions(payload) {
    return await emsApi.get(REQUISITION_SERVICE.GET_OPTIONS, payload);
  }

  async fnComplete(payload) {
    return await emsApi.patch(
      `${REQUISITION_SERVICE.COMPLETE_REQUISITION}/${payload.id}`,
      payload
    );
  }

  async fnProcess(payload) {
    return await emsApi.patch(
      `${REQUISITION_SERVICE.PROCESS_REQUISITION}/${payload.id}`,
      payload
    );
  }

  async fnApprove(payload) {
    return await emsApi.patch(
      `${REQUISITION_SERVICE.APPROVE_REQUISITION}/${payload.id}`,
      payload
    );
  }

  async fnReject(payload) {
    return await emsApi.patch(
      `${REQUISITION_SERVICE.REJECT_REQUISITION}/${payload.id}`,
      payload
    );
  }

  async fnPostAddRequest(values) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return await emsApi.post(
      REQUISITION_SERVICE.ADD_REQUISITION,
      values.formData,
      {
        headers: headers,
      }
    );
  }

  async fnRequisitionWorkers() {
    return await emsApi.get(REQUISITION_SERVICE.GET_REQUISITION_WORKERS);
  }
}

Requisitions.api = new Requisitions();
export default Requisitions;
