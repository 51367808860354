import {
  GET_DEPARTMENT_OPTIONS,
  GET_DEPARTMENT_OPTIONS_SUCCESS,
  GET_DEPARTMENT_OPTIONS_FAILED,
  GET_COST_CENTER_OPTIONS,
  GET_COST_CENTER_OPTIONS_SUCCESS,
  GET_COST_CENTER_OPTIONS_FAILED,
  GET_CLIENTS,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAILED,
  GET_CLIENT_SUPPLIERS,
  GET_CLIENT_SUPPLIERS_SUCCESS,
  GET_CLIENT_SUPPLIERS_FAILED,
  GET_CURRENCY_OPTIONS,
  GET_CURRENCY_OPTIONS_SUCCESS,
  GET_CURRENCY_OPTIONS_FAILED,
  GET_COST_TRACKING_OPTIONS,
  GET_COST_TRACKING_OPTIONS_SUCCESS,
  GET_COST_TRACKING_OPTIONS_FAILED,
} from "./types";

const INIT_STATE = {
  costCenter: {
    loading: true,
    data: [],
    error: false,
    errorMessage: null,
  },
  department: {
    loading: true,
    data: [],
    error: false,
    errorMessage: null,
  },
  clients: {
    loading: true,
    data: [],
    error: false,
    errorMessage: null,
  },
  clientSuppliers: {
    loading: true,
    data: [],
    error: false,
    errorMessage: null,
  },
  currency: {
    loading: true,
    data: [],
    error: false,
    errorMessage: null,
  },
  costTracking: {
    loading: true,
    data: [],
    error: false,
    errorMessage: null,
  },
};

const clientfields = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEPARTMENT_OPTIONS:
      return {
        ...state,
        department: {
          ...state.department,
          loading: true,
        },
      };

    case GET_DEPARTMENT_OPTIONS_SUCCESS:
      return {
        ...state,
        department: {
          ...state.department,
          loading: false,
          data: action.payload,
          error: false,
          errorMessage: null,
        },
      };

    case GET_DEPARTMENT_OPTIONS_FAILED:
      return {
        ...state,
        department: {
          ...state.department,
          loading: false,
          data: [],
          error: true,
          errorMessage: action.payload,
        },
      };

    case GET_COST_CENTER_OPTIONS:
      return {
        ...state,
        costCenter: {
          ...state.costCenter,
          loading: true,
        },
      };

    case GET_COST_CENTER_OPTIONS_SUCCESS:
      return {
        ...state,
        costCenter: {
          ...state.costCenter,
          loading: false,
          data: action.payload,
          error: false,
          errorMessage: null,
        },
      };

    case GET_COST_CENTER_OPTIONS_FAILED:
      return {
        ...state,
        costCenter: {
          ...state.costCenter,
          loading: false,
          data: [],
          error: true,
          errorMessage: action.payload,
        },
      };

    case GET_CLIENTS:
      return {
        ...state,
        clients: {
          ...state.clients,
          loading: true,
        },
        department: {
          loading: true,
          data: [],
          error: false,
          errorMessage: null,
        },
      };

    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: {
          ...state.clients,
          loading: false,
          data: action.payload,
          error: false,
          errorMessage: null,
        },
      };

    case GET_CLIENTS_FAILED:
      return {
        ...state,
        clients: {
          ...state.clients,
          loading: false,
          data: [],
          error: true,
          errorMessage: action.payload,
        },
      };

    case GET_CLIENT_SUPPLIERS:
      return {
        ...state,
        clientSuppliers: {
          ...state.clientSuppliers,
          loading: true,
        },
      };

    case GET_CLIENT_SUPPLIERS_SUCCESS:
      return {
        ...state,
        clientSuppliers: {
          ...state.clientSuppliers,
          loading: false,
          data: action.payload,
          error: false,
          errorMessage: null,
        },
      };

    case GET_CLIENT_SUPPLIERS_FAILED:
      return {
        ...state,
        clientSuppliers: {
          ...state.clientSuppliers,
          loading: false,
          data: [],
          error: true,
          errorMessage: action.payload,
        },
      };

    case GET_CURRENCY_OPTIONS:
      return {
        ...state,
        currency: {
          ...state.currency,
          loading: true,
        },
      };

    case GET_CURRENCY_OPTIONS_SUCCESS:
      return {
        ...state,
        currency: {
          ...state.currency,
          loading: false,
          data: action.payload,
          error: false,
          errorMessage: null,
        },
      };

    case GET_CURRENCY_OPTIONS_FAILED:
      return {
        ...state,
        currency: {
          ...state.currency,
          loading: false,
          data: [],
          error: true,
          errorMessage: action.payload,
        },
      };

    case GET_COST_TRACKING_OPTIONS:
      return {
        ...state,
        costTracking: {
          ...state.costTracking,
          loading: true,
        },
      };

    case GET_COST_TRACKING_OPTIONS_SUCCESS:
      return {
        ...state,
        costTracking: {
          ...state.costTracking,
          loading: false,
          data: action.payload,
          error: false,
          errorMessage: null,
        },
      };

    case GET_COST_TRACKING_OPTIONS_FAILED:
      return {
        ...state,
        costTracking: {
          ...state.costTracking,
          loading: false,
          data: [],
          error: true,
          errorMessage: action.payload,
        },
      };

    default:
      return state;
  }
};

export default clientfields;
