import { emsApi } from "..";
import { DOCUMENT_SERVICE } from "../constants";

class Document {
  async fnGetDocumentListAll() {
    return await emsApi.get(DOCUMENT_SERVICE.GET_DOCUMENT_LIST_ALL);
  }

  async fnGetDocumentList(values) {
    return await emsApi.post(
      `${DOCUMENT_SERVICE.GET_DOCUMENT_LIST}`, values.payload
    );
  }

  async fnUpdateDocument(values) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return await emsApi.post(
      `${DOCUMENT_SERVICE.UPDATE_DOCUMENT}`,
      values,
      {
        headers: headers,
      }
    );
  }
}

Document.api = new Document();
export default Document;
