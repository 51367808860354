import {
  GET_OPERATION_CLIENTS,
  GET_OPERATION_CLIENTS_SUCCESS,
  GET_OPERATION_CLIENTS_FAILED,
  SAVE_MONTHLY_OPERATION,
  SAVE_MONTHLY_OPERATION_SUCCESS,
  SAVE_MONTHLY_OPERATION_FAILED,
  GET_OPERATION_LIST,
  GET_OPERATION_LIST_SUCCESS,
  GET_OPERATION_LIST_FAILED,
} from "./types";

const INIT_STATE = {
  loading: true,
  data: [],
  clientsData: [],
  error: false,
  errorMessage: null,
  isSubmitted: false
};

const requisition = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_OPERATION_CLIENTS:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        isSubmitted: false
      };

    case GET_OPERATION_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        clientsData: action.payload,
        error: false,
        errorMessage: null,
      };

    case GET_OPERATION_CLIENTS_FAILED:
      return {
        ...state,
        loading: false,
        clientsData: [],
        error: true,
        errorMessage: action.payload,
      };

    case SAVE_MONTHLY_OPERATION:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        messages: null,
      };

    case SAVE_MONTHLY_OPERATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
        errorMessage: null,
        messages: action.payload.message,
        isSubmitted: true
      };

    case SAVE_MONTHLY_OPERATION_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: true,
        errorMessage: action.payload,
        messages: null,
      };

    case GET_OPERATION_LIST:
      return {
        ...state,
        loading: true,
        error: false,
        data: null,
        errorMessage: null,
        isSubmitted: false
      };

    case GET_OPERATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
        errorMessage: null,
      };

    case GET_OPERATION_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: true,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default requisition;
