import React from "react";
import { Link } from "react-router-dom";
import { size, map } from "lodash";

const Img = (cell) => {
  return (
    <React.Fragment>
      {!cell.value ? (
        <div className="avatar-sm d-inline-block align-middle me-2">
          <div className="avatar-title bg-light-subtle text-light font-size-24 m-0 rounded-circle">
            <i className="bx bxs-user-circle"></i>
          </div>
        </div>
      ) : (
        <div>
          <img className="rounded-circle avatar-sm" src={cell.value} alt="" />
        </div>
      )}
    </React.Fragment>
  );
};

const Docs = (cell) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  return (
    <React.Fragment>
      {map(cell.value, (tag, index) => (
        <Link key={tag.id+`_`+index}
          className="text-primary justify-content-start"
          to={`${baseUrl}${tag.new_file}`}
          style={{
            fontWeight: 700,
            fontFamily: "IBM Plex Sans",
          }}
          target="_blank"
        >
          <i
            className="mdi mdi-file-document-outline font-size-18"
            id="edittooltip"
          ></i>
        </Link>
      ))}
    </React.Fragment>
  );
};

const ID = (cell) => {
  return cell.value ? cell.value : "";
};

const Name = (cell) => {
  return cell.value ? cell.value : "";
};

const Status = (cell) => {
  return cell.value === 1 ? "Active" : "In-Active";
};

const Date = (cell) => {
  return cell.value ? cell.value : "";
};

export { ID, Name, Status, Date, Img, Docs };
