import { emsApi } from "..";
import { JOB_ORDER_SERVICE } from "../constants";

class JobOrder {
  async fnGetJobOrderList(payload) {
    return await emsApi.post(JOB_ORDER_SERVICE.GET_JOB_ORDER_LIST, payload);
  }

  async fnPostUpdateJobOrder(values) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    return await emsApi.post(
      JOB_ORDER_SERVICE.UPDATE_JOB_ORDER,
      values.formData,
      {
        headers: headers,
      }
    );
  }

  async fnGetJOCandidates(values) {
    return await emsApi.get(
      `${JOB_ORDER_SERVICE.GET_CANDIDATE_LIST}/${values.id}`
    );
  }

  async fnPostAddJobOrderNewCandidate(values) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    return await emsApi.post(
      JOB_ORDER_SERVICE.POST_ADD_CANDIDATE,
      values.formData,
      {
        headers: headers,
      }
    );
  }

  async fnGetCandidate(values) {
    return await emsApi.get(`${JOB_ORDER_SERVICE.GET_CANDIDATE}/${values.id}`);
  }

  async fnPostUpdateCandidateStatus(payload) {
    return await emsApi.post(
      JOB_ORDER_SERVICE.POST_UPDATE_CANDIDATE_STATUS,
      payload
    );
  }

  async fnPostAddCandidateInterview(payload) {
    return await emsApi.post(
      JOB_ORDER_SERVICE.POST_ADD_CANDIDATE_INTERVIEW,
      payload
    );
  }

  async fnPostUpdateCandidateInterview(payload) {
    return await emsApi.post(
      JOB_ORDER_SERVICE.POST_UPDATE_CANDIDATE_INTERVIEW,
      payload
    );
  }

  async fnPostUpdateCandidateOffer(payload) {
    return await emsApi.post(
      JOB_ORDER_SERVICE.POST_ADD_CANDIDATE_OFFER,
      payload
    );
  }

  async fnPostAcceptCandidate(payload) {
    return await emsApi.post(JOB_ORDER_SERVICE.POST_ACCEPT_CANDIDATE, payload);
  }

  async fnPostConvertCandidateIntoWorker(payload) {
    return await emsApi.post(JOB_ORDER_SERVICE.POST_CONVERT_WORKER, payload);
  }
}

JobOrder.api = new JobOrder();
export default JobOrder;
