import {
  GET_CLIENT_LIST_ALL_SUCCESS,
  GET_CLIENT_LIST_ALL_FAILED,
  GET_CLIENT_LIST_SUCCESS,
  GET_CLIENT_LIST_FAILED,
  GET_CLIENTS_LIST_SUPPLIERS_REPORTINGMANAGER_SUCCESS,
  GET_CLIENTS_LIST_SUPPLIERS_REPORTINGMANAGER_FAILED,
  ADD_NEW_CLIENT_SUCCESS,
  ADD_NEW_CLIENT_FAILED,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILED,
  UPDATE_CLIENT_CONTACTS_SUCCESS,
  UPDATE_CLIENT_CONTACTS_FAILED,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILED,
  CLEAR_CLIENT_SAVE_MESSAGE,
} from "./types";

const INIT_STATE = {
  loading: true,
  data: [],
  listData: [],
  suprepData: [],
  actionReturnData: [],
  error: false,
  errorMessage: null,
};

const Clients = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLIENT_LIST_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        listData: action.payload,
        error: false,
        errorMessage: null,
      };

    case GET_CLIENT_LIST_ALL_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case GET_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
        errorMessage: null,
      };

    case GET_CLIENT_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case GET_CLIENTS_LIST_SUPPLIERS_REPORTINGMANAGER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        suprepData: action.payload,
        error: false,
        errorMessage: null,
      };

    case GET_CLIENTS_LIST_SUPPLIERS_REPORTINGMANAGER_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case ADD_NEW_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        isSubmitted: true,
      };

    case ADD_NEW_CLIENT_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        isSubmitted: false,
      };

    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        actionReturnData: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
        isSubmitted: true,
      };

    case UPDATE_CLIENT_CONTACTS_SUCCESS:
      return {
        ...state,
        actionReturnData: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
        isSubmitted: true,
      };

    case UPDATE_CLIENT_CONTACTS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        isSubmitted: false,
      };

    case UPDATE_CLIENT_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        isSubmitted: false,
      };

    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
      };

    case DELETE_CLIENT_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case CLEAR_CLIENT_SAVE_MESSAGE:
      return {
        ...state,
        actionReturnData: [],
      };
    default:
      return state;
  }
};

export default Clients;
