import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { actionCreator, types } from "../../store";
import ConfirmModal from "../../components/Common/ConfirmModal";

import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Card,
  CardBody,
  Form,
  FormFeedback,
} from "reactstrap";

import { REQUISITION_STATUSES, REQUISITION_TYPES } from "../../constants/app";
const baseUrl = process.env.REACT_APP_BASE_URL;

const ViewDetailsModal = ({
  groupModal,
  toggleGroup,
  selectedRequisitionData,
  validation,
  confirmMoveModal,
  toggleConfirmMoveModal,
  requisitionData,
  clientfields,
  ...props
}) => {
  const [remarks, setRemarks] = React.useState();

  const onConfirmProcessClick = async () => {
    await props.actionCreator({
      type: types.PROCESS_REQUISITION,
      payload: {
        id: selectedRequisitionData.id,
        remarks: remarks,
      },
    });
    toggleConfirmMoveModal(toggleConfirmMoveModal);
  };

  const processClick = async () => {
    await props.actionCreator({
      type: types.PROCESS_REQUISITION,
      payload: {
        id: selectedRequisitionData.id,
        remarks: remarks,
      },
    });
  };

  const completeClick = async () => {
    await props.actionCreator({
      type: types.COMPLETE_REQUISITION,
      payload: {
        id: selectedRequisitionData.id,
        remarks: remarks,
      },
    });
  };

  const rejectClick = async () => {
    await props.actionCreator({
      type: types.REJECT_REQUISITION,
      payload: {
        id: selectedRequisitionData.id,
        remarks: values.remarks,
      },
    });
  };

  return (
    <>
      <Modal
        isOpen={groupModal}
        toggle={toggleGroup}
        style={{ maxWidth: "900px", width: "80%" }}
      >
        <ModalHeader toggle={toggleGroup}>
          <span className="small">Request</span> #{selectedRequisitionData.id}{" "}
          <span
            className="text-muted small font-size-13"
            style={{ paddingLeft: "20px" }}
          >
            {selectedRequisitionData.type} -
          </span>
          <span
            className={`badge font-size-11 m-1 ${
              selectedRequisitionData?.status?.toLowerCase().includes("pending")
                ? "text-warning bg-warning-subtle"
                : selectedRequisitionData?.status
                      ?.toLowerCase()
                      .includes("cancel") ||
                    selectedRequisitionData?.status
                      ?.toLowerCase()
                      .includes("reject")
                  ? "text-danger bg-danger-subtle"
                  : "text-success bg-success-subtle"
            }`}
          >
            {selectedRequisitionData.status}
          </span>
        </ModalHeader>
        <ModalBody>
          <Row className="align-items-center pt-3">
            <Col md={4}>
              <div
                style={{
                  textAlign: "left",
                  fontSize: 16,
                }}
              >
                <Label
                  className="form-label"
                  style={{
                    fontSize: 13,
                  }}
                >
                  Request Date &nbsp;&nbsp;
                </Label>
                <span
                  style={{
                    fontSize: 13,
                  }}
                  className="text-muted"
                >
                  {selectedRequisitionData.request_date?.split(" ")[0]}
                </span>
              </div>
            </Col>
            <Col md={4}>
              <div
                style={{
                  textAlign: "left",
                  fontSize: 16,
                }}
              >
                <Label
                  className="form-label"
                  style={{
                    fontSize: 13,
                  }}
                >
                  Urgency Level &nbsp;&nbsp;
                </Label>
                <span
                  style={{
                    fontSize: 13,
                  }}
                  className="text-muted"
                >
                  {selectedRequisitionData.urgency_level_name}
                </span>
              </div>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col lg={12}>
              <Card>
                <span
                  className="card-header bg-transparent border-bottom small"
                  style={{ fontWeight: 600, padding: "13px", fontSize: "13px" }}
                >
                  Request Details
                </span>
                <CardBody>
                  <Row className="align-items-center">
                    <Col md={2}>
                      <div
                        style={{
                          textAlign: "left",
                          fontSize: 16,
                        }}
                      >
                        <span
                          className="form-label"
                          style={{
                            fontSize: 13,
                            fontWeight: "500",
                          }}
                        >
                          Requested By &nbsp;&nbsp;
                        </span>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div
                        style={{
                          textAlign: "left",
                        }}
                      >
                        <span
                          style={{
                            fontSize: 13,
                          }}
                          className="text-muted2"
                        >
                          {selectedRequisitionData.requested_by}
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row className="align-items-center">
                    <Col md={2}>
                      <div
                        style={{
                          textAlign: "left",
                          fontSize: 16,
                        }}
                      >
                        <span
                          className="form-label"
                          style={{
                            fontSize: 13,
                            fontWeight: "500",
                          }}
                        >
                          Email &nbsp;&nbsp;
                        </span>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div
                        style={{
                          textAlign: "left",
                        }}
                      >
                        <span
                          style={{
                            fontSize: 13,
                          }}
                          className="text-muted2"
                        >
                          {selectedRequisitionData.email}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <span
                  className="card-header bg-transparent border-bottom small"
                  style={{ fontWeight: 600, padding: "13px", fontSize: "13px" }}
                >
                  Specifications
                </span>
                <CardBody>
                  {selectedRequisitionData?.content &&
                    typeof JSON.parse(selectedRequisitionData.content) ===
                      "object" &&
                    Array.isArray(
                      JSON.parse(selectedRequisitionData.content)
                    ) &&
                    JSON.parse(selectedRequisitionData.content).map(
                      (data, index) => (
                        <React.Fragment key={index}>
                          {data.label.toLowerCase().includes("description") ||
                          data.label.toLowerCase().includes("details") ||
                          data.label.toLowerCase().includes("notes") ? (
                            <>
                              <Row
                                className="align-items-center pt-3"
                                key={index}
                              >
                                <Col md={12}>
                                  <div
                                    style={{
                                      textAlign: "left",
                                      fontSize: 16,
                                    }}
                                  >
                                    <span
                                      className="form-label"
                                      style={{
                                        fontSize: 13,
                                        fontWeight: "500",
                                      }}
                                    >
                                      {data.label} &nbsp;&nbsp;
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                              <Row
                                className="align-items-center"
                                key={`desc_${index}`}
                              >
                                <Col lg={12}>
                                  <div
                                    style={{
                                      textAlign: "left",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 13,
                                      }}
                                      className="text-muted2"
                                    >
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: JSON.parse(data.values)[0]
                                            .name,
                                        }}
                                      />
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <Row className="align-items-center" key={index}>
                              <Col md={3}>
                                <div
                                  style={{
                                    textAlign: "left",
                                    fontSize: 16,
                                  }}
                                >
                                  <span
                                    className="form-label"
                                    style={{
                                      fontSize: 13,
                                      fontWeight: "500",
                                    }}
                                  >
                                    {data.label} &nbsp;&nbsp;
                                  </span>
                                </div>
                              </Col>
                              <Col md={4}>
                                <div
                                  style={{
                                    textAlign: "left",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 13,
                                    }}
                                    className="text-muted2"
                                  >
                                    {data.label == "Department"
                                      ? clientfields.department.data.filter(
                                          (item) =>
                                            parseInt(
                                              JSON.parse(data.values)[0].name
                                            ) === item.id
                                        )[0].label
                                      : data.label == "Cost Center"
                                        ? clientfields.costCenter.data.filter(
                                            (item) =>
                                              parseInt(
                                                JSON.parse(data.values)[0].name
                                              ) === item.id
                                          )[0].label
                                        : JSON.parse(data.values)[0].name}
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          )}
                        </React.Fragment>
                      )
                    )}

                  <Row className="align-items-center pt-4">
                    <Col md={6}>
                      <div
                        style={{
                          textAlign: "left",
                          fontSize: 16,
                        }}
                      >
                        <span
                          className="form-label"
                          style={{
                            fontSize: 13,
                            fontWeight: "500",
                          }}
                        >
                          Attachments &nbsp;&nbsp;
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-center pt-2">
                    {selectedRequisitionData?.documents &&
                      selectedRequisitionData.documents.map((data, index) => (
                        <Col md={1} key={index}>
                          <div
                            style={{
                              alignContent: "center",
                              textAlign: "center",
                              color: "#5156BE",
                            }}
                          >
                            <Link
                              key={data.id + `_` + index}
                              className="text-primary justify-content-start"
                              to={`${baseUrl}${data.new_file}`}
                              target="_blank"
                            >
                              <i
                                className="mdi mdi-file-document-outline"
                                id="edittooltip"
                                style={{ fontSize: "35px" }}
                              ></i>
                            </Link>
                          </div>
                        </Col>
                      ))}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {selectedRequisitionData.status_id !=
            REQUISITION_STATUSES.CANCELLED &&
            selectedRequisitionData.status_id !=
              REQUISITION_STATUSES.REJECTED_BY_PMO && (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col lg={12}>
                    <Card>
                      <span
                        className="card-header bg-transparent border-bottom small"
                        style={{
                          fontWeight: 600,
                          padding: "13px",
                          fontSize: "13px",
                        }}
                      >
                        Approval Process
                      </span>
                      <CardBody>
                        <Row className="align-items-center pt-4 pb-2">
                          <Col md={12}>
                            <div
                              style={{
                                textAlign: "left",
                                fontSize: 16,
                              }}
                            >
                              <span
                                className="form-label"
                                style={{
                                  fontSize: 13,
                                  fontWeight: "500",
                                }}
                              >
                                Justification Approval / Rejection &nbsp;&nbsp;
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} className="pb-4">
                            <Input
                              type="textarea"
                              name="remarks"
                              className="form-control"
                              multiple={false}
                              onChange={(e) => {
                                setRemarks(e.target.value);
                                validation.handleChange(e);
                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.remarks || ""}
                              invalid={
                                validation.touched.remarks &&
                                validation.errors.remarks
                                  ? true
                                  : false
                              }
                              style={{
                                backgroundColor: "white",
                                height: "130px",
                              }}
                            ></Input>
                            {validation.touched.remarks &&
                            validation.errors.remarks ? (
                              <FormFeedback type="invalid">
                                {validation.errors.remarks}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={12}>
                            <div
                              className="col-sm-auto"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                              }}
                            >
                              {selectedRequisitionData.status_id !=
                                REQUISITION_STATUSES.APPROVED_BY_PMO && (
                                <div
                                  className="mb-4"
                                  style={{ marginRight: 10 }}
                                >
                                  <button
                                    type="submit"
                                    className="btn btn-info waves-effect waves-light"
                                    onClick={() =>
                                      validation.setFieldValue(
                                        "action",
                                        "approve"
                                      )
                                    }
                                  >
                                    Approve
                                  </button>
                                </div>
                              )}
                              {selectedRequisitionData.status_id ==
                                REQUISITION_STATUSES.APPROVED_BY_PMO &&
                                selectedRequisitionData.type_id !=
                                  REQUISITION_TYPES.OTHERS &&
                                selectedRequisitionData.type_id !=
                                  REQUISITION_TYPES.EVALUATE_WORKER && (
                                  <div
                                    className="mb-4"
                                    style={{ marginRight: 10 }}
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-info waves-effect waves-light"
                                      onClick={() => {
                                        processClick();
                                        toggleGroup();
                                      }}
                                    >
                                      Process
                                    </button>
                                  </div>
                                )}
                              {selectedRequisitionData.status_id ==
                                REQUISITION_STATUSES.APPROVED_BY_PMO &&
                                (selectedRequisitionData.type_id ==
                                  REQUISITION_TYPES.OTHERS ||
                                  selectedRequisitionData.type_id ==
                                    REQUISITION_TYPES.EVALUATE_WORKER) && (
                                  <div
                                    className="mb-4"
                                    style={{ marginRight: 10 }}
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-info waves-effect waves-light"
                                      onClick={() => {
                                        completeClick();
                                        toggleGroup();
                                      }}
                                    >
                                      Complete
                                    </button>
                                  </div>
                                )}
                              <div className="mb-4" style={{ marginRight: 10 }}>
                                <button
                                  type="submit"
                                  className="btn btn-danger waves-effect waves-light"
                                  onClick={() =>
                                    validation.setFieldValue("action", "reject")
                                  }
                                >
                                  Reject
                                </button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Form>
            )}
        </ModalBody>
      </Modal>
      <ConfirmModal
        show={confirmMoveModal}
        onConfirmClick={onConfirmProcessClick}
        onCloseClick={toggleConfirmMoveModal}
      />
    </>
  );
};

const mapStateToProps = () => ({});
export default connect(mapStateToProps, { actionCreator })(ViewDetailsModal);
