import {
  GET_APEX_CHARTS_DATA,
  GET_APEX_CHARTS_DATA_SUCCESS,
  GET_APEX_CHARTS_DATA_FAILED,
  GET_PIE_CHARTS_DATA,
  GET_PIE_CHARTS_DATA_SUCCESS,
  GET_PIE_CHARTS_DATA_FAILED,
  GET_BAR_CHARTS_DATA,
  GET_BAR_CHARTS_DATA_SUCCESS,
  GET_BAR_CHARTS_DATA_FAILED,
  GET_TOP_CHARTS_DATA,
  GET_TOP_CHARTS_DATA_SUCCESS,
  GET_TOP_CHARTS_DATA_FAILED,
  GET_RECENT_ACTIVITY_DATA,
  GET_RECENT_ACTIVITY_DATA_SUCCESS,
  GET_RECENT_ACTIVITY_DATA_FAILED,
} from "./types";

const INIT_STATE = {
  apexCharts: {
    loading: true,
    data: [],
    error: false,
    errorMessage: null,
  },
  pieCharts: {
    loading: true,
    data: [],
    error: false,
    errorMessage: null,
  },
  barCharts: {
    loading: true,
    data: [],
    error: false,
    errorMessage: null,
  },
  topCharts: {
    loading: true,
    data: [],
    error: false,
    errorMessage: null,
  },
  recentRequestsActivity: {
    loading: true,
    data: [],
    error: false,
    errorMessage: null,
  },
};

const dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_APEX_CHARTS_DATA:
      return {
        ...state,
        apexCharts: {
          ...state.apexCharts,
          loading: true,
        },
      };

    case GET_APEX_CHARTS_DATA_SUCCESS:
      return {
        ...state,
        apexCharts: {
          ...state.apexCharts,
          loading: false,
          data: action.payload,
          error: false,
          errorMessage: null,
        },
      };

    case GET_APEX_CHARTS_DATA_FAILED:
      return {
        ...state,
        apexCharts: {
          ...state.apexCharts,
          loading: false,
          data: [],
          error: true,
          errorMessage: action.payload,
        },
      };

    case GET_PIE_CHARTS_DATA:
      return {
        ...state,
        pieCharts: {
          ...state.pieCharts,
          loading: true,
        },
      };

    case GET_PIE_CHARTS_DATA_SUCCESS:
      return {
        ...state,
        pieCharts: {
          ...state.pieCharts,
          loading: false,
          data: action.payload,
          error: false,
          errorMessage: null,
        },
      };

    case GET_PIE_CHARTS_DATA_FAILED:
      return {
        ...state,
        pieCharts: {
          ...state.pieCharts,
          loading: false,
          data: [],
          error: true,
          errorMessage: action.payload,
        },
      };

    case GET_BAR_CHARTS_DATA:
      return {
        ...state,
        barCharts: {
          ...state.barCharts,
          loading: true,
        },
      };

    case GET_BAR_CHARTS_DATA_SUCCESS:
      return {
        ...state,
        barCharts: {
          ...state.barCharts,
          loading: false,
          data: action.payload,
          error: false,
          errorMessage: null,
        },
      };

    case GET_BAR_CHARTS_DATA_FAILED:
      return {
        ...state,
        barCharts: {
          ...state.barCharts,
          loading: false,
          data: [],
          error: true,
          errorMessage: action.payload,
        },
      };

    case GET_TOP_CHARTS_DATA:
      return {
        ...state,
        topCharts: {
          ...state.topCharts,
          loading: true,
        },
      };

    case GET_TOP_CHARTS_DATA_SUCCESS:
      return {
        ...state,
        topCharts: {
          ...state.topCharts,
          loading: false,
          data: action.payload,
          error: false,
          errorMessage: null,
        },
      };

    case GET_TOP_CHARTS_DATA_FAILED:
      return {
        ...state,
        topCharts: {
          ...state.topCharts,
          loading: false,
          data: [],
          error: true,
          errorMessage: action.payload,
        },
      };

    case GET_RECENT_ACTIVITY_DATA:
      return {
        ...state,
        recentRequestsActivity: {
          ...state.recentRequestsActivity,
          loading: true,
        },
      };

    case GET_RECENT_ACTIVITY_DATA_SUCCESS:
      return {
        ...state,
        recentRequestsActivity: {
          ...state.recentRequestsActivity,
          loading: false,
          data: action.payload,
          error: false,
          errorMessage: null,
        },
      };

    case GET_RECENT_ACTIVITY_DATA_FAILED:
      return {
        ...state,
        recentRequestsActivity: {
          ...state.recentRequestsActivity,
          loading: false,
          data: [],
          error: true,
          errorMessage: action.payload,
        },
      };

    default:
      return state;
  }
};

export default dashboard;
