export const GET_DOCUMENT_LIST_ALL = "@documents/GET_DOCUMENT_LIST_ALL";
export const GET_DOCUMENT_LIST_ALL_SUCCESS =
  "@documents/GET_DOCUMENT_LIST_ALL_SUCCESS";
export const GET_DOCUMENT_LIST_ALL_FAILED =
  "@documents/GET_DOCUMENT_LIST_ALL_FAILED";

export const GET_DOCUMENT_LIST = "@documents/GET_DOCUMENT_LIST";
export const GET_DOCUMENT_LIST_SUCCESS = "@documents/GET_DOCUMENT_LIST_SUCCESS";
export const GET_DOCUMENT_LIST_FAILED = "@documents/GET_DOCUMENT_LIST_FAILED";

export const ADD_NEW_DOCUMENT = "@documents/ADD_NEW_DOCUMENT";
export const ADD_NEW_DOCUMENT_SUCCESS = "@documents/ADD_NEW_DOCUMENT_SUCCESS";
export const ADD_NEW_DOCUMENT_FAILED = "@documents/ADD_NEW_DOCUMENT_FAILED";

export const UPDATE_DOCUMENT = "@documents/UPDATE_DOCUMENT";
export const UPDATE_DOCUMENT_SUCCESS = "@documents/UPDATE_DOCUMENT_SUCCESS";
export const UPDATE_DOCUMENT_FAILED = "@documents/UPDATE_DOCUMENT_FAILED";

export const CLEAR_DOCUMENT_SAVE_MESSAGE =
  "@documents/CLEAR_DOCUMENT_SAVE_MESSAGE";
