import {
  GET_SUPPLIER_LIST_ALL_SUCCESS,
  GET_SUPPLIER_LIST_ALL_FAILED,
  GET_SUPPLIER_LIST_SUCCESS,
  GET_SUPPLIER_LIST_FAILED,
  ADD_NEW_SUPPLIER_SUCCESS,
  ADD_NEW_SUPPLIER_FAILED,
  UPDATE_SUPPLIER_SUCCESS,
  UPDATE_SUPPLIER_FAILED,
  DELETE_SUPPLIER_SUCCESS,
  DELETE_SUPPLIER_FAILED,
  GET_SUPPLIER_DOCUMENT_LIST_SUCCESS,
  GET_SUPPLIER_DOCUMENT_LIST_FAILED,
  GET_SUPPLIERS_BI_SUCCESS,
  GET_SUPPLIERS_BI_FAILED,
  CLEAR_SUPPLIER_SAVE_MESSAGE,
} from "./types";

const INIT_STATE = {
  loading: true,
  data: [],
  actionReturnData: [],
  error: false,
  errorMessage: null,
  suppliersBIData: [],
};

const suppliers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUPPLIER_LIST_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
        errorMessage: null,
      };

    case GET_SUPPLIER_LIST_ALL_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case GET_SUPPLIER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
        errorMessage: null,
      };

    case GET_SUPPLIER_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case GET_SUPPLIER_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
        errorMessage: null,
      };

    case GET_SUPPLIER_DOCUMENT_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case ADD_NEW_SUPPLIER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
      };

    case ADD_NEW_SUPPLIER_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case UPDATE_SUPPLIER_SUCCESS:
      return {
        ...state,
        actionReturnData: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
        isSubmitted: true,
      };

    case UPDATE_SUPPLIER_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case DELETE_SUPPLIER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
      };

    case DELETE_SUPPLIER_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case GET_SUPPLIERS_BI_SUCCESS:
      return {
        ...state,
        loading: false,
        suppliersBIData: action.payload.data,
        error: false,
        errorMessage: null,
      };

    case GET_SUPPLIERS_BI_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case CLEAR_SUPPLIER_SAVE_MESSAGE:
      return {
        ...state,
        actionReturnData: [],
      };
    default:
      return state;
  }
};

export default suppliers;
