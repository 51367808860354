import { emsApi } from "..";
import { CLIENTS_SERVICE } from "../constants";

class Clients {
  async fnGetClientListAll() {
    return await emsApi.get(CLIENTS_SERVICE.GET_CLIENTS_LIST_ALL);
  }

  async fnGetClientList(values) {
    return await emsApi.get(`${CLIENTS_SERVICE.GET_CLIENTS_LIST}/${values}`);
  }

  async fnGetClientListSuppliersReportingManager() {
    return await emsApi.get(
      CLIENTS_SERVICE.GET_CLIENTS_LIST_SUPPLIERS_REPORTINGMANAGER
    );
  }

  async fnAddNewClient(payload) {
    return await emsApi.post(CLIENTS_SERVICE.ADD_NEW_CLIENTS, payload);
  }

  async fnUpdateClient(values) {
    return await emsApi.patch(
      `${CLIENTS_SERVICE.UPDATE_CLIENTS}/${values.id}`,
      values
    );
  }

  async fnUpdateClientContacts(values) {
    return await emsApi.patch(
      `${CLIENTS_SERVICE.UPDATE_CLIENT_CONTACTS}/${values.id}`,
      values
    );
  }

  async fnDeleteClient(id) {
    return await emsApi.delete(`${CLIENTS_SERVICE.DELETE_CLIENTS}/${id}`);
  }
}

Clients.api = new Clients();
export default Clients;
