const ID = (cell) => {
  return cell.value ? cell.value : "";
};

const OrigFile = (cell) => {
  return cell.value ? cell.value : "";
};

const Remarks = (cell) => {
  return cell.value ? cell.value : "";
};

const Name = (cell) => {
  return cell.value ? cell.value : "";
};

const Client = (cell) => {
  return cell.value ? cell.value : "";
};

const Supplier = (cell) => {
  return cell.value ? cell.value : "";
};

const ReportingManager = (cell) => {
  return cell.value ? cell.value : "";
};

const Department = (cell) => {
  return cell.value ? cell.value : "";
};

const ContractStartDate = (cell) => {
  return cell.value ? cell.value : "";
};

const TerminationDate = (cell) => {
  return cell.value ? cell.value : "";
};

export {
  ID,
  OrigFile,
  Remarks,
  Name,
  Client,
  Supplier,
  ReportingManager,
  Department,
  ContractStartDate,
  TerminationDate,
};
