export const GET_USERGROUP = "@user-group/GET_USERGROUP";
export const GET_USERGROUP_SUCCESS = "@user-group/GET_USERGROUP_SUCCESS";
export const GET_USERGROUP_FAILED = "@user-group/GET_USERGROUP_FAILED";
export const GET_SELECTED_USERGROUP = "@user-group/GET_SELECTED_USERGROUP";
export const GET_SELECTED_USERGROUP_SUCCESS =
  "@user-group/GET_SELECTED_USERGROUP_SUCCESS";
export const GET_SELECTED_USERGROUP_FAILED =
  "@user-group/GET_SELECTED_USERGROUP_FAILED";
export const POST_USERGROUP_SUBMIT = "@user-group/POST_USERGROUP_SUBMIT";
export const POST_USERGROUP_SUBMIT_SUCCESS =
  "@user-group/POST_USERGROUP_SUBMIT_SUCCESS";
export const POST_USERGROUP_SUBMIT_FAILED =
  "@user-group/POST_USERGROUP_SUBMIT_FAILED";
export const GET_USERGROUP_LIST = "@user-group/GET_USERGROUP_LIST";
export const GET_USERGROUP_LIST_SUCCESS =
  "@user-group/GET_USERGROUP_LIST_SUCCESS";
export const GET_USERGROUP_LIST_FAILED =
  "@user-group/GET_USERGROUP_LIST_FAILED";
export const CLEAR_USERGROUP = "@user-group/CLEAR_USERGROUP";

export const ADD_NEW_USERGROUP = "@user/ADD_NEW_USERGROUP";
export const ADD_NEW_USERGROUP_SUCCESS = "@user/ADD_NEW_USERGROUP_SUCCESS";
export const ADD_NEW_USERGROUP_FAILED = "@user/ADD_NEW_USERGROUP_FAILED";

export const UPDATE_USERGROUP = "@user/UPDATE_USERGROUP";
export const UPDATE_USERGROUP_SUCCESS = "@user/UPDATE_USERGROUP_SUCCESS";
export const UPDATE_USERGROUP_FAILED = "@user/UPDATE_USERGROUP_FAILED";

export const DELETE_USERGROUP = "@user-group/DELETE_USERGROUP";
export const DELETE_USERGROUP_SUCCESS = "@user-group/DELETE_USERGROUP_SUCCESS";
export const DELETE_USERGROUP_FAILED = "@user-group/DELETE_USERGROUP_FAILED";
export const CLEAR_USERGROUP_ERROR = "@user-group/CLEAR_USERGROUP_ERROR";

export const GET_USERGROUP_SETTINGS = "@user/GET_USERGROUP_SETTINGS";
export const GET_USERGROUP_SETTINGS_SUCCESS = "@user/GET_USERGROUP_SETTINGS_SUCCESS";
export const GET_USERGROUP_SETTINGS_FAILED = "@user/GET_USERGROUP_SETTINGS_FAILED";
