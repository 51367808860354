import React, { useState } from "react";
import moment from "moment";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import TableContainer from "../../components/Common/TableContainer";
import { actionCreator, types } from "../../store";
import Dropzone from "react-dropzone";
import { useParams, useNavigate } from "react-router-dom";
import addLogo from "../../assets/images/add.png";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ToastContainer, toast } from "react-toastify";

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Dropdown,
  DropdownToggle,
  UncontrolledDropdown,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Collapse,
  DropdownItem,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";

import classnames from "classnames";
import { CLEAR_DOCUMENT_SAVE_MESSAGE } from "../../store/actions";

const ClientsList = ({
  app,
  clients,
  suppliers,
  clientfields,
  document,
  route,
  ...props
}) => {
  document.title = "Client List";

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const [selectedSuppiler, setSelectedSupplier] = React.useState({});

  const [modal, setModal] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);

  //Information
  const [assignSuppliers, setAssignSuppliers] = React.useState([]);
  const [supplierList, setSupplierList] = React.useState([]);
  const [addresses, setAddresses] = React.useState([]);
  const [notes, setNotes] = React.useState([]);

  //contacts
  const [contacts, setContacts] = React.useState([]);
  const [departmentList, setDepartmentList] = React.useState([]);

  //documents
  const [documentsCol, setDocumentsCol] = React.useState([]);

  //Tabs
  const [headerPillsTab, setHeaderPillsTab] = useState("1");

  const [dynamicOptionsValue, setDynamicOptionsValue] = React.useState({});
  const [dynamicOptionsValue2, setDynamicOptionsValue2] = React.useState({});

  const [contactsDynamicOptionsValue, setContactsDynamicOptionsValue] =
    React.useState([]);
  const [contactsDynamicOptionsValue2, setContactsDynamicOptionsValue2] =
    React.useState([]);

  const [documentDynamicOptionsValue, setDocumentDynamicOptionsValue] =
    React.useState([]);
  const [documentDynamicOptionsValue2, setDocumentDynamicOptionsValue2] =
    React.useState([]);

  const [documentListDynamicOptionsValue, setDocumentListDynamicOptionsValue] =
    React.useState([]);

  //Dropzone
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [showFileError, setShowFileError] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState([]);

  //Get ID and Check Add/Edit
  const { id } = useParams();

  const breadcrumbItem = isEdit && id ? "Manage Client" : "Add Client";

  // validation information
  const validationInformation = useFormik({
    enableReinitialize: true,
    initialValues: dynamicOptionsValue,
    validationSchema: Yup.object(dynamicOptionsValue2),
    onSubmit: (values) => {
      if (isEdit) {
        values.id = id;
        // update suppliers
        props.actionCreator({
          type: types.UPDATE_CLIENT,
          payload: values,
        });
      } else {
        // add new suppliers
        props.actionCreator({
          type: types.ADD_NEW_CLIENT,
          payload: values,
        });
        navigate("/clients");
      }
    },
    errors: (values) => {
      console.log("error", values);
    },
  });

  // validation contacts
  const validationContacts = useFormik({
    enableReinitialize: true,
    initialValues: contactsDynamicOptionsValue,
    validationSchema: Yup.object(contactsDynamicOptionsValue2),
    onSubmit: (values) => {
      if (isEdit) {
        values.id = id;
        // update suppliers
        props.actionCreator({
          type: types.UPDATE_CLIENT_CONTACTS,
          payload: values,
        });
      }
    },
  });

  // validation document
  const validationDocument = useFormik({
    enableReinitialize: true,
    initialValues: documentDynamicOptionsValue,
    validationSchema: Yup.object(documentDynamicOptionsValue2),
    onSubmit: (values) => {
      if (selectedFiles.length === 0) {
        setShowFileError(true);
        return true;
      }
      documentsCol.push({
        document_name: values.document_name,
        owner_remarks: values.owner_remarks,
        pmo_remarks: "",
        file: selectedFiles,
      });
      validationDocumentList.setFieldValue(`documents`, documentsCol);
      validationDocument.resetForm();
      setSelectedFiles([]);
      setModal(false);
    },
  });

  // validation document
  const validationDocumentList = useFormik({
    enableReinitialize: true,
    initialValues: documentListDynamicOptionsValue,
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      const formData = new FormData();

      values.documents.forEach((value, index) => {
        if (value?.file?.name) {
          formData.append(`files_${index}`, value.file, value.file.name);
        }
      });

      formData.append("id", id);
      formData.append("type", "client");
      formData.append("documents", JSON.stringify(values.documents));

      // update document
      props.actionCreator({
        type: types.UPDATE_DOCUMENT,
        payload: formData,
        module: "client",
      });
    },
  });

  const toggleHeaderPills = (tab) => {
    if (headerPillsTab !== tab) setHeaderPillsTab(tab);
  };

  const addMoreAssignSupplierHandler = async () => {
    const finalAssignSupplier = validationInformation.values.assign_suppliers;
    finalAssignSupplier.push({
      supplier_id: "",
      business_terms: "",
    });

    setAssignSuppliers(finalAssignSupplier);
  };

  const removeAssignSupplierHandler = (index) => {
    if (assignSuppliers && assignSuppliers.length > 0) {
      setAssignSuppliers((assignSuppliers) =>
        assignSuppliers.filter((_, i) => i !== index)
      );
      if (
        validationInformation.values?.assign_suppliers &&
        validationInformation.values.assign_suppliers.length > 0
      ) {
        validationInformation.setFieldValue(
          `assign_suppliers`,
          validationInformation.values.assign_suppliers.filter(
            (_, i) => i !== index
          )
        );
      }
    }
  };

  const addMoreAddressHandler = () => {
    const finalAddress = validationInformation.values.addresses;
    finalAddress.push({
      building_name: "",
      unit: "",
      postal_code: "",
      address: "",
      city: "",
      country: "",
    });
    setAddresses(finalAddress);
  };

  const removeAddressHandler = (index) => {
    if (addresses && addresses.length > 0) {
      setAddresses((addresses) => addresses.filter((_, i) => i !== index));
      if (
        validationInformation.values?.addresses &&
        validationInformation.values.addresses.length > 0
      ) {
        validationInformation.setFieldValue(
          `addresses`,
          validationInformation.values.addresses.filter((_, i) => i !== index)
        );
      }
    }
  };

  const addMoreNotesHandler = () => {
    const finalNotes = validationInformation.values.notes;
    finalNotes.push({
      note: "",
    });
    setNotes(finalNotes);
  };

  const removeSelectedNotesHandler = (index) => {
    if (notes && notes.length > 0) {
      setNotes((notes) => notes.filter((_, i) => i !== index));
      if (
        validationInformation.values?.notes &&
        validationInformation.values.notes.length > 0
      ) {
        validationInformation.setFieldValue(
          `notes`,
          validationInformation.values.notes.filter((_, i) => i !== index)
        );
      }
    }
  };

  const addMoreContactsHandler = () => {
    const finalContacts = validationContacts.values.contacts;
    finalContacts.push({
      name: "",
      department_id: "",
      position: "",
      email: "",
      username: "",
      active: "",
    });
    setContacts(finalContacts);
  };

  const removeSelectedContactHandler = (index) => {
    if (contacts && contacts.length > 0) {
      setContacts((contact) => contact.filter((_, i) => i !== index));
      if (
        validationContacts.values?.contacts &&
        validationContacts.values.contacts.length > 0
      ) {
        validationContacts.setFieldValue(
          `contacts`,
          validationContacts.values.contacts.filter((_, i) => i !== index)
        );
      }
    }
  };

  const removeSelectedDocumentHandler = (index) => {
    if (documentsCol && documentsCol.length > 0) {
      setDocumentsCol((document) => document.filter((_, i) => i !== index));
      if (
        validationDocumentList.values?.documents &&
        validationDocumentList.values.documents.length > 0
      ) {
        validationDocumentList.setFieldValue(
          `documents`,
          validationDocumentList.values.documents.filter((_, i) => i !== index)
        );
      }
    }
  };

  const handleNotification = async (message) => {
    toast(message, {
      position: "top-right",
      hideProgressBar: true,
      className: "bg-success text-white",
    });

    clearClientSaveMessage();
    clearDocumentSaveMessage();
  };

  const handleErrorNotification = async (message) => {
    toast(message, {
      position: "top-right",
      hideProgressBar: true,
      className: "bg-danger text-white",
    });
  };

  const clearClientSaveMessage = async () => {
    await props.actionCreator({
      type: types.CLEAR_CLIENT_SAVE_MESSAGE,
    });
  };

  const clearDocumentSaveMessage = async () => {
    await props.actionCreator({
      type: types.CLEAR_DOCUMENT_SAVE_MESSAGE,
    });
  };

  React.useEffect(() => {
    clients.isSubmitted &&
      clients.actionReturnData?.message &&
      handleNotification(clients.actionReturnData.message);
    clients.error &&
      clients.errorMessage &&
      handleErrorNotification(clients.errorMessage);
  }, [clients.actionReturnData.message]);

  React.useEffect(() => {
    const edit = location.pathname.includes("/edit");
    setIsEdit(edit);
    if (edit) {
      props.actionCreator({
        type: types.GET_CLIENT_LIST,
        payload: { id: id },
      });

      props.actionCreator({
        type: types.GET_DOCUMENT_LIST,
        payload: {
          type: "client",
          source_id: id,
        },
      });
    }

    props.actionCreator({
      type: types.GET_SUPPLIER_LIST_ALL,
      payload: {},
    });

    props.actionCreator({
      type: types.GET_DEPARTMENT_OPTIONS,
      payload: {
        type: "department",
        client_id: id,
      },
    });

    const selectedClient = {
      client_name: "",
      website: "",
      date_establishment: "",
      contract_start_date: "",
      contract_end_date: "",
      addresses: [],
      notes: [],
      assign_suppliers: [],
      supplier_options: [],
    };
    setDynamicOptionsValue(selectedClient);
    setDynamicOptionsValue2({
      client_name: Yup.string().required("Enter Client Name"),
      assign_suppliers: Yup.array(
        Yup.object({
          supplier_id: Yup.string().required("Select Supplier"),
          business_terms: Yup.string().required("Enter Business Terms"),
        })
      ),
      addresses: Yup.array(
        Yup.object({
          postal_code: Yup.string().required("Enter Postal Code"),
          address: Yup.string().required("Enter Address"),
          city: Yup.string().required("Enter City"),
          country: Yup.string().required("Enter Country"),
        })
      ),
      notes: Yup.array(
        Yup.object({
          note: Yup.string().required("Enter Notes"),
        })
      ),
    });

    const contacts = {
      name: "",
      department_id: "",
      position: "",
      email: "",
      username: "",
      active: [],
    };
    setContactsDynamicOptionsValue(contacts);
    setContactsDynamicOptionsValue2({
      contacts: Yup.array(
        Yup.object({
          name: Yup.string().required("Enter Name"),
          department_id: Yup.string().required("Select Department"),
          email: Yup.string().required("Enter Email"),
          username: Yup.string().required("Enter Username"),
        })
      ),
    });

    setDocumentDynamicOptionsValue({
      document_name: "",
      file: "",
    });
    setDocumentDynamicOptionsValue2({
      document_name: Yup.string().required("Enter Document Name"),
      file: Yup.mixed().required("Select a File"),
    });

    setDocumentListDynamicOptionsValue({
      documents: {
        document_name: "",
        owner_remarks: "",
        pmo_remarks: "",
        file: "",
      },
    });
  }, []);

  React.useEffect(() => {
    if (isEdit) {
      if (clients?.data) {
        let selectedClient = {
          client_name: clients.data?.client_name,
          website: clients.data?.website,
          date_establishment: clients.data?.date_establishment,
          contract_start_date: clients.data?.contract_start_date,
          contract_end_date: clients.data?.contract_end_date,
          addresses: clients.data?.addresses,
          notes: clients.data?.notes,
          assign_suppliers: clients.data?.assign_suppliers,
          contacts: clients.data?.contacts,
        };
        setDynamicOptionsValue(selectedClient);
        setAddresses(selectedClient.addresses);
        setNotes(selectedClient.notes);
        setAssignSuppliers(selectedClient.assign_suppliers);
        setContacts(selectedClient.contacts);
        setDepartmentList(clientfields?.department?.data || []);

        setContactsDynamicOptionsValue({ contacts: selectedClient.contacts });
      }

      const selectedDocuments = {
        documents: document.data,
      };

      setDocumentsCol(document.data);
      setDocumentListDynamicOptionsValue({
        documents: selectedDocuments.documents,
      });
    }
    setSupplierList(suppliers?.data);
  }, [clients, suppliers, clientfields, document, isEdit]);

  React.useEffect(() => {
    document.isSubmitted &&
      document.actionReturnData?.message &&
      handleNotification(document.actionReturnData.message);
    document.error &&
      document.errorMessage &&
      handleErrorNotification(document.errorMessage);

    if (isEdit) {
      setDocumentsCol(document.data);
      setDocumentListDynamicOptionsValue({ documents: document.data });
    }
  }, [document.actionReturnData]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleClientDocumentClick = (arg) => {
    const suppliers = arg;
    setSelectedSupplier({
      id: suppliers.id,
      supplier_name: suppliers.supplier_name,
      uen_no: suppliers.uen_no,
    });
    setIsEdit(true);
    toggle();
  };

  var node = React.useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete suppliers modal
  const [deleteModal, setDeleteModal] = React.useState(false);

  const onClickDelete = (suppliers) => {
    setSelectedSupplier(suppliers);
    setDeleteModal(true);
  };

  const formatDate = (date) => {
    return date ? moment(date).format("YYYY-MM-DD") : "";
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            titleLink="/clients"
            title="Clients"
            breadcrumbItem={breadcrumbItem}
          />
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header align-items-center d-flex">
                <div className="flex-shrink-0">
                  <ul
                    className="nav justify-content-end nav-pills card-header-pills"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({
                          active: headerPillsTab === "1",
                        })}
                        onClick={() => {
                          toggleHeaderPills("1");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          Client Information
                        </span>
                      </NavLink>
                    </NavItem>
                    {isEdit && (
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: headerPillsTab === "2",
                          })}
                          onClick={() => {
                            toggleHeaderPills("2");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-user"></i>
                          </span>
                          <span className="d-none d-sm-block">
                            Contacts/Hiring Managers
                          </span>
                        </NavLink>
                      </NavItem>
                    )}
                    {isEdit && (
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: headerPillsTab === "3",
                          })}
                          onClick={() => {
                            toggleHeaderPills("3");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-user"></i>
                          </span>
                          <span className="d-none d-sm-block">Documents</span>
                        </NavLink>
                      </NavItem>
                    )}
                  </ul>
                </div>
              </div>
              <div className="card-body">
                <TabContent className="text-muted" activeTab={headerPillsTab}>
                  <TabPane tabId="1">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validationInformation.handleSubmit();
                        return false;
                      }}
                    >
                      <Row>
                        <Col xs={12}>
                          <Card>
                            <CardHeader>
                              <h4 className="card-title">Client</h4>
                              <p className="card-title-desc">Explain here.</p>
                            </CardHeader>
                            <CardBody className="p-4">
                              <Row>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Client Name
                                    </Label>
                                    <Input
                                      name="client_name"
                                      type="text"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation?.values
                                          ?.client_name || ""
                                      }
                                      invalid={
                                        validationInformation.touched
                                          .client_name &&
                                        validationInformation.errors.client_name
                                          ? true
                                          : false
                                      }
                                    />
                                    {validationInformation.touched
                                      .client_name &&
                                    validationInformation.errors.client_name ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validationInformation.errors
                                            .client_name
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Website
                                    </Label>
                                    <Input
                                      name="website"
                                      type="text"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation?.values
                                          ?.website || ""
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Date of Establishment
                                    </Label>
                                    <Input
                                      id="date_establishment"
                                      name="date_establishment"
                                      type="date"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation?.values
                                          ?.date_establishment || ""
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Contract Start Date
                                    </Label>
                                    <Input
                                      name="contract_start_date"
                                      type="date"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation?.values
                                          ?.contract_start_date || ""
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Contract End Date
                                    </Label>
                                    <Input
                                      name="contract_end_date"
                                      type="date"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation?.values
                                          ?.contract_end_date || ""
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xs={12}>
                          <Card>
                            <CardHeader>
                              <Row>
                                <Col md={6}>
                                  <div className="mb-3">
                                    <h5 className="card-title">
                                      Assign Suppliers
                                    </h5>
                                    <p className="card-title-desc">
                                      Explain here.
                                    </p>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                    <div>
                                      <Link
                                        to="#"
                                        onClick={addMoreAssignSupplierHandler}
                                      >
                                        <img src={addLogo} alt="Add" />
                                      </Link>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody className="p-4">
                              <Row>
                                <Col xs={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Supplier
                                    </Label>
                                  </div>
                                </Col>
                                <Col xs={8}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Business Terms
                                    </Label>
                                  </div>
                                </Col>
                              </Row>
                              {assignSuppliers && assignSuppliers.length > 0 ? (
                                assignSuppliers.map(
                                  (supplier, supplierIndex) => (
                                    <Row
                                      key={`${supplierIndex}${supplier.supplier_id}`}
                                    >
                                      <Col xs={3}>
                                        <div className="mb-3">
                                          <Input
                                            type="select"
                                            name={`assign_suppliers[${supplierIndex}].supplier_id`}
                                            className="form-select"
                                            multiple={false}
                                            onBlur={
                                              validationInformation.handleBlur
                                            }
                                            value={
                                              validationInformation?.values
                                                ?.assign_suppliers?.[
                                                supplierIndex
                                              ]?.supplier_id || ""
                                            }
                                            invalid={
                                              validationInformation?.touched
                                                ?.assign_suppliers &&
                                              validationInformation?.touched
                                                ?.assign_suppliers?.[
                                                supplierIndex
                                              ] &&
                                              validationInformation?.errors
                                                ?.assign_suppliers &&
                                              validationInformation?.errors
                                                ?.assign_suppliers?.[
                                                supplierIndex
                                              ] &&
                                              validationInformation?.errors
                                                ?.assign_suppliers?.[
                                                supplierIndex
                                              ]?.supplier_id
                                                ? true
                                                : false
                                            }
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              validationInformation.setFieldValue(
                                                `assign_suppliers.${supplierIndex}.supplier_id`,
                                                e.target.options[
                                                  e.target.selectedIndex
                                                ].text || ""
                                              );
                                              validationInformation.setFieldValue(
                                                `assign_suppliers.${supplierIndex}.supplier_id`,
                                                value || ""
                                              );
                                            }}
                                          >
                                            <option value={""}>-select-</option>
                                            {supplierList
                                              .filter(
                                                (supplier) =>
                                                  supplier.supplier_name
                                              )
                                              .map((item, index) => (
                                                <option
                                                  key={index}
                                                  value={item.id}
                                                >
                                                  {item.supplier_name}
                                                </option>
                                              ))}
                                          </Input>

                                          {validationInformation?.errors
                                            ?.assign_suppliers?.[supplierIndex]
                                            ?.supplier_id && (
                                            <FormFeedback type="invalid">
                                              {
                                                validationInformation?.errors
                                                  ?.assign_suppliers[
                                                  supplierIndex
                                                ]?.supplier_id
                                              }
                                            </FormFeedback>
                                          )}
                                        </div>
                                      </Col>
                                      <Col xs={8}>
                                        <div className="mb-3">
                                          <Input
                                            name={`assign_suppliers.${supplierIndex}.business_terms`}
                                            label="business_terms"
                                            type="text"
                                            onChange={(e) => {
                                              validationInformation.handleChange(
                                                e
                                              );
                                            }}
                                            value={
                                              validationInformation.values
                                                ?.assign_suppliers?.[
                                                supplierIndex
                                              ]?.business_terms || ""
                                            }
                                            invalid={
                                              validationInformation.touched
                                                ?.assign_suppliers?.[
                                                supplierIndex
                                              ] &&
                                              validationInformation.errors
                                                ?.assign_suppliers?.[
                                                supplierIndex
                                              ]?.business_terms
                                                ? true
                                                : false
                                            }
                                          />
                                          {validationInformation.errors
                                            ?.assign_suppliers?.[supplierIndex]
                                            ?.business_terms && (
                                            <FormFeedback type="invalid">
                                              {
                                                validationInformation.errors
                                                  .assign_suppliers[
                                                  supplierIndex
                                                ].business_terms
                                              }
                                            </FormFeedback>
                                          )}
                                        </div>
                                      </Col>
                                      <Col xs={1}>
                                        <div className="text-center">
                                          <Link
                                            to="#"
                                            className="text-danger"
                                            onClick={() =>
                                              removeAssignSupplierHandler(
                                                supplierIndex
                                              )
                                            }
                                          >
                                            <i
                                              className="mdi mdi-delete font-size-18"
                                              id="deletetooltip"
                                            ></i>
                                          </Link>
                                        </div>
                                      </Col>
                                    </Row>
                                  )
                                )
                              ) : (
                                <Row>
                                  <Col>
                                    <p className="text-center">
                                      No records found
                                    </p>
                                  </Col>
                                </Row>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xs={12}>
                          <Card>
                            <CardHeader>
                              <Row>
                                <Col md={6}>
                                  <div className="mb-3">
                                    <h5 className="card-title">Address</h5>
                                    <p className="card-title-desc">
                                      Explain here.
                                    </p>
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                    <div>
                                      <Link
                                        to="#"
                                        onClick={addMoreAddressHandler}
                                      >
                                        <img src={addLogo} alt="Add" />
                                      </Link>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody className="p-4">
                              <Row>
                                <Col xs={2}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Building Name
                                    </Label>
                                  </div>
                                </Col>
                                <Col xs={1}>
                                  <div className="mb-3">
                                    <Label className="form-label">Unit</Label>
                                  </div>
                                </Col>
                                <Col xs={1}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Postal Code
                                    </Label>
                                  </div>
                                </Col>
                                <Col xs={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Address
                                    </Label>
                                  </div>
                                </Col>
                                <Col xs={2}>
                                  <div className="mb-3">
                                    <Label className="form-label">City</Label>
                                  </div>
                                </Col>
                                <Col xs={2}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Country
                                    </Label>
                                  </div>
                                </Col>
                              </Row>
                              {addresses && addresses.length > 0 ? (
                                addresses.map((address, addressIndex) => (
                                  <Row key={`${addressIndex}${address.id}`}>
                                    <Col xs={2}>
                                      <div className="mb-3">
                                        <Input
                                          name={`addresses.${addressIndex}.building_name`}
                                          label="building_name"
                                          type="text"
                                          onChange={
                                            validationInformation.handleChange
                                          }
                                          onBlur={
                                            validationInformation.handleBlur
                                          }
                                          value={
                                            validationInformation.values
                                              ?.addresses?.[addressIndex]
                                              ?.building_name || ""
                                          }
                                          invalid={
                                            validationInformation.touched
                                              ?.addresses?.[addressIndex] &&
                                            validationInformation.errors
                                              ?.addresses?.[addressIndex]
                                              ?.building_name
                                              ? true
                                              : false
                                          }
                                        />
                                        {validationInformation.errors
                                          ?.addresses?.[addressIndex]
                                          ?.building_name && (
                                          <FormFeedback type="invalid">
                                            {
                                              validationInformation.errors
                                                .addresses[addressIndex]
                                                .building_name
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={1}>
                                      <div className="mb-3">
                                        <Input
                                          name={`addresses.${addressIndex}.unit`}
                                          label="unit"
                                          type="text"
                                          onChange={
                                            validationInformation.handleChange
                                          }
                                          onBlur={
                                            validationInformation.handleBlur
                                          }
                                          value={
                                            validationInformation.values
                                              ?.addresses?.[addressIndex]
                                              ?.unit || ""
                                          }
                                          invalid={
                                            validationInformation.touched
                                              ?.addresses?.[addressIndex] &&
                                            validationInformation.errors
                                              ?.addresses?.[addressIndex]?.unit
                                              ? true
                                              : false
                                          }
                                        />
                                        {validationInformation.errors
                                          ?.addresses?.[addressIndex]?.unit && (
                                          <FormFeedback type="invalid">
                                            {
                                              validationInformation.errors
                                                .addresses[addressIndex].unit
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={1}>
                                      <div className="mb-3">
                                        <Input
                                          name={`addresses.${addressIndex}.postal_code`}
                                          label="postal_code"
                                          type="text"
                                          onChange={
                                            validationInformation.handleChange
                                          }
                                          onBlur={
                                            validationInformation.handleBlur
                                          }
                                          value={
                                            validationInformation.values
                                              ?.addresses?.[addressIndex]
                                              ?.postal_code || ""
                                          }
                                          invalid={
                                            validationInformation.touched
                                              ?.addresses?.[addressIndex] &&
                                            validationInformation.errors
                                              ?.addresses?.[addressIndex]
                                              ?.postal_code
                                              ? true
                                              : false
                                          }
                                        />
                                        {validationInformation.errors
                                          ?.addresses?.[addressIndex]
                                          ?.postal_code && (
                                          <FormFeedback type="invalid">
                                            {
                                              validationInformation.errors
                                                .addresses[addressIndex]
                                                .postal_code
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={3}>
                                      <div className="mb-3">
                                        <Input
                                          name={`addresses.${addressIndex}.address`}
                                          label="address"
                                          type="text"
                                          onChange={
                                            validationInformation.handleChange
                                          }
                                          onBlur={
                                            validationInformation.handleBlur
                                          }
                                          value={
                                            validationInformation.values
                                              ?.addresses?.[addressIndex]
                                              ?.address || ""
                                          }
                                          invalid={
                                            validationInformation.touched
                                              ?.addresses?.[addressIndex] &&
                                            validationInformation.errors
                                              ?.addresses?.[addressIndex]
                                              ?.address
                                              ? true
                                              : false
                                          }
                                        />
                                        {validationInformation.errors
                                          ?.addresses?.[addressIndex]
                                          ?.address && (
                                          <FormFeedback type="invalid">
                                            {
                                              validationInformation.errors
                                                .addresses[addressIndex].address
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={2}>
                                      <div className="mb-3">
                                        <Input
                                          name={`addresses.${addressIndex}.city`}
                                          label="city"
                                          type="text"
                                          onChange={
                                            validationInformation.handleChange
                                          }
                                          onBlur={
                                            validationInformation.handleBlur
                                          }
                                          value={
                                            validationInformation.values
                                              ?.addresses?.[addressIndex]
                                              ?.city || ""
                                          }
                                          invalid={
                                            validationInformation.touched
                                              ?.addresses?.[addressIndex] &&
                                            validationInformation.errors
                                              ?.addresses?.[addressIndex]?.city
                                              ? true
                                              : false
                                          }
                                        />
                                        {validationInformation.errors
                                          ?.addresses?.[addressIndex]?.city && (
                                          <FormFeedback type="invalid">
                                            {
                                              validationInformation.errors
                                                .addresses[addressIndex].city
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={2}>
                                      <div className="mb-3">
                                        <Input
                                          name={`addresses.${addressIndex}.country`}
                                          label="country"
                                          type="text"
                                          onChange={
                                            validationInformation.handleChange
                                          }
                                          onBlur={
                                            validationInformation.handleBlur
                                          }
                                          value={
                                            validationInformation.values
                                              ?.addresses?.[addressIndex]
                                              ?.country || ""
                                          }
                                          invalid={
                                            validationInformation.touched
                                              ?.addresses?.[addressIndex] &&
                                            validationInformation.errors
                                              ?.addresses?.[addressIndex]
                                              ?.country
                                              ? true
                                              : false
                                          }
                                        />
                                        {validationInformation.errors
                                          ?.addresses?.[addressIndex]
                                          ?.country && (
                                          <FormFeedback type="invalid">
                                            {
                                              validationInformation.errors
                                                .addresses[addressIndex].country
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={1}>
                                      <div className="text-center">
                                        <Link
                                          to="#"
                                          className="text-danger"
                                          onClick={() =>
                                            removeAddressHandler(addressIndex)
                                          }
                                        >
                                          <i
                                            className="mdi mdi-delete font-size-18"
                                            id="deletetooltip"
                                          ></i>
                                        </Link>
                                      </div>
                                    </Col>
                                  </Row>
                                ))
                              ) : (
                                <Row>
                                  <Col>
                                    <p className="text-center">
                                      No records found
                                    </p>
                                  </Col>
                                </Row>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xs={12}>
                          <Card>
                            <CardHeader>
                              <Row>
                                <Col md={6}>
                                  <div className="mb-3">
                                    <h5 className="card-title">Notes</h5>
                                    <p className="card-title-desc">
                                      Explain here.
                                    </p>
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                    <div>
                                      <Link
                                        to="#"
                                        onClick={addMoreNotesHandler}
                                      >
                                        <img src={addLogo} alt="Add" />
                                      </Link>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody className="p-4">
                              {notes && notes.length > 0 ? (
                                notes.map((note, noteIndex) => (
                                  <Row key={`${noteIndex}${note.id}`}>
                                    <Col xs={11}>
                                      <div className="mb-3">
                                        <Input
                                          name={`notes.${noteIndex}.note`}
                                          className={`form-control ${
                                            validationInformation.touched
                                              ?.notes?.[noteIndex]?.note &&
                                            validationInformation.errors
                                              ?.notes?.[noteIndex]?.note
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          label="note"
                                          rows="2"
                                          onChange={
                                            validationInformation.handleChange
                                          }
                                          onBlur={
                                            validationInformation.handleBlur
                                          }
                                          value={
                                            validationInformation.values
                                              ?.notes?.[noteIndex]?.note || ""
                                          }
                                        />
                                        {validationInformation.errors?.notes?.[
                                          noteIndex
                                        ]?.note && (
                                          <FormFeedback type="invalid">
                                            {
                                              validationInformation.errors
                                                .notes[noteIndex].note
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={1}>
                                      <div className="text-center">
                                        <Link
                                          to="#"
                                          className="text-danger"
                                          onClick={() =>
                                            removeSelectedNotesHandler(
                                              noteIndex
                                            )
                                          }
                                        >
                                          <i
                                            className="mdi mdi-delete font-size-18"
                                            id="deletetooltip"
                                          ></i>
                                        </Link>
                                      </div>
                                    </Col>
                                  </Row>
                                ))
                              ) : (
                                <Row>
                                  <Col>
                                    <p className="text-center">
                                      No records found
                                    </p>
                                  </Col>
                                </Row>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary save-user"
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Save
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </TabPane>
                  <TabPane tabId="2">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validationContacts.handleSubmit();
                        return false;
                      }}
                    >
                      <Col xs={12}>
                        <Card>
                          <CardHeader>
                            <Row>
                              <Col md={6}>
                                <div className="mb-3">
                                  <h5 className="card-title">
                                    Contacts/Hiring Managers
                                  </h5>
                                  <p className="card-title-desc">
                                    Explain here.
                                  </p>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                  <div>
                                    <Link
                                      to="#"
                                      onClick={addMoreContactsHandler}
                                    >
                                      <img src={addLogo} alt="Add" />
                                    </Link>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody className="p-4">
                            <Row>
                              <Col xs={2}>
                                <div className="mb-3">
                                  <Label className="form-label">Name</Label>
                                </div>
                              </Col>
                              <Col xs={2}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Department
                                  </Label>
                                </div>
                              </Col>
                              <Col xs={2}>
                                <div className="mb-3">
                                  <Label className="form-label">Position</Label>
                                </div>
                              </Col>
                              <Col xs={2}>
                                <div className="mb-3">
                                  <Label className="form-label">Email</Label>
                                </div>
                              </Col>
                              <Col xs={2}>
                                <div className="mb-3">
                                  <Label className="form-label">Username</Label>
                                </div>
                              </Col>
                              <Col xs={1}>
                                <div className="mb-3">
                                  <Label className="form-label">Access</Label>
                                </div>
                              </Col>
                              <Col xs={1}>&nbsp;</Col>
                            </Row>
                            {contacts && contacts.length > 0 ? (
                              contacts.map((contact, contactIndex) => (
                                <Row key={`${contactIndex}${contact.id}`}>
                                  <Col xs={2}>
                                    <div className="mb-3">
                                      <Input
                                        name={`contacts.${contactIndex}.name`}
                                        className={`form-control ${
                                          validationContacts.touched
                                            ?.contacts?.[contactIndex]?.name &&
                                          validationContacts.errors?.contacts?.[
                                            contactIndex
                                          ]?.name
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        label="name"
                                        rows="2"
                                        onChange={
                                          validationContacts.handleChange
                                        }
                                        onBlur={validationContacts.handleBlur}
                                        value={
                                          validationContacts.values?.contacts?.[
                                            contactIndex
                                          ]?.name || ""
                                        }
                                      />
                                      {validationContacts.errors?.contacts?.[
                                        contactIndex
                                      ]?.name && (
                                        <FormFeedback type="invalid">
                                          {
                                            validationContacts.errors.contacts[
                                              contactIndex
                                            ].name
                                          }
                                        </FormFeedback>
                                      )}
                                    </div>
                                  </Col>
                                  <Col xs={2}>
                                    <div className="mb-3">
                                      <Input
                                        type="select"
                                        name={`contacts.[${contactIndex}].department_id`}
                                        className="form-select"
                                        multiple={false}
                                        onBlur={validationContacts.handleBlur}
                                        value={
                                          validationContacts?.values
                                            ?.contacts?.[contactIndex]
                                            ?.department_id || ""
                                        }
                                        invalid={
                                          validationContacts?.touched
                                            ?.contacts &&
                                          validationContacts?.touched
                                            ?.contacts?.[contactIndex] &&
                                          validationContacts?.errors
                                            ?.contacts &&
                                          validationContacts?.errors
                                            ?.contacts?.[contactIndex] &&
                                          validationContacts?.errors
                                            ?.contacts?.[contactIndex]
                                            ?.department_id
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          validationContacts.setFieldValue(
                                            `contacts.${contactIndex}.department_id`,
                                            e.target.options[
                                              e.target.selectedIndex
                                            ].text || ""
                                          );
                                          validationContacts.setFieldValue(
                                            `contacts.${contactIndex}.department_id`,
                                            value || ""
                                          );
                                        }}
                                      >
                                        <option value={""}>-select-</option>
                                        {departmentList
                                          .filter(
                                            (department) => department.label
                                          )
                                          .map((item, index) => (
                                            <option
                                              key={index}
                                              value={item.value}
                                            >
                                              {item.label}
                                            </option>
                                          ))}
                                      </Input>
                                      {validationContacts.errors?.contacts?.[
                                        contactIndex
                                      ]?.department_id && (
                                        <FormFeedback type="invalid">
                                          {
                                            validationContacts.errors.contacts[
                                              contactIndex
                                            ].department_id
                                          }
                                        </FormFeedback>
                                      )}
                                    </div>
                                  </Col>
                                  <Col xs={2}>
                                    <div className="mb-3">
                                      <Input
                                        name={`contacts.${contactIndex}.position`}
                                        className={`form-control ${
                                          validationContacts.touched
                                            ?.contacts?.[contactIndex]
                                            ?.position &&
                                          validationContacts.errors?.contacts?.[
                                            contactIndex
                                          ]?.position
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        label="Position"
                                        rows="2"
                                        onChange={
                                          validationContacts.handleChange
                                        }
                                        onBlur={validationContacts.handleBlur}
                                        value={
                                          validationContacts.values?.contacts?.[
                                            contactIndex
                                          ]?.position || ""
                                        }
                                      />
                                      {validationContacts.errors?.contacts?.[
                                        contactIndex
                                      ]?.position && (
                                        <FormFeedback type="invalid">
                                          {
                                            validationContacts.errors.contacts[
                                              contactIndex
                                            ].position
                                          }
                                        </FormFeedback>
                                      )}
                                    </div>
                                  </Col>
                                  <Col xs={2}>
                                    <div className="mb-3">
                                      <Input
                                        name={`contacts.${contactIndex}.email`}
                                        className={`form-control ${
                                          validationContacts.touched
                                            ?.contacts?.[contactIndex]?.email &&
                                          validationContacts.errors?.contacts?.[
                                            contactIndex
                                          ]?.email
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        label="Email"
                                        rows="2"
                                        onChange={
                                          validationContacts.handleChange
                                        }
                                        onBlur={validationContacts.handleBlur}
                                        value={
                                          validationContacts.values?.contacts?.[
                                            contactIndex
                                          ]?.email || ""
                                        }
                                      />
                                      {validationContacts.errors?.contacts?.[
                                        contactIndex
                                      ]?.email && (
                                        <FormFeedback type="invalid">
                                          {
                                            validationContacts.errors.contacts[
                                              contactIndex
                                            ].email
                                          }
                                        </FormFeedback>
                                      )}
                                    </div>
                                  </Col>
                                  <Col xs={2}>
                                    <div className="mb-3">
                                      <Input
                                        name={`contacts.${contactIndex}.username`}
                                        className={`form-control ${
                                          validationContacts.touched
                                            ?.contacts?.[contactIndex]
                                            ?.username &&
                                          validationContacts.errors?.contacts?.[
                                            contactIndex
                                          ]?.username
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        label="Username"
                                        rows="2"
                                        onChange={
                                          validationContacts.handleChange
                                        }
                                        onBlur={validationContacts.handleBlur}
                                        value={
                                          validationContacts.values?.contacts?.[
                                            contactIndex
                                          ]?.username || ""
                                        }
                                      />
                                      {validationContacts.errors?.contacts?.[
                                        contactIndex
                                      ]?.username && (
                                        <FormFeedback type="invalid">
                                          {
                                            validationContacts.errors.contacts[
                                              contactIndex
                                            ].username
                                          }
                                        </FormFeedback>
                                      )}
                                    </div>
                                  </Col>
                                  <Col xs={1}>
                                    <div className="mb-3">
                                      <div
                                        className="form-check form-switch mb-3"
                                        dir="ltr"
                                      >
                                        <Input
                                          type="checkbox"
                                          name={`contacts.${contactIndex}.status`}
                                          className="form-check-input"
                                          onChange={(e) => {
                                            validationContacts.setFieldValue(
                                              `contacts.${contactIndex}.status`,
                                              e.target.checked ?? 0
                                            );
                                          }}
                                          onBlur={validationContacts.handleBlur}
                                          value={
                                            validationContacts.values
                                              ?.contacts?.[contactIndex]
                                              ?.status || "1"
                                          }
                                          defaultChecked={false}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col xs={1}>
                                    <div className="text-center">
                                      <Link
                                        to="#"
                                        className="text-danger"
                                        onClick={() =>
                                          removeSelectedContactHandler(
                                            contactIndex
                                          )
                                        }
                                      >
                                        <i
                                          className="mdi mdi-delete font-size-18"
                                          id="deletetooltip"
                                        ></i>
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                              ))
                            ) : (
                              <Row>
                                <Col>
                                  <p className="text-center">
                                    No records found
                                  </p>
                                </Col>
                              </Row>
                            )}
                            <Row>
                              <Col className="d-flex justify-content-end">
                                <button
                                  type="submit"
                                  className="btn btn-primary save-user"
                                >
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  Save
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Form>
                  </TabPane>
                  <TabPane tabId="3">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validationDocumentList.handleSubmit();
                        return false;
                      }}
                    >
                      <Col xs={12}>
                        <Card>
                          <CardHeader>
                            <Row>
                              <Col md={6}>
                                <div className="mb-3">
                                  <h5 className="card-title">
                                    Upload Document
                                  </h5>
                                  <p className="card-title-desc">
                                    Explain here.
                                  </p>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                  <div>
                                    <Link
                                      to="#"
                                      onClick={handleClientDocumentClick}
                                    >
                                      <img src={addLogo} alt="Add" />
                                    </Link>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody className="p-4">
                            <Row>
                              <Col xs={2}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Document Name
                                  </Label>
                                </div>
                              </Col>
                              <Col xs={3}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Remarks by Uploader
                                  </Label>
                                </div>
                              </Col>
                              <Col xs={2}>
                                <div className="mb-3">
                                  <Label className="form-label">File</Label>
                                </div>
                              </Col>
                              <Col xs={3}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    PMO Comments
                                  </Label>
                                </div>
                              </Col>
                            </Row>

                            {documentsCol && documentsCol.length > 0 ? (
                              documentsCol.map((document, documentIndex) => (
                                <Row key={`${documentIndex}${document.id}`}>
                                  <Col xs={2}>
                                    <div className="mb-3">
                                      {validationDocumentList.values
                                        ?.documents?.[documentIndex]
                                        ?.document_name || ""}
                                    </div>
                                  </Col>
                                  <Col xs={3}>
                                    <div className="mb-3">
                                      {validationDocumentList.values
                                        ?.documents?.[documentIndex]
                                        ?.owner_remarks || ""}
                                    </div>
                                  </Col>
                                  <Col xs={2}>
                                    <div className="mb-3">
                                      <Card
                                        style={{
                                          marginBottom: 0,
                                          width: "50%",
                                          border: 0,
                                        }}
                                      >
                                        <div className="p-0">
                                          <Row className="align-items-center">
                                            <Col>
                                              {validationDocumentList?.values
                                                ?.documents?.[documentIndex]
                                                ?.file?.path && (
                                                <Link
                                                  to={URL.createObjectURL(
                                                    validationDocumentList
                                                      ?.values?.documents?.[
                                                      documentIndex
                                                    ]?.file
                                                  )}
                                                  className="text-primary justify-content-start"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  <i
                                                    className="mdi mdi-file-document-outline font-size-18"
                                                    id="edittooltip"
                                                  ></i>
                                                </Link>
                                              )}
                                              {!validationDocumentList?.values
                                                ?.documents?.[documentIndex]
                                                ?.file?.path && (
                                                <Link
                                                  to={`${baseUrl}${validationDocumentList?.values?.documents?.[documentIndex]?.new_file}`}
                                                  className="text-primary justify-content-start"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  <i
                                                    className="mdi mdi-file-document-outline font-size-18"
                                                    id="edittooltip"
                                                  ></i>
                                                </Link>
                                              )}
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                    </div>
                                  </Col>
                                  <Col xs={3}>
                                    <div className="mb-3">
                                      <Input
                                        name={`documents.${documentIndex}.pmo_remarks`}
                                        className={`form-control ${
                                          validationDocumentList.touched
                                            ?.documents?.[documentIndex]
                                            ?.pmo_remarks &&
                                          validationDocumentList.errors
                                            ?.documents?.[documentIndex]
                                            ?.pmo_remarks
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        label="PMO Remarks"
                                        rows="2"
                                        onChange={
                                          validationDocumentList.handleChange
                                        }
                                        onBlur={
                                          validationDocumentList.handleBlur
                                        }
                                        value={
                                          validationDocumentList.values
                                            ?.documents?.[documentIndex]
                                            ?.pmo_remarks || ""
                                        }
                                      />
                                      {validationDocumentList.errors
                                        ?.documents?.[documentIndex]
                                        ?.pmo_remarks && (
                                        <FormFeedback type="invalid">
                                          {
                                            validationDocumentList.errors
                                              ?.documents?.[documentIndex]
                                              .pmo_remarks
                                          }
                                        </FormFeedback>
                                      )}
                                    </div>
                                  </Col>
                                  <Col xs={1}>
                                    <div className="text-center">
                                      <Link
                                        to="#"
                                        className="text-danger"
                                        onClick={() =>
                                          removeSelectedDocumentHandler(
                                            documentIndex
                                          )
                                        }
                                      >
                                        <i
                                          className="mdi mdi-delete font-size-18"
                                          id="deletetooltip"
                                        ></i>
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                              ))
                            ) : (
                              <Row>
                                <Col>
                                  <p className="text-center">
                                    No records found
                                  </p>
                                </Col>
                              </Row>
                            )}
                          </CardBody>
                        </Card>
                        <Row>
                          <Col className="d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn btn-primary save-user"
                            >
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              Save
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    </Form>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Upload Document
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validationDocument.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label className="form-label">Document Name</Label>
                      <Input
                        name="document_name"
                        type="text"
                        onChange={validationDocument.handleChange}
                        onBlur={validationDocument.handleBlur}
                        value={validationDocument.values.document_name || ""}
                        invalid={
                          validationDocument.touched.document_name &&
                          validationDocument.errors.document_name
                            ? true
                            : false
                        }
                      />
                      {validationDocument.touched.document_name &&
                      validationDocument.errors.document_name ? (
                        <FormFeedback type="invalid">
                          {validationDocument.errors.document_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label className="form-label">Remarks</Label>
                      <Input
                        name="owner_remarks"
                        type="text"
                        onChange={validationDocument.handleChange}
                        onBlur={validationDocument.handleBlur}
                        value={validationDocument.values.owner_remarks || ""}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Dropzone
                      onDrop={(acceptedFiles, fileRejections) => {
                        setSelectedFiles(acceptedFiles?.[0] || "");
                        setErrorMessages(
                          fileRejections.map(
                            ({ file, errors }) =>
                              `${file.name}: ${errors.map((e) => e.message).join(", ")} `
                          )
                        );
                        validationDocument.setFieldValue(
                          "file",
                          acceptedFiles?.[0] || ""
                        );
                      }}
                      className="error"
                      accept={{
                        "application/pdf": [".pdf"],
                      }}
                      multiple={false} // Disable multiple file selection
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bx-cloud-upload" />
                            </div>
                            <h4>Drop or click to attach a pdf file.</h4>
                            {errorMessages.length > 0 && (
                              <div
                                className="error-messages"
                                style={{
                                  fontSize: 12,
                                }}
                              >
                                {errorMessages.map((message, index) => (
                                  <p key={index} className="text-danger">
                                    {message}
                                  </p>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    {validationDocument.errors.file && (
                      <p className="text-danger">
                        {validationDocument.errors.file}
                      </p>
                    )}
                  </Col>
                </Row>
                {selectedFiles?.name && (
                  <Row>
                    <Col>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Card
                          style={{
                            marginLeft: 5,
                            marginRight: 5,
                            width: "50%",
                          }}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col
                                className="col-auto text-primary"
                                style={{
                                  marginBottom: 10,
                                }}
                              >
                                <i
                                  className="mdi mdi-file-document-outline font-size-30"
                                  id="edittooltip"
                                ></i>
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {selectedFiles.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{selectedFiles.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs={12}>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-primary save-user"
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp; Save &nbsp;&nbsp;&nbsp;&nbsp;
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

const mapStateToProps = ({
  app,
  clients,
  suppliers,
  clientfields,
  document,
}) => ({
  app,
  clients,
  suppliers,
  clientfields,
  document,
});

export default connect(mapStateToProps, { actionCreator })(ClientsList);
