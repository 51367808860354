import { call, put, takeLatest, all, fork } from "redux-saga/effects";

import {
  GET_DEPARTMENT_OPTIONS,
  GET_DEPARTMENT_OPTIONS_SUCCESS,
  GET_DEPARTMENT_OPTIONS_FAILED,
  GET_COST_CENTER_OPTIONS,
  GET_COST_CENTER_OPTIONS_SUCCESS,
  GET_COST_CENTER_OPTIONS_FAILED,
  GET_CLIENTS,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAILED,
  GET_CLIENT_SUPPLIERS,
  GET_CLIENT_SUPPLIERS_SUCCESS,
  GET_CLIENT_SUPPLIERS_FAILED,
  GET_CURRENCY_OPTIONS,
  GET_CURRENCY_OPTIONS_SUCCESS,
  GET_CURRENCY_OPTIONS_FAILED,
  GET_COST_TRACKING_OPTIONS,
  GET_COST_TRACKING_OPTIONS_SUCCESS,
  GET_COST_TRACKING_OPTIONS_FAILED,
} from "./types";

import clientFieldsServices from "../../api/services/client-fields";

function* fnGetDepartmentOptions({ payload }) {
  try {
    const data = yield call(
      clientFieldsServices.api.fnGetFieldsOptions,
      payload
    );
    yield put({
      type: GET_DEPARTMENT_OPTIONS_SUCCESS,
      payload: [...data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_DEPARTMENT_OPTIONS_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetCostCenterOptions({ payload }) {
  try {
    const data = yield call(
      clientFieldsServices.api.fnGetFieldsOptions,
      payload
    );
    yield put({
      type: GET_COST_CENTER_OPTIONS_SUCCESS,
      payload: [...data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_COST_CENTER_OPTIONS_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetClients({ payload }) {
  try {
    const data = yield call(clientFieldsServices.api.fnGetClients, payload);
    yield put({
      type: GET_CLIENTS_SUCCESS,
      payload: [...data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_CLIENTS_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetClientSuppliers({ payload }) {
  try {
    const data = yield call(
      clientFieldsServices.api.fnGetClientSuppliers,
      payload
    );
    yield put({
      type: GET_CLIENT_SUPPLIERS_SUCCESS,
      payload: [...data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_CLIENT_SUPPLIERS_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetCurrencyOptions({ payload }) {
  try {
    const data = yield call(
      clientFieldsServices.api.fnGetFieldsOptions,
      payload
    );
    yield put({
      type: GET_CURRENCY_OPTIONS_SUCCESS,
      payload: [...data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_CURRENCY_OPTIONS_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetCostTrackingOptions({ payload }) {
  try {
    const data = yield call(
      clientFieldsServices.api.fnGetFieldsOptions,
      payload
    );
    yield put({
      type: GET_COST_TRACKING_OPTIONS_SUCCESS,
      payload: [...data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_COST_TRACKING_OPTIONS_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

export function* watchGetSerialLogsData() {
  yield takeLatest(GET_DEPARTMENT_OPTIONS, fnGetDepartmentOptions);
  yield takeLatest(GET_COST_CENTER_OPTIONS, fnGetCostCenterOptions);
  yield takeLatest(GET_CLIENTS, fnGetClients);
  yield takeLatest(GET_CLIENT_SUPPLIERS, fnGetClientSuppliers);
  yield takeLatest(GET_CURRENCY_OPTIONS, fnGetCurrencyOptions);
  yield takeLatest(GET_COST_TRACKING_OPTIONS, fnGetCostTrackingOptions);
}

function* clientFieldsSaga() {
  yield all([fork(watchGetSerialLogsData)]);
}

export default clientFieldsSaga;
