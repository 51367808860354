import React from "react";
import { Link } from "react-router-dom";
import { map } from "lodash";
import bxFile from "../../../assets/images/bx-file.png";

import { candidateStatusColors } from "../../../helpers/utility_helper";

const baseUrl = process.env.REACT_APP_BASE_URL;

const Tags = (cell) => {
  const status = candidateStatusColors.find(
    (item) => item.id === cell.row.original.status_id
  );

  return (
    <React.Fragment>
      <Link
        to={`/job-order/candidate/${cell.row.original.id}`}
        className={`badge text-white font-size-11 m-1`}
        style={{
          padding: 5,
          backgroundColor: status.color || "none",
        }}
      >
        {cell.row.original.status_name}
      </Link>
    </React.Fragment>
  );
};

const ResumeIcons = (cell) => {
  return (
    <React.Fragment>
      {map(cell.value, (item, index) => (
        <a
          key={index}
          href={`${baseUrl}${item.new}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-muted font-weight-bold"
        >
          <img
            data-dz-thumbnail=""
            height="80"
            className="avatar-sm rounded"
            alt={item.orig}
            src={bxFile}
          />
        </a>
      ))}
    </React.Fragment>
  );
};

const Navigate = (cell) => {
  return (
    <React.Fragment>
      <Link
        to={`/job-order/candidate/${cell.row.original.id}`}
        style={{
          fontWeight: 700,
          fontFamily: "IBM Plex Sans",
        }}
      >
        {cell.row.original.candidate_name}
      </Link>
    </React.Fragment>
  );
};

const Name = (cell) => {
  return cell.value ? cell.value : "";
};

export { Name, Navigate, Tags, ResumeIcons };
