import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TableContainer from '../../components/Common/TableContainer';
import { actionCreator, types } from '../../store';
import { useParams, useNavigate } from 'react-router-dom';

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import { ID, ClientName, Status, NoSuppliers, NoWorkers } from './column';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const ClientsList = ({ app, clients, ...props }) => {
  document.title = 'Clients List';

  const [clientList, setClientList] = React.useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'client_id',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const clientData = cellProps.row.original;
          return (
            <>
              <Link
                to={`/clients/edit/${clientData.client_id}`}
                style={{
                  fontWeight: 700,
                  fontFamily: 'IBM Plex Sans',
                }}
              >
                <ID {...cellProps} />
              </Link>{' '}
            </>
          );
        },
      },
      {
        Header: 'Client Name',
        accessor: 'client_name',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const clientData = cellProps.row.original;
          return (
            <>
              <Link
                to={`/clients/edit/${clientData.client_id}`}
                style={{
                  fontWeight: 700,
                  fontFamily: 'IBM Plex Sans',
                }}
              >
                <ClientName {...cellProps} />{' '}
              </Link>{' '}
            </>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Status {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'No. of Suppliers',
        accessor: 'no_suppliers',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <NoSuppliers {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'No. of Workers',
        accessor: 'no_workers',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <NoWorkers {...cellProps} />{' '}
            </>
          );
        },
      },
    ],
    [],
  );

  React.useEffect(() => {
    setClientList(clients?.listData || []);
    // props.actionCreator({
    //   type: types.GET_CLIENT_LIST_ALL,
    // });
  }, [clients]);

  React.useEffect(() => {
    props.actionCreator({
      type: types.GET_CLIENT_LIST_ALL,
    });
  }, []);

  var node = React.useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Dashboard"
            titleLink="/dashboard"
            breadcrumbItem="Clients"
          />
          <Row>
            <Col lg="12">
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="mb-3">
                    <h5 className="card-title">
                      Clients List{' '}
                      <span className="text-muted fw-normal ms-2">
                        ({clientList?.length})
                      </span>
                    </h5>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                    <div>
                      <Link to={`/clients/add`} className="btn btn-primary">
                        <i className="bx bx-plus me-1"></i> Add Client
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <TableContainer
                    columns={columns}
                    data={clientList || []}
                    isGlobalFilter={true}
                    customPageSize={10}
                    className="table align-middle datatable dt-responsive table-check nowrap"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ app, clients }) => ({
  app,
  clients,
});

export default connect(mapStateToProps, { actionCreator })(ClientsList);
