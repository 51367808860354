import React from "react";

import {
  Row,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

import defaultAvatar from "../../../../assets/images/users/user-dummy-img.jpg";

const ScheduleInterview = ({
  addMoreProposeTimeHandler,
  candidateData,
  filterClientContacts,
  handleSearchInterviewer,
  handleCheckboxChange,
  proposeInterviewTime,
  removeSelectedProposeTimeHandler,
  scheduleInterviewModal,
  setProposeTimeHandler,
  toggleScheduleInterviewModal,
  validation,
}) => {
  return (
    <React.Fragment>
      <Modal
        isOpen={scheduleInterviewModal}
        toggle={toggleScheduleInterviewModal}
        style={{ maxWidth: "964px", width: "100%" }}
      >
        <ModalHeader toggle={toggleScheduleInterviewModal} tag="h4">
          Schedule Interview
        </ModalHeader>
        <ModalBody style={{ margin: 15 }}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col lg={4}>
                <div className="mb-3">
                  <Label className="form-label">Candidate</Label>
                  <Input
                    type="select"
                    name="jo_candidate_id"
                    className="form-select"
                    multiple={false}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.jo_candidate_id || "0"}
                    invalid={
                      validation.touched.jo_candidate_id &&
                      validation.errors.jo_candidate_id
                        ? true
                        : false
                    }
                  >
                    <option
                      value={candidateData.length > 0 && candidateData[0].id}
                    >
                      {candidateData.length > 0 &&
                        candidateData[0].candidate_name}
                    </option>
                  </Input>
                  {validation.touched.jo_candidate_id &&
                  validation.errors.jo_candidate_id ? (
                    <FormFeedback type="invalid">
                      {validation.errors.jo_candidate_id}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                  <Label className="form-label">Interviewer(s)</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    onChange={handleSearchInterviewer}
                    style={{
                      fontStyle: "italic",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      minHeight: 100,
                      alignItems: "center",
                      border: validation.errors.interviewers
                        ? "1px solid #fd625e"
                        : "none",
                    }}
                  >
                    {filterClientContacts.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginRight: 40,
                        }}
                      >
                        <Input
                          type="checkbox"
                          name="interviewers"
                          style={{
                            marginRight: 10,
                            marginTop: 10,
                            borderColor: "#ADB5BD",
                            borderRadius: 4,
                            height: 18,
                            width: 18,
                          }}
                          value={item.client_contact_id}
                          checked={validation.values.interviewers.includes(
                            parseInt(item.client_contact_id)
                          )}
                          onChange={handleCheckboxChange}
                        />
                        <img
                          className="rounded-circle"
                          style={{
                            marginRight: 10,
                            backgroundColor: "none",
                            height: 36,
                            width: 36,
                          }}
                          src={defaultAvatar}
                          alt="Header Avatar"
                        />
                        <Label
                          style={{
                            marginTop: 10,
                          }}
                        >
                          {" "}
                          {item.client_contact_name}{" "}
                        </Label>
                      </div>
                    ))}
                  </div>
                  {validation.errors.interviewers ? (
                    <p
                      style={{
                        display: "block",
                        width: "100%",
                        fontSize: 12,
                        marginTop: "0.25rem",
                        color: "#fd625e",
                      }}
                    >
                      {validation.errors.interviewers}
                    </p>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                  <Label className="form-label">
                    <span>Proposed Interview Time</span>
                    <button
                      type="button"
                      className="btn btn-primary btn-rounded waves-effect waves-light"
                      style={{
                        marginLeft: 10,
                        width: 40,
                      }}
                      onClick={() => {
                        addMoreProposeTimeHandler();
                      }}
                    >
                      +
                    </button>
                  </Label>

                  <Row
                    style={{
                      marginTop: 20,
                    }}
                  >
                    <Col lg={3}>
                      <div className="mb-3">
                        <Label className="form-label">Date</Label>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <Label className="form-label">Start Time (HH:MM)</Label>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <Label className="form-label">Duration</Label>
                      </div>
                    </Col>
                    <Col lg={3} />
                  </Row>
                  {proposeInterviewTime.map(
                    (proposeTimeItem, proposeTimeIndex) => (
                      <Row key={`${proposeTimeIndex}${proposeTimeItem.id}`}>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Input
                              type="date"
                              name={`interview_schedules[${proposeTimeIndex}].date`}
                              format="YYYY-MM-DD"
                              value={
                                validation?.values?.interview_schedules[
                                  proposeTimeIndex
                                ]?.date || ""
                              }
                              onChange={(e) => {
                                setProposeTimeHandler(
                                  proposeTimeIndex,
                                  e.target.value
                                );
                                validation.handleChange(e);
                              }}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.errors.interview_schedules &&
                                validation.errors.interview_schedules[
                                  proposeTimeIndex
                                ] &&
                                validation.errors.interview_schedules[
                                  proposeTimeIndex
                                ].date
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Input
                              type="time"
                              name={`interview_schedules[${proposeTimeIndex}].startTime`}
                              value={
                                validation?.values?.interview_schedules[
                                  proposeTimeIndex
                                ]?.startTime || ""
                              }
                              onChange={(e) => {
                                setProposeTimeHandler(
                                  proposeTimeIndex,
                                  null,
                                  e.target.value
                                );
                                validation.handleChange(e);
                              }}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.errors.interview_schedules &&
                                validation.errors.interview_schedules[
                                  proposeTimeIndex
                                ] &&
                                validation.errors.interview_schedules[
                                  proposeTimeIndex
                                ].startTime
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              name={`interview_schedules[${proposeTimeIndex}].duration`}
                              value={
                                validation?.values?.interview_schedules[
                                  proposeTimeIndex
                                ]?.duration || ""
                              }
                              onChange={(e) => {
                                setProposeTimeHandler(
                                  proposeTimeIndex,
                                  null,
                                  null,
                                  e.target.value
                                );
                                validation.handleChange(e);
                              }}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.errors.interview_schedules &&
                                validation.errors.interview_schedules[
                                  proposeTimeIndex
                                ] &&
                                validation.errors.interview_schedules[
                                  proposeTimeIndex
                                ].duration
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </Col>
                        <Col xs={3}>
                          <div className="text-start">
                            <i
                              className="mdi mdi-delete font-size-18"
                              style={{
                                fontSize: 35,
                                color: "#FD625E",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                removeSelectedProposeTimeHandler(
                                  proposeTimeIndex
                                )
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                    )
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="mb-3 mt-5">
                  <Label className="form-label">Subject</Label>
                  <Input
                    type="text"
                    name="subject"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.subject || ""}
                    invalid={
                      validation.touched.subject && validation.errors.subject
                        ? true
                        : false
                    }
                  />
                  {validation.touched.subject && validation.errors.subject ? (
                    <FormFeedback type="invalid">
                      {validation.errors.subject}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="mb-3 mt-4">
                  <Label className="form-label">Other Details</Label>
                  <Input
                    type="textarea"
                    name="details"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.details || ""}
                    invalid={
                      validation.touched.details && validation.errors.details
                        ? true
                        : false
                    }
                    style={{
                      height: 200,
                    }}
                  />
                  {validation.touched.details && validation.errors.details ? (
                    <FormFeedback type="invalid">
                      {validation.errors.details}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row
              style={{
                marginTop: 40,
              }}
            >
              <Col
                lg={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-info text-white"
                    style={{
                      backgroundColor: "#5156BE",
                      width: 142,
                    }}
                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ScheduleInterview;
