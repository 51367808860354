import React from "react";
import ReactApexChart from "react-apexcharts";

const Spinearea = ({ series, categories, yaxis }) => {
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    colors: ["#5156be", "#2ab57d"],
    xaxis: {
      type: "month",
      categories: categories,
    },
    yaxis: {
      min: yaxis.min,
      max: yaxis.max,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="350"
      className="apex-charts"
    />
  );
};

export default Spinearea;
