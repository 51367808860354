export const GET_APEX_CHARTS_DATA = "@dashboard/GET_APEX_CHARTS_DATA";
export const GET_APEX_CHARTS_DATA_SUCCESS =
  "@dashboard/GET_APEX_CHARTS_DATA_SUCCESS";
export const GET_APEX_CHARTS_DATA_FAILED =
  "@dashboard/GET_APEX_CHARTS_DATA_FAILED";

export const GET_PIE_CHARTS_DATA = "@dashboard/GET_PIE_CHARTS_DATA";
export const GET_PIE_CHARTS_DATA_SUCCESS =
  "@dashboard/GET_PIE_CHARTS_DATA_SUCCESS";
export const GET_PIE_CHARTS_DATA_FAILED =
  "@dashboard/GET_PIE_CHARTS_DATA_FAILED";

export const GET_BAR_CHARTS_DATA = "@dashboard/GET_BAR_CHARTS_DATA";
export const GET_BAR_CHARTS_DATA_SUCCESS =
  "@dashboard/GET_BAR_CHARTS_DATA_SUCCESS";
export const GET_BAR_CHARTS_DATA_FAILED =
  "@dashboard/GET_BAR_CHARTS_DATA_FAILED";

export const GET_TOP_CHARTS_DATA = "@dashboard/GET_TOP_CHARTS_DATA";
export const GET_TOP_CHARTS_DATA_SUCCESS =
  "@dashboard/GET_TOP_CHARTS_DATA_SUCCESS";
export const GET_TOP_CHARTS_DATA_FAILED =
  "@dashboard/GET_TOP_CHARTS_DATA_FAILED";

export const GET_RECENT_ACTIVITY_DATA = "@dashboard/GET_RECENT_ACTIVITY_DATA";
export const GET_RECENT_ACTIVITY_DATA_SUCCESS =
  "@dashboard/GET_RECENT_ACTIVITY_DATA_SUCCESS";
export const GET_RECENT_ACTIVITY_DATA_FAILED =
  "@dashboard/GET_RECENT_ACTIVITY_DATA_FAILED";
