import { emsApi } from "..";
import { CLIENT_SERVICE } from "../constants";

class ClientFields {
  async fnGetFieldsOptions(payload) {
    return await emsApi.post(CLIENT_SERVICE.GET_FIELD_OPTIONS, payload);
  }

  async fnGetClients() {
    return await emsApi.get(CLIENT_SERVICE.GET_CLIENT_LIST);
  }

  async fnGetClientSuppliers(payload) {
    return await emsApi.get(
      `${CLIENT_SERVICE.GET_CLIENT_SUPPLIER_LIST}/${payload.client_id}`
    );
  }
}

ClientFields.api = new ClientFields();
export default ClientFields;
