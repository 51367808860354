import { Button } from "reactstrap";

const PagingControl = ({ totalPages, pageNum, setPageNum }) => {
  const styles = {
    container: {
      marginTop: 8,
      marginBottom: 8,
    },
    inlineFlex: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    pageInfo: {
      padding: 8,
      color: "hsl(218,49%,66%)",
      fontSize: 14,
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.inlineFlex}>
        <Button
          onClick={() => setPageNum(pageNum - 1)}
          disabled={pageNum - 1 === -1}
        >
          {"Previous"}
        </Button>
        <div style={styles.pageInfo}>
          Page: {pageNum + 1}/{totalPages}
        </div>
        <Button
          onClick={() => setPageNum(pageNum + 1)}
          disabled={pageNum + 1 > totalPages - 1}
        >
          {"Next"}
        </Button>
      </div>
    </div>
  );
};

export default PagingControl;
