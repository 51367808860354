import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import WorkerPieChart from "./WorkerPieChart";

const WorkersCard = ({ title, elements, colors, percentage }) => {
  return (
    <React.Fragment>
      <Col xl={6} lg={12} md={12} sm={12}>
        <Card className="card-h-100">
          <CardBody>
            <div className="d-flex flex-wrap align-items-center mb-4">
              <h5 className="card-title me-2">{title}</h5>
            </div>
            <Row className="align-items-center">
              <div className="col-sm">
                <div id="wallet-balance" className="apex-charts">
                  <WorkerPieChart workerData={percentage} elements={elements} />
                </div>
              </div>
              <div className="col-sm align-self-center">
                <div className="mt-4 mt-sm-0">
                  {elements.map((item, index) => (
                    <div key={index}>
                      <p className="mb-2">
                        <i
                          className={`mdi mdi-circle align-middle font-size-10 me-2 ${colors[index]}`}
                        ></i>{" "}
                        {item}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default WorkersCard;
