import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TableContainer from '../../components/Common/TableContainer';
import { actionCreator, types } from '../../store';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import {
  ID,
  SupplierName,
  UENNo,
  Client,
  Department,
  Manager,
  Workers,
  Score,
  Status,
  Date,
  Name,
} from './column';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';

const SuppilersList = ({ app, suppliers, ...props }) => {
  document.title = 'Suppliers List | MPS';
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedSuppiler, setSelectedSupplier] = React.useState({});
  const [modal, setModal] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      serial_no: (selectedSuppiler && selectedSuppiler.serial_no) || '',
      nsn: (selectedSuppiler && selectedSuppiler.nsn) || '',
      name: (selectedSuppiler && selectedSuppiler.name) || '',
      expiry_date: (selectedSuppiler && selectedSuppiler.expiry_date) || '',
      status: (selectedSuppiler && selectedSuppiler.status) || '',
    },
    validationSchema: Yup.object({
      serial_no: Yup.string().required('Please enter Serial No.'),
      nsn: Yup.string().required('Please enter NSN'),
      name: Yup.string().required('Please enter Tool Name'),
      expiry_date: Yup.string().required('Please enter Expiry Date'),
      status: Yup.string().required('Please select Status'),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateSupplier = {
          id: selectedSuppiler.id,
          serial_no: values.serial_no,
          nsn: values.nsn,
          name: values.name,
          expiry_date: values.expiry_date,
          status: values.status,
        };

        // update suppliers
        props.actionCreator({
          type: types.UPDATE_SUPPLIER,
          payload: updateSupplier,
        });
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newSupplier = {
          serial_no: values.serial_no,
          nsn: values.nsn,
          name: values.name,
          expiry_date: values.expiry_date,
          status: values.status,
        };

        // save new suppliers
        props.actionCreator({
          type: types.ADD_NEW_SUPPLIER,
          payload: newSupplier,
        });
        validation.resetForm();
      }
      toggle();
    },
  });

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const supplierData = cellProps.row.original;
          return (
            <Link
              to={`/suppliers/edit/${supplierData.id}`}
              style={{
                fontWeight: 700,
                fontFamily: 'IBM Plex Sans',
              }}
            >
              <ID {...cellProps} />
            </Link>
          );
        },
      },
      {
        Header: 'Supplier Name',
        accessor: 'supplier_name',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const supplierData = cellProps.row.original;
          return (
            <Link
              to={`/suppliers/edit/${supplierData.id}`}
              style={{
                fontWeight: 700,
                fontFamily: 'IBM Plex Sans',
              }}
            >
              <SupplierName {...cellProps} />
            </Link>
          );
        },
      },
      {
        Header: 'UEN No.',
        accessor: 'uen_no',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <UENNo {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'Date Added',
        accessor: 'date_added',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Date {...cellProps} />{' '}
            </>
          );
        },
      },
    ],
    [],
  );

  React.useEffect(() => {
    props.actionCreator({
      type: types.GET_SUPPLIER_LIST_ALL,
    });
  }, []);

  React.useEffect(() => {
    props.actionCreator({
      type: types.GET_SUPPLIER_LIST_ALL,
    });
  }, [suppliers]);

  const toggle = () => {
    setModal(!modal);
  };

  var node = React.useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete suppliers modal
  const [deleteModal, setDeleteModal] = React.useState(false);

  const onClickDelete = (suppliers) => {
    setSelectedSupplier(suppliers);
    setDeleteModal(true);
  };

  const handleDeleteSupplier = () => {
    props.actionCreator({
      type: types.DELETE_SUPPLIER,
      payload: {
        id: selectedSuppiler.id,
      },
    });
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleSupplierClicks = () => {
    setSelectedSupplier({});
    setIsEdit(false);
    toggle();
  };

  const formatDate = (date) => {
    return date ? moment(date).format('YYYY-MM-DD') : '';
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteSupplier}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Dashboard"
            titleLink="/dashboard"
            breadcrumbItem="Suppliers"
          />
          <Row>
            <Col lg="12">
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="mb-3">
                    <h5 className="card-title">
                      Suppliers List{' '}
                      <span className="text-muted fw-normal ms-2">
                        ({suppliers.data?.length})
                      </span>
                    </h5>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                    <div>
                      <Link to={`/suppliers/add`} className="btn btn-primary">
                        <i className="bx bx-plus me-1"></i> Add New
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <TableContainer
                    columns={columns}
                    data={suppliers.data}
                    isGlobalFilter={true}
                    customPageSize={10}
                    className="table align-middle datatable dt-responsive table-check nowrap"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? 'Edit Gauge' : 'New Gauge'}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Name</Label>
                              <Input
                                name="name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ''}
                                invalid={
                                  validation.touched.name &&
                                  validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                              validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Row></Row>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Serial No.</Label>
                              <Input
                                name="serial_no"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.serial_no || ''}
                                invalid={
                                  validation.touched.serial_no &&
                                  validation.errors.serial_no
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.serial_no &&
                              validation.errors.serial_no ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.serial_no}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">NSN</Label>
                              <Input
                                name="nsn"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.nsn || ''}
                                invalid={
                                  validation.touched.nsn &&
                                  validation.errors.nsn
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.nsn &&
                              validation.errors.nsn ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.nsn}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Expiry Date</Label>
                              <Input
                                type="date"
                                name="expiry_date"
                                className="form-select"
                                multiple={false}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.expiry_date || ''}
                                invalid={
                                  validation.touched.expiry_date &&
                                  validation.errors.expiry_date
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.expiry_date &&
                              validation.errors.expiry_date ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.expiry_date}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Status</Label>
                              <Input
                                type="select"
                                name="status"
                                className="form-select"
                                multiple={false}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.status || '0'}
                                invalid={
                                  validation.touched.status &&
                                  validation.errors.status
                                    ? true
                                    : false
                                }
                              >
                                <option value={''}>Select Status</option>
                                <option value={1}>Active</option>
                                <option value={2}>In-Active</option>
                              </Input>
                              {validation.touched.status &&
                              validation.errors.status ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.status}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ app, suppliers }) => ({
  app,
  suppliers,
});

export default connect(mapStateToProps, { actionCreator })(SuppilersList);
