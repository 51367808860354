import React from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = ({ series, categories, yaxis }) => {
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      labels: {
        formatter: function (value) {
          return yaxis.currency ? `$${value}` : value;
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#2ab57d"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      min: yaxis.min,
      max: yaxis.max,
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height="350"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default BarChart;
