import React from "react";
import { Row, Col } from "reactstrap";

const StatusTrail = ({ candidateData, defaultCandidateStatus }) => {
  return (
    <React.Fragment>
      <Row
        style={{
          margin: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Col
          lg={12}
          md={12}
          sm={12}
          style={{
            display: "flex",
            position: "relative",
            flexDirection: "row",
            width: 500,
          }}
        >
          {candidateData.length > 0 && (
            <div
              style={{
                border: "none",
                borderWidth: "0px",
                borderStyle: "solid",
                borderColor: "transparent",
                backgroundImage:
                  "repeating-linear-gradient(90deg, #000000 0%, #000000 1px, transparent 1px, transparent 4px)",
                backgroundClip: "padding-box",
                padding: "1px",
                borderRadius: "4px",
                position: "absolute",
                top: 28,
                left: "48%",
                transform: "translateX(-50%)",
                width: "100%",
                maxWidth: 350,
                zIndex: 1,
              }}
            ></div>
          )}

          {candidateData.length > 0 &&
            defaultCandidateStatus.map((item, index) => (
              <div
                key={index}
                style={{
                  zIndex: 2,
                  textAlign: "center",
                  margin: "10px",
                  maxWidth: 100,
                  boxSizing: "border-box",
                }}
              >
                <i
                  className="mdi mdi-check-circle"
                  style={{
                    fontSize: 25,
                    color:
                      candidateData &&
                      candidateData[0].statuses.includes(item.id)
                        ? "#2AB57D"
                        : "#74788D",
                    backgroundColor: "#ffffff",
                  }}
                ></i>
                <p
                  style={{
                    fontWeight:
                      candidateData &&
                      candidateData[0].statuses.includes(item.id)
                        ? 700
                        : 400,
                  }}
                >
                  {item.status}
                </p>
              </div>
            ))}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default StatusTrail;
