import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Input } from 'reactstrap';
import { actionCreator, types } from '../../store';
import TableContainer from '../../components/Common/TableContainer';
import Breadcrumb from '../../components/Common/Breadcrumb';
import { actions } from './data';
import { Name, Tags, Request } from './column';
import { ToastContainer, toast } from 'react-toastify';

const Requisitions = ({ requisition, ...props }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Request No.',
        accessor: 'id',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Request {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'Date Requested',
        accessor: 'date_added_str',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Name {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'Request Type',
        accessor: 'request_type_name',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Name {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'Status',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Tags {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'Requested By',
        accessor: 'requested_by',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Name {...cellProps} />{' '}
            </>
          );
        },
      },
    ],
    [],
  );

  React.useEffect(() => {
    if (requisition.messages) {
      toast(requisition.messages, {
        position: 'top-right',
        hideProgressBar: true,
        className: 'bg-success text-white',
      });
      clearMessages();
    }
  }, [requisition]);

  React.useEffect(() => {
    fetchRequitions();
  }, []);

  const fetchRequitions = async () => {
    await props.actionCreator({
      type: types.GET_REQUEST_LIST,
      payload: {
        date_added: null,
      },
    });
  };

  const clearMessages = async () => {
    await props.actionCreator({
      type: types.CLEAR_MESSAGES,
    });
  };

  //meta title
  document.title = 'Requisitions';
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Dashboard"
            titleLink="/dashboard"
            breadcrumbItem="Requisitions"
          />
          <Row>
            <Col lg={12}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                {actions.map((item) => (
                  <Link
                    key={item.id}
                    to={`${item.url}/${item.id}`}
                    style={{
                      border: '#5156BE 1px solid',
                      display: 'flex',
                      flexDirection: 'row',
                      width: 280,
                      borderRadius: 4,
                      padding: 15,
                      justifyContent: 'space-between',
                      margin: 10,
                    }}
                  >
                    <div
                      style={{
                        marginRight: 10,
                      }}
                    >
                      <h5
                        style={{
                          fontWeight: 600,
                          color: '#5156BE',
                        }}
                      >
                        {item.title}
                      </h5>
                      <p
                        style={{
                          color: '#74788D',
                        }}
                      >
                        {item.desription}
                      </p>
                    </div>
                    <div>
                      <img
                        src={item.icon}
                        style={{
                          width: 70,
                          height: 70,
                        }}
                      />
                    </div>
                  </Link>
                ))}
              </div>
            </Col>
          </Row>
          <Row
            className="align-items-center"
            style={{
              border: '#E9E9EF 1px solid',
              borderRadius: 4,
              padding: 20,
              marginTop: 30,
              marginBottom: 30,
            }}
          >
            <Col>
              <div className="mb-3">
                <h5 className="card-title">Activity</h5>
              </div>
            </Col>

            <Col xl={12}>
              <TableContainer
                columns={columns}
                data={requisition.data}
                isGlobalFilter={true}
                customPageSize={10}
                className="table align-middle datatable dt-responsive table-check nowrap"
              />
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ requisition }) => ({
  requisition,
});

export default connect(mapStateToProps, { actionCreator })(Requisitions);
