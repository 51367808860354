import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { actionCreator, types } from '../../store';
import TableContainer from '../../components/Common/TableContainer';
import Breadcrumb from '../../components/Common/Breadcrumb';
import { Name, Navigate } from './column';
import { ToastContainer, toast } from 'react-toastify';

const JobOrder = ({ joborder, clientfields, ...props }) => {
  const { clients, department } = clientfields;
  const [filter, setFilter] = React.useState({
    filterByClient: '',
    filterByDepartment: '',
  });
  let navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Navigate {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'Job Title',
        accessor: 'job_title',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Navigate {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'Client',
        accessor: 'client_name',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Name {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'Department',
        accessor: 'department',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Name {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'No. of Suppliers',
        accessor: 'supllier_count',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Name {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'No. of Positions',
        accessor: 'no_positions',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Name {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'Offers',
        accessor: 'total_offers',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Name {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'Approved by PMO',
        accessor: 'approved_by_pmo',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Name {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'Date Added',
        accessor: 'date_added_str',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Name {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'Actions',
        Cell: (cellProps) => {
          // Access the row data from cellProps
          const jobOrderData = cellProps.row.original;

          return (
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn btn-primary waves-light waves-effect dropdown-toggle"
                tag="div"
              >
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="text-center">
                <DropdownItem
                  onClick={() => {
                    navigate(`/job-order/overview/${jobOrderData.id}`);
                  }}
                >
                  Overview
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    navigate(`/job-order/cv-submission/${jobOrderData.id}`);
                  }}
                >
                  CV Submission
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    [],
  );

  React.useEffect(() => {
    if (joborder.messages) {
      toast(joborder.messages, {
        position: 'top-right',
        hideProgressBar: true,
        className: 'bg-success text-white',
      });
      clearMessages();
    }
  }, [joborder]);

  React.useEffect(() => {
    filter.filterByClient && fetchDepartment();
    fetchJobOrder();
  }, [filter]);

  React.useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    await props.actionCreator({
      type: types.GET_CLIENTS,
    });
  };

  const fetchJobOrder = async () => {
    await props.actionCreator({
      type: types.GET_JOB_ORDER_LIST,
      payload: {
        department: filter.filterByDepartment,
        client_id: filter.filterByClient,
      },
    });
  };

  const clearMessages = async () => {
    await props.actionCreator({
      type: types.CLEAR_JO_MESSAGES,
    });
  };

  const resetFilter = () => {
    setFilter({
      filterByClient: '',
      filterByDepartment: '',
    });
  };

  const handleFilter = (filter, event) => {
    if (filter === 'client') {
      setFilter((prevFilter) => {
        const newFilter = {
          ...prevFilter,
          filterByClient: event.target.value,
        };

        return newFilter;
      });
    }

    if (filter === 'department') {
      setFilter((prevFilter) => {
        const newFilter = {
          ...prevFilter,
          filterByDepartment: event.target.value,
        };

        return newFilter;
      });
    }
  };

  const fetchDepartment = async () => {
    await props.actionCreator({
      type: types.GET_DEPARTMENT_OPTIONS,
      payload: {
        type: 'department',
        client_id: filter.filterByClient,
      },
    });
  };

  //meta title
  document.title = 'Job Order';
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Dashboard"
            titleLink="/dashboard"
            breadcrumbItem="Job Order"
          />
          <Row>
            <Col lg={3}>
              <div className="mb-3">
                <Input
                  type="select"
                  className="form-select"
                  multiple={false}
                  onChange={(e) => handleFilter('client', e)}
                  value={filter.filterByClient}
                >
                  <option value={''}>Filter by Client</option>
                  {clients.data.map((item, index) => (
                    <option key={index} value={item.client_id}>
                      {item.client_name}
                    </option>
                  ))}
                </Input>
              </div>
            </Col>
            <Col lg={3}>
              <div className="mb-3">
                <Input
                  type="select"
                  className="form-select"
                  multiple={false}
                  onChange={(e) => handleFilter('department', e)}
                  value={filter.filterByDepartment}
                >
                  <option value={''}>Filter By Department</option>
                  {department.data.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Input>
              </div>
            </Col>
            {(filter.filterByClient || filter.filterByDepartment) && (
              <Col lg={2}>
                <div className="mb-3">
                  <button
                    type="button"
                    className="btn"
                    onClick={resetFilter}
                    style={{
                      color: '#4549a2',
                      textDecoration: 'underline',
                      fontWeight: 700,
                    }}
                  >
                    Reset Filter
                  </button>
                </div>
              </Col>
            )}
          </Row>
          <Row className="align-items-center">
            <Col xl={12}>
              <TableContainer
                columns={columns}
                data={joborder.data}
                isGlobalFilter={true}
                customPageSize={10}
                className="table align-middle datatable dt-responsive table-check nowrap"
              />
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ joborder, clientfields }) => ({
  joborder,
  clientfields,
});

export default connect(mapStateToProps, { actionCreator })(JobOrder);
