import React from "react";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, Col, Row, Label, Input } from "reactstrap";
import { actionCreator } from "../../../store";

const BarChart = ({ dashboard, filter, setFilter }) => {
  const series = [
    {
      name: "Cost",
      data: [2.5, 3.2, 5.0, 10.1, 4.2, 3.8, 3, 2.4, 4.0, 1.2, 3.5, 0.8],
    },
  ];
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return "$" + val;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#adb5bd"],
      },
    },

    colors: ["#5156be"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      position: "top",
      labels: {
        offsetY: -18,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
        offsetY: -35,
      },
    },
    fill: {
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [50, 0, 100, 100],
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return "$" + val;
        },
      },
    },
    title: {
      text: `Monthly Cost in ${filter.filterByYear}`,
      floating: true,
      offsetY: 330,
      align: "center",
      style: {
        color: "#adb5bd",
      },
    },
  };

  const [monthlyCostData, setMonthlyCostData] = React.useState([]);
  const [yearList, setYearList] = React.useState([]);

  React.useEffect(() => {
    filterYearHandler();
  }, []);

  React.useEffect(() => {
    monthlyCostHandler(dashboard.barCharts.data.monthlyCost);
  }, [dashboard.barCharts.data]);

  const filterYearHandler = () => {
    const currentYear = moment().year();
    const yearsArray = [];
    for (let i = 0; i < 6; i++) {
      yearsArray.push(currentYear - i);
    }
    setYearList(yearsArray);
  };

  const monthlyCostHandler = (monthlyCost) => {
    let month1 = [];
    for (let i = 1; i <= 12; i++) {
      const year1 = monthlyCost?.find(
        (item) =>
          parseInt(item.month) === i &&
          parseInt(item.year) === parseInt(filter.filterByYear)
      );

      month1.push(year1 ? (year1.total_amount ? year1.total_amount : 0) : 0);
    }

    let monthlyNewCost = [];

    monthlyNewCost.push({
      name: "Cost",
      data: month1,
    });

    const maxTotalAmount = monthlyCost?.reduce((max, obj) => {
      // Use 0 if total_amount is null, otherwise use the actual value
      const amount = obj.total_amount !== null ? obj.total_amount : 0;
      return Math.max(max, amount);
    }, 0);

    setMonthlyCostData({
      series: monthlyNewCost,
      max: maxTotalAmount,
    });
  };

  return (
    <React.Fragment>
      <Col xl={8}>
        <Card>
          <CardHeader>
            <Row>
              <Col xl={9}>
                <h4 className="card-title mb-0">Monthly Cost </h4>
              </Col>
              <Col
                xl={3}
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Label
                  className="form-label"
                  style={{
                    marginRight: 20,
                    paddingTop: 10,
                  }}
                >
                  Year
                </Label>
                <Input
                  type="select"
                  className="form-select"
                  multiple={false}
                  defaultValue={filter.filterByYear}
                  onChange={(event) => {
                    setFilter((prevFilter) => {
                      const newFilter = {
                        ...prevFilter,
                        filterByYear: event.target.value,
                      };

                      return newFilter;
                    });
                  }}
                >
                  {yearList.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <ReactApexChart
              options={options}
              series={monthlyCostData?.series || []}
              type="bar"
              height={350}
              className="apex-charts"
            />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

const mapStateToProps = ({ dashboard }) => ({
  dashboard,
});

export default connect(mapStateToProps, { actionCreator })(BarChart);
