import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_MENU,
  GET_MENU_SUCCESS,
  GET_MENU_FAILED,
  GET_SELECTED_WORKSHOP,
  GET_SELECTED_WORKSHOP_SUCCESS,
  GET_SELECTED_WORKSHOP_FAILED,
} from "./types";

import appServices from "../../api/services/app";

export function* fnGetMenus() {
  try {
    const data = yield call(appServices.api.fnGetMenus);
    if (data) {
      yield put({
        type: GET_MENU_SUCCESS,
        payload: { menu: data.data.data.result },
      });
    }
  } catch (error) {
    yield put({
      type: GET_MENU_FAILED,
      payload: error.response?.data ? error.response?.data.message : "",
    });
  }
}

export function* fnGetSelectedWorkshop() {
  try {
    const data = yield call(appServices.api.fnGetSelectedWorkshop);
    if (data) {
      yield put({
        type: GET_SELECTED_WORKSHOP_SUCCESS,
        payload: data.data.result,
      });
    }
  } catch (error) {
    yield put({
      type: GET_SELECTED_WORKSHOP_FAILED,
      payload: error.response?.data ? error.response?.data.message : "",
    });
  }
}

export default function* watcher() {
  yield takeLatest(GET_MENU, fnGetMenus);
  yield takeLatest(GET_SELECTED_WORKSHOP, fnGetSelectedWorkshop);
}
