import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { actionCreator, types } from '../../store';
import Configuration from './Configuration';

import {
  Col,
  Container,
  Row,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as Yup from 'yup';
import { useFormik } from 'formik';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { isEmpty } from 'lodash';
import UserGroups from './UserGroups';
import Users from './Users';

const User = ({ user, usergroup, authentication, ...props }) => {
  document.title = 'User List | MSP';

  const [headerPillsTab, setHeaderPillsTab] = React.useState('1');

  const toggleHeaderPills = (tab) => {
    if (headerPillsTab !== tab) setHeaderPillsTab(tab);
  };

  //meta title
  document.title = headerPillsTab == 3 ? 'Settings | MSP' : 'User | MSP';
  return (
    <React.Fragment>
      <div className="page-content user">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {headerPillsTab != 3 && (
            <Breadcrumbs
              title="Dashboard"
              titleLink="/dashboard"
              breadcrumbItem="General"
            />
          )}
          {headerPillsTab == 3 && (
            <Breadcrumbs
              title="Dashboard"
              titleLink="/dashboard"
              breadcrumbItem="General"
            />
          )}
          <p className="card-title-desc pb-4">
            The General section provides an overview or default settings that
            apply across the application.
          </p>
          <div className="col-xl-12">
            <div className="card" style={{ border: 'none' }}>
              <div
                className="card-header align-items-center d-flex"
                style={{ border: 'none' }}
              >
                <div className="flex-shrink-0">
                  <ul
                    className="nav justify-content-end nav-pills card-header-pills"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({
                          active: headerPillsTab === '1',
                        })}
                        onClick={() => {
                          toggleHeaderPills('1');
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">Users</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({
                          active: headerPillsTab === '2',
                        })}
                        onClick={() => {
                          toggleHeaderPills('2');
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">User Groups</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({
                          active: headerPillsTab === '3',
                        })}
                        onClick={() => {
                          toggleHeaderPills('3');
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">Configuration</span>
                      </NavLink>
                    </NavItem>
                  </ul>
                </div>
              </div>

              <div
                className="card-body"
                style={{ border: '1px solid #e9e9ef' }}
              >
                <TabContent className="text-muted" activeTab={headerPillsTab}>
                  <TabPane tabId="1">
                    <Users />
                  </TabPane>
                  <TabPane tabId="2">
                    <UserGroups />
                  </TabPane>
                  <TabPane tabId="3">
                    <Configuration />
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>

          <Row>
            <Col lg="12"></Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user, usergroup, authentication }) => ({
  user,
  usergroup,
  authentication,
});

export default connect(mapStateToProps, { actionCreator })(User);
