const ID = (cell) => {
    return cell.value ? cell.value : "";
  };
  
  const ClientName = (cell) => {
    return cell.value ? cell.value : "";
  };
  
  const Status = (cell) => {
    return cell.value ? cell.value : "";
  };
  
  const NoSuppliers = (cell) => {
    return cell.value !== 0 ? cell.value : "0";
  };
  
  const NoWorkers = (cell) => {
    return cell.value !== 0 ? cell.value : "0";
  };
  
  export {
    ID,
    ClientName,
    Status,
    NoSuppliers,
    NoWorkers,
  };
  