export const GET_JOB_ORDER_LIST = "@joborder/GET_JOB_ORDER_LIST";
export const GET_JOB_ORDER_LIST_SUCCESS =
  "@joborder/GET_JOB_ORDER_LIST_SUCCESS";
export const GET_JOB_ORDER_LIST_FAILED = "@joborder/GET_JOB_ORDER_LIST_FAILED";

export const POST_UPDATE_JOB_ORDER = "@joborder/POST_UPDATE_JOB_ORDER";
export const POST_UPDATE_JOB_ORDER_SUCCESS =
  "@joborder/POST_UPDATE_JOB_ORDER_SUCCESS";
export const POST_UPDATE_JOB_ORDER_FAILED =
  "@joborder/POST_UPDATE_JOB_ORDER_FAILED";

export const GET_CANDIDATE_LIST = "@joborder/GET_CANDIDATE_LIST";
export const GET_CANDIDATE_LIST_SUCCESS =
  "@joborder/GET_CANDIDATE_LIST_SUCCESS";
export const GET_CANDIDATE_LIST_FAILED = "@joborder/GET_CANDIDATE_LIST_FAILED";

export const POST_ADD_CANDIDATE = "@joborder/POST_ADD_CANDIDATE";
export const POST_ADD_CANDIDATE_SUCCESS =
  "@joborder/POST_ADD_CANDIDATE_SUCCESS";
export const POST_ADD_CANDIDATE_FAILED = "@joborder/POST_ADD_CANDIDATE_FAILED";

export const POST_UPDATE_CANDIDATE_STATUS =
  "@joborder/POST_UPDATE_CANDIDATE_STATUS";
export const POST_UPDATE_CANDIDATE_STATUS_SUCCESS =
  "@joborder/POST_UPDATE_CANDIDATE_STATUS_SUCCESS";
export const POST_UPDATE_CANDIDATE_STATUS_FAILED =
  "@joborder/POST_UPDATE_CANDIDATE_STATUS_FAILED";

export const GET_CANDIDATE = "@joborder/GET_CANDIDATE";
export const GET_CANDIDATE_SUCCESS = "@joborder/GET_CANDIDATE_SUCCESS";
export const GET_CANDIDATE_FAILED = "@joborder/GET_CANDIDATE_FAILED";

export const POST_ADD_CANDIDATE_INTERVIEW =
  "@joborder/POST_ADD_CANDIDATE_INTERVIEW";
export const POST_ADD_CANDIDATE_INTERVIEW_SUCCESS =
  "@joborder/POST_ADD_CANDIDATE_INTERVIEW_SUCCESS";
export const POST_ADD_CANDIDATE_INTERVIEW_FAILED =
  "@joborder/POST_ADD_CANDIDATE_INTERVIEW_FAILED";

export const POST_UPDATE_CANDIDATE_INTERVIEW =
  "@joborder/POST_UPDATE_CANDIDATE_INTERVIEW";
export const POST_UPDATE_CANDIDATE_INTERVIEW_SUCCESS =
  "@joborder/POST_UPDATE_CANDIDATE_INTERVIEW_SUCCESS";
export const POST_UPDATE_CANDIDATE_INTERVIEW_FAILED =
  "@joborder/POST_UPDATE_CANDIDATE_INTERVIEW_FAILED";

export const POST_ADD_CANDIDATE_OFFER = "@joborder/POST_ADD_CANDIDATE_OFFER";
export const POST_ADD_CANDIDATE_OFFER_SUCCESS =
  "@joborder/POST_ADD_CANDIDATE_OFFER_SUCCESS";
export const POST_ADD_CANDIDATE_OFFER_FAILED =
  "@joborder/POST_ADD_CANDIDATE_OFFER_FAILED";

export const POST_ACCEPT_CANDIDATE = "@joborder/POST_ACCEPT_CANDIDATE";
export const POST_ACCEPT_CANDIDATE_SUCCESS =
  "@joborder/POST_ACCEPT_CANDIDATE_SUCCESS";
export const POST_ACCEPT_CANDIDATE_FAILED =
  "@joborder/POST_ACCEPT_CANDIDATE_FAILED";

export const POST_CONVERT_WORKER = "@joborder/POST_CONVERT_WORKER";
export const POST_CONVERT_WORKER_SUCCESS =
  "@joborder/POST_CONVERT_WORKER_SUCCESS";
export const POST_CONVERT_WORKER_FAILED =
  "@joborder/POST_CONVERT_WORKER_FAILED";

export const CLEAR_JO_MESSAGES = "@joborder/CLEAR_JO_MESSAGES";
export const SAVE_SELECTED_JOB_DATA = "@joborder/SAVE_SELECTED_JOB_DATA";
export const CLEAR_NEW_WORKER_ID = "@joborder/CLEAR_NEW_WORKER_ID";
