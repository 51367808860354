import React from "react";
import { Field } from "formik";
import StyledText from "../../StyledText";

const InputCheckbox = ({
  name,
  label,
  placeholder = "Please select",
  addedStyle = {},
  disabled = false,
  addedTextStyle,
  value,
  parent,
  siblings,
  children,
}) => {
  return (
    <>
      <Field name={name}>
        {({ field, form }) => {
          const handleToggleCheck = () => {
            form.setFieldValue(name, field.value ? false : true);
            if (parent) {
              let flg = false;
              for (let x of siblings) {
                if (
                  form.values[navNameToLowerCase(x.name)] &&
                  navNameToLowerCase(x.name) !== name
                ) {
                  flg = true;
                  form.setFieldValue(parent, true);
                }
              }
              if (!flg) {
                form.setFieldValue(parent, field.value ? false : true);
              }
            }

            if (children) {
              for (let x of children) {
                form.setFieldValue(
                  navNameToLowerCase(x.name),
                  field.value ? false : true
                );
              }
            }
          };

          const navNameToLowerCase = (string) => {
            return string.replace(/\s/g, "").toLowerCase();
          };

          return (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "5px",
                }}
              >
                <input
                  type={"checkbox"}
                  id={name}
                  name={name}
                  placeholder={placeholder}
                  onChange={handleToggleCheck}
                  checked={value ? value : field.value}
                  value={value ? value : field.value}
                  style={{ ...styles.container, ...addedStyle }}
                  disabled={disabled}
                />
                {label && (
                  <StyledText size={14} style={addedTextStyle} htmlFor={name}>
                    {label}
                  </StyledText>
                )}
              </div>
              {form.errors[name] && form.touched[name] && (
                <StyledText
                  style={{
                    color: "red",
                  }}
                >
                  {form.errors[name]}
                </StyledText>
              )}
            </>
          );
        }}
      </Field>
    </>
  );
};

const styles = {
  container: {
    marginRight: 10,
    marginTop: 1,
  },
};

export default InputCheckbox;
