import React from "react";
import PagingControl from "./PagingControl";
import { Document, Page, pdfjs } from "react-pdf";
import { Row, Col } from "reactstrap";
import "react-pdf/dist/esm/Page/TextLayer.css";

// Set the workerSrc to the correct path
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const ResumePreview = ({ candidateData }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [pageNum, setPageNum] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  return (
    <React.Fragment>
      {candidateData.length > 0 && (
        <Row>
          <Col
            xs={12}
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              border: "#E9E9EF solid 1px",
              backgroundColor: "#E7E7E7",
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <PagingControl
              pageNum={pageNum}
              setPageNum={setPageNum}
              totalPages={totalPages}
            />
            <div
              style={{
                maxWidth: 900,
                margin: "20px auto",
                marginTop: 30,
                border: "1px solid #999",
                position: "relative",
              }}
            >
              <Document
                file={`${baseUrl}${candidateData[0].latest_resume.file_path}`}
                onLoadSuccess={(data) => {
                  setTotalPages(data.numPages);
                }}
              >
                <Page
                  pageNumber={pageNum + 1}
                  width={800}
                  height={1200}
                  renderAnnotationLayer={false}
                />
              </Document>
            </div>
            <PagingControl
              pageNum={pageNum}
              setPageNum={setPageNum}
              totalPages={totalPages}
            />
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default ResumePreview;
