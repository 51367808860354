import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

const Authmiddleware = ({ authentication, ...props }) => {
  if (!authentication.authenticated) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
  return <React.Fragment>{props.children}</React.Fragment>;
};

const mapStateToProps = ({ authentication }) => ({
  authentication,
});

export default connect(mapStateToProps, {})(Authmiddleware);
