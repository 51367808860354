import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Import Icons
import FeatherIcon from "feather-icons-react";

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// import images
import ptlogo from "../../assets/images/pt-logo.png";
import logo from "../../assets/images/logo.png";

import { actionCreator } from "../../store";

const Header = ({ app, layout, ...props }) => {
  const body = document.body;
  const [isClick, setClick] = useState(true);

  /*** Sidebar menu icon and default menu set */
  function tToggle() {
    setClick(!isClick);
    if (isClick === true) {
      body.classList.add("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "lg");
    } else {
      body.classList.remove("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "sm");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={ptlogo} alt="" height="40" />{" "}
                  {/* <span
                    className="logo-txt"
                    style={{
                      position: "absolute",
                      fontFamily: "Roboto",
                      color: "#495057",
                      top: 27,
                      left: 78,
                      fontSize: 16,
                    }}
                  >
                    Tapfin Admin
                  </span> */}
                </span>
              </Link>
            </div>

            <button
              onClick={() => {
                tToggle();
              }}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>
          </div>

          <div className="d-flex">
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

const mapStateToProps = ({ layout, app, ...state }) => {
  const { layoutMode } = layout;
  return { app, layout, layoutMode };
};

export default connect(mapStateToProps, { actionCreator })(Header);
