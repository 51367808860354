import {
  GET_DOCUMENT_LIST_ALL_SUCCESS,
  GET_DOCUMENT_LIST_ALL_FAILED,
  GET_DOCUMENT_LIST_SUCCESS,
  GET_DOCUMENT_LIST_FAILED,
  ADD_NEW_DOCUMENT_SUCCESS,
  ADD_NEW_DOCUMENT_FAILED,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_FAILED,
  CLEAR_DOCUMENT_SAVE_MESSAGE,
} from "./types";

const INIT_STATE = {
  loading: true,
  data: [],
  listData: [],
  actionReturnData: [],
  error: false,
  errorMessage: null,
};

const Document = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DOCUMENT_LIST_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        listData: action.payload,
        error: false,
        errorMessage: null,
      };

    case GET_DOCUMENT_LIST_ALL_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    case GET_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
        errorMessage: null,
      };

    case GET_DOCUMENT_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case ADD_NEW_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
      };

    case ADD_NEW_DOCUMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case UPDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        actionReturnData: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
        isSubmitted: true,
      };

    case UPDATE_DOCUMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        isSubmitted: false,
      };

    case CLEAR_DOCUMENT_SAVE_MESSAGE:
      return {
        ...state,
        actionReturnData: [],
      };

    default:
      return state;
  }
};

export default Document;
