import React from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import CountUp from 'react-countup';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
} from 'reactstrap';
import { connect } from 'react-redux';
import { actionCreator, types } from '../../../store';

import Breadcrumb from '../../../components/Common/Breadcrumb';

/** import Mini Widget data */
import WorkerCard from './WorkerCard';
import LineApexChart from './LineApexChart';

const options = {
  chart: {
    height: 50,
    type: 'line',
    toolbar: { show: false },
    sparkline: {
      enabled: true,
    },
  },
  colors: ['#5156be'],
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: false,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return '';
        },
      },
    },
    marker: {
      show: false,
    },
  },
};

const WorkerBI = ({ clientfields, workers, ...props }) => {
  const currentYear = `${moment().year()}`;
  const previousYear = `${moment().subtract(1, 'year').year()}`;
  const { clients, clientSuppliers, department } = clientfields;
  const [filter, setFilter] = React.useState({
    filterByClient: '',
    filterBySupplier: '',
    filterByDepartment: '',
    filterByYear1: currentYear,
    filterByYear2: previousYear,
  });
  const [widgetsData, setWidgetsData] = React.useState([]);
  const [departmentPieChartData, setDepartmentPieChartData] = React.useState(
    [],
  );
  const [costCenterPieChartData, setCostCenterPieChartData] = React.useState(
    [],
  );
  const [workerMonthlyCostData, setWorkerMonthlyCostData] = React.useState([]);
  const [newlyHiredWorkerData, setNewlyHiredWorkerData] = React.useState([]);
  const [activeWorkersData, setActiveWorkersData] = React.useState([]);
  const [endOfContractWorkersData, setEndOfContractWorkersData] =
    React.useState([]);
  const [terminatedWorkersData, setTerminatedWorkersData] = React.useState([]);

  React.useEffect(() => {
    filter.filterByClient && fetchDepartment();
    filter.filterByClient && fetchSuppliers();
    clients.data.length > 0 && fetchWorkersBI();
  }, [filter, clients]);

  React.useEffect(() => {
    setWorkerStatusCardHandler(workers.workersBIData.workerStatus);
    pieChartDataHandler(workers.workersBIData.workerPieChart);
    workerMonthlyCostHandler(workers.workersBIData.workerMonthlyCost);
    newlyHiredWorkersHandler(workers.workersBIData.workerNewlyHired);
    activeWorkersHandler(workers.workersBIData.workerActive);
    endOfContractWorkersHandler(workers.workersBIData.workerEndOfContract);
    terminatedWorkersHandler(workers.workersBIData.workerTerminated);
  }, [workers.workersBIData]);

  React.useEffect(() => {
    fetchClients();
  }, []);

  const fetchWorkersBI = async () => {
    let clientIds = [];
    // filter is 'ALL'
    if (!filter.filterByClient) {
      for (let client of clients.data) {
        clientIds.push(client.client_id);
      }
    }

    await props.actionCreator({
      type: types.GET_WORKERS_BI,
      payload: {
        client_id: filter.filterByClient ? filter.filterByClient : clientIds,
        supplier_id: filter.filterBySupplier,
        department_id: filter.filterByDepartment,
        year1: filter.filterByYear1,
        year2: filter.filterByYear2,
      },
    });
  };

  const fetchClients = async () => {
    await props.actionCreator({
      type: types.GET_CLIENTS,
    });
  };

  const fetchSuppliers = async () => {
    await props.actionCreator({
      type: types.GET_CLIENT_SUPPLIERS,
      payload: {
        client_id: filter.filterByClient,
      },
    });
  };

  const fetchDepartment = async () => {
    await props.actionCreator({
      type: types.GET_DEPARTMENT_OPTIONS,
      payload: {
        type: 'department',
        client_id: filter.filterByClient,
      },
    });
  };

  const handleFilter = (filter, event) => {
    if (filter === 'client') {
      setFilter((prevFilter) => {
        const newFilter = {
          ...prevFilter,
          filterByClient: parseInt(event.target.value),
        };

        return newFilter;
      });
    }

    if (filter === 'supplier') {
      setFilter((prevFilter) => {
        const newFilter = {
          ...prevFilter,
          filterBySupplier: event.target.value,
        };

        return newFilter;
      });
    }

    if (filter === 'department') {
      setFilter((prevFilter) => {
        const newFilter = {
          ...prevFilter,
          filterByDepartment: event.target.value,
        };

        return newFilter;
      });
    }

    if (filter === 'Year1') {
      setFilter((prevFilter) => {
        const newFilter = {
          ...prevFilter,
          filterByYear1: event.target.value,
        };

        return newFilter;
      });
    }

    if (filter === 'Year2') {
      setFilter((prevFilter) => {
        const newFilter = {
          ...prevFilter,
          filterByYear2: event.target.value,
        };

        return newFilter;
      });
    }
  };

  const resetFilter = () => {
    setFilter({
      filterByClient: '',
      filterBySupplier: '',
      filterByDepartment: '',
      filterByYear1: currentYear,
      filterByYear2: previousYear,
    });
  };

  const setWorkerStatusCardHandler = (workerStatus) => {
    let widgetData = [];

    widgetData.push({
      id: 1,
      title: 'Active Workers',
      totalNumber: workerStatus?.total_active_workers || 0,
      series: [2, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2, 47, 42, 15],
    });

    widgetData.push({
      id: 2,
      title: 'Hired Workers',
      totalNumber: workerStatus?.total_hired_workers || 0,
      series: [15, 42, 47, 2, 14, 19, 65, 75, 47, 15, 42, 47, 2, 14, 12],
    });

    widgetData.push({
      id: 3,
      title: 'Terminated Workers',
      totalNumber: workerStatus?.total_terminated_workers || 0,
      series: [47, 15, 2, 67, 22, 20, 36, 60, 60, 30, 50, 11, 12, 3, 8],
    });

    setWidgetsData(widgetData);
  };

  const pieChartDataHandler = (workerPiechartData) => {
    let elements = [];
    let percentage = [];
    let colors = [];

    if (workerPiechartData?.department) {
      for (let chartData of workerPiechartData.department) {
        elements.push(chartData.label);
        percentage.push(chartData.total);
        colors.push('text-primary');
      }

      setDepartmentPieChartData({
        elements,
        colors,
        percentage,
      });
    } else {
      setDepartmentPieChartData([]);
    }

    elements = [];
    percentage = [];
    colors = [];

    if (workerPiechartData?.cost_center) {
      for (let chartData of workerPiechartData.cost_center) {
        elements.push(chartData.label);
        percentage.push(chartData.total);
        colors.push('text-primary');
      }

      setCostCenterPieChartData({
        elements,
        colors,
        percentage,
      });
    } else {
      setCostCenterPieChartData([]);
    }
  };

  const workerMonthlyCostHandler = (workerMonthlyCost) => {
    let month1 = [];
    let month2 = [];
    for (let i = 1; i <= 12; i++) {
      const year1 = workerMonthlyCost?.find(
        (item) =>
          parseInt(item.month) === i &&
          parseInt(item.year) === parseInt(filter.filterByYear1),
      );

      const year2 = workerMonthlyCost?.find(
        (item) =>
          parseInt(item.month) === i &&
          parseInt(item.year) === parseInt(filter.filterByYear2),
      );

      month1.push(year1 ? (year1.total_amount ? year1.total_amount : 0) : 0);
      month2.push(year2 ? (year2.total_amount ? year2.total_amount : 0) : 0);
    }

    let monthlyCost = [];

    monthlyCost.push({
      name: filter.filterByYear1,
      data: month1,
    });
    monthlyCost.push({
      name: filter.filterByYear2,
      data: month2,
    });

    const maxTotalAmount = workerMonthlyCost?.reduce((max, obj) => {
      // Use 0 if total_amount is null, otherwise use the actual value
      const amount = obj.total_amount !== null ? obj.total_amount : 0;
      return Math.max(max, amount);
    }, 0);

    setWorkerMonthlyCostData({
      series: monthlyCost,
      max: maxTotalAmount,
    });
  };

  const newlyHiredWorkersHandler = (hiredWorkers) => {
    let month1 = [];
    let month2 = [];
    for (let i = 1; i <= 12; i++) {
      const year1 = hiredWorkers?.find(
        (item) =>
          parseInt(item.month) === i &&
          parseInt(item.year) === parseInt(filter.filterByYear1),
      );

      const year2 = hiredWorkers?.find(
        (item) =>
          parseInt(item.month) === i &&
          parseInt(item.year) === parseInt(filter.filterByYear2),
      );

      month1.push(year1 ? (year1.total_hired ? year1.total_hired : 0) : 0);
      month2.push(year2 ? (year2.total_hired ? year2.total_hired : 0) : 0);
    }

    let newlyHired = [];

    newlyHired.push({
      name: filter.filterByYear1,
      data: month1,
    });
    newlyHired.push({
      name: filter.filterByYear2,
      data: month2,
    });

    const maxTotalHired = hiredWorkers?.reduce((max, obj) => {
      // Use 0 if total_hired is null, otherwise use the actual value
      const amount = obj.total_hired !== null ? obj.total_hired : 0;
      return Math.max(max, amount);
    }, 0);

    setNewlyHiredWorkerData({
      series: newlyHired,
      max: maxTotalHired,
    });
  };

  const activeWorkersHandler = (activeWorkers) => {
    let month1 = [];
    let month2 = [];
    for (let i = 1; i <= 12; i++) {
      const year1 = activeWorkers?.find(
        (item) =>
          parseInt(item.month) === i &&
          parseInt(item.year) === parseInt(filter.filterByYear1),
      );

      const year2 = activeWorkers?.find(
        (item) =>
          parseInt(item.month) === i &&
          parseInt(item.year) === parseInt(filter.filterByYear2),
      );

      month1.push(
        year1
          ? year1.total_active_workers
            ? year1.total_active_workers
            : 0
          : 0,
      );
      month2.push(
        year2
          ? year2.total_active_workers
            ? year2.total_active_workers
            : 0
          : 0,
      );
    }

    let newActiveWorkers = [];

    newActiveWorkers.push({
      name: filter.filterByYear1,
      data: month1,
    });
    newActiveWorkers.push({
      name: filter.filterByYear2,
      data: month2,
    });

    const maxTotalActiveWorker = activeWorkers?.reduce((max, obj) => {
      // Use 0 if total_active_workers is null, otherwise use the actual value
      const amount =
        obj.total_active_workers !== null ? obj.total_active_workers : 0;
      return Math.max(max, amount);
    }, 0);

    setActiveWorkersData({
      series: newActiveWorkers,
      max: maxTotalActiveWorker,
    });
  };

  const endOfContractWorkersHandler = (endOfContractWorkers) => {
    let month1 = [];
    let month2 = [];
    for (let i = 1; i <= 12; i++) {
      const year1 = endOfContractWorkers?.find(
        (item) =>
          parseInt(item.month) === i &&
          parseInt(item.year) === parseInt(filter.filterByYear1),
      );

      const year2 = endOfContractWorkers?.find(
        (item) =>
          parseInt(item.month) === i &&
          parseInt(item.year) === parseInt(filter.filterByYear2),
      );

      month1.push(
        year1
          ? year1.total_end_contract_workers
            ? year1.total_end_contract_workers
            : 0
          : 0,
      );
      month2.push(
        year2
          ? year2.total_end_contract_workers
            ? year2.total_end_contract_workers
            : 0
          : 0,
      );
    }

    let newEndContract = [];

    newEndContract.push({
      name: filter.filterByYear1,
      data: month1,
    });
    newEndContract.push({
      name: filter.filterByYear2,
      data: month2,
    });

    const maxTotalEndContractWorker = endOfContractWorkers?.reduce(
      (max, obj) => {
        // Use 0 if total_end_contract_workers is null, otherwise use the actual value
        const amount =
          obj.total_end_contract_workers !== null
            ? obj.total_end_contract_workers
            : 0;
        return Math.max(max, amount);
      },
      0,
    );

    setEndOfContractWorkersData({
      series: newEndContract,
      max: maxTotalEndContractWorker,
    });
  };

  const terminatedWorkersHandler = (terminatedWorkers) => {
    let month1 = [];
    let month2 = [];
    for (let i = 1; i <= 12; i++) {
      const year1 = terminatedWorkers?.find(
        (item) =>
          parseInt(item.month) === i &&
          parseInt(item.year) === parseInt(filter.filterByYear1),
      );

      const year2 = terminatedWorkers?.find(
        (item) =>
          parseInt(item.month) === i &&
          parseInt(item.year) === parseInt(filter.filterByYear2),
      );

      month1.push(
        year1
          ? year1.total_terminated_workers
            ? year1.total_terminated_workers
            : 0
          : 0,
      );
      month2.push(
        year2
          ? year2.total_terminated_workers
            ? year2.total_terminated_workers
            : 0
          : 0,
      );
    }

    let terminatedData = [];

    terminatedData.push({
      name: filter.filterByYear1,
      data: month1,
    });
    terminatedData.push({
      name: filter.filterByYear2,
      data: month2,
    });

    const maxTotalTerminatedWorker = terminatedWorkers?.reduce((max, obj) => {
      // Use 0 if total_terminated_workers is null, otherwise use the actual value
      const amount =
        obj.total_terminated_workers !== null
          ? obj.total_terminated_workers
          : 0;
      return Math.max(max, amount);
    }, 0);

    setTerminatedWorkersData({
      series: terminatedData,
      max: maxTotalTerminatedWorker,
    });
  };

  //meta title
  document.title = 'Workers BI';

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Dashboard"
            titleLink="/dashboard"
            breadcrumbItem="Workers BI"
          />
          <Row>
            <Col lg={3}>
              <div className="mb-3">
                <Label className="form-label">Client</Label>
                <Input
                  type="select"
                  className="form-select"
                  multiple={false}
                  onChange={(e) => handleFilter('client', e)}
                  value={filter.filterByClient}
                >
                  <option value={''}>All</option>
                  {clients.data.map((item, index) => (
                    <option key={index} value={item.client_id}>
                      {item.client_name}
                    </option>
                  ))}
                </Input>
              </div>
            </Col>
            <Col lg={3}>
              <div className="mb-3">
                <Label className="form-label">Supplier</Label>
                <Input
                  type="select"
                  className="form-select"
                  multiple={false}
                  onChange={(e) => handleFilter('supplier', e)}
                  value={filter.filterBySupplier}
                >
                  <option value={''}>All</option>
                  {clientSuppliers.data.map((item, index) => (
                    <option key={index} value={item.supplier_id}>
                      {item.supplier_name}
                    </option>
                  ))}
                </Input>
              </div>
            </Col>
            <Col lg={3}>
              <div className="mb-3">
                <Label className="form-label">Department</Label>
                <Input
                  type="select"
                  className="form-select"
                  multiple={false}
                  onChange={(e) => handleFilter('department', e)}
                  value={filter.filterByDepartment}
                >
                  <option value={''}>All</option>
                  {department.data.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Input>
              </div>
            </Col>
            {(filter.filterByClient ||
              filter.filterBySupplier ||
              filter.filterByDepartment) && (
              <Col lg={2}>
                <div className="mb-3">
                  <button
                    type="button"
                    className="btn"
                    onClick={resetFilter}
                    style={{
                      color: '#4549a2',
                      textDecoration: 'underline',
                      fontWeight: 700,
                    }}
                  >
                    Reset Filter
                  </button>
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <Col lg={3}>
              <div className="mb-3">
                <Label className="form-label">Year 1</Label>
                <Input
                  type="number"
                  className="form-control"
                  onChange={(e) => handleFilter('Year1', e)}
                  value={filter.filterByYear1}
                  placeholder="YYYY"
                  min="1900"
                  max={currentYear} // Set the maximum year
                  step="1"
                />
              </div>
            </Col>
            <Col lg={3}>
              <div className="mb-3">
                <Label className="form-label">Year 2</Label>
                <Input
                  type="number"
                  className="form-control"
                  onChange={(e) => handleFilter('Year2', e)}
                  value={filter.filterByYear2}
                  placeholder="YYYY"
                  min="1900"
                  max={currentYear} // Set the maximum year
                  step="1"
                />
              </div>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: 50,
            }}
          >
            {(widgetsData || []).map((widget, key) => (
              <Col xl={4} md={6} key={key}>
                <Card className="card-h-100">
                  <CardBody>
                    <Row className="align-items-center">
                      <Col xs={6}>
                        <span className="text-muted mb-3 lh-1 d-block text-truncate">
                          {widget.title}
                        </span>
                        <h4 className="mb-3">
                          <span className="counter-value">
                            <CountUp
                              start={0}
                              end={widget.totalNumber}
                              duration={2}
                              // decimals={2}
                              separator=""
                            />
                            {widget.postFix}
                          </span>
                        </h4>
                      </Col>
                      <Col xs={6}>
                        <ReactApexChart
                          options={options}
                          series={[{ data: [...widget['series']] }]}
                          type="line"
                          className="apex-charts mb-2"
                          dir="ltr"
                          height={50}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <Row>
            {departmentPieChartData?.elements && (
              <WorkerCard
                title={'Workers per Department'}
                elements={departmentPieChartData.elements}
                colors={departmentPieChartData.colors}
                percentage={departmentPieChartData.percentage}
              />
            )}
            {costCenterPieChartData?.elements && (
              <WorkerCard
                title={'Workers per Cost Center'}
                elements={costCenterPieChartData.elements}
                colors={costCenterPieChartData.colors}
                percentage={costCenterPieChartData.percentage}
              />
            )}
          </Row>
          <Row>
            <Col xl={6}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Monthly Cost of Workers</h4>
                </CardHeader>
                <CardBody>
                  {workerMonthlyCostData?.series && (
                    <LineApexChart
                      title={'Average Monthly Cost of Workers'}
                      // series={[
                      //   {
                      //     name: "2023",
                      //     data: [
                      //       4900, 4200, 6800, 7900, 6500, 6000, 6500, 0, 0, 0,
                      //       0, 0,
                      //     ],
                      //   },
                      //   {
                      //     name: "2024",
                      //     data: [
                      //       4000, 4800, 4600, 5100, 7000, 6000, 5800, 0, 0, 0,
                      //       0, 0,
                      //     ],
                      //   },
                      // ]}
                      series={workerMonthlyCostData.series}
                      categories={[
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sept',
                        'Oct',
                        'Nov',
                        'Dec',
                      ]}
                      yaxis={{
                        min: 0,
                        max: workerMonthlyCostData.max,
                        currency: true,
                      }}
                      stroke={{
                        width: [3, 3],
                        curve: 'straight',
                      }}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col xl={6}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Newly Hired Workers</h4>
                </CardHeader>
                <CardBody>
                  {newlyHiredWorkerData?.series && (
                    <LineApexChart
                      title={'Newly Hired Workers Statistics'}
                      // series={[
                      //   {
                      //     name: "2023",
                      //     data: [19, 18, 20, 23, 19, 20, 22],
                      //   },
                      //   {
                      //     name: "2024",
                      //     data: [16, 11, 20, 13, 23, 15, 9],
                      //   },
                      // ]}
                      series={newlyHiredWorkerData.series}
                      categories={[
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sept',
                        'Oct',
                        'Nov',
                        'Dec',
                      ]}
                      yaxis={{
                        min: 0,
                        max: newlyHiredWorkerData.max,
                        currency: false,
                      }}
                      stroke={{
                        width: [3, 4, 3],
                        curve: 'straight',
                        dashArray: [0, 8, 5],
                      }}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={6}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Active Workers</h4>
                </CardHeader>
                <CardBody>
                  {activeWorkersData?.series && (
                    <LineApexChart
                      title={'Active Workers'}
                      // series={[
                      //   {
                      //     name: "2023",
                      //     data: [21, 16, 29, 36, 30, 28, 34],
                      //   },
                      //   {
                      //     name: "2024",
                      //     data: [26, 9, 36, 12, 40, 20, 17],
                      //   },
                      // ]}
                      series={activeWorkersData.series}
                      categories={[
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sept',
                        'Oct',
                        'Nov',
                        'Dec',
                      ]}
                      yaxis={{
                        min: 0,
                        max: activeWorkersData.max,
                        currency: false,
                      }}
                      stroke={{
                        width: [3, 3],
                        curve: 'straight',
                      }}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col xl={6}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">End of Contract Workers</h4>
                </CardHeader>
                <CardBody>
                  {endOfContractWorkersData?.series && (
                    <LineApexChart
                      title={'End of Contract Workers'}
                      // series={[
                      //   {
                      //     name: "2023",
                      //     data: [21, 16, 29, 36, 30, 28, 34],
                      //   },
                      //   {
                      //     name: "2024",
                      //     data: [26, 9, 36, 12, 40, 20, 17],
                      //   },
                      // ]}
                      series={endOfContractWorkersData.series}
                      categories={[
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sept',
                        'Oct',
                        'Nov',
                        'Dec',
                      ]}
                      yaxis={{
                        min: 0,
                        max: endOfContractWorkersData.max,
                        currency: false,
                      }}
                      stroke={{
                        width: [3, 3],
                        curve: 'straight',
                      }}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={6}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Terminated Workers</h4>
                </CardHeader>
                <CardBody>
                  {terminatedWorkersData?.series && (
                    <LineApexChart
                      title={'Terminated Workers'}
                      // series={[
                      //   {
                      //     name: "2023",
                      //     data: [21, 16, 29, 36, 30, 28, 34],
                      //   },
                      //   {
                      //     name: "2024",
                      //     data: [26, 9, 36, 12, 40, 20, 17],
                      //   },
                      // ]}
                      series={terminatedWorkersData.series}
                      categories={[
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sept',
                        'Oct',
                        'Nov',
                        'Dec',
                      ]}
                      yaxis={{
                        min: 0,
                        max: terminatedWorkersData.max,
                        currency: false,
                      }}
                      stroke={{
                        width: [3, 3],
                        curve: 'straight',
                      }}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ clientfields, workers }) => ({
  clientfields,
  workers,
});

export default connect(mapStateToProps, { actionCreator })(WorkerBI);
