const defaultCandidateStatusData = [
  {
    id: 1,
    status: "Submitted",
    active: true,
  },
  {
    id: 2,
    status: "Shortlisted",
    active: false,
  },
  {
    id: 3,
    status: "Interview Process",
    active: false,
  },
  {
    id: 6,
    status: "Offered",
    active: false,
  },
  {
    id: 7,
    status: "Accepted",
    active: false,
  },
];

const defaultCandidateStatusActions = [
  {
    id: 2,
    actionStatus: "Shortlist",
  },
  {
    id: 3,
    actionStatus: "Schedule Interview",
    active: false,
  },
  {
    id: 6,
    actionStatus: "Offer",
    active: false,
  },
  {
    id: 7,
    actionStatus: "Accept",
    active: false,
  },
  {
    id: 8,
    actionStatus: "Reject",
    active: false,
  },
];

const defaulStatusActionsForSupplierOps = [
  {
    id: 7,
    actionStatus: "Accept",
    active: false,
  },
];

const defaulStatusActionsForClientHM = [
  {
    id: 3,
    actionStatus: "Schedule Interview",
    active: false,
  },
  {
    id: 6,
    actionStatus: "Offer",
    active: false,
  },
  {
    id: 8,
    actionStatus: "Reject",
    active: false,
  },
];
export {
  defaultCandidateStatusData,
  defaultCandidateStatusActions,
  defaulStatusActionsForSupplierOps,
  defaulStatusActionsForClientHM,
};
