import { call, put, takeLatest } from "redux-saga/effects";

import {
  GET_DOCUMENT_LIST_ALL,
  GET_DOCUMENT_LIST_ALL_SUCCESS,
  GET_DOCUMENT_LIST_ALL_FAILED,
  GET_DOCUMENT_LIST,
  GET_DOCUMENT_LIST_SUCCESS,
  GET_DOCUMENT_LIST_FAILED,
  ADD_NEW_DOCUMENT,
  ADD_NEW_DOCUMENT_SUCCESS,
  ADD_NEW_DOCUMENT_FAILED,
  UPDATE_DOCUMENT,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_FAILED,
} from "./types";

import documentServices from "../../api/services/document";

function* fnGetDocumentListAll() {
  try {
    const data = yield call(documentServices.api.fnGetDocumentListAll);

    yield put({
      type: GET_DOCUMENT_LIST_ALL_SUCCESS,
      payload: [...data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_DOCUMENT_LIST_ALL_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetDocumentList(payload) {
  const sourceId = payload.payload.source_id;
  const type = payload.payload.type;

  try {
    const data = yield call(documentServices.api.fnGetDocumentList, payload);
    yield put({
      type: GET_DOCUMENT_LIST_SUCCESS,
      payload: data.data.result,
    });
  } catch (error) {
    yield put({
      type: GET_DOCUMENT_LIST_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnAddNewDocument({ payload }) {
  try {
    yield call(documentServices.api.fnAddNewDocument, payload);

    yield put({
      type: ADD_NEW_DOCUMENT_SUCCESS,
    });
    yield put({
      type: GET_DOCUMENT_LIST,
    });
  } catch (error) {
    yield put({
      type: ADD_NEW_DOCUMENT_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnUpdateDocument({ payload, module }) {
  const id = payload.get("id");

  try {
    const data = yield call(documentServices.api.fnUpdateDocument, payload);
    if (data.status == 200) {
      yield put({
        type: UPDATE_DOCUMENT_SUCCESS,
        payload: { message: data?.data.message, status: "success" },
      });

      yield put({
        type: GET_DOCUMENT_LIST,
        payload: {
          type: module,
          source_id: id,
        },
      });
    } else {
      yield put({
        type: UPDATE_DOCUMENT_FAILED,
        payload: data?.data.message || "",
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_DOCUMENT_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

export default function* watcher() {
  yield takeLatest(GET_DOCUMENT_LIST_ALL, fnGetDocumentListAll);
  yield takeLatest(GET_DOCUMENT_LIST, fnGetDocumentList);
  yield takeLatest(ADD_NEW_DOCUMENT, fnAddNewDocument);
  yield takeLatest(UPDATE_DOCUMENT, fnUpdateDocument);
}
