import axios from "axios";
import { store, types } from "../store";
/**
 * ===================================
 * START OF AXIOS CONFIGURATION
 * ===================================
 */
const emsApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
  },
  timeout: 50000,
});

const windowServiceApi = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
  },
  timeout: 50000,
});

/**
 * ===================================
 * END OF AXIOS CONFIGURATION
 * ===================================
 */

// interceptor this is for the token
emsApi.interceptors.request.use(
  async (config) => {
    const { authentication } = store.getState();
    if (authentication.token) {
      config.headers.authorization = `${authentication.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// interceptor to be updated depends on requirements
emsApi.interceptors.response.use(
  async (config) => {
    const { authentication } = store.getState();
    if (authentication.token) {
      config.headers = {
        Authorization: `${authentication.token}`,
        "Content-Type": "application/x-www-form-urlencoded",
      };
    }

    return config;
  },
  async function (error) {
    if (error.response.status === 403) {
      // session timeout
      store.dispatch({
        type: types.SESSION_TIMEOUT,
        payload: error.response.data ? error.response.data.message : "",
      });
    }
    return Promise.reject(error);
  }
);

windowServiceApi.interceptors.response.use(
  async (config) => {
    config.headers = {
      "Content-Type": "application/json",
    };
    return config;
  },
  async function (error) {
    if (!error.response) {
      // Network or server error
      console.error("Network/Server error", error);
    } else if (error.response.status === 500 || error.response.status === 403) {
      console.error("Invalid.", error);
    }
    return Promise.reject(error);
  }
);

export { emsApi, windowServiceApi };
