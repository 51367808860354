export const GET_REQUEST_LIST = "@requisition/GET_REQUEST_LIST";
export const GET_REQUEST_LIST_SUCCESS = "@requisition/GET_REQUEST_LIST_SUCCESS";
export const GET_REQUEST_LIST_FAILED = "@requisition/GET_REQUEST_LIST_FAILED";

export const GET_REQUEST_LIST_FILTERED =
  "@requisition/GET_REQUEST_LIST_FILTERED";
export const GET_REQUEST_LIST_FILTERED_SUCCESS =
  "@requisition/GET_REQUEST_LIST_FILTERED_SUCCESS";
export const GET_REQUEST_LIST_FILTERED_FAILED =
  "@requisition/GET_REQUEST_LIST_FILTERED_FAILED";

export const GET_TOTAL = "@requisition/GET_TOTAL";
export const GET_TOTAL_SUCCESS = "@requisition/GET_TOTAL_SUCCESS";
export const GET_TOTAL_FAILED = "@requisition/GET_TOTAL_FAILED";

export const GET_OPTIONS = "@requisition/GET_OPTIONS";
export const GET_OPTIONS_SUCCESS = "@requisition/GET_OPTIONS_SUCCESS";
export const GET_OPTIONS_FAILED = "@requisition/GET_OPTIONS_FAILED";

export const APPROVE_REQUISITION = "@requisition/APPROVE_REQUISITION";
export const APPROVE_REQUISITION_SUCCESS =
  "@requisition/APPROVE_REQUISITION_SUCCESS";
export const APPROVE_REQUISITION_FAILED =
  "@requisition/APPROVE_REQUISITION_FAILED";

export const PROCESS_REQUISITION = "@requisition/PROCESS_REQUISITION";
export const PROCESS_REQUISITION_SUCCESS =
  "@requisition/PROCESS_REQUISITION_SUCCESS";
export const PROCESS_REQUISITION_FAILED =
  "@requisition/PROCESS_REQUISITION_FAILED";

  export const COMPLETE_REQUISITION = "@requisition/COMPLETE_REQUISITION";
  export const COMPLETE_REQUISITION_SUCCESS =
    "@requisition/COMPLETE_REQUISITION_SUCCESS";
  export const COMPLETE_REQUISITION_FAILED =
    "@requisition/COMPLETE_REQUISITION_FAILED";

export const REJECT_REQUISITION = "@requisition/REJECT_REQUISITION";
export const REJECT_REQUISITION_SUCCESS =
  "@requisition/REJECT_REQUISITION_SUCCESS";
export const REJECT_REQUISITION_FAILED =
  "@requisition/REJECT_REQUISITION_FAILED";

export const ADD_REQUISITION = "@requisition/ADD_REQUISITION";
export const ADD_REQUISITION_SUCCESS = "@requisition/ADD_REQUISITION_SUCCESS";
export const ADD_REQUISITION_FAILED = "@requisition/ADD_REQUISITION_FAILED";

export const GET_REQUISITION_WORKER = "@requisition/GET_REQUISITION_WORKER";
export const GET_REQUISITION_WORKER_SUCCESS =
  "@requisition/GET_REQUISITION_WORKER_SUCCESS";
export const GET_REQUISITION_WORKER_FAILED =
  "@requisition/GET_REQUISITION_WORKER_FAILED";

export const CLEAR_MESSAGES = "@requisition/CLEAR_MESSAGES";
