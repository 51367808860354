import { call, put, takeLatest } from "redux-saga/effects";

import {
  SAVE_CONFIGURATION,
  SAVE_CONFIGURATION_SUCCESS,
  SAVE_CONFIGURATION_FAILED,
} from "./types";

import configurationServices from "../../api/services/configuration";

function* fnSaveConfiguration({ payload }) {
  try {
    const result = yield call(configurationServices.api.fnSaveConfiguration, payload);

    if (result) {
      yield put({
        type: SAVE_CONFIGURATION_SUCCESS,
        payload: { message: result.data.message },
      });
    }
  } catch (error) {
    yield put({
      type: SAVE_CONFIGURATION_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

export default function* watcher() {
  yield takeLatest(SAVE_CONFIGURATION, fnSaveConfiguration);
}
