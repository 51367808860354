import jobRequest from "../../assets/images/msp/job-request.png";
import evaluateWorker from "../../assets/images/msp/evaluate-worker.png";
import addNewUser from "../../assets/images/msp/add-new-user.png";
import deactivateUser from "../../assets/images/msp/deactivate-user.png";
import notifyEndContract from "../../assets/images/msp/notify-end.png";
import requestExtension from "../../assets/images/msp/request-extension.png";
import others from "../../assets/images/msp/others.png";

const actions = [
  {
    id: 1,
    title: "Job Request",
    url: "/requisition/job-request",
    desription: "Explain here",
    icon: jobRequest,
  },
  {
    id: 2,
    title: "Evaluate Worker",
    url: "/requisition/evaluate-worker",
    desription: "Explain here",
    icon: evaluateWorker,
  },
  {
    id: 5,
    title: "Early Termination of Contract",
    url: "/requisition/early-termination",
    desription: "Explain here",
    icon: notifyEndContract,
  },
  {
    id: 6,
    title: "Request for Worker Extension",
    url: "/requisition/worker-extension",
    desription: "Explain here",
    icon: requestExtension,
  },
  {
    id: 7,
    title: "Others",
    url: "/requisition/others",
    desription: "Rewards, Bonus, Incentives, PO Changes",
    icon: others,
  },
];

export { actions };
