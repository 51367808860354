import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";

//pass new generated access token here
const token = accessToken;

//apply base url for axios
const API_URL = "";

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}

export const handleErrorResponse = (error) => {
  if (error.response) {
    return {
      payload:
        error.response.data.message || "An error occurred on the server.",
      status: error.response.status,
      data: error.response.data,
    };
  } else if (error.request) {
    return {
      payload:
        "No response received from the server. Please check your internet connection or try again later.",
      status: 500,
      data: "Network Error",
    };
  } else {
    return {
      payload: error.message,
      status: null,
      data: null,
    };
  }
};
