import {
  GET_MENU_SUCCESS,
  GET_MENU_FAILED,
  CLEAR_MENU,
  GET_SELECTED_WORKSHOP_SUCCESS,
  GET_SELECTED_WORKSHOP_FAILED,
} from "./types";

const INITIAL_STATE = {
  loading: true,
  data: [],
  selectedWorkshopData: [],
  error: null,
  errorMessage: null,
};

const app = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.menu,
        error: null,
        errorMessage: null,
      };

    case GET_MENU_FAILED:
      return {
        ...state,
        error: true,
        errorMessage: action.payload.message,
      };

    case CLEAR_MENU:
      return INITIAL_STATE;

    case GET_SELECTED_WORKSHOP_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedWorkshopData: action.payload,
        error: null,
        errorMessage: null,
      };

    case GET_SELECTED_WORKSHOP_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.message,
      };

    default:
      return state;
  }
};

export default app;
