import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TableContainer from '../../components/Common/TableContainer';
import { actionCreator, types } from '../../store';
import Dropzone from 'react-dropzone';
import { useParams, useNavigate } from 'react-router-dom';
import addLogo from '../../assets/images/add.png';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { ToastContainer, toast } from 'react-toastify';

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Dropdown,
  DropdownToggle,
  UncontrolledDropdown,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Collapse,
  DropdownItem,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import { ID, OrigFile, Remarks, File } from './column';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';

import classnames from 'classnames';

const WorkersList = ({
  app,
  workers,
  clients,
  suppliers,
  clientfields,
  document,
  route,
  ...props
}) => {
  document.title = 'Client List';

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const { department, costCenter } = clientfields;

  const [selectedSuppiler, setSelectedSupplier] = React.useState({});

  const [modal, setModal] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);

  //Information
  const [addresses, setAddresses] = React.useState([]);
  const [evaluations, setEvaluations] = React.useState([]);
  const [contacts, setContacts] = React.useState([]);
  const [notes, setNotes] = React.useState([]);
  const [documents, setDocuments] = React.useState([]);

  //documents
  const [documentsCol, setDocumentsCol] = React.useState([]);

  const [Options1, setOptions1] = React.useState([]);
  const [Options2, setOptions2] = React.useState([]);

  const [filter, setFilter] = React.useState({
    filterByClient: '',
    filterByDepartment: '',
    filterBySupplier: '',
  });

  //Dropzone
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState([]);

  //Tabs
  const [headerPillsTab, setHeaderPillsTab] = useState('1');

  const [dynamicOptionsValue, setDynamicOptionsValue] = React.useState({});
  const [dynamicOptionsValue2, setDynamicOptionsValue2] = React.useState({});

  const [documentDynamicOptionsValue, setDocumentDynamicOptionsValue] =
    React.useState([]);
  const [documentDynamicOptionsValue2, setDocumentDynamicOptionsValue2] =
    React.useState([]);
  const [documentListDynamicOptionsValue, setDocumentListDynamicOptionsValue] =
    React.useState([]);

  //Get ID and Check Add/Edit
  const { id } = useParams();

  const breadcrumbItem = isEdit && id ? 'Manage Worker' : 'Add Worker';

  // validation
  const validationInformation = useFormik({
    enableReinitialize: true,
    initialValues: dynamicOptionsValue,
    validationSchema: Yup.object(dynamicOptionsValue2),
    onSubmit: (values) => {
      if (isEdit) {
        values.id = id;
        // update suppliers
        props.actionCreator({
          type: types.UPDATE_WORKER,
          payload: values,
        });
      } else {
        // add new workers
        props.actionCreator({
          type: types.ADD_NEW_WORKER,
          payload: values,
        });

        //Redirect to /workers
        navigate('/workers', { state: { reload: true } });
      }
    },
    errors: (values) => {
      console.log('error', values);
    },
  });

  // validation document
  const validationDocument = useFormik({
    enableReinitialize: true,
    initialValues: documentDynamicOptionsValue,
    validationSchema: Yup.object(documentDynamicOptionsValue2),
    onSubmit: (values) => {
      if (selectedFiles.length === 0) {
        setShowFileError(true);
        return true;
      }
      documentsCol.push({
        document_name: values.document_name,
        owner_remarks: values.owner_remarks,
        pmo_remarks: '',
        file: selectedFiles,
      });
      validationDocumentList.setFieldValue(`documents`, documentsCol);
      validationDocument.resetForm();
      setSelectedFiles([]);
      setModal(false);
    },
  });

  // validation document
  const validationDocumentList = useFormik({
    enableReinitialize: true,
    initialValues: documentListDynamicOptionsValue,
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      const formData = new FormData();

      values.documents.forEach((value, index) => {
        if (value?.file?.name) {
          formData.append(`files_${index}`, value.file, value.file.name);
        }
      });

      formData.append('id', id);
      formData.append('type', 'worker');
      formData.append('documents', JSON.stringify(values.documents));

      // update document
      props.actionCreator({
        type: types.UPDATE_DOCUMENT,
        payload: formData,
        module: 'worker',
      });
    },
  });

  const toggleHeaderPills = (tab) => {
    if (headerPillsTab !== tab) setHeaderPillsTab(tab);
  };

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    setSelectedFiles(files);
  }

  const addMoreAddressHandler = () => {
    const finalAddress = validationInformation.values.addresses;
    finalAddress.push({
      id: '',
      building_name: '',
      unit: '',
      postal_code: '',
      address: '',
      city: '',
      country: '',
    });
    setAddresses(finalAddress);
  };

  const removeAddressHandler = (index) => {
    if (addresses && addresses.length > 0) {
      setAddresses((addresses) => addresses.filter((_, i) => i !== index));
      if (
        validationInformation.values?.addresses &&
        validationInformation.values.addresses.length > 0
      ) {
        validationInformation.setFieldValue(
          `addresses`,
          validationInformation.values.addresses.filter((_, i) => i !== index),
        );
      }
    }
  };

  const addMoreEvaluationHandler = () => {
    const finalEvaluation = validationInformation.values.evaluations;
    finalEvaluation.push({
      id: '',
      evaluated_by: '',
      rating: '',
      remarks: '',
    });
    setEvaluations(finalEvaluation);
  };

  const removeEvaluationHandler = (index) => {
    if (evaluations && evaluations.length > 0) {
      setEvaluations((evaluations) =>
        evaluations.filter((_, i) => i !== index),
      );
      if (
        validationInformation.values?.evaluations &&
        validationInformation.values.evaluations.length > 0
      ) {
        validationInformation.setFieldValue(
          `evaluations`,
          validationInformation.values.evaluations.filter(
            (_, i) => i !== index,
          ),
        );
      }
    }
  };

  const addMoreNotesHandler = () => {
    const finalNotes = validationInformation.values.notes;
    finalNotes.push({
      note: '',
    });
    setNotes(finalNotes);
  };

  const removeSelectedNotesHandler = (index) => {
    if (notes && notes.length > 0) {
      setNotes((notes) => notes.filter((_, i) => i !== index));
      if (
        validationInformation.values?.notes &&
        validationInformation.values.notes.length > 0
      ) {
        validationInformation.setFieldValue(
          `notes`,
          validationInformation.values.notes.filter((_, i) => i !== index),
        );
      }
    }
  };

  const removeSelectedDocumentHandler = (index) => {
    if (documentsCol && documentsCol.length > 0) {
      setDocumentsCol((document) => document.filter((_, i) => i !== index));
      if (
        validationDocumentList.values?.documents &&
        validationDocumentList.values.documents.length > 0
      ) {
        validationDocumentList.setFieldValue(
          `documents`,
          validationDocumentList.values.documents.filter((_, i) => i !== index),
        );
      }
    }
  };

  const handleWorkerNotification = async (message) => {
    toast(message, {
      position: 'top-right',
      hideProgressBar: true,
      className: 'bg-success text-white',
    });

    clearWorkerSaveMessage();
  };

  const handleNotification = async (message) => {
    toast(message, {
      position: 'top-right',
      hideProgressBar: true,
      className: 'bg-success text-white',
    });

    clearDocumentSaveMessage();
  };

  const clearWorkerSaveMessage = async () => {
    await props.actionCreator({
      type: types.CLEAR_WORKER_SAVE_MESSAGE,
    });
  };

  const clearDocumentSaveMessage = async () => {
    await props.actionCreator({
      type: types.CLEAR_DOCUMENT_SAVE_MESSAGE,
    });
  };

  const handleErrorNotification = async (message) => {
    toast(message, {
      position: 'top-right',
      hideProgressBar: true,
      className: 'bg-danger text-white',
    });
  };

  React.useEffect(() => {
    workers.isSubmitted &&
      workers.actionReturnData?.message &&
      handleWorkerNotification(workers.actionReturnData.message);
    workers.error &&
      workers.errorMessage &&
      handleErrorNotification(workers.errorMessage);
  }, [workers.actionReturnData.message]);

  React.useEffect(() => {
    const edit = location.pathname.includes('/edit');
    setIsEdit(edit);
    if (edit) {
      props.actionCreator({
        type: types.GET_WORKER_LIST,
        payload: { id: id },
      });
      props.actionCreator({
        type: types.GET_DOCUMENT_LIST,
        payload: {
          type: 'worker',
          source_id: id,
        },
      });
    }

    props.actionCreator({
      type: types.GET_CLIENT_LIST_ALL,
    });
    props.actionCreator({
      type: types.GET_SUPPLIER_LIST_ALL,
    });
    props.actionCreator({
      type: types.GET_CLIENTS_LIST_SUPPLIERS_REPORTINGMANAGER,
    });

    setFilter({ filterByClient: '' });

    const selectedWorker = {
      client_id: '',
      supplier_id: '',
      name: '',
      job_title: '',
      contract_start_date: '',
      contract_end_date: '',
      department: '',
      cost_center: '',
      reason_for_hiring: '',
      company_email: '',
      job_order_type: '',
      date_jo_opened: '',
      date_jo_filled: '',
      added_by: '',
      date_added: '',
      date_modified: '',
      status: '1',
      employee_no: '',
      reporting_manager_id: '',
      contract_type: '',
      po_no: '',
      job_order_id: '',
      reason_for_termination: '',
      date_terminated: '',
      date_of_birth: '',
      nationality: '',
      personal_email: '',
      mobile: '',
      addresses: [],
      evaluations: [],
      notes: [],
    };

    setDynamicOptionsValue(selectedWorker);

    setDynamicOptionsValue2({
      name: Yup.string().required('Enter Name'),
      status: Yup.string().required('Select Status'),
      client_id: Yup.string().required('Select Client'),
      supplier_id: Yup.string().required('Select Supplier'),
      // employee_no: Yup.string().required("Enter Employee No"),
      // job_title: Yup.string().required("Enter Job Title"),
      // contract_start_date: Yup.string().required("Enter Contract Start date"),
      // contract_end_date: Yup.string().required("Enter Contract End date"),
      department: Yup.string().required('Select Department'),
      // cost_center: Yup.string().required("Select Cost Center"),
      // reason_for_hiring: Yup.string().required("Select Reason For Hiring"),
      // company_email: Yup.string().required("Enter Company Email"),
      // job_order_type: Yup.string().required("Select Job Order Type"),
      // reporting_manager_id: Yup.string().required("Select Reporting Manager"),
      contract_type: Yup.string().required('Select Contract Type'),
      addresses: Yup.array(
        Yup.object({
          building_name: Yup.string().required('Enter Building Name'),
          unit: Yup.string().required('Enter Unit'),
          postal_code: Yup.string().required('Enter Postal Code'),
          address: Yup.string().required('Enter Address'),
          city: Yup.string().required('Enter City'),
          country: Yup.string().required('Enter Country'),
        }),
      ),
      evaluations: Yup.array(
        Yup.object({
          evaluated_by: Yup.string().required('Enter Evaluate By'),
          rating: Yup.string().required('Enter Rating'),
          remarks: Yup.string().required('Enter Remarks'),
        }),
      ),
      notes: Yup.array(
        Yup.object({
          note: Yup.string().required('Enter Notes'),
        }),
      ),
    });

    setDocumentDynamicOptionsValue({
      document_name: '',
      file: '',
    });
    setDocumentDynamicOptionsValue2({
      document_name: Yup.string().required('Enter Document Name'),
      file: Yup.mixed().required('Select a File'),
    });

    setDocumentListDynamicOptionsValue({
      documents: {
        document_name: '',
        owner_remarks: '',
        pmo_remarks: '',
        file: '',
      },
    });
  }, []);

  React.useEffect(() => {
    if (isEdit && workers?.data) {
      setFilter({ filterByClient: workers.data?.client_id || '' });

      // Set the Options1 based on the selected client_id
      const clientData = clients.suprepData[0]?.data;
      if (clientData) {
        const parsedClientData = JSON.parse(clientData);
        const selectedClient = parsedClientData.find(
          (client) => client.id === workers.data.client_id,
        );

        if (selectedClient) {
          setOptions1(selectedClient.suppliers || []);
          setOptions2(selectedClient.reportingmanager || []);
        }
      }

      let selectedWorker = {
        client_id: '',
        supplier_id: '',
        name: '',
        job_title: '',
        contract_start_date: '',
        contract_end_date: '',
        department: '',
        cost_center: '',
        reason_for_hiring: '',
        company_email: '',
        job_order_type: '',
        date_jo_opened: '',
        date_jo_filled: '',
        added_by: '',
        date_added: '',
        date_modified: '',
        status: '1',
        employee_no: '',
        reporting_manager_id: '',
        contract_type: '',
        po_no: '',
        job_order_id: '',
        reason_for_termination: '',
        date_terminated: '',
        date_of_birth: '',
        nationality: '',
        personal_email: '',
        mobile: '',
        addresses: [],
        evaluations: [],
        notes: [],
      };

      selectedWorker = {
        name: workers.data?.name,
        status: workers.data?.status || '1',
        client_id: workers.data?.client_id || '',
        supplier_id: workers.data?.supplier_id || '',
        employee_no: workers.data?.employee_no || '',
        job_title: workers.data?.job_title || '',
        contract_start_date:
          formatDate(workers.data?.contract_start_date) || '',
        contract_end_date: formatDate(workers.data?.contract_end_date) || '',
        department: workers.data?.department || '',
        cost_center: workers.data?.cost_center || '',
        reason_for_hiring: workers.data?.reason_for_hiring || '',
        company_email: workers.data?.company_email || '',
        job_order_type: workers.data?.job_order_type || '',
        date_jo_opened: formatDate(workers.data?.date_jo_opened) || '',
        date_jo_filled: formatDate(workers.data?.date_jo_filled) || '',
        reporting_manager_id: workers.data?.reporting_manager_id || '',
        contract_type: workers.data?.contract_type || '',
        po_no: workers.data?.po_no || '',
        job_order_id: workers.data?.job_order_id || '',
        reason_for_termination: workers.data?.reason_for_termination || '',
        date_terminated: formatDate(workers.data?.date_terminated) || '',
        date_of_birth: formatDate(workers.data?.date_of_birth) || '',
        nationality: workers.data?.nationality || '',
        personal_email: workers.data?.personal_email || '',
        mobile: workers.data?.mobile || '',
        addresses: workers.data?.addresses || [],
        evaluations: workers.data?.evaluations || [],
        notes: workers.data?.notes || [],
      };
      setDynamicOptionsValue(selectedWorker);
      setAddresses(selectedWorker.addresses);
      setEvaluations(selectedWorker.evaluations);
      setNotes(selectedWorker.notes);

      const selectedDocuments = {
        documents: document.data,
      };

      setDocumentsCol(document.data);
      setDocumentListDynamicOptionsValue({
        documents: selectedDocuments.documents,
      });
    }
  }, [workers, isEdit, clients.suprepData, document]);

  React.useEffect(() => {
    if (filter.filterByClient) {
      fetchDepartment();
      fetchCostCenter();
    }
  }, [filter.filterByClient]);

  const fetchDepartment = async () => {
    await props.actionCreator({
      type: types.GET_DEPARTMENT_OPTIONS,
      payload: {
        type: 'department',
        client_id: filter.filterByClient,
      },
    });
  };

  const fetchCostCenter = async () => {
    await props.actionCreator({
      type: types.GET_COST_CENTER_OPTIONS,
      payload: {
        type: 'cost center',
        client_id: filter.filterByClient,
      },
    });
  };

  React.useEffect(() => {
    document.isSubmitted &&
      handleNotification(document.actionReturnData.message);
    document.error &&
      document.errorMessage &&
      handleErrorNotification(document.errorMessage);

    if (isEdit) {
      setDocumentsCol(document.data);
      setDocumentListDynamicOptionsValue({ documents: document.data });
    }
  }, [document.actionReturnData.message]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleWorkerDocumentClick = (arg) => {
    const suppliers = arg;
    setSelectedSupplier({
      id: suppliers.id,
      supplier_name: suppliers.supplier_name,
      uen_no: suppliers.uen_no,
    });
    // setIsEdit(true);
    toggle();
  };

  const handleDocumentClick = () => {
    validationDocument.resetForm();

    toggle();
  };

  const handleSupplierClick = (arg) => {
    const suppliers = arg;

    setSelectedSupplier({
      id: suppliers.id,
      supplier_name: suppliers.supplier_name,
      uen_no: suppliers.uen_no,
    });

    setIsEdit(true);

    toggle();
  };

  const handleFilter = (filter, event) => {
    validationInformation.handleChange(event); // Ensure this still handles validation

    if (filter === 'client') {
      setFilter((prevFilter) => {
        const newFilter = {
          ...prevFilter,
          filterByClient: event.target.value,
        };

        return newFilter;
      });
    }

    // props.actionCreator({
    //   type: types.GET_CLIENT_LIST,
    //   payload: { id: event.target.value },
    // });
  };

  // const handleDocumentClick = (arg) => {
  //   const suppliers = arg;
  //   setSelectedSupplier({
  //     id: suppliers.id,
  //     supplier_name: suppliers.supplier_name,
  //     uen_no: suppliers.uen_no,
  //   });
  //   setIsEdit(true);
  //   toggle();
  // };

  var node = React.useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete suppliers modal
  const [deleteModal, setDeleteModal] = React.useState(false);

  const onClickDelete = (suppliers) => {
    setSelectedSupplier(suppliers);
    setDeleteModal(true);
  };

  const handleDeleteSupplier = () => {
    props.actionCreator({
      type: types.DELETE_SUPPLIER,
      payload: {
        id: selectedSuppiler.id,
      },
    });
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleSupplierClicks = () => {
    setSelectedSupplier({});
    setIsEdit(false);
    toggle();
  };

  const formatDate = (date) => {
    return date ? moment(date).format('YYYY-MM-DD') : '';
  };

  const handleSave = () => {
    validationInformation.handleSubmit(); // This triggers the formik onSubmit
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const supplierData = cellProps.row.original;
          return <ID {...cellProps} />;
        },
      },
      {
        Header: 'Document Name',
        accessor: 'orig_file',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const supplierData = cellProps.row.original;
          return <OrigFile {...cellProps} />;
        },
      },
      {
        Header: 'Remarks',
        accessor: 'owner_remarks',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const supplierData = cellProps.row.original;
          return <Remarks {...cellProps} />;
        },
      },
      {
        Header: 'File',
        accessor: 'new_file',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const document = cellProps.row.original; // Get the entire document object

          if (document?.fileObject) {
            const fileUrl = URL.createObjectURL(document.fileObject); // Create URL for the file
            return (
              <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                <i
                  className="mdi mdi-file-document-outline font-size-18"
                  id="edittooltip"
                ></i>
              </a>
            );
          } else {
            const { new_file } = cellProps.row.original;
            return (
              <a
                href={`${baseUrl}${new_file}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  className="mdi mdi-file-document-outline font-size-18"
                  id="edittooltip"
                ></i>
              </a>
            );
          }
        },
      },
      {
        Header: 'PMO Remarks',
        accessor: 'pmo_remarks',
        filterable: false,
        disableFilters: true,
        Cell: ({ row, value }) => {
          const handleChange = (e) => {
            const newValue = e.target.value;
            setDocuments((prevDocuments) =>
              prevDocuments.map((doc, index) =>
                index === row.index ? { ...doc, pmo_remarks: newValue } : doc,
              ),
            );
          };

          // Ensure value is always a string
          const safeValue = value ?? '';

          return (
            <Input
              name={`pmo_remarks.${row.index}`}
              type="text"
              value={safeValue}
              onChange={handleChange}
            />
          );
        },
      },
      {
        Header: 'Action',
        Cell: (cellProps) => {
          // Access the row index from cellProps
          const rowIndex = cellProps.row.index;

          return (
            <div className="d-flex gap-3">
              <Link
                className="text-danger"
                to="#"
                onClick={() => onClickFileDelete(rowIndex)}
              >
                <button className="btn btn-danger" id="deletetooltip">
                  Delete
                </button>
              </Link>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteSupplier}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Workers"
            breadcrumbItem={breadcrumbItem}
            titleLink="/workers"
          />{' '}
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header align-items-center d-flex">
                <div className="flex-shrink-0">
                  <ul
                    className="nav justify-content-end nav-pills card-header-pills"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({
                          active: headerPillsTab === '1',
                        })}
                        onClick={() => {
                          toggleHeaderPills('1');
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          Worker Information
                        </span>
                      </NavLink>
                    </NavItem>
                    {isEdit && (
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: headerPillsTab === '2',
                          })}
                          onClick={() => {
                            toggleHeaderPills('2');
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-user"></i>
                          </span>
                          <span className="d-none d-sm-block">Documents</span>
                        </NavLink>
                      </NavItem>
                    )}
                  </ul>
                </div>
              </div>

              <div className="card-body">
                <TabContent className="text-muted" activeTab={headerPillsTab}>
                  <TabPane tabId="1">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validationInformation.handleSubmit();
                        return false;
                      }}
                    >
                      <Row>
                        <Col xs={12}>
                          <Card>
                            <CardHeader>
                              <h4 className="card-title">Service Data</h4>
                              <p className="card-title-desc">Explain here.</p>
                            </CardHeader>
                            <CardBody className="p-4">
                              <Row>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">Name</Label>
                                    <Input
                                      name="name"
                                      type="text"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation.values.name || ''
                                      }
                                      invalid={
                                        validationInformation.touched.name &&
                                        validationInformation.errors.name
                                          ? true
                                          : false
                                      }
                                    />
                                    {validationInformation.touched.name &&
                                    validationInformation.errors.name ? (
                                      <FormFeedback type="invalid">
                                        {validationInformation.errors.name}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <Label className="form-label">Status</Label>
                                  <div className="mb-3">
                                    <Input
                                      name="status"
                                      type="select"
                                      className="form-select"
                                      multiple={false}
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation.values.status ||
                                        ''
                                      }
                                      invalid={
                                        validationInformation.touched.status &&
                                        validationInformation.errors.status
                                      }
                                    >
                                      <option value={''}>select</option>
                                      <option value={'1'}>Active</option>
                                      <option value={'2'}>In-Active</option>
                                      <option value={'3'}>Terminated</option>
                                    </Input>

                                    {validationInformation.touched.status &&
                                    validationInformation.errors.status ? (
                                      <FormFeedback type="invalid">
                                        {validationInformation.errors.status}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <Label className="form-label">Client</Label>
                                  <div className="mb-3">
                                    <Input
                                      name="client_id"
                                      type="select"
                                      className="form-select"
                                      multiple={false}
                                      onChange={(e) => {
                                        handleFilter('client', e);
                                        setOptions1(
                                          JSON.parse(
                                            clients.suprepData[0]?.data,
                                          ).filter(
                                            (item, index, self) =>
                                              item.id == e.target.value,
                                          )[0]?.suppliers,
                                        );
                                        setOptions2(
                                          JSON.parse(
                                            clients.suprepData[0]?.data,
                                          ).filter(
                                            (item, index, self) =>
                                              item.id == e.target.value,
                                          )[0]?.reportingmanager,
                                        );
                                      }}
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation.values
                                          .client_id || ''
                                      }
                                      invalid={
                                        validationInformation.touched
                                          .client_id &&
                                        validationInformation.errors.client_id
                                          ? true
                                          : false
                                      }
                                    >
                                      <option value={''}>select</option>
                                      {clients.suprepData[0] &&
                                        JSON.parse(
                                          clients.suprepData[0].data,
                                        ).map((client, index) => (
                                          <option key={index} value={client.id}>
                                            {client.trade_name}{' '}
                                          </option>
                                        ))}
                                    </Input>
                                    {validationInformation.touched.client_id &&
                                    validationInformation.errors.client_id ? (
                                      <FormFeedback type="invalid">
                                        {validationInformation.errors.client_id}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <Label className="form-label">Supplier</Label>
                                  <div className="mb-3">
                                    <Input
                                      type="select"
                                      name="supplier_id"
                                      className="form-select"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation?.values
                                          ?.supplier_id || ''
                                      }
                                      invalid={
                                        validationInformation.touched
                                          .supplier_id &&
                                        validationInformation.errors.supplier_id
                                          ? true
                                          : false
                                      }
                                    >
                                      <option value={''}>select</option>
                                      {Options1 &&
                                        Options1.map((supplier, index) => (
                                          <option
                                            key={index}
                                            value={supplier.supplier_id}
                                          >
                                            {supplier.supplier_name}
                                          </option>
                                        ))}
                                    </Input>

                                    {validationInformation.touched
                                      .supplier_id &&
                                    validationInformation.errors.supplier_id ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validationInformation.errors
                                            .supplier_id
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Employee No.
                                    </Label>
                                    <Input
                                      name="employee_no"
                                      type="text"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation.values
                                          .employee_no || ''
                                      }
                                      invalid={
                                        validationInformation.touched
                                          .employee_no &&
                                        validationInformation.errors.employee_no
                                          ? true
                                          : false
                                      }
                                    />
                                    {validationInformation.touched
                                      .employee_no &&
                                    validationInformation.errors.employee_no ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validationInformation.errors
                                            .employee_no
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Job Title
                                    </Label>
                                    <Input
                                      name="job_title"
                                      type="text"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation.values
                                          .job_title || ''
                                      }
                                      invalid={
                                        validationInformation.touched
                                          .job_title &&
                                        validationInformation.errors.job_title
                                          ? true
                                          : false
                                      }
                                    />
                                    {validationInformation.touched.job_title &&
                                    validationInformation.errors.job_title ? (
                                      <FormFeedback type="invalid">
                                        {validationInformation.errors.job_title}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Contract Start Date
                                    </Label>
                                    <Input
                                      name="contract_start_date"
                                      type="date"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation.values
                                          .contract_start_date || ''
                                      }
                                      invalid={
                                        validationInformation.touched
                                          .contract_start_date &&
                                        validationInformation.errors
                                          .contract_start_date
                                          ? true
                                          : false
                                      }
                                    />
                                    {validationInformation.touched
                                      .contract_start_date &&
                                    validationInformation.errors
                                      .contract_start_date ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validationInformation.errors
                                            .contract_start_date
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Contract End Date
                                    </Label>
                                    <Input
                                      name="contract_end_date"
                                      type="date"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation.values
                                          .contract_end_date || ''
                                      }
                                      invalid={
                                        validationInformation.touched
                                          .contract_end_date &&
                                        validationInformation.errors
                                          .contract_end_date
                                          ? true
                                          : false
                                      }
                                    />
                                    {validationInformation.touched
                                      .contract_end_date &&
                                    validationInformation.errors
                                      .contract_end_date ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validationInformation.errors
                                            .contract_end_date
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={3}>
                                  <Label className="form-label">
                                    Department
                                  </Label>
                                  <div className="mb-3">
                                    <Input
                                      type="select"
                                      name="department"
                                      className="form-select"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation?.values
                                          ?.department || ''
                                      }
                                      invalid={
                                        validationInformation.touched
                                          .department &&
                                        validationInformation.errors.department
                                          ? true
                                          : false
                                      }
                                    >
                                      <option value={''}>select</option>
                                      {department.data.map((depart, index) => (
                                        <option
                                          key={index}
                                          value={depart.value}
                                        >
                                          {depart.label}
                                        </option>
                                      ))}
                                    </Input>

                                    {validationInformation.touched.department &&
                                    validationInformation.errors.department ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validationInformation.errors
                                            .department
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <Label className="form-label">
                                    Cost Center
                                  </Label>
                                  <div className="mb-3">
                                    <Input
                                      type="select"
                                      name="cost_center"
                                      className="form-select"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation?.values
                                          ?.cost_center || ''
                                      }
                                      invalid={
                                        validationInformation.touched
                                          .cost_center &&
                                        validationInformation.errors.cost_center
                                          ? true
                                          : false
                                      }
                                    >
                                      <option value={''}>select</option>
                                      {costCenter.data.map((cost, index) => (
                                        <option key={index} value={cost.value}>
                                          {cost.label}
                                        </option>
                                      ))}
                                    </Input>

                                    {validationInformation.touched
                                      .cost_center &&
                                    validationInformation.errors.cost_center ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validationInformation.errors
                                            .cost_center
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <Label className="form-label">
                                    Reason for Hiring
                                  </Label>
                                  <div className="mb-3">
                                    <Input
                                      name="reason_for_hiring"
                                      type="select"
                                      className="form-select"
                                      multiple={false}
                                      onChange={(e) => {
                                        validationInformation.handleChange(e);
                                      }}
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation.values
                                          .reason_for_hiring || ''
                                      }
                                      invalid={
                                        validationInformation.touched
                                          .reason_for_hiring &&
                                        validationInformation.errors
                                          .reason_for_hiring
                                          ? true
                                          : false
                                      }
                                    >
                                      <option value={''}>select</option>
                                      <option value={'Not Specified'}>
                                        Not Specified
                                      </option>
                                      <option value={'Increased Demand'}>
                                        Increased Demand
                                      </option>
                                      <option value={'Maternity Replacement'}>
                                        Maternity Replacement
                                      </option>
                                      <option value={'Temporary Replacement'}>
                                        Temporary Replacement
                                      </option>
                                      <option value={'New Position'}>
                                        New Position
                                      </option>
                                      <option
                                        value={'Service Contract Execution'}
                                      >
                                        Service Contract Execution
                                      </option>
                                    </Input>
                                    {validationInformation.touched
                                      .reason_for_hiring &&
                                    validationInformation.errors
                                      .reason_for_hiring ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validationInformation.errors
                                            .reason_for_hiring
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Company Email
                                    </Label>
                                    <Input
                                      name="company_email"
                                      type="text"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation.values
                                          .company_email || ''
                                      }
                                      invalid={
                                        validationInformation.touched
                                          .company_email &&
                                        validationInformation.errors
                                          .company_email
                                          ? true
                                          : false
                                      }
                                    />
                                    {validationInformation.touched
                                      .company_email &&
                                    validationInformation.errors
                                      .company_email ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validationInformation.errors
                                            .company_email
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={3}>
                                  <Label className="form-label">
                                    Job Order Type
                                  </Label>
                                  <div className="mb-3">
                                    <Input
                                      name="job_order_type"
                                      type="select"
                                      className="form-select"
                                      multiple={false}
                                      onChange={(e) => {
                                        validationInformation.handleChange(e);
                                      }}
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation.values
                                          .job_order_type || ''
                                      }
                                      invalid={
                                        validationInformation.touched
                                          .job_order_type &&
                                        validationInformation.errors
                                          .job_order_type
                                          ? true
                                          : false
                                      }
                                    >
                                      <option value={''}>select</option>
                                      <option value={'New'}>New</option>
                                      <option value={'Replace'}>Replace</option>
                                    </Input>
                                    {validationInformation.touched
                                      .job_order_type &&
                                    validationInformation.errors
                                      .job_order_type ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validationInformation.errors
                                            .job_order_type
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <Label className="form-label">
                                    Reporting Manager
                                  </Label>
                                  <div className="mb-3">
                                    <Input
                                      name="reporting_manager_id"
                                      type="select"
                                      className="form-select"
                                      multiple={false}
                                      onChange={(e) => {
                                        validationInformation.handleChange(e);
                                      }}
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation.values
                                          .reporting_manager_id || ''
                                      }
                                      invalid={
                                        validationInformation.touched
                                          .reporting_manager_id &&
                                        validationInformation.errors
                                          .reporting_manager_id
                                          ? true
                                          : false
                                      }
                                    >
                                      <option value={''}>select</option>
                                      {Options2 &&
                                        Options2.map((item, index) => (
                                          <option key={index} value={item.id}>
                                            {item.name}
                                          </option>
                                        ))}
                                    </Input>
                                    {validationInformation.touched
                                      .reporting_manager_id &&
                                    validationInformation.errors
                                      .reporting_manager_id ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validationInformation.errors
                                            .reporting_manager_id
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <Label className="form-label">
                                    Contract Type
                                  </Label>
                                  <div className="mb-3">
                                    <Input
                                      name="contract_type"
                                      type="select"
                                      className="form-select"
                                      multiple={false}
                                      onChange={(e) => {
                                        validationInformation.handleChange(e);
                                      }}
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation.values
                                          .contract_type || ''
                                      }
                                      invalid={
                                        validationInformation.touched
                                          .contract_type &&
                                        validationInformation.errors
                                          .contract_type
                                          ? true
                                          : false
                                      }
                                    >
                                      <option value={''}>select</option>
                                      <option value={'Staff Augmentation'}>
                                        Staff Augmentation
                                      </option>
                                      <option value={'Outsourcing of Services'}>
                                        Statement of Work
                                      </option>
                                      <option
                                        value={'Outsourcing of Activities'}
                                      >
                                        Freelancers
                                      </option>
                                    </Input>
                                    {validationInformation.touched
                                      .contract_type &&
                                    validationInformation.errors
                                      .contract_type ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validationInformation.errors
                                            .contract_type
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">PO No.</Label>
                                    <Input
                                      name="po_no"
                                      type="text"
                                      placeholder="optional"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation.values.po_no || ''
                                      }
                                      invalid={
                                        validationInformation.touched.po_no &&
                                        validationInformation.errors.po_no
                                          ? true
                                          : false
                                      }
                                    />
                                    {validationInformation.touched.po_no &&
                                    validationInformation.errors.po_no ? (
                                      <FormFeedback type="invalid">
                                        {validationInformation.errors.po_no}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Job Order No.
                                    </Label>
                                    <Input
                                      name="job_order_id"
                                      type="text"
                                      placeholder="optional"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation.values
                                          .job_order_id || ''
                                      }
                                      invalid={
                                        validationInformation.touched
                                          .job_order_id &&
                                        validationInformation.errors
                                          .job_order_id
                                          ? true
                                          : false
                                      }
                                    />
                                    {validationInformation.touched
                                      .job_order_id &&
                                    validationInformation.errors
                                      .job_order_id ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validationInformation.errors
                                            .job_order_id
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Date Job Order Opened
                                    </Label>
                                    <Input
                                      name="date_jo_opened"
                                      type="date"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation.values
                                          .date_jo_opened || ''
                                      }
                                    />
                                  </div>
                                </Col>{' '}
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Date Job Order Filled
                                    </Label>
                                    <Input
                                      name="date_jo_filled"
                                      type="date"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation.values
                                          .date_jo_filled || ''
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xs={12}>
                          <Card>
                            <CardHeader>
                              <Row>
                                <Col md={6}>
                                  <div className="mb-3">
                                    <h5 className="card-title">
                                      Termination Details
                                    </h5>
                                    <p className="card-title-desc">
                                      Explain here.
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody className="p-4">
                              <Row>
                                <Col lg={3}>
                                  <Label className="form-label">
                                    Reason For Termination
                                  </Label>
                                  <div className="mb-3">
                                    <Input
                                      name="reason_for_termination"
                                      type="text"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation.values
                                          .reason_for_termination || ''
                                      }
                                      invalid={
                                        validationInformation.touched
                                          .reason_for_termination &&
                                        validationInformation.errors
                                          .reason_for_termination
                                          ? true
                                          : false
                                      }
                                    />
                                    {validationInformation.touched
                                      .reason_for_termination &&
                                    validationInformation.errors
                                      .reason_for_termination ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validationInformation.errors
                                            .reason_for_termination
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Termination Date
                                    </Label>
                                    <Input
                                      name="date_terminated"
                                      type="date"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation.values
                                          .date_terminated || ''
                                      }
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xs={12}>
                          <Card>
                            <CardHeader>
                              <Row>
                                <Col md={6}>
                                  <div className="mb-3">
                                    <h5 className="card-title">
                                      Other Employee Data{' '}
                                    </h5>
                                    <p className="card-title-desc">
                                      Explain here.
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody className="p-4">
                              <Row>
                                {/* <Col lg={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Contract Start Date
                                    </Label>
                                    <Input
                                      name="contract_start_date"
                                      type="date"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation.contract_start_date
                                      }
                                    />
                                  </div>
                                </Col> */}
                                <Col lg={3}>
                                  <Label className="form-label">
                                    Date Of Birth
                                  </Label>
                                  <div className="mb-3">
                                    <Input
                                      name="date_of_birth"
                                      type="date"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation.values
                                          .date_of_birth || ''
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Nationality
                                    </Label>
                                    <Input
                                      name="nationality"
                                      type="text"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation.values
                                          .nationality || ''
                                      }
                                      invalid={
                                        validationInformation.touched
                                          .nationality &&
                                        validationInformation.errors.nationality
                                          ? true
                                          : false
                                      }
                                    />
                                    {validationInformation.touched
                                      .nationality &&
                                    validationInformation.errors.nationality ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validationInformation.errors
                                            .nationality
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Personal Email
                                    </Label>
                                    <Input
                                      name="personal_email"
                                      type="text"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation.values
                                          .personal_email || ''
                                      }
                                      invalid={
                                        validationInformation.touched
                                          .personal_email &&
                                        validationInformation.errors
                                          .personal_email
                                          ? true
                                          : false
                                      }
                                    />
                                    {validationInformation.touched
                                      .personal_email &&
                                    validationInformation.errors
                                      .personal_email ? (
                                      <FormFeedback type="invalid">
                                        {
                                          validationInformation.errors
                                            .personal_email
                                        }
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">Mobile</Label>
                                    <Input
                                      name="mobile"
                                      type="text"
                                      onChange={
                                        validationInformation.handleChange
                                      }
                                      onBlur={validationInformation.handleBlur}
                                      value={
                                        validationInformation.values.mobile ||
                                        ''
                                      }
                                      invalid={
                                        validationInformation.touched.mobile &&
                                        validationInformation.errors.mobile
                                          ? true
                                          : false
                                      }
                                    />
                                    {validationInformation.touched.mobile &&
                                    validationInformation.errors.mobile ? (
                                      <FormFeedback type="invalid">
                                        {validationInformation.errors.mobile}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xs={12}>
                          <Card>
                            <CardHeader>
                              <Row>
                                <Col md={6}>
                                  <div className="mb-3">
                                    <h5 className="card-title">Address</h5>
                                    <p className="card-title-desc">
                                      Explain here.
                                    </p>
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                    <div>
                                      <Link
                                        to="#"
                                        onClick={addMoreAddressHandler}
                                      >
                                        <img src={addLogo} alt="Add" />
                                      </Link>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody className="p-4">
                              <Row>
                                <Col xs={2}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Building Name
                                    </Label>
                                  </div>
                                </Col>
                                <Col xs={1}>
                                  <div className="mb-3">
                                    <Label className="form-label">Unit</Label>
                                  </div>
                                </Col>
                                <Col xs={1}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Postal Code
                                    </Label>
                                  </div>
                                </Col>
                                <Col xs={3}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Address
                                    </Label>
                                  </div>
                                </Col>
                                <Col xs={2}>
                                  <div className="mb-3">
                                    <Label className="form-label">City</Label>
                                  </div>
                                </Col>
                                <Col xs={2}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Country
                                    </Label>
                                  </div>
                                </Col>
                              </Row>
                              {addresses && addresses.length > 0 ? (
                                addresses.map((address, addressIndex) => (
                                  <Row key={`${addressIndex}`}>
                                    <Col xs={2}>
                                      <div className="mb-3">
                                        <Input
                                          name={`addresses.${addressIndex}.building_name`}
                                          label="building_name"
                                          type="text"
                                          onChange={
                                            validationInformation.handleChange
                                          }
                                          onBlur={
                                            validationInformation.handleBlur
                                          }
                                          value={
                                            validationInformation.values
                                              ?.addresses?.[addressIndex]
                                              ?.building_name || ''
                                          }
                                          invalid={
                                            validationInformation.touched
                                              ?.addresses?.[addressIndex] &&
                                            validationInformation.errors
                                              ?.addresses?.[addressIndex]
                                              ?.building_name
                                              ? true
                                              : false
                                          }
                                        />
                                        {validationInformation.errors
                                          ?.addresses?.[addressIndex]
                                          ?.building_name && (
                                          <FormFeedback type="invalid">
                                            {
                                              validationInformation.errors
                                                .addresses[addressIndex]
                                                .building_name
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={1}>
                                      <div className="mb-3">
                                        <Input
                                          name={`addresses.${addressIndex}.unit`}
                                          label="unit"
                                          type="text"
                                          onChange={
                                            validationInformation.handleChange
                                          }
                                          onBlur={
                                            validationInformation.handleBlur
                                          }
                                          value={
                                            validationInformation.values
                                              ?.addresses?.[addressIndex]
                                              ?.unit || ''
                                          }
                                          invalid={
                                            validationInformation.touched
                                              ?.addresses?.[addressIndex] &&
                                            validationInformation.errors
                                              ?.addresses?.[addressIndex]?.unit
                                              ? true
                                              : false
                                          }
                                        />
                                        {validationInformation.errors
                                          ?.addresses?.[addressIndex]?.unit && (
                                          <FormFeedback type="invalid">
                                            {
                                              validationInformation.errors
                                                .addresses[addressIndex].unit
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={1}>
                                      <div className="mb-3">
                                        <Input
                                          name={`addresses.${addressIndex}.postal_code`}
                                          label="postal_code"
                                          type="text"
                                          onChange={
                                            validationInformation.handleChange
                                          }
                                          onBlur={
                                            validationInformation.handleBlur
                                          }
                                          value={
                                            validationInformation.values
                                              ?.addresses?.[addressIndex]
                                              ?.postal_code || ''
                                          }
                                          invalid={
                                            validationInformation.touched
                                              ?.addresses?.[addressIndex] &&
                                            validationInformation.errors
                                              ?.addresses?.[addressIndex]
                                              ?.postal_code
                                              ? true
                                              : false
                                          }
                                        />
                                        {validationInformation.errors
                                          ?.addresses?.[addressIndex]
                                          ?.postal_code && (
                                          <FormFeedback type="invalid">
                                            {
                                              validationInformation.errors
                                                .addresses[addressIndex]
                                                .postal_code
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={3}>
                                      <div className="mb-3">
                                        <Input
                                          name={`addresses.${addressIndex}.address`}
                                          label="address"
                                          type="text"
                                          onChange={
                                            validationInformation.handleChange
                                          }
                                          onBlur={
                                            validationInformation.handleBlur
                                          }
                                          value={
                                            validationInformation.values
                                              ?.addresses?.[addressIndex]
                                              ?.address || ''
                                          }
                                          invalid={
                                            validationInformation.touched
                                              ?.addresses?.[addressIndex] &&
                                            validationInformation.errors
                                              ?.addresses?.[addressIndex]
                                              ?.address
                                              ? true
                                              : false
                                          }
                                        />
                                        {validationInformation.errors
                                          ?.addresses?.[addressIndex]
                                          ?.address && (
                                          <FormFeedback type="invalid">
                                            {
                                              validationInformation.errors
                                                .addresses[addressIndex].address
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={2}>
                                      <div className="mb-3">
                                        <Input
                                          name={`addresses.${addressIndex}.city`}
                                          label="city"
                                          type="text"
                                          onChange={
                                            validationInformation.handleChange
                                          }
                                          onBlur={
                                            validationInformation.handleBlur
                                          }
                                          value={
                                            validationInformation.values
                                              ?.addresses?.[addressIndex]
                                              ?.city || ''
                                          }
                                          invalid={
                                            validationInformation.touched
                                              ?.addresses?.[addressIndex] &&
                                            validationInformation.errors
                                              ?.addresses?.[addressIndex]?.city
                                              ? true
                                              : false
                                          }
                                        />
                                        {validationInformation.errors
                                          ?.addresses?.[addressIndex]?.city && (
                                          <FormFeedback type="invalid">
                                            {
                                              validationInformation.errors
                                                .addresses[addressIndex].city
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={2}>
                                      <div className="mb-3">
                                        <Input
                                          name={`addresses.${addressIndex}.country`}
                                          label="country"
                                          type="text"
                                          onChange={
                                            validationInformation.handleChange
                                          }
                                          onBlur={
                                            validationInformation.handleBlur
                                          }
                                          value={
                                            validationInformation.values
                                              ?.addresses?.[addressIndex]
                                              ?.country || ''
                                          }
                                          invalid={
                                            validationInformation.touched
                                              ?.addresses?.[addressIndex] &&
                                            validationInformation.errors
                                              ?.addresses?.[addressIndex]
                                              ?.country
                                              ? true
                                              : false
                                          }
                                        />
                                        {validationInformation.errors
                                          ?.addresses?.[addressIndex]
                                          ?.country && (
                                          <FormFeedback type="invalid">
                                            {
                                              validationInformation.errors
                                                .addresses[addressIndex].country
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>
                                    <Col xs={1}>
                                      <div className="text-center">
                                        <Link
                                          to="#"
                                          className="text-danger"
                                          onClick={() =>
                                            removeAddressHandler(addressIndex)
                                          }
                                        >
                                          <i
                                            className="mdi mdi-delete font-size-18"
                                            id="deletetooltip"
                                          ></i>
                                        </Link>
                                      </div>
                                    </Col>
                                  </Row>
                                ))
                              ) : (
                                <Row>
                                  <Col>
                                    <p className="text-center">
                                      No records found
                                    </p>
                                  </Col>
                                </Row>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xs={12}>
                          <Card>
                            <CardHeader>
                              <Row>
                                <Col md={6}>
                                  <div className="mb-3">
                                    <h5 className="card-title">Evaluation</h5>
                                    <p className="card-title-desc">
                                      Explain here.
                                    </p>
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                    <div>
                                      <Link
                                        to="#"
                                        onClick={addMoreEvaluationHandler}
                                      >
                                        <img src={addLogo} alt="Add" />
                                      </Link>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody className="p-4">
                              <Row>
                                <Col xs={4}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Evaluate By
                                    </Label>
                                  </div>
                                </Col>
                                <Col xs={2}>
                                  <div className="mb-3">
                                    <Label className="form-label">Rating</Label>
                                  </div>
                                </Col>
                                <Col xs={5}>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Remarks
                                    </Label>
                                  </div>
                                </Col>
                              </Row>
                              {evaluations && evaluations.length > 0 ? (
                                evaluations.map(
                                  (evaluation, evaluationIndex) => (
                                    <Row
                                      key={`${evaluationIndex}${evaluation.id}`}
                                    >
                                      <Col xs={4}>
                                        <div className="mb-3">
                                          <Input
                                            name={`evaluations.${evaluationIndex}.evaluated_by`}
                                            label="evaluated_by"
                                            type="text"
                                            onChange={
                                              validationInformation.handleChange
                                            }
                                            onBlur={
                                              validationInformation.handleBlur
                                            }
                                            value={
                                              validationInformation.values
                                                ?.evaluations?.[evaluationIndex]
                                                ?.evaluated_by || ''
                                            }
                                            invalid={
                                              validationInformation.touched
                                                ?.evaluations?.[
                                                evaluationIndex
                                              ] &&
                                              validationInformation.errors
                                                ?.evaluations?.[evaluationIndex]
                                                ?.evaluated_by
                                                ? true
                                                : false
                                            }
                                          />
                                          {validationInformation.errors
                                            ?.evaluations?.[evaluationIndex]
                                            ?.evaluated_by && (
                                            <FormFeedback type="invalid">
                                              {
                                                validationInformation.errors
                                                  .evaluations[evaluationIndex]
                                                  .evaluated_by
                                              }
                                            </FormFeedback>
                                          )}
                                        </div>
                                      </Col>
                                      <Col xs={2}>
                                        <div className="mb-3">
                                          <Input
                                            name={`evaluations.${evaluationIndex}.rating`}
                                            label="rating"
                                            type="text"
                                            onChange={
                                              validationInformation.handleChange
                                            }
                                            onBlur={
                                              validationInformation.handleBlur
                                            }
                                            value={
                                              validationInformation.values
                                                ?.evaluations?.[evaluationIndex]
                                                ?.rating || ''
                                            }
                                            invalid={
                                              validationInformation.touched
                                                ?.evaluations?.[
                                                evaluationIndex
                                              ] &&
                                              validationInformation.errors
                                                ?.evaluations?.[evaluationIndex]
                                                ?.rating
                                                ? true
                                                : false
                                            }
                                          />
                                          {validationInformation.errors
                                            ?.evaluations?.[evaluationIndex]
                                            ?.rating && (
                                            <FormFeedback type="invalid">
                                              {
                                                validationInformation.errors
                                                  .evaluations[evaluationIndex]
                                                  .rating
                                              }
                                            </FormFeedback>
                                          )}
                                        </div>
                                      </Col>
                                      <Col xs={5}>
                                        <div className="mb-3">
                                          <Input
                                            name={`evaluations.${evaluationIndex}.remarks`}
                                            label="remarks"
                                            type="text"
                                            onChange={
                                              validationInformation.handleChange
                                            }
                                            onBlur={
                                              validationInformation.handleBlur
                                            }
                                            value={
                                              validationInformation.values
                                                ?.evaluations?.[evaluationIndex]
                                                ?.remarks || ''
                                            }
                                            invalid={
                                              validationInformation.touched
                                                ?.evaluations?.[
                                                evaluationIndex
                                              ] &&
                                              validationInformation.errors
                                                ?.evaluations?.[evaluationIndex]
                                                ?.remarks
                                                ? true
                                                : false
                                            }
                                          />
                                          {validationInformation.errors
                                            ?.evaluations?.[evaluationIndex]
                                            ?.remarks && (
                                            <FormFeedback type="invalid">
                                              {
                                                validationInformation.errors
                                                  .evaluations[evaluationIndex]
                                                  .remarks
                                              }
                                            </FormFeedback>
                                          )}
                                        </div>
                                      </Col>
                                      <Col xs={1}>
                                        <div className="text-center">
                                          <Link
                                            to="#"
                                            className="text-danger"
                                            onClick={() =>
                                              removeEvaluationHandler(
                                                evaluationIndex,
                                              )
                                            }
                                          >
                                            <i
                                              className="mdi mdi-delete font-size-18"
                                              id="deletetooltip"
                                            ></i>
                                          </Link>
                                        </div>
                                      </Col>
                                    </Row>
                                  ),
                                )
                              ) : (
                                <Row>
                                  <Col>
                                    <p className="text-center">
                                      No records found
                                    </p>
                                  </Col>
                                </Row>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                        <Col xs={12}>
                          <Card>
                            <CardHeader>
                              <Row>
                                <Col md={6}>
                                  <div className="mb-3">
                                    <h5 className="card-title">Notes</h5>
                                    <p className="card-title-desc">
                                      Explain here.
                                    </p>
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                    <div>
                                      <Link
                                        to="#"
                                        onClick={addMoreNotesHandler}
                                      >
                                        <img src={addLogo} alt="Add" />
                                      </Link>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody className="p-4">
                              {notes && notes.length > 0 ? (
                                notes.map((note, noteIndex) => (
                                  <Row key={`${noteIndex}`}>
                                    <Col xs={11}>
                                      <div className="mb-3">
                                        <Input
                                          name={`notes.${noteIndex}.note`}
                                          className={`form-control ${
                                            validationInformation.touched
                                              ?.notes?.[noteIndex]?.note &&
                                            validationInformation.errors
                                              ?.notes?.[noteIndex]?.note
                                              ? 'is-invalid'
                                              : ''
                                          }`}
                                          label="note"
                                          rows="2"
                                          onChange={
                                            validationInformation.handleChange
                                          }
                                          onBlur={
                                            validationInformation.handleBlur
                                          }
                                          value={
                                            validationInformation.values
                                              ?.notes?.[noteIndex]?.note || ''
                                          }
                                        />
                                        {validationInformation.errors?.notes?.[
                                          noteIndex
                                        ]?.note && (
                                          <FormFeedback type="invalid">
                                            {
                                              validationInformation.errors
                                                .notes[noteIndex].note
                                            }
                                          </FormFeedback>
                                        )}
                                      </div>
                                    </Col>

                                    <Col xs={1}>
                                      <div className="text-center">
                                        <Link
                                          to="#"
                                          className="text-danger"
                                          onClick={() =>
                                            removeSelectedNotesHandler(
                                              noteIndex,
                                            )
                                          }
                                        >
                                          <i
                                            className="mdi mdi-delete font-size-18"
                                            id="deletetooltip"
                                          ></i>
                                        </Link>
                                      </div>
                                    </Col>
                                  </Row>
                                ))
                              ) : (
                                <Row>
                                  <Col>
                                    <p className="text-center">
                                      No records found
                                    </p>
                                  </Col>
                                </Row>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary save-user"
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Save
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </TabPane>
                  <TabPane tabId="2">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validationDocumentList.handleSubmit();
                        return false;
                      }}
                    >
                      <Col xs={12}>
                        <Card>
                          <CardHeader>
                            <Row>
                              <Col md={6}>
                                <div className="mb-3">
                                  <h5 className="card-title">
                                    Upload Document
                                  </h5>
                                  <p className="card-title-desc">
                                    Explain here.
                                  </p>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                  <div>
                                    <Link
                                      to="#"
                                      onClick={handleWorkerDocumentClick}
                                    >
                                      <img src={addLogo} alt="Add" />
                                    </Link>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody className="p-4">
                            <Row>
                              <Col xs={2}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Document Name
                                  </Label>
                                </div>
                              </Col>
                              <Col xs={3}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Remarks by Uploader
                                  </Label>
                                </div>
                              </Col>
                              <Col xs={2}>
                                <div className="mb-3">
                                  <Label className="form-label">File</Label>
                                </div>
                              </Col>
                              <Col xs={3}>
                                <div className="mb-3">
                                  <Label className="form-label">
                                    PMO Comments
                                  </Label>
                                </div>
                              </Col>
                            </Row>

                            {documentsCol && documentsCol.length > 0 ? (
                              documentsCol.map((document, documentIndex) => (
                                <Row key={`${documentIndex}`}>
                                  <Col xs={2}>
                                    <div className="mb-3">
                                      {validationDocumentList.values
                                        ?.documents?.[documentIndex]
                                        ?.document_name || ''}
                                    </div>
                                  </Col>
                                  <Col xs={3}>
                                    <div className="mb-3">
                                      {validationDocumentList.values
                                        ?.documents?.[documentIndex]
                                        ?.owner_remarks || ''}
                                    </div>
                                  </Col>
                                  <Col xs={2}>
                                    <div className="mb-3">
                                      <Card
                                        style={{
                                          marginBottom: 0,
                                          width: '50%',
                                          border: 0,
                                        }}
                                      >
                                        <div className="p-0">
                                          <Row className="align-items-center">
                                            <Col>
                                              {validationDocumentList?.values
                                                ?.documents?.[documentIndex]
                                                ?.file?.path && (
                                                <Link
                                                  to={URL.createObjectURL(
                                                    validationDocumentList
                                                      ?.values?.documents?.[
                                                      documentIndex
                                                    ]?.file,
                                                  )}
                                                  className="text-primary justify-content-start"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  <i
                                                    className="mdi mdi-file-document-outline font-size-18"
                                                    id="edittooltip"
                                                  ></i>
                                                </Link>
                                              )}
                                              {!validationDocumentList?.values
                                                ?.documents?.[documentIndex]
                                                ?.file?.path && (
                                                <Link
                                                  to={`${baseUrl}${validationDocumentList?.values?.documents?.[documentIndex]?.new_file}`}
                                                  className="text-primary justify-content-start"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  <i
                                                    className="mdi mdi-file-document-outline font-size-18"
                                                    id="edittooltip"
                                                  ></i>
                                                </Link>
                                              )}
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                    </div>
                                  </Col>
                                  <Col xs={3}>
                                    <div className="mb-3">
                                      <Input
                                        name={`documents.${documentIndex}.pmo_remarks`}
                                        className={`form-control ${
                                          validationDocumentList.touched
                                            ?.documents?.[documentIndex]
                                            ?.pmo_remarks &&
                                          validationDocumentList.errors
                                            ?.documents?.[documentIndex]
                                            ?.pmo_remarks
                                            ? 'is-invalid'
                                            : ''
                                        }`}
                                        label="PMO Remarks"
                                        rows="2"
                                        onChange={
                                          validationDocumentList.handleChange
                                        }
                                        onBlur={
                                          validationDocumentList.handleBlur
                                        }
                                        value={
                                          validationDocumentList.values
                                            ?.documents?.[documentIndex]
                                            ?.pmo_remarks || ''
                                        }
                                      />
                                      {validationDocumentList.errors
                                        ?.documents?.[documentIndex]
                                        ?.pmo_remarks && (
                                        <FormFeedback type="invalid">
                                          {
                                            validationDocumentList.errors
                                              ?.documents?.[documentIndex]
                                              .pmo_remarks
                                          }
                                        </FormFeedback>
                                      )}
                                    </div>
                                  </Col>
                                  <Col xs={1}>
                                    <div className="text-center">
                                      <Link
                                        to="#"
                                        className="text-danger"
                                        onClick={() =>
                                          removeSelectedDocumentHandler(
                                            documentIndex,
                                          )
                                        }
                                      >
                                        <i
                                          className="mdi mdi-delete font-size-18"
                                          id="deletetooltip"
                                        ></i>
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                              ))
                            ) : (
                              <Row>
                                <Col>
                                  <p className="text-center">
                                    No records found
                                  </p>
                                </Col>
                              </Row>
                            )}
                          </CardBody>
                        </Card>
                        <Row>
                          <Col className="d-flex justify-content-end">
                            <button
                              type="submit"
                              className="btn btn-primary save-user"
                            >
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              Save
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    </Form>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Upload Document
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validationDocument.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label className="form-label">Document Name</Label>
                      <Input
                        name="document_name"
                        type="text"
                        onChange={validationDocument.handleChange}
                        onBlur={validationDocument.handleBlur}
                        value={validationDocument.values.document_name || ''}
                        invalid={
                          validationDocument.touched.document_name &&
                          validationDocument.errors.document_name
                            ? true
                            : false
                        }
                      />
                      {validationDocument.touched.document_name &&
                      validationDocument.errors.document_name ? (
                        <FormFeedback type="invalid">
                          {validationDocument.errors.document_name}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label className="form-label">Remarks</Label>
                      <Input
                        name="owner_remarks"
                        type="text"
                        onChange={validationDocument.handleChange}
                        onBlur={validationDocument.handleBlur}
                        value={validationDocument.values.owner_remarks || ''}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Dropzone
                      onDrop={(acceptedFiles, fileRejections) => {
                        setSelectedFiles(acceptedFiles?.[0] || '');
                        setErrorMessages(
                          fileRejections.map(
                            ({ file, errors }) =>
                              `${file.name}: ${errors.map((e) => e.message).join(', ')} `,
                          ),
                        );
                        validationDocument.setFieldValue(
                          'file',
                          acceptedFiles?.[0] || '',
                        );
                      }}
                      className="error"
                      accept={{
                        'application/pdf': ['.pdf'],
                      }}
                      multiple={false} // Disable multiple file selection
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bx-cloud-upload" />
                            </div>
                            <h4>Drop or click to attach a pdf file.</h4>
                            {errorMessages.length > 0 && (
                              <div
                                className="error-messages"
                                style={{
                                  fontSize: 12,
                                }}
                              >
                                {errorMessages.map((message, index) => (
                                  <p key={index} className="text-danger">
                                    {message}
                                  </p>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    {validationDocument.errors.file && (
                      <p className="text-danger">
                        {validationDocument.errors.file}
                      </p>
                    )}
                  </Col>
                </Row>
                {selectedFiles?.name && (
                  <Row>
                    <Col>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                        style={{ display: 'flex', flexDirection: 'row' }}
                      >
                        <Card
                          style={{
                            marginLeft: 5,
                            marginRight: 5,
                            width: '50%',
                          }}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col
                                className="col-auto text-primary"
                                style={{
                                  marginBottom: 10,
                                }}
                              >
                                <i
                                  className="mdi mdi-file-document-outline font-size-30"
                                  id="edittooltip"
                                ></i>
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {selectedFiles.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{selectedFiles.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs={12}>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-primary save-user"
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp; Save &nbsp;&nbsp;&nbsp;&nbsp;
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

const mapStateToProps = ({
  app,
  workers,
  clients,
  suppliers,
  clientfields,
  document,
}) => ({
  app,
  workers,
  clients,
  suppliers,
  clientfields,
  document,
});

export default connect(mapStateToProps, { actionCreator })(WorkersList);
