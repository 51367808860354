import {
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAILED,
  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAILED,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  CLEAR_USER,
  CLEAR_USER_ERROR,
  GET_USER_RULES_SUCCESS,
  GET_USER_RULES_FAILED,
} from "./types";

const INIT_STATE = {
  loading: true,
  data: [],
  rules:[],
  error: false,
  errorMessage: null,
  status: null,
  isUserSubmitted: false,
};

const user = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
        errorMessage: null,
        status: null,
      };

    case GET_USER_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        status: action.status,
      };

    case ADD_NEW_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        isUserSubmitted: true,
        message: action.payload.message,
      };

    case ADD_NEW_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        isUserSubmitted: false,
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        isUserSubmitted: true,
        message: action.payload.message,
      };

    case UPDATE_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        isUserSubmitted: false,
        status: action.status,
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        isUserSubmitted: true,
        message: action.payload.message,
      };

    case DELETE_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        isUserSubmitted: false,
      };

    case GET_USER_RULES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        isUserSubmitted: false,
        message: action.payload.message,
        rules: action.payload,
      };

    case GET_USER_RULES_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.payload,
        isUserSubmitted: false,
        rules: []
      };

    case CLEAR_USER:
      return INIT_STATE;
    case CLEAR_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        status: null,
      };
    default:
      return state;
  }
};

export default user;
