import {
  GET_JOB_ORDER_LIST,
  GET_JOB_ORDER_LIST_SUCCESS,
  GET_JOB_ORDER_LIST_FAILED,
  POST_UPDATE_JOB_ORDER,
  POST_UPDATE_JOB_ORDER_SUCCESS,
  POST_UPDATE_JOB_ORDER_FAILED,
  GET_CANDIDATE_LIST,
  GET_CANDIDATE_LIST_SUCCESS,
  GET_CANDIDATE_LIST_FAILED,
  POST_ADD_CANDIDATE,
  POST_ADD_CANDIDATE_SUCCESS,
  POST_ADD_CANDIDATE_FAILED,
  SAVE_SELECTED_JOB_DATA,
  GET_CANDIDATE,
  GET_CANDIDATE_SUCCESS,
  GET_CANDIDATE_FAILED,
  POST_UPDATE_CANDIDATE_STATUS,
  POST_UPDATE_CANDIDATE_STATUS_SUCCESS,
  POST_UPDATE_CANDIDATE_STATUS_FAILED,
  POST_ADD_CANDIDATE_INTERVIEW,
  POST_ADD_CANDIDATE_INTERVIEW_SUCCESS,
  POST_ADD_CANDIDATE_INTERVIEW_FAILED,
  POST_UPDATE_CANDIDATE_INTERVIEW,
  POST_UPDATE_CANDIDATE_INTERVIEW_SUCCESS,
  POST_UPDATE_CANDIDATE_INTERVIEW_FAILED,
  POST_ADD_CANDIDATE_OFFER,
  POST_ADD_CANDIDATE_OFFER_SUCCESS,
  POST_ADD_CANDIDATE_OFFER_FAILED,
  POST_ACCEPT_CANDIDATE,
  POST_ACCEPT_CANDIDATE_SUCCESS,
  POST_ACCEPT_CANDIDATE_FAILED,
  POST_CONVERT_WORKER,
  POST_CONVERT_WORKER_SUCCESS,
  POST_CONVERT_WORKER_FAILED,
  CLEAR_JO_MESSAGES,
  CLEAR_NEW_WORKER_ID,
} from "./types";

const INIT_STATE = {
  loading: true,
  data: [],
  error: false,
  errorMessage: null,
  messages: null,
  candidatesData: [],
  selectedJOBData: [],
  selectedCandidateData: [],
  newConvertedWorkerID: null,
};

const joborder = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_JOB_ORDER_LIST:
      return {
        ...state,
        loading: true,
      };

    case GET_JOB_ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        messages: action.payload.message,
        error: false,
        errorMessage: null,
      };

    case GET_JOB_ORDER_LIST_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: true,
        errorMessage: action.payload,
      };

    case POST_UPDATE_JOB_ORDER:
      return {
        ...state,
        loading: true,
      };

    case POST_UPDATE_JOB_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        messages: action.payload,
      };

    case POST_UPDATE_JOB_ORDER_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        messages: null,
      };

    case GET_CANDIDATE_LIST:
      return {
        ...state,
        loading: true,
      };

    case GET_CANDIDATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        candidatesData: action.payload.data,
        messages: action.payload.message,
        error: false,
        errorMessage: null,
      };

    case GET_CANDIDATE_LIST_FAILED:
      return {
        ...state,
        loading: false,
        candidatesData: [],
        error: true,
        errorMessage: action.payload,
      };

    case POST_ADD_CANDIDATE:
      return {
        ...state,
        loading: true,
      };

    case POST_ADD_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        messages: action.payload,
      };

    case POST_ADD_CANDIDATE_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        messages: null,
      };

    case GET_CANDIDATE:
      return {
        ...state,
        loading: true,
      };

    case GET_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedCandidateData: action.payload.data,
        messages: action.payload.message,
        error: false,
        errorMessage: null,
      };

    case GET_CANDIDATE_FAILED:
      return {
        ...state,
        loading: false,
        selectedCandidateData: [],
        error: true,
        errorMessage: action.payload,
      };

    case POST_UPDATE_CANDIDATE_STATUS:
      return {
        ...state,
        loading: true,
      };

    case POST_UPDATE_CANDIDATE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        messages: action.payload.messages,
      };

    case POST_UPDATE_CANDIDATE_STATUS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        messages: null,
      };

    case POST_ADD_CANDIDATE_INTERVIEW:
      return {
        ...state,
        loading: true,
      };

    case POST_ADD_CANDIDATE_INTERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        messages: action.payload.messages,
      };

    case POST_ADD_CANDIDATE_INTERVIEW_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        messages: null,
      };

    case POST_UPDATE_CANDIDATE_INTERVIEW:
      return {
        ...state,
        loading: true,
      };

    case POST_UPDATE_CANDIDATE_INTERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        messages: action.payload.messages,
      };

    case POST_UPDATE_CANDIDATE_INTERVIEW_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        messages: null,
      };

    case POST_ADD_CANDIDATE_OFFER:
      return {
        ...state,
        loading: true,
      };

    case POST_ADD_CANDIDATE_OFFER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        messages: action.payload.messages,
      };

    case POST_ADD_CANDIDATE_OFFER_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        messages: null,
      };

    case POST_ACCEPT_CANDIDATE:
      return {
        ...state,
        loading: true,
      };

    case POST_ACCEPT_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        messages: action.payload.messages,
      };

    case POST_ACCEPT_CANDIDATE_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        messages: null,
      };

    case POST_CONVERT_WORKER:
      return {
        ...state,
        loading: true,
      };

    case POST_CONVERT_WORKER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        messages: action.payload.messages,
        newConvertedWorkerID: action.payload.result,
      };

    case POST_CONVERT_WORKER_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        messages: null,
      };

    case CLEAR_JO_MESSAGES:
      return {
        ...state,
        messages: null,
      };

    case CLEAR_NEW_WORKER_ID:
      return {
        ...state,
        newConvertedWorkerID: null,
      };

    case SAVE_SELECTED_JOB_DATA:
      return {
        ...state,
        selectedJOBData: action.payload,
      };

    default:
      return state;
  }
};

export default joborder;
