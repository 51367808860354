import { emsApi } from "..";
import { CONFIGURATION_SERVICE } from "../constants";

class Configuration {
  async fnSaveConfiguration(payload) {
    return await emsApi.post(CONFIGURATION_SERVICE.SAVE_CONFIGURATION, payload);
  }
}

Configuration.api = new Configuration();
export default Configuration;