import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { throttle, debounce } from 'lodash';
import Dropzone from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  Form,
  Label,
  Input,
  FormFeedback,
  TabContent,
  TabPane,
  Table,
} from 'reactstrap';
import classnames from 'classnames';
import { actionCreator, types } from '../../store';

import Breadcrumb from '../../components/Common/Breadcrumb';
import './customStyles.css';

const MonthlyOperation = ({ operations, authentication, ...props }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const defaultMonth = moment().format('YYYY-MM');
  const [verticalActiveTab, setverticalActiveTab] = React.useState('');
  const [monthYear, setMonthYear] = React.useState(defaultMonth);
  const [searchValue, setSearchValue] = React.useState('');
  const [selectectedClient, setSelectectedClient] = React.useState();
  const [selectectedSupplierId, setSelectectedSupplierId] = React.useState();
  const [disabledSaveButton, setDisabledSaveButton] = React.useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isEdit, setIsEdit] = React.useState(false);
  const [operationId, setOperationId] = React.useState(null);

  const [filteredOptions, setFilteredOptions] = React.useState([]); // clients list and fields
  const [operationFields, setOperationFields] = React.useState([]); // all the dynamic fields
  const [origClientFields, setOrigClientFields] = React.useState([]); // options

  React.useEffect(() => {
    fetchClients();
  }, []);

  React.useEffect(() => {
    const { user_rules, user_group_id } = authentication.data.data;
    if (user_rules.length > 0 && user_rules[0]?.type === 'client') {
      setFilteredOptions(
        operations.clientsData.filter((client) =>
          user_rules
            .filter((r) => r.type === 'client')
            .map((option) => option.source_id)
            .includes(client.id),
        ),
      );
    } else {
      setFilteredOptions(operations.clientsData);
    }
  }, [operations.clientsData]);

  React.useEffect(() => {
    if (filteredOptions.length > 0 && !verticalActiveTab) {
      toggleVertical(filteredOptions[0]?.id);
    }
  }, [filteredOptions]);

  React.useEffect(() => {
    operations.isSubmitted &&
      handleNotification() &&
      toggleVertical(selectectedClient);
    operations.error && operations.errorMessage && handleErrorNotification();

    let populateFields = [];
    if (operations.data) {
      if (operations.data?.fields && operations.data?.fields.length > 0) {
        operations.data.fields.forEach((item, index) => {
          populateFields.push({
            id: item.client_field_id,
            label: item.item_name,
            details: item.details,
            amount: item.amount,
            remarks: item.remarks,
            addtnl_field: item.additional_field,
            file: item.docs,
            entries_id: item.id,
          });
        });

        setIsEdit(true);
        setOperationFields(populateFields);
        setOperationId(operations.data.monthly_operation_id);
      } else {
        const clientFields = filteredOptions.filter(
          (client) => client.id === parseInt(selectectedClient),
        );
        if (clientFields[0]?.fields) {
          clientFields[0].fields.length > 0 &&
            clientFields[0].fields.forEach((item, index) => {
              clientFields[0].fields[index].details = '';
              clientFields[0].fields[index].amount = '';
              clientFields[0].fields[index].remarks = '';
              clientFields[0].fields[index].addtnl_field = '';
              clientFields[0].fields[index].file = null;
              clientFields[0].fields[index].entries_id = '';
            });
          setIsEdit(false);
          setOperationFields(clientFields[0].fields);
        }

        setOperationId(null);
      }
    }
  }, [operations]);

  const fetchClients = async () => {
    await props.actionCreator({
      type: types.GET_OPERATION_CLIENTS,
    });
  };

  const toggleVertical = (id) => {
    if (verticalActiveTab !== id) {
      setverticalActiveTab(id);

      const matchMonth = monthYear.match(/(\d+)-(\d+)/);
      const year = matchMonth[1];
      const month = matchMonth[2];

      props.actionCreator({
        type: types.GET_OPERATION_LIST,
        payload: {
          id,
          month,
          year,
        },
      });

      const clientFields = filteredOptions.filter(
        (client) => client.id === parseInt(id),
      );
      setSelectectedClient(clientFields[0].id);
      setSelectectedSupplierId(clientFields[0].supplier_id);

      setOrigClientFields(clientFields[0].fields);
      setDisabledSaveButton(clientFields[0].fields.length > 0 ? false : true);
    }
  };

  const getOperation = (date) => {
    if (selectectedClient) {
      const matchMonth = date.match(/(\d+)-(\d+)/);
      const year = matchMonth[1];
      const month = matchMonth[2];

      props.actionCreator({
        type: types.GET_OPERATION_LIST,
        payload: {
          id: selectectedClient,
          month,
          year,
        },
      });

      const clientFields = filteredOptions.filter(
        (client) => client.id === parseInt(selectectedClient),
      );
      setSelectectedClient(clientFields[0].id);
      setSelectectedSupplierId(clientFields[0].supplier_id);

      setOrigClientFields(clientFields[0].fields);
      setDisabledSaveButton(clientFields[0].fields.length > 0 ? false : true);
    }
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      fields: operationFields,
    },
    validationSchema: Yup.object({
      fields: Yup.array(
        Yup.object({
          details: Yup.string().required('Details is required'),
          amount: Yup.string().required('Amount is required'),
          id: Yup.string().required('Item is required'),
        }),
      ),
    }),
    onSubmit: (values, { setSubmitting }, event) => {
      setDisabledSaveButton(true);
      const formData = new FormData();
      values.fields.forEach((field, index) => {
        field?.file?.forEach((file, fileIndex) => {
          if (file.name) {
            const setID = field.addtnl_field
              ? 'addtnl_' + field.id + '_' + index
              : field.id;
            formData.append(`file_${setID}_${fileIndex}`, file, file.name);
          }
        });
      });

      formData.append('is_edit', isEdit);
      if (isEdit) {
        //formData.append("edit_id", isEdit);
      }
      operationId;
      formData.append('operation_id', operationId);
      formData.append('id', selectectedClient);
      formData.append('supplier_id', selectectedSupplierId);
      formData.append('month', monthYear || defaultMonth);
      formData.append('fields_to_save', JSON.stringify(values.fields));

      if (values.fields.length == 0) {
        toast("Cannot save. Doesn't have clients fields", {
          position: 'top-right',
          hideProgressBar: true,
          className: 'bg-danger text-white',
        });
      } else {
        props.actionCreator({
          type: types.SAVE_MONTHLY_OPERATION,
          payload: formData,
        });
      }

      validation.resetForm();
      setverticalActiveTab('');
    },
  });

  const handleNotification = async () => {
    toast(operations.messages, {
      position: 'top-right',
      hideProgressBar: true,
      className: 'bg-success text-white',
    });
  };

  const handleErrorNotification = async () => {
    toast(operations.errorMessage, {
      position: 'top-right',
      hideProgressBar: true,
      className: 'bg-danger text-white',
    });
  };

  const throttledFilter = useCallback(
    throttle((input) => {
      const filtered = operations.clientsData.filter((option) =>
        option.trade_name.toLowerCase().includes(input.toLowerCase()),
      );
      setFilteredOptions(filtered);
    }, 300),
    [operations.clientsData],
  );

  const debouncedFilter = useCallback(
    debounce((input) => {
      const filtered = operations.clientsData.filter((option) =>
        option.trade_name.toLowerCase().includes(input.toLowerCase()),
      );
      setFilteredOptions(filtered);
    }, 500),
    [operations.clientsData],
  );

  const handleChange = (e) => {
    const input = e.target.value;
    setSearchValue(input);
    setverticalActiveTab('');
    throttledFilter(input);
    debouncedFilter(input);
  };

  //meta title
  document.title = 'Monthly Operation | MPS';
  return (
    <React.Fragment>
      <div className="page-content monthly-operation">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb
            title="Dashboard"
            titleLink="/dashboard"
            breadcrumbItem="Monthly Operation"
          />
          <Row>
            <Col lg="12">
              <Row>
                <Col xl="12">
                  <Row>
                    <Col sm="2" className="pb-3 pt-4">
                      <Label
                        className="form-label"
                        style={{ fontSize: '13px' }}
                      >
                        Select Month and Year
                      </Label>
                      <Input
                        type="month"
                        name="shift_date"
                        className=""
                        defaultValue={defaultMonth}
                        onChange={(e) => {
                          setMonthYear(e.target.value);
                          getOperation(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12" className="pb-1">
                      <Card style={{ backgroundColor: '#F9F9F9' }}>
                        <CardBody style={{ padding: '5px' }}>
                          <Row>
                            <Col className="text-center">
                              <Label
                                className="form-label mb-0 text-primary"
                                style={{ fontSize: '15px', fontWeight: '700' }}
                              >
                                {moment(monthYear).format('MMMM YYYY') ||
                                  moment(defaultMonth).format('MMMM YYYY')}
                              </Label>
                              <div
                                className="activity-icon"
                                style={{
                                  height: '1.7rem',
                                  width: '1.7rem',
                                  position: 'absolute',
                                  right: '14px',
                                  top: '-3px',
                                }}
                              >
                                <Link
                                  className={`text-danger icon-operation ${disabledSaveButton ? 'disabled' : ''}`}
                                  to="#"
                                >
                                  <span className="avatar-title  text-primary rounded-circle">
                                    <i
                                      className={`mdi mdi-plus font-size-14 icon-operation ${disabledSaveButton ? 'disabled' : ''}`}
                                      style={{ color: '#fff' }}
                                      onClick={() => {
                                        if (!disabledSaveButton) {
                                          setOperationFields([
                                            ...validation.values.fields,
                                            {
                                              id: '',
                                              label: '',
                                              details: '',
                                              amount: '',
                                              remarks: '',
                                              addtnl_field: 1,
                                              file: null,
                                              entries_id: '',
                                            },
                                          ]);
                                        }
                                      }}
                                    ></i>
                                  </span>
                                </Link>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <form className="app-search d-lg-block pt-5">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search for Client..."
                            style={{ fontSize: '12px' }}
                            value={searchValue}
                            onChange={handleChange}
                          />
                        </div>
                      </form>
                      <Nav pills className="flex-column monthly-operation">
                        {filteredOptions.map((option, index) => (
                          <NavItem key={index}>
                            <NavLink
                              style={{ cursor: 'pointer', fontSize: '12px' }}
                              className={classnames({
                                'mb-2': true,
                                active: verticalActiveTab === option.id,
                              })}
                              onClick={() => {
                                toggleVertical(option.id);
                              }}
                            >
                              {option.trade_name}
                            </NavLink>
                          </NavItem>
                        ))}
                      </Nav>
                    </Col>
                    <Col md="10">
                      <Card style={{ border: '0' }}>
                        <CardBody className="p-0">
                          <Row>
                            <Col>
                              <div
                                className="col-sm-auto mb-2"
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <button
                                  type="button"
                                  onClick={() => {
                                    validation.handleSubmit();
                                  }}
                                  style={{ marginTop: '20px', width: '160px' }}
                                  className="btn btn-primary waves-effect waves-light"
                                  disabled={disabledSaveButton}
                                >
                                  Save
                                </button>
                              </div>
                            </Col>
                          </Row>
                          <Label className="form-label">Currency: SGD </Label>
                          <div className="table-responsive">
                            <Table
                              className="table mb-0"
                              style={{
                                border: '1px solid #E9E9EF',
                                fontSize: '13px',
                                textAlign: 'center',
                              }}
                            >
                              <thead className="align-middle">
                                <tr>
                                  <th style={{ width: '20%' }}>Item</th>
                                  <th style={{ width: '20%' }}>Details</th>
                                  <th style={{ width: '20%' }}>Amount</th>
                                  <th style={{ width: '20%' }}>
                                    Remarks by PMO
                                  </th>
                                  <th style={{ width: '15%' }}>
                                    Attached File
                                  </th>
                                  <th style={{ width: '5%' }}>Action</th>
                                </tr>
                              </thead>
                              <tbody className="align-middle"></tbody>
                            </Table>
                          </div>
                          <TabContent
                            activeTab={verticalActiveTab}
                            className="text-muted mt-4 mt-md-0"
                          >
                            {filteredOptions.map((option, index) => (
                              <TabPane tabId={index} key={index}>
                                {/* DBS */}

                                <div className="table-responsive">
                                  <Form
                                    onSubmit={(e) => {
                                      e.preventDefault();
                                      validation.handleSubmit();
                                      return false;
                                    }}
                                  >
                                    <Table
                                      className="table mb-0"
                                      style={{
                                        border: '1px solid #E9E9EF',
                                        fontSize: '13px',
                                      }}
                                    >
                                      <tbody className="align-middle">
                                        {operationFields &&
                                          operationFields.map(
                                            (field, index1) => (
                                              <tr key={index1}>
                                                <td style={{ width: '20%' }}>
                                                  {isEdit &&
                                                    validation.values.fields[
                                                      index1
                                                    ]?.entries_id &&
                                                    field.label}
                                                  {!isEdit &&
                                                    !validation.values.fields[
                                                      index1
                                                    ]?.entries_id &&
                                                    field.label}

                                                  {!validation.values.fields[
                                                    index1
                                                  ]?.entries_id &&
                                                    validation.values.fields[
                                                      index1
                                                    ]?.addtnl_field && (
                                                      <Input
                                                        type="select"
                                                        name={`fields[${index1}].id`}
                                                        className="form-select"
                                                        multiple={false}
                                                        onBlur={
                                                          validation.handleBlur
                                                        }
                                                        value={
                                                          validation?.values
                                                            ?.fields[index1]
                                                            ? validation?.values
                                                                ?.fields[index1]
                                                                .id
                                                            : ''
                                                        }
                                                        invalid={
                                                          validation.touched
                                                            .fields &&
                                                          validation.touched
                                                            .fields[index1] &&
                                                          validation.errors
                                                            .fields &&
                                                          validation.errors
                                                            .fields[index1] &&
                                                          validation.errors
                                                            .fields[index1].id
                                                            ? true
                                                            : false
                                                        }
                                                        onChange={(e) => {
                                                          const value =
                                                            e.target.value;
                                                          validation.setFieldValue(
                                                            `fields.${index1}.label`,
                                                            e.target.options[
                                                              e.target
                                                                .selectedIndex
                                                            ].text || '',
                                                          );
                                                          validation.setFieldValue(
                                                            `fields.${index1}.id`,
                                                            value || '',
                                                          );
                                                        }}
                                                      >
                                                        <option value={''}>
                                                          Select Item
                                                        </option>
                                                        {origClientFields
                                                          .filter(
                                                            (client) =>
                                                              client.label,
                                                          )
                                                          .map(
                                                            (item, index) => (
                                                              <option
                                                                key={index}
                                                                value={item.id}
                                                              >
                                                                {item.label}
                                                              </option>
                                                            ),
                                                          )}
                                                      </Input>
                                                    )}
                                                  {validation.errors.fields &&
                                                  validation.errors.fields[
                                                    `${index1}`
                                                  ] &&
                                                  validation.errors.fields[
                                                    `${index1}`
                                                  ].id ? (
                                                    <FormFeedback type="invalid">
                                                      {
                                                        validation.errors
                                                          .fields[`${index1}`]
                                                          .id
                                                      }
                                                    </FormFeedback>
                                                  ) : null}
                                                </td>
                                                <td style={{ width: '20%' }}>
                                                  <Input
                                                    type="text"
                                                    name={`fields[${index1}].details`}
                                                    value={
                                                      validation?.values
                                                        ?.fields[index1]
                                                        ?.details || ''
                                                    }
                                                    onChange={(e) => {
                                                      validation.handleChange(
                                                        e,
                                                      );
                                                    }}
                                                    invalid={
                                                      validation.touched
                                                        .fields &&
                                                      validation.touched.fields[
                                                        index1
                                                      ] &&
                                                      validation.errors
                                                        .fields &&
                                                      validation.errors.fields[
                                                        index1
                                                      ] &&
                                                      validation.errors.fields[
                                                        index1
                                                      ].details
                                                        ? true
                                                        : false
                                                    }
                                                  />

                                                  {validation.errors.fields &&
                                                  validation.errors.fields[
                                                    `${index1}`
                                                  ] &&
                                                  validation.errors.fields[
                                                    `${index1}`
                                                  ].details ? (
                                                    <FormFeedback type="invalid">
                                                      {
                                                        validation.errors
                                                          .fields[`${index1}`]
                                                          .details
                                                      }
                                                    </FormFeedback>
                                                  ) : null}
                                                </td>
                                                <td style={{ width: '20%' }}>
                                                  <Input
                                                    type="text"
                                                    name={`fields[${index1}].amount`}
                                                    value={
                                                      validation?.values
                                                        ?.fields[index1]
                                                        ?.amount || ''
                                                    }
                                                    onChange={(e) => {
                                                      validation.handleChange(
                                                        e,
                                                      );
                                                    }}
                                                    invalid={
                                                      validation.touched
                                                        .fields &&
                                                      validation.touched.fields[
                                                        index1
                                                      ] &&
                                                      validation.errors
                                                        .fields &&
                                                      validation.errors.fields[
                                                        index1
                                                      ] &&
                                                      validation.errors.fields[
                                                        index1
                                                      ].amount
                                                        ? true
                                                        : false
                                                    }
                                                  />

                                                  {validation.errors.fields &&
                                                  validation.errors.fields[
                                                    `${index1}`
                                                  ] &&
                                                  validation.errors.fields[
                                                    `${index1}`
                                                  ].amount ? (
                                                    <FormFeedback type="invalid">
                                                      {
                                                        validation.errors
                                                          .fields[`${index1}`]
                                                          .amount
                                                      }
                                                    </FormFeedback>
                                                  ) : null}
                                                </td>
                                                <td style={{ width: '20%' }}>
                                                  <Input
                                                    type="textarea"
                                                    name={`fields[${index1}].remarks`}
                                                    value={
                                                      validation?.values
                                                        ?.fields[index1]
                                                        ?.remarks || ''
                                                    }
                                                    onChange={(e) => {
                                                      validation.handleChange(
                                                        e,
                                                      );
                                                    }}
                                                    style={{
                                                      height: '55px',
                                                    }}
                                                  />
                                                </td>
                                                <td
                                                  style={{
                                                    width: '15%',
                                                    position: 'relative',
                                                  }}
                                                >
                                                  {(validation.values.fields[
                                                    index1
                                                  ]?.file == null ||
                                                    validation.values.fields[
                                                      index1
                                                    ]?.file.length === 0) && (
                                                    <Dropzone
                                                      onDrop={(
                                                        acceptedFiles,
                                                        fileRejections,
                                                      ) => {
                                                        validation.setFieldValue(
                                                          `fields[${index1}].file`,
                                                          acceptedFiles,
                                                        );
                                                        setSelectedFiles(
                                                          acceptedFiles,
                                                        );
                                                      }}
                                                      accept={{
                                                        'application/pdf': [
                                                          '.pdf',
                                                        ],
                                                      }}
                                                      maxFiles={4}
                                                    >
                                                      {({
                                                        getRootProps,
                                                        getInputProps,
                                                        fileRejections,
                                                      }) => (
                                                        <>
                                                          <div
                                                            className="dropzone"
                                                            style={{
                                                              minHeight: '15px',
                                                              height: '55px',
                                                              width: '59px',
                                                              backgroundColor:
                                                                '#e9e9ef',
                                                              cursor: 'pointer',
                                                              border: 0,
                                                              margin: 'auto',
                                                            }}
                                                          >
                                                            <div
                                                              className="dz-message needsclick p-0"
                                                              {...getRootProps()}
                                                            >
                                                              <input
                                                                {...getInputProps()}
                                                              />
                                                              <div className="">
                                                                <i
                                                                  className="mdi mdi-upload"
                                                                  style={{
                                                                    fontSize:
                                                                      '1.3rem',
                                                                  }}
                                                                />
                                                                <p
                                                                  style={{
                                                                    fontSize: 11,
                                                                  }}
                                                                >
                                                                  Upload
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          {fileRejections.length >
                                                            0 && (
                                                            <div
                                                              style={{
                                                                margin: 'auto',
                                                              }}
                                                            >
                                                              <p
                                                                style={{
                                                                  color:
                                                                    '#fd625e',
                                                                  marginBottom:
                                                                    '0px',
                                                                  width: '100%',
                                                                  marginTop:
                                                                    '0.25rem',
                                                                  fontSize:
                                                                    '0.875em',
                                                                }}
                                                              >
                                                                You can upload
                                                                up to 4 files
                                                                only
                                                              </p>
                                                            </div>
                                                          )}
                                                        </>
                                                      )}
                                                    </Dropzone>
                                                  )}

                                                  {validation.values.fields[
                                                    index1
                                                  ]?.file?.length > 0 && (
                                                    <>
                                                      <div>
                                                        <div
                                                          className="dropzone-previews mt-0"
                                                          id="file-previews"
                                                          style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                              'row',
                                                            justifyContent:
                                                              'space-between',
                                                            flexWrap: 'wrap',
                                                            width: 62,
                                                            height: 89,
                                                            margin: 'auto',
                                                          }}
                                                        >
                                                          {validation.values.fields[
                                                            index1
                                                          ]?.file.map(
                                                            (f, i) => {
                                                              return (
                                                                <Card
                                                                  style={{
                                                                    marginBottom: 0,
                                                                    width:
                                                                      '50%',
                                                                    border: 0,
                                                                  }}
                                                                  key={
                                                                    i + '-file'
                                                                  }
                                                                >
                                                                  <div className="p-0">
                                                                    <Row className="align-items-center">
                                                                      <Col>
                                                                        {f.path && (
                                                                          <Link
                                                                            to={URL.createObjectURL(
                                                                              f,
                                                                            )}
                                                                            className="text-muted font-weight-bold"
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                          >
                                                                            <i
                                                                              className="mdi mdi-file-document-outline text-primary font-size-20"
                                                                              id="edittooltip"
                                                                            ></i>
                                                                          </Link>
                                                                        )}
                                                                        {!f.path && (
                                                                          <Link
                                                                            to={`${baseUrl}${f.new_file}`}
                                                                            className="text-muted font-weight-bold"
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                          >
                                                                            <i
                                                                              className="mdi mdi-file-document-outline text-primary font-size-20"
                                                                              id="edittooltip"
                                                                            ></i>
                                                                          </Link>
                                                                        )}
                                                                      </Col>
                                                                    </Row>
                                                                  </div>
                                                                </Card>
                                                              );
                                                            },
                                                          )}
                                                          {validation.values
                                                            .fields[index1]
                                                            ?.file.length >
                                                            0 && (
                                                            <button
                                                              type="button"
                                                              onClick={() => {
                                                                validation.setFieldValue(
                                                                  `fields[${index1}].file`,
                                                                  [],
                                                                );
                                                              }}
                                                              style={{
                                                                backgroundColor:
                                                                  'orange',
                                                                color: 'white',
                                                                border: 'none',
                                                                borderRadius:
                                                                  '3px',
                                                                cursor:
                                                                  'pointer',
                                                                marginTop:
                                                                  '0px',
                                                                height: '22px',
                                                              }}
                                                            >
                                                              Remove
                                                            </button>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </>
                                                  )}
                                                </td>
                                                <td style={{ width: '5%' }}>
                                                  {validation.values.fields[
                                                    index1
                                                  ] &&
                                                    !validation.values.fields[
                                                      index1
                                                    ]?.entries_id &&
                                                    validation.values.fields[
                                                      index1
                                                    ]?.addtnl_field && (
                                                      <Link
                                                        className="text-danger"
                                                        to="#"
                                                        onClick={() => {
                                                          const newFields = [
                                                            ...validation.values
                                                              .fields,
                                                          ];
                                                          newFields.splice(
                                                            index1,
                                                            1,
                                                          );
                                                          setOperationFields(
                                                            newFields,
                                                          );
                                                        }}
                                                      >
                                                        <i
                                                          className="mdi mdi-delete font-size-24"
                                                          id="deletetooltip"
                                                        ></i>
                                                      </Link>
                                                    )}
                                                </td>
                                              </tr>
                                            ),
                                          )}

                                        {operationFields.length == 0 && (
                                          <tr>
                                            <td
                                              colSpan={6}
                                              className="text-center"
                                            >
                                              No cost tracking has been
                                              assigned. Please contact PMO
                                              Support.
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </Table>
                                  </Form>
                                </div>
                              </TabPane>
                            ))}
                          </TabContent>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

const mapStateToProps = ({ operations, authentication }) => ({
  operations,
  authentication,
});

export default connect(mapStateToProps, { actionCreator })(MonthlyOperation);
