import React, { Fragment } from "react";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import {
  Table,
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
} from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import { Link } from "react-router-dom";

//Import Flatepicker
import Flatpickr from "react-flatpickr";

import chart from "../../assets/images/chart.png";
import expired from "../../assets/images/expired.png";
import deadline from "../../assets/images/deadline.png";
import completedTask from "../../assets/images/completed-task.png";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isWorkItems,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const divStyle = {
    backgroundColor: "#fff",
    borderWidth: "1px",
    borderColor: "rgb(204, 204, 204)",
    height: "34px",
  };

  return (
    <Col sm={4}>
      <div className="search-box mb-0 d-inline-block">
        <div className="position-relative">
          <label htmlFor="search-bar-0" className="search-label">
            <span id="search-bar-0-label" className="sr-only">
              Search this table
            </span>
            <input
              onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              id="search-bar-0"
              type="text"
              className="form-control"
              placeholder={isWorkItems ? `Request No.` : ``}
              value={value || ""}
              style={
                isWorkItems
                  ? {
                      backgroundColor: "#fff",
                      borderWidth: "1px",
                      borderColor: "rgb(204, 204, 204)",
                      height: "34px",
                      fontSize: "15px",
                    }
                  : { backgroundColor: "#fff" }
              }
            />
          </label>
          <i className="bx bx-search-alt search-icon"></i>
        </div>
      </div>
    </Col>
  );
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isAddOptions,
  // isAddUserList,
  handleOrderClicks,
  // handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  isWorkItems,
  handleInvoiceClick,
  isBordered,
  theadClass,
  handleFilterClientSelect,
  handleFilterSupplierSelect,
  handleFilterStatusSelect,
  requisition,
  customHiddenColumns
}) => {
  const [options, setOptions] = React.useState([]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: customPageSize, hiddenColumns: customHiddenColumns || [] },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };
  return (
    <Fragment>
      <Row>
        {isWorkItems && (
          <>
            <Row>
              {isGlobalFilter && (
                <>
                  <Col md={6}>
                    <div className="col-sm">
                      <div className="d-flex align-items-center gap-1 mb-4">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn btn-link text-muted py-1 font-size-16 shadow-none dropdown-toggle"
                            tag="a"
                            style={{ borderWidth: 1, borderColor: "#ccc" }}
                          >
                            Filter by Client{" "}
                            <i className="mdi mdi-chevron-down"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem
                              onClick={() => handleFilterClientSelect("All")}
                            >
                              All
                            </DropdownItem>
                            {requisition.optionsData[0] && JSON.parse(requisition.optionsData[0]?.clients)
                              .filter(
                                (item, index, self) =>
                                  index ===
                                  self.findIndex((t) => t.id === item.id)
                              )
                              .map((item, index) => (
                                <DropdownItem
                                  key={index}
                                  onClick={() => {
                                    handleFilterClientSelect(item.id)
                                    setOptions(item.list)
                                  }}
                                >
                                  {item.trade_name}
                                </DropdownItem>
                              ))}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn btn-link text-muted py-1 font-size-16 shadow-none dropdown-toggle"
                            tag="a"
                            style={{ borderWidth: 1, borderColor: "#ccc" }}
                          >
                            Filter by Supplier{" "}
                            <i className="mdi mdi-chevron-down"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem
                              onClick={() => handleFilterSupplierSelect("All")}
                            >
                              All
                            </DropdownItem>
                            {options
                              .map((item, index) => (
                                <DropdownItem
                                  key={index}
                                  onClick={() =>
                                    handleFilterSupplierSelect(item.supplier_id)
                                  }
                                >
                                  {item.supplier_name}
                                </DropdownItem>
                              ))}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn btn-link text-muted py-1 font-size-16 shadow-none dropdown-toggle"
                            tag="a"
                            style={{ borderWidth: 1, borderColor: "#ccc" }}
                          >
                            Filter by Status{" "}
                            <i className="mdi mdi-chevron-down"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem
                              onClick={() => handleFilterStatusSelect("All")}
                            >
                              All
                            </DropdownItem>
                            {requisition.optionsData[0]?.status
                              .filter(
                                (item, index, self) =>
                                  index ===
                                  self.findIndex((t) => t.id === item.id)
                              )
                              .map((item, index) => (
                                <DropdownItem
                                  key={index}
                                  onClick={() =>
                                    handleFilterStatusSelect(item.id)
                                  }
                                >
                                  {item.name}
                                </DropdownItem>
                              ))}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        <div className="col-12" style={{ paddingTop: "21px" }}>
                          <label className="d-flex justify-content-start fw-normal">
                            {/* <span className="" style={{ marginRight: 10 }}>
                </span> */}
                            <GlobalFilter
                              preGlobalFilteredRows={preGlobalFilteredRows}
                              globalFilter={state.globalFilter}
                              setGlobalFilter={setGlobalFilter}
                              isWorkItems={true}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* <Col sm={12} md={6}>
              <label className="d-flex justify-content-start fw-normal">
                
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  isWorkItems={true}
                />
              </label>
            </Col> */}
                </>
              )}
            </Row>
            <Row className="col-md-12" style={{ maxWidth: "1000px" }}>
              <Col sm={3} lg={3}>
                <Card
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    color: "#5156BE",
                    border: "solid #5156BE 1px",
                  }}
                >
                  <>
                    <div style={{ margin: 10, width: "60%" }}>
                      <h5 className="card-title" style={{ fontSize: 12 }}>
                        Total Request
                      </h5>
                      <p
                        className="card-text"
                        style={{ fontWeight: 600, fontSize: 15 }}
                      >
                        {requisition.totalData[0]?.total_requisition}
                      </p>
                    </div>
                    <div
                      style={{
                        alignContent: "center",
                        width: "40%",
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={completedTask}
                        className="card-img-top"
                        alt="Workshops"
                        style={{
                          // maxHeight: 50,
                          maxWidth: 37,
                          alignSelf: "center",
                        }}
                      />
                    </div>
                  </>
                </Card>
              </Col>
              <Col sm={3} lg={3}>
                <Card
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    color: "#5156BE",
                    border: "solid #5156BE 1px",
                  }}
                >
                  <>
                    <div style={{ margin: 10, width: "60%" }}>
                      <h5 className="card-title" style={{ fontSize: 12 }}>
                        Total Pending
                      </h5>
                      <p
                        className="card-text"
                        style={{ fontWeight: 600, fontSize: 15 }}
                      >
                        {requisition.totalData[0]?.total_pending}
                      </p>
                    </div>
                    <div
                      style={{
                        alignContent: "center",
                        width: "40%",
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={deadline}
                        className="card-img-top"
                        alt="Workshops"
                        style={{
                          // maxHeight: 50,
                          maxWidth: 37,
                          alignSelf: "center",
                        }}
                      />
                    </div>
                  </>
                </Card>
              </Col>
              <Col sm={3} lg={3}>
                <Card
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    color: "#5156BE",
                    border: "solid #5156BE 1px",
                  }}
                >
                  <>
                    <div style={{ margin: 10, width: "60%" }}>
                      <h5 className="card-title" style={{ fontSize: 12 }}>
                        Total Completion
                      </h5>
                      <p
                        className="card-text"
                        style={{ fontWeight: 600, fontSize: 15 }}
                      >
                        {requisition.totalData[0]?.total_completed}
                      </p>
                    </div>
                    <div
                      style={{
                        alignContent: "center",
                        width: "40%",
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={chart}
                        className="card-img-top"
                        alt="Workshops"
                        style={{
                          // maxHeight: 50,
                          maxWidth: 70,
                          alignSelf: "center",
                        }}
                      />
                    </div>
                  </>
                </Card>
              </Col>
              {/* <Col
              sm={3}
              lg={3}
              style={{
                alignContent: "center",
              }}
            >
              <Card
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <img
                  src={deadline}
                  className="card-img-top"
                  alt="Camps"
                  style={{
                    // maxHeight: 50,
                    maxWidth: 37,
                    alignSelf: "center",
                  }}
                />
                <div style={{ margin: 20 }}>
                  <h5 className="card-title" style={{ fontSize: 20 }}>
                    Camps
                  </h5>
                  <p
                    className="card-text"
                    style={{ fontWeight: 700, fontSize: 18 }}
                  >
                    1
                  </p>
                </div>
              </Card>
            </Col> */}
              {/* <Col
              sm={3}
              lg={3}
              style={{
                alignContent: "center",
              }}
            >
              <Card
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <img
                  src={chart}
                  className="card-img-top"
                  alt="Units"
                  style={{
                    maxHeight: 50,
                    maxWidth: 50,
                    alignSelf: "center",
                  }}
                />
                <div style={{ margin: 20 }}>
                  <h5 className="card-title" style={{ fontSize: 20 }}>
                    Units
                  </h5>
                  <p
                    className="card-text"
                    style={{ fontWeight: 700, fontSize: 18 }}
                  >
                    12
                  </p>
                </div>
              </Card>
            </Col> */}
              {/* <Col
              sm={3}
              lg={3}
              style={{
                alignContent: "center",
              }}
            >
              <Card
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <img
                  src={chart}
                  className="card-img-top"
                  alt="Units"
                  style={{
                    maxHeight: 50,
                    maxWidth: 50,
                    alignSelf: "center",
                  }}
                />
                <div style={{ margin: 20 }}>
                  <h5 className="card-title" style={{ fontSize: 20 }}>
                    Units
                  </h5>
                  <p
                    className="card-text"
                    style={{ fontWeight: 700, fontSize: 18 }}
                  >
                    12
                  </p>
                </div>
              </Card>
            </Col> */}
              <Col sm={3} lg={3}>
                <Card
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    color: "#FD625E",
                    border: "solid #FD625E 1px",
                  }}
                >
                  <>
                    <div style={{ margin: 10, width: "70%" }}>
                      <h5 className="card-title" style={{ fontSize: 12 }}>
                        Total Delayed Requests
                      </h5>
                      <p
                        className="card-text"
                        style={{ fontWeight: 600, fontSize: 15 }}
                      >
                        {requisition.totalData[0]?.total_delayed_request}
                      </p>
                    </div>
                    <div
                      style={{
                        alignContent: "center",
                        width: "30%",
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={expired}
                        className="card-img-top"
                        alt="Workshops"
                        style={{
                          // maxHeight: 50,
                          maxWidth: 35,
                          alignSelf: "center",
                        }}
                      />
                    </div>
                  </>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6}>
                <label className="d-flex">
                  <span className="pt-1 fw-normal">Show</span>
                  <Col lg={1} className="ms-1 me-1">
                    <select
                      className="custom-select me-2 pe-3 custom-select-sm form-control form-control-sm form-select form-select-sm"
                      value={pageSize}
                      onChange={onChangeInSelect}
                    >
                      {[10, 20, 30, 40, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {" "}
                          {pageSize}
                        </option>
                      ))}
                    </select>
                  </Col>{" "}
                  <span className="pt-1 fw-normal">entries</span>
                </label>
              </Col>
            </Row>
          </>
        )}

        {!isWorkItems && (
          <Row>
            <Col sm={12} md={6}>
              <label className="d-flex">
                <span className="pt-1 fw-normal">Show</span>
                <Col lg={1} className="ms-1 me-1">
                  <select
                    className="custom-select me-2 pe-3 custom-select-sm form-control form-control-sm form-select form-select-sm"
                    value={pageSize}
                    onChange={onChangeInSelect}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {" "}
                        {pageSize}
                      </option>
                    ))}
                  </select>
                </Col>{" "}
                <span className="pt-1 fw-normal">entries</span>
              </label>
            </Col>
            {isGlobalFilter && (
              <Col sm={12} md={6}>
                <label className="d-flex justify-content-end fw-normal">
                  <span className="pt-2" style={{ marginRight: 10 }}>
                    Search:
                  </span>
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    isWorkItems={false}
                  />
                </label>
              </Col>
            )}
          </Row>
        )}
        {isAddOptions && (
          <Col sm="12">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {/* {isAddUserList && (
          <Col sm="12">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" /> 
                Create New User
               </Button>
            </div>
          </Col>
        )}  */}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive" style={{ minHeight: 200 }}>
        <Table
          hover
          {...getTableProps()}
          className={className}
          bordered={isBordered}
        >
          <thead className={theadClass}>
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th key={column.id}>
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    <Filter column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map((cell) => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
            {page.length === 0 && (
              <tr className="text-center">
                <td colSpan={headerGroups[0].headers?.length}> No Record Found.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      <Row className="align-items-center g-3 text-center text-sm-start">
        <div className="col-sm">
          <div>
            Showing<span className="fw-normal ms-1">{page.length}</span> of{" "}
            <span className="fw-normal">{data.length}</span> Results
          </div>
        </div>
        <div className="col-sm-auto">
          <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
            <li
              className={!canPreviousPage ? "page-item disabled" : "page-item"}
            >
              <Link to="#" className="page-link" onClick={previousPage}>
                Previous
              </Link>
            </li>
            {pageOptions.map((item, key) => (
              <React.Fragment key={key}>
                <li className="page-item">
                  <Link
                    to="#"
                    className={
                      pageIndex === item ? "page-link active" : "page-link"
                    }
                    onClick={() => gotoPage(item)}
                  >
                    {item + 1}
                  </Link>
                </li>
              </React.Fragment>
            ))}
            <li className={!canNextPage ? "page-item disabled" : "page-item"}>
              <Link to="#" className="page-link" onClick={nextPage}>
                Next
              </Link>
            </li>
          </ul>
        </div>
      </Row>
    </Fragment>
  );
};

export default TableContainer;
