import React from 'react';
import { connect } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Card,
  Col,
  Form,
  FormFeedback,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { actionCreator, types } from '../../../store';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Dropzone from 'react-dropzone';

import Breadcrumb from '../../../components/Common/Breadcrumb';

const JobRequest = ({ authentication, clientfields, ...props }) => {
  const { user_rules, user_group_id } = authentication.data.data;
  const editorRef = React.useRef();
  const [editor, setEditor] = React.useState(false);
  const [attachmentModal, setAttachmentModal] = React.useState(null);
  const [jobDescription, setJobDescription] = React.useState('');
  const [selectedFiles, setselectedFiles] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState([]);
  const { CKEditor, ClassicEditor } = editorRef.current || {};
  const { department, costCenter } = clientfields;
  const { id } = useParams();
  let navigate = useNavigate();

  React.useEffect(() => {
    editorRef.current = {
      CKEditor: require('@ckeditor/ckeditor5-react').CKEditor,
      ClassicEditor: require('@ckeditor/ckeditor5-build-classic'),
    };
    setEditor(true);
  }, []);

  React.useEffect(() => {
    fetchDepartment();
    fetchCostCenter();
  }, []);

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type_of_services: '',
      department: '',
      costCenter: '',
      positionNumber: '',
      salaryBudget: '',
      jobTitle: '',
      pmo_declaration: '',
    },
    validationSchema: Yup.object({
      type_of_services: Yup.string().required('Select Type of Services'),
      department: Yup.string().required('Select a Department'),
      costCenter: Yup.string().required('Select a Cost Center'),
      positionNumber: Yup.string().required('Select a No. of Positions'),
      salaryBudget: Yup.string()
        .matches(/^[0-9]*$/, 'Client budget should only contain numbers.')
        .required('Enter a Client Budget'),
      jobTitle: Yup.string().required('Enter a Job Title'),
      pmo_declaration: Yup.string().required(
        'You must answer the PMO Declaration',
      ),
    }),
    onSubmit: async (values) => {
      let newJobRequest = new FormData();

      if (selectedFiles.length > 0) {
        for (let i = 0; i < selectedFiles.length; i++) {
          newJobRequest.append(
            'files',
            selectedFiles[i],
            selectedFiles[i].name,
          );
        }
      }

      let request_content = [];

      request_content.push({
        label: 'Type of Services',
        column: 'type_of_services',
        values: JSON.stringify([
          {
            id: '',
            name: values['type_of_services'],
          },
        ]),
      });

      request_content.push({
        label: 'Department',
        column: 'department',
        values: JSON.stringify([
          {
            id: 'department',
            name: values['department'],
          },
        ]),
      });

      request_content.push({
        label: 'Cost Center',
        column: 'cost_center',
        values: JSON.stringify([
          {
            id: '',
            name: values['costCenter'],
          },
        ]),
      });

      request_content.push({
        label: 'No. of Position',
        column: 'no_positions',
        values: JSON.stringify([
          {
            id: '',
            name: values['positionNumber'],
          },
        ]),
      });

      request_content.push({
        label: 'Client Budget',
        column: 'client_salary_budget',
        values: JSON.stringify([
          {
            id: '',
            name: values['salaryBudget'],
          },
        ]),
      });

      request_content.push({
        label: 'Job Title',
        column: 'job_title',
        values: JSON.stringify([
          {
            id: '',
            name: values['jobTitle'],
          },
        ]),
      });

      request_content.push({
        label: 'Job Description',
        column: 'job_description',
        values: JSON.stringify([
          {
            id: '',
            name: jobDescription,
          },
        ]),
      });

      newJobRequest.append('request_type_id', id);
      newJobRequest.append('pmo_declaration', values['pmo_declaration']);
      newJobRequest.append('request_content', JSON.stringify(request_content));

      // save new job request
      props.actionCreator({
        type: types.ADD_REQUISITION,
        payload: {
          formData: newJobRequest,
        },
      });

      validation.resetForm();
      setselectedFiles([]);
      setJobDescription('');
      navigate('/requisition');
    },
  });

  const fetchDepartment = async () => {
    let clientId = null;
    if (user_rules != 'all') {
      clientId = parseInt(
        user_rules.filter((r) => r.type === 'client')[0].source_id,
      );
    }
    await props.actionCreator({
      type: types.GET_DEPARTMENT_OPTIONS,
      payload: {
        type: 'department',
        client_id: clientId,
      },
    });
  };

  const fetchCostCenter = async () => {
    let clientId = null;
    if (user_rules != 'all') {
      clientId = parseInt(
        user_rules.filter((r) => r.type === 'client')[0].source_id,
      );
    }
    await props.actionCreator({
      type: types.GET_COST_CENTER_OPTIONS,
      payload: {
        type: 'cost center',
        client_id: clientId,
      },
    });
  };

  const toggleAttachmentModal = () => {
    setAttachmentModal(!attachmentModal);
  };

  //meta title
  document.title = 'Job Request';

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            titleLink="/requisition"
            title="Requisitions"
            breadcrumbItem="Job Request"
          />
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col lg={12}>
                <Row>
                  <Col lg={3}>
                    <div className="mb-3">
                      <Label className="form-label">Types of Services</Label>
                      <Input
                        type="select"
                        name="type_of_services"
                        className="form-select"
                        multiple={false}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.type_of_services || '0'}
                        invalid={
                          validation.touched.type_of_services &&
                          validation.errors.type_of_services
                            ? true
                            : false
                        }
                      >
                        <option value={''}>select</option>
                        <option value={'Staff Augmentation'}>
                          Staff Augmentation
                        </option>
                        <option value={'Outsourcing of Services'}>
                          Statement of Work
                        </option>
                        <option value={'Outsourcing of Activities'}>
                          Freelancers
                        </option>
                      </Input>
                      {validation.touched.type_of_services &&
                      validation.errors.type_of_services ? (
                        <FormFeedback type="invalid">
                          {validation.errors.type_of_services}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-3">
                      <Label className="form-label">Department</Label>
                      <Input
                        type="select"
                        name="department"
                        className="form-select"
                        multiple={false}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.department || '0'}
                        invalid={
                          validation.touched.department &&
                          validation.errors.department
                            ? true
                            : false
                        }
                      >
                        <option value={''}> </option>
                        {department.data.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.department &&
                      validation.errors.department ? (
                        <FormFeedback type="invalid">
                          {validation.errors.department}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-3">
                      <Label className="form-label">Cost Center</Label>
                      <Input
                        type="select"
                        name="costCenter"
                        className="form-select"
                        multiple={false}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.costCenter || '0'}
                        invalid={
                          validation.touched.costCenter &&
                          validation.errors.costCenter
                            ? true
                            : false
                        }
                      >
                        <option value={''}> </option>
                        {costCenter.data.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </Input>
                      {validation.touched.costCenter &&
                      validation.errors.costCenter ? (
                        <FormFeedback type="invalid">
                          {validation.errors.costCenter}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <div className="mb-3">
                      <Label className="form-label">No. of Positions</Label>
                      <Input
                        type="number"
                        name="positionNumber"
                        className="form-control"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.positionNumber || '0'}
                        invalid={
                          validation.touched.positionNumber &&
                          validation.errors.positionNumber
                            ? true
                            : false
                        }
                        min={0}
                      />
                      {validation.touched.positionNumber &&
                      validation.errors.positionNumber ? (
                        <FormFeedback type="invalid">
                          {validation.errors.positionNumber}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-3">
                      <Label className="form-label">Client Budget</Label>
                      <Input
                        type="text"
                        name="salaryBudget"
                        className="form-control"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.salaryBudget || ''}
                        invalid={
                          validation.touched.salaryBudget &&
                          validation.errors.salaryBudget
                            ? true
                            : false
                        }
                      />
                      {validation.touched.salaryBudget &&
                      validation.errors.salaryBudget ? (
                        <FormFeedback type="invalid">
                          {validation.errors.salaryBudget}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label className="form-label">Job Title</Label>
                      <Input
                        type="text"
                        name="jobTitle"
                        className="form-control"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.jobTitle || ''}
                        invalid={
                          validation.touched.jobTitle &&
                          validation.errors.jobTitle
                            ? true
                            : false
                        }
                      />
                      {validation.touched.jobTitle &&
                      validation.errors.jobTitle ? (
                        <FormFeedback type="invalid">
                          {validation.errors.jobTitle}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label className="form-label">Job Description</Label>
                      {editor ? (
                        <CKEditor
                          editor={ClassicEditor}
                          data={jobDescription}
                          onReady={(editor) => {
                            console.log('Editor is ready to use!', editor);
                            // You can store the "editor" and use when it is needed.
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setJobDescription(data);
                          }}
                        />
                      ) : (
                        <p>ckeditor5</p>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row
                  style={{
                    marginTop: 25,
                  }}
                >
                  <Col lg={2}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Add Attachments (optional)
                      </Label>
                      <button
                        type="button"
                        className="btn btn-light"
                        style={{
                          width: 95,
                          height: 90,
                        }}
                        onClick={toggleAttachmentModal}
                      >
                        <i className="mdi mdi-upload display-6"> </i>
                        <p>Upload</p>
                      </button>
                    </div>
                  </Col>
                  <Col lg={10}>
                    <div
                      className="dropzone-previews mt-3"
                      id="file-previews"
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            style={{
                              marginLeft: 5,
                              marginRight: 5,
                              width: '50%',
                            }}
                            key={i + '-file'}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col
                                  className="col-auto text-primary"
                                  style={{
                                    marginBottom: 10,
                                  }}
                                >
                                  <i
                                    className="mdi mdi-file-document-outline font-size-30"
                                    id="edittooltip"
                                  ></i>
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  </Col>
                  <Modal
                    isOpen={attachmentModal}
                    toggle={toggleAttachmentModal}
                    style={{ maxWidth: '700px', width: '80%' }}
                  >
                    <ModalHeader toggle={toggleAttachmentModal} tag="h4">
                      Attach Files
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col xs={12}>
                          <Dropzone
                            onDrop={(acceptedFiles, fileRejections) => {
                              setselectedFiles(acceptedFiles);
                              setErrorMessages(
                                fileRejections.map(
                                  ({ file, errors }) =>
                                    `${file.name}: ${errors.map((e) => e.message).join(', ')} `,
                                ),
                              );

                              if (!fileRejections.length) {
                                toggleAttachmentModal();
                              }
                            }}
                            accept={{
                              'application/pdf': ['.pdf'],
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick mt-2"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bx-cloud-upload" />
                                  </div>
                                  <h4>
                                    Drop files here or click to attach files.
                                  </h4>
                                  {errorMessages.length > 0 && (
                                    <div
                                      className="error-messages"
                                      style={{
                                        fontSize: 12,
                                      }}
                                    >
                                      {errorMessages.map((message, index) => (
                                        <p key={index} className="text-danger">
                                          {message}
                                        </p>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </Row>
                <Row
                  style={{
                    marginTop: 40,
                  }}
                >
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Has TAPFIN under Manpower Staffing Services (S) Pte Ltd
                        (Agency) briefed you on the TGFEP?*
                      </Label>
                      <Input
                        type="select"
                        name="pmo_declaration"
                        style={{
                          width: 332,
                        }}
                        className="form-select"
                        multiple={false}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.pmo_declaration || ''}
                        invalid={
                          validation.touched.pmo_declaration &&
                          validation.errors.pmo_declaration
                            ? true
                            : false
                        }
                      >
                        <option value={''}> </option>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                      </Input>
                      {validation.touched.pmo_declaration &&
                      validation.errors.pmo_declaration ? (
                        <FormFeedback type="invalid">
                          {validation.errors.pmo_declaration}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        style={{
                          marginLeft: 20,
                        }}
                      >
                        By answering Yes you agree that you
                        <p
                          style={{
                            marginLeft: 10,
                            marginTop: 15,
                          }}
                        >
                          1. Have been briefed by the Agency read and fully
                          understood the prevailing Tripartite Guidelines on
                          Fair Employment Practices (TGFEP).
                        </p>
                        <p
                          style={{
                            marginLeft: 10,
                          }}
                        >
                          2. Will ensure that the selection criteria in your job
                          descriptions are stated clearly and not use any
                          language that is discriminatory.
                        </p>
                        <p
                          style={{
                            marginLeft: 10,
                          }}
                        >
                          3. Will assess every profiled applicant from the
                          Agency based on merit and not discriminate against
                          applicant(s) on ground(s) which is irrelevant to the
                          job position.
                        </p>
                        <p
                          style={{
                            marginLeft: 10,
                          }}
                        >
                          4. Will provide an acknowledgement using the
                          prescribed form to instruct the Agency that it need
                          not attract Singaporeans for a particular job order,
                          and will not hold the Agency responsible for any
                          consequence hereafter. You understand that the Agency
                          will retain such acknowledgement for one year from the
                          date of signature and furnish it as and when requested
                          by the Ministry of Manpower.
                        </p>
                        For more information, please refer to the TGFEP;
                        Employment Agency License Conditions and Briefing Notes
                        on TGFEP.
                      </Label>
                    </div>
                  </Col>
                </Row>
                <Row
                  style={{
                    margin: 40,
                  }}
                >
                  <Col
                    lg={12}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-info text-white"
                        style={{
                          backgroundColor: '#5156BE',
                          width: 175,
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ authentication, clientfields }) => ({
  authentication,
  clientfields,
});

export default connect(mapStateToProps, { actionCreator })(JobRequest);
