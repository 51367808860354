export const GET_OPERATION_CLIENTS = "@requisition/GET_OPERATION_CLIENTS";
export const GET_OPERATION_CLIENTS_SUCCESS = "@requisition/GET_OPERATION_CLIENTS_SUCCESS";
export const GET_OPERATION_CLIENTS_FAILED = "@requisition/GET_OPERATION_CLIENTS_FAILED";

export const SAVE_MONTHLY_OPERATION = "@requisition/SAVE_MONTHLY_OPERATION";
export const SAVE_MONTHLY_OPERATION_SUCCESS = "@requisition/SAVE_MONTHLY_OPERATION_SUCCESS";
export const SAVE_MONTHLY_OPERATION_FAILED = "@requisition/SAVE_MONTHLY_OPERATION_FAILED";

export const GET_OPERATION_LIST = "@requisition/GET_OPERATION_LIST";
export const GET_OPERATION_LIST_SUCCESS = "@requisition/GET_OPERATION_LIST_SUCCESS";
export const GET_OPERATION_LIST_FAILED = "@requisition/GET_OPERATION_LIST_FAILED";
