import React from "react";
import { Link } from "react-router-dom";

const Tags = (cell) => {
  const color =
    cell.row.original.status_id === 1
      ? "bg-warning"
      : cell.row.original.status_id === 4 || cell.row.original.status_id === 5
        ? "bg-danger"
        : "bg-success";
  return (
    <React.Fragment>
      <Link
        to="#"
        className={`badge ${color} text-white font-size-11 m-1`}
        style={{
          padding: 5,
        }}
      >
        {cell.row.original.status}
      </Link>
    </React.Fragment>
  );
};

const Navigate = (cell) => {

  // Access the row data from cellProps
  const jobOrderData = cell.row.original;  

  return (
    <React.Fragment>
      <Link
        to={`/job-order/overview/${jobOrderData.id}`}
        style={{
          fontWeight: 700,
          fontFamily: "IBM Plex Sans",
        }}
      >
        {cell.value}
      </Link>
    </React.Fragment>
  );
};

const Name = (cell) => {
  return cell.value ? cell.value : "";
};

export { Name, Navigate, Tags };
