import { call, put, takeLatest } from "redux-saga/effects";

import { handleErrorResponse } from "../../helpers/api_helper";
import {
  GET_USERGROUP,
  GET_USERGROUP_SUCCESS,
  GET_USERGROUP_FAILED,
  GET_SELECTED_USERGROUP,
  GET_SELECTED_USERGROUP_SUCCESS,
  GET_SELECTED_USERGROUP_FAILED,
  POST_USERGROUP_SUBMIT,
  POST_USERGROUP_SUBMIT_SUCCESS,
  POST_USERGROUP_SUBMIT_FAILED,
  GET_USERGROUP_LIST,
  GET_USERGROUP_LIST_SUCCESS,
  GET_USERGROUP_LIST_FAILED,
  ADD_NEW_USERGROUP,
  ADD_NEW_USERGROUP_SUCCESS,
  ADD_NEW_USERGROUP_FAILED,
  UPDATE_USERGROUP,
  UPDATE_USERGROUP_SUCCESS,
  UPDATE_USERGROUP_FAILED,
  DELETE_USERGROUP,
  DELETE_USERGROUP_SUCCESS,
  DELETE_USERGROUP_FAILED,
  GET_USERGROUP_SETTINGS,
  GET_USERGROUP_SETTINGS_SUCCESS,
  GET_USERGROUP_SETTINGS_FAILED,
} from "./types";

import usergroupServices from "../../api/services/user-group";

export function* fnGetUserGroup({ payload }) {
  const { display, page } = payload;
  try {
    const response = yield call(usergroupServices.api.fnGetUserGroup, {
      display,
      page,
    });

    if (response) {
      yield put({
        type: GET_USERGROUP_SUCCESS,
        payload: {
          ...response.data.data,
        },
      });
    }
  } catch (error) {
    yield put({
      type: GET_USERGROUP_FAILED,
      ...handleErrorResponse(error),
    });
  }
}

export function* fnGetUserGroupList() {
  try {
    const response = yield call(usergroupServices.api.fnGetUserGroupList);
    if (response) {
      yield put({
        type: GET_USERGROUP_LIST_SUCCESS,
        payload: [...response.data.data],
      });
    }
  } catch (error) {
    yield put({
      type: GET_USERGROUP_LIST_FAILED,
      ...handleErrorResponse(error),
    });
  }
}

export function* fnGetUserGroupByID({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(usergroupServices.api.fnGetUserGroupByID, {
      id,
    });
    if (response) {
      yield put({
        type: GET_SELECTED_USERGROUP_SUCCESS,
        payload: { ...response.data.data },
      });
    }
  } catch (error) {
    yield put({
      type: GET_SELECTED_USERGROUP_FAILED,
      ...handleErrorResponse(error),
    });
  }
}

export function* fnPostUserGroup({ payload }) {
  try {
    const response = yield call(usergroupServices.api.fnPostUserGroup, payload);
    if (response) {
      yield put({ type: POST_USERGROUP_SUBMIT_SUCCESS });
    }
  } catch (error) {
    yield put({
      type: POST_USERGROUP_SUBMIT_FAILED,
      ...handleErrorResponse(error),
    });
  }
}

function* fnAddNewUserGroup({ payload }) {
  try {
    const result = yield call(usergroupServices.api.fnAddNewUserGroup, payload);

    if (result) {
      yield put({
        type: ADD_NEW_USERGROUP_SUCCESS,
        payload: { message: result.data.message },
      });
      yield put({
        type: GET_USERGROUP_LIST,
      });
    }
  } catch (error) {
    yield put({
      type: ADD_NEW_USERGROUP_FAILED,
      ...handleErrorResponse(error),
    });
  }
}

function* fnUpdateUserGroup({ payload }) {
  try {
    const result = yield call(usergroupServices.api.fnUpdateUserGroup, payload);
    if (result) {
      yield put({
        type: UPDATE_USERGROUP_SUCCESS,
        payload: { message: result.data.message },
      });
      yield put({
        type: GET_USERGROUP_LIST,
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_USERGROUP_FAILED,
      ...handleErrorResponse(error),
    });
  }
}

function* fnDeleteUserGroup({ payload }) {
  try {
    const { id } = payload;

    const result = yield call(usergroupServices.api.fnDeleteUserGroup, id);
    yield put({
      type: DELETE_USERGROUP_SUCCESS,
      payload: { message: result.data.message },
    });
    yield put({
      type: GET_USERGROUP_LIST,
    });
  } catch (error) {
    yield put({
      type: DELETE_USERGROUP_FAILED,
      ...handleErrorResponse(error),
    });
  }
}

export function* fnGetUserGroupSettings({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(usergroupServices.api.fnGetUserGroupSettings, {
      id,
    });
    if (response) {
      yield put({
        type: GET_USERGROUP_SETTINGS_SUCCESS,
        payload: { ...response.data.data },
      });
    }
  } catch (error) {
    yield put({
      type: GET_USERGROUP_SETTINGS_FAILED,
      ...handleErrorResponse(error),
    });
  }
}

export default function* watcher() {
  yield takeLatest(GET_USERGROUP, fnGetUserGroup);
  yield takeLatest(GET_SELECTED_USERGROUP, fnGetUserGroupByID);
  yield takeLatest(GET_USERGROUP_LIST, fnGetUserGroupList);
  yield takeLatest(POST_USERGROUP_SUBMIT, fnPostUserGroup);
  yield takeLatest(ADD_NEW_USERGROUP, fnAddNewUserGroup);
  yield takeLatest(UPDATE_USERGROUP, fnUpdateUserGroup);
  yield takeLatest(DELETE_USERGROUP, fnDeleteUserGroup);
  yield takeLatest(GET_USERGROUP_SETTINGS, fnGetUserGroupSettings);
}
