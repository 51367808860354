import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { actionCreator } from "../../../store";

//SimpleBar
import SimpleBar from "simplebar-react";

const RecentActivity = ({ dashboard }) => {
  const [recentRequestsActivity, setRecentRequestsActivity] = React.useState(
    []
  );

  React.useEffect(() => {
    dashboard.recentRequestsActivity.data?.recentRequests &&
      setRecentRequestsActivity(
        dashboard.recentRequestsActivity.data?.recentRequests
      );
  }, [dashboard.recentRequestsActivity.data]);

  return (
    <React.Fragment>
      <Col xl={8}>
        <Card>
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">
              Recent Requests Activity
            </h4>
          </CardHeader>

          <CardBody className="px-0">
            <SimpleBar className="px-3" style={{ maxHeight: "352px" }}>
              <ul className="list-unstyled activity-wid mb-0">
                {recentRequestsActivity.map((item, index) => (
                  <li key={index} className="activity-list activity-border">
                    <div className="activity-icon avatar-md">
                      {index % 2 ? (
                        <span
                          className="avatar-title bg-warning-subtle text-warning rounded-circle"
                          style={{
                            fontSize: 12,
                          }}
                        >
                          {item.id}
                        </span>
                      ) : (
                        <span
                          className="avatar-title  bg-primary-subtle text-primary rounded-circle"
                          style={{
                            fontSize: 12,
                          }}
                        >
                          {item.id}
                        </span>
                      )}
                    </div>
                    <div className="timeline-list-item">
                      <div className="d-flex">
                        <div className="flex-grow-1 overflow-hidden me-4">
                          <h5 className="font-size-14 mb-1">
                            {item.formatted_date_added}
                          </h5>
                          <p className="text-truncate text-muted font-size-13">
                            <span
                              style={{
                                fontWeight: 700,
                                marginRight: 10,
                              }}
                            >
                              {item.trade_name}
                            </span>
                            <span>{item.full_name}</span>
                          </p>
                        </div>

                        <div className="flex-shrink-0 text-end">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="text-muted font-size-24"
                              tag="a"
                            >
                              <i className="mdi mdi-dots-vertical"></i>
                            </DropdownToggle>

                            <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem
                                tag={Link}
                                to={`/work-items/${item.id}`}
                              >
                                View details{" "}
                                <i className="mdi mdi-arrow-right ms-1"></i>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </SimpleBar>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

const mapStateToProps = ({ dashboard }) => ({
  dashboard,
});

export default connect(mapStateToProps, { actionCreator })(RecentActivity);
