const candidateStatusColors = [
  {
    id: 1,
    color: "#FFBF53",
    status: "Submitted",
  },
  {
    id: 2,
    color: "#FF53C5",
    status: "shortlisted",
  },
  {
    id: 3,
    color: "#4BA6EF",
    status: "Schedule Interview",
  },
  {
    id: 4,
    color: "#4BA6EF",
    status: "Reschedule Interview",
  },
  {
    id: 5,
    color: "#4BA6EF",
    status: "No show Interview",
  },
  {
    id: 6,
    color: "#2AB57D",
    status: "Offered",
  },
  {
    id: 7,
    color: "#5156BE",
    status: "Accepted",
  },
  {
    id: 8,
    color: "#FD625E",
    status: "Rejected",
  },
  {
    id: 9,
    color: "#FD625E",
    status: "Rejected Interview Schedule",
  },
  {
    id: 10,
    color: "#4BA6EF",
    status: "Accepted interview schedule by candidate",
  },
  {
    id: 11,
    color: "#802ab5",
    status: "Convert a Worker",
  },
];

export { candidateStatusColors };
