import React from "react";
import ReactApexChart from "react-apexcharts";

const LineApexChart = ({ title, series, categories, yaxis, stroke = {} }) => {
  const options = {
    chart: {
      height: 380,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#5156be", "#2ab57d"],
    dataLabels: {
      enabled: false,
    },
    stroke: stroke,
    series: series,
    title: {
      text: title,
      align: "left",
      style: {
        fontWeight: "500",
      },
    },
    grid: {
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
      borderColor: "#f1f1f1",
    },
    markers: {
      style: "inverted",
      size: 0,
    },
    xaxis: {
      categories: categories,
      title: {
        text: "Month",
      },
    },
    yaxis: {
      title: {
        text: "Cost",
      },
      min: yaxis.min,
      max: yaxis.max,
      labels: {
        formatter: function (value) {
          return yaxis.currency ? `$${value}` : value;
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height="380"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default LineApexChart;
