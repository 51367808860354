import React from "react";
import * as Yup from "yup";
import { connect } from "react-redux";
import { useFormik } from "formik";
import {
  Row,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { actionCreator, types } from "../../../../store";

const OfferCandidate = ({
  candidateData,
  jo_candidate_id,
  offerCandidateModal,
  toggleOfferModal,
  ...props
}) => {
  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      jo_candidate_id: (jo_candidate_id && parseInt(jo_candidate_id)) || null,
      pay_rate: "",
      reporting_location: "",
      reporting_date: "",
      reporting_time: "",
      person_to_report_to: "",
      contact_details: "",
    },
    validationSchema: Yup.object({
      pay_rate: Yup.string().required("Please enter Pay Rate."),
      reporting_location: Yup.string().required(
        "Please enter Reporting Location."
      ),
      reporting_date: Yup.string().required("Please enter Reporting Date."),
      reporting_time: Yup.string().required("Please enter Reporting Time."),
      person_to_report_to: Yup.string().required(
        "Please enter Person to Report to."
      ),
      contact_details: Yup.string().required("Please enter Contact Details."),
    }),
    onSubmit: async (values) => {
      let newOfferToCandidate = {
        jo_candidate_id: values["jo_candidate_id"],
        pay_rate: values["pay_rate"],
        reporting_location: values["reporting_location"],
        reporting_date: values["reporting_date"],
        reporting_time: values["reporting_time"],
        person_to_report_to: values["person_to_report_to"],
        contact_details: values["contact_details"],
      };

      // save new candidate interview
      props.actionCreator({
        type: types.POST_ADD_CANDIDATE_OFFER,
        payload: newOfferToCandidate,
      });

      validation.resetForm();
      toggleOfferModal();
    },
  });

  return (
    <React.Fragment>
      <Modal
        isOpen={offerCandidateModal}
        toggle={toggleOfferModal}
        style={{ maxWidth: "800px", width: "100%" }}
      >
        <ModalHeader toggle={toggleOfferModal} tag="h4">
          Offer Candidate Form
        </ModalHeader>
        <ModalBody style={{ margin: 15 }}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col lg={5}>
                <div>
                  <Label className="form-label">Candidate</Label>
                  <Input
                    type="select"
                    name="jo_candidate_id"
                    className="form-select"
                    multiple={false}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.jo_candidate_id || "0"}
                    invalid={
                      validation.touched.jo_candidate_id &&
                      validation.errors.jo_candidate_id
                        ? true
                        : false
                    }
                  >
                    <option
                      value={candidateData.length > 0 && candidateData[0].id}
                    >
                      {candidateData.length > 0 &&
                        candidateData[0].candidate_name}
                    </option>
                  </Input>
                  {validation.touched.jo_candidate_id &&
                  validation.errors.jo_candidate_id ? (
                    <FormFeedback type="invalid">
                      {validation.errors.jo_candidate_id}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={5}>
                <div className="mb-3 mt-3">
                  <Label className="form-label">Pay Rate</Label>
                  <Input
                    type="number"
                    name="pay_rate"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.pay_rate || ""}
                    invalid={
                      validation.touched.pay_rate && validation.errors.pay_rate
                        ? true
                        : false
                    }
                  />
                  {validation.touched.pay_rate && validation.errors.pay_rate ? (
                    <FormFeedback type="invalid">
                      {validation.errors.pay_rate}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={5}>
                <div className="mb-3">
                  <Label className="form-label">Reporting Location</Label>
                  <Input
                    type="text"
                    name="reporting_location"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.reporting_location || ""}
                    invalid={
                      validation.touched.reporting_location &&
                      validation.errors.reporting_location
                        ? true
                        : false
                    }
                  />
                  {validation.touched.reporting_location &&
                  validation.errors.reporting_location ? (
                    <FormFeedback type="invalid">
                      {validation.errors.reporting_location}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={5}>
                <div className="mb-3 mt-4">
                  <Label className="form-label">Reporting Date</Label>
                  <Input
                    type="date"
                    name="reporting_date"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.reporting_date || ""}
                    invalid={
                      validation.touched.reporting_date &&
                      validation.errors.reporting_date
                        ? true
                        : false
                    }
                  />
                  {validation.touched.reporting_date &&
                  validation.errors.reporting_date ? (
                    <FormFeedback type="invalid">
                      {validation.errors.reporting_date}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={4}>
                <div className="mb-3 mt-4">
                  <Label className="form-label">Reporting Time (HH:MM)</Label>
                  <Input
                    type="time"
                    name="reporting_time"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.reporting_time || ""}
                    invalid={
                      validation.touched.reporting_time &&
                      validation.errors.reporting_time
                        ? true
                        : false
                    }
                  />
                  {validation.touched.reporting_time &&
                  validation.errors.reporting_time ? (
                    <FormFeedback type="invalid">
                      {validation.errors.reporting_time}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={5}>
                <div className="mb-3">
                  <Label className="form-label">Person to Report to</Label>
                  <Input
                    type="text"
                    name="person_to_report_to"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.person_to_report_to || ""}
                    invalid={
                      validation.touched.person_to_report_to &&
                      validation.errors.person_to_report_to
                        ? true
                        : false
                    }
                  />
                  {validation.touched.person_to_report_to &&
                  validation.errors.person_to_report_to ? (
                    <FormFeedback type="invalid">
                      {validation.errors.person_to_report_to}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={5}>
                <div className="mb-3">
                  <Label className="form-label">Contact Details</Label>
                  <Input
                    type="text"
                    name="contact_details"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.contact_details || ""}
                    invalid={
                      validation.touched.contact_details &&
                      validation.errors.contact_details
                        ? true
                        : false
                    }
                  />
                  {validation.touched.contact_details &&
                  validation.errors.contact_details ? (
                    <FormFeedback type="invalid">
                      {validation.errors.contact_details}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row
              style={{
                marginTop: 40,
              }}
            >
              <Col
                lg={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-info text-white"
                    style={{
                      backgroundColor: "#5156BE",
                      width: 142,
                    }}
                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = ({ joborder }) => ({
  joborder,
});

export default connect(mapStateToProps, { actionCreator })(OfferCandidate);
