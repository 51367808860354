import { emsApi } from "..";
import { OPERATION_SERVICE } from "../constants";

class Operations {
  async fnGetOperationClients(payload) {
    return await emsApi.get(OPERATION_SERVICE.GET_OPERATION_CLIENTS, payload);
  }

  async fnSave(payload) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return await emsApi.post(`${OPERATION_SERVICE.SAVE_OPERATION}`, payload, {
      headers: headers,
    });
  }

  async fnGetOperationList(payload) {
    return await emsApi.post(OPERATION_SERVICE.GET_OPERATION_LIST, payload);
  }
}

Operations.api = new Operations();
export default Operations;
