import React from "react";
import { Navigate } from "react-router-dom";

// utility pages
import PageMaintenance from "../pages/Utility/PageMaintenance";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";

// authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// authentication inner related pages
import PageLogin from "../pages/AuthenticationInner/PageLogin";
import PageRegister from "../pages/AuthenticationInner/PageRegister";
import RecoverPassword from "../pages/AuthenticationInner/RecoverPassword";
import AuthLogout from "../pages/AuthenticationInner/Logout";
import UserProfile from "../pages/Authentication/user-profile";
import User from "../pages/User";
import UserGroup from "../pages/UserGroup";
import Dashboard from "../pages/Dashboard";
import Suppliers from "../pages/Suppliers";
import SuppliersManage from "../pages/Suppliers/Manage";
import Clients from "../pages/Clients";
import ClientsManage from "../pages/Clients/Manage";
import Workers from "../pages/Workers";
import WorkerManage from "../pages/Workers/Manage";
import Requisition from "../pages/Requisition";
import JobRequest from "../pages/Requisition/JobRequest";
import EvaluateWorker from "../pages/Requisition/EvaluateWorker";
import EarlyTermination from "../pages/Requisition/EarlyTermination";
import WorkerExtension from "../pages/Requisition/WorkerExtension";
import Others from "../pages/Requisition/Others";
import PmoWorkItems from "../pages/PmoWorkItems";
import MonthlyOperation from "../pages/MonthlyOperation";
import JobOrder from "../pages/JobOrder";
import JobOrderOverview from "../pages/JobOrder/Overview";
import CandidatePreview from "../pages/JobOrder/Candidate/Preview";
import WorkerBI from "../pages/Workers/BI";
import SuppliersBI from "../pages/Suppliers/BI";

const userRoutes = [
  // dashboard page
  { path: "/dashboard", component: <Dashboard /> },

  // profile page
  { path: "/profile", component: <UserProfile /> },

  // add your custom pages here
  { path: "/user", component: <User /> },
  { path: "/user-group", component: <UserGroup /> },
  // suppliers
  { path: "/suppliers", component: <Suppliers /> },
  { path: "/suppliers/add", component: <SuppliersManage route="add" /> },
  { path: "/suppliers/edit/:id", component: <SuppliersManage route="edit" /> },
  { path: "/work-items", component: <PmoWorkItems /> },
  { path: "/work-items/:id", component: <PmoWorkItems /> },
  { path: "/monthly-operation", component: <MonthlyOperation /> },
  { path: "/supplier-bi", component: <SuppliersBI /> },
  // requisition
  { path: "/requisition", component: <Requisition /> },
  { path: "/requisition/job-request/:id", component: <JobRequest /> },
  { path: "/requisition/evaluate-worker/:id", component: <EvaluateWorker /> },
  {
    path: "/requisition/early-termination/:id",
    component: <EarlyTermination />,
  },
  { path: "/requisition/worker-extension/:id", component: <WorkerExtension /> },
  { path: "/requisition/others/:id", component: <Others /> },
  // job order
  { path: "/job-order", component: <JobOrder /> },
  { path: "/job-order/:action/:id", component: <JobOrderOverview /> },
  { path: "/job-order/candidate/:id", component: <CandidatePreview /> },

  // clients
  { path: "/clients", component: <Clients /> },
  { path: "/clients/add", component: <ClientsManage route="add" /> },
  { path: "/clients/edit/:id", component: <ClientsManage route="edit" /> },

  // workers
  { path: "/workers", component: <Workers /> },
  { path: "/workers/add", component: <WorkerManage route="add" /> },
  { path: "/workers/edit/:id", component: <WorkerManage route="edit" /> },
  { path: "/worker-bi", component: <WorkerBI /> },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
];

const authRoutes = [
  // authencation pages
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  // authentication inner pages
  { path: "/page-login", component: <PageLogin /> },
  { path: "/page-register", component: <PageRegister /> },
  { path: "/page-recoverpw", component: <RecoverPassword /> },
  { path: "/page-logout", component: <AuthLogout /> },

  // utility page
  { path: "/pages-maintenance", component: <PageMaintenance /> },
  { path: "/pages-404", component: <Error404 /> },
  { path: "/pages-500", component: <Error500 /> },
  { path: "/", exact: true, component: <Navigate to="/login" /> },
];

export { userRoutes, authRoutes };
