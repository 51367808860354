export const GET_SUPPLIER_LIST_ALL = "@suppliers/GET_SUPPLIER_LIST_ALL";
export const GET_SUPPLIER_LIST_ALL_SUCCESS =
  "@suppliers/GET_SUPPLIER_LIST_ALL_SUCCESS";
export const GET_SUPPLIER_LIST_ALL_FAILED =
  "@suppliers/GET_SUPPLIER_LIST_ALL_FAILED";

export const GET_SUPPLIER_LIST = "@suppliers/GET_SUPPLIER_LIST";
export const GET_SUPPLIER_LIST_SUCCESS = "@suppliers/GET_SUPPLIER_LIST_SUCCESS";
export const GET_SUPPLIER_LIST_FAILED = "@suppliers/GET_SUPPLIER_LIST_FAILED";

export const GET_SUPPLIER_DOCUMENT_LIST =
  "@suppliers/GET_SUPPLIER_DOCUMENT_LIST";
export const GET_SUPPLIER_DOCUMENT_LIST_SUCCESS =
  "@suppliers/GET_SUPPLIER_DOCUMENT_LIST_SUCCESS";
export const GET_SUPPLIER_DOCUMENT_LIST_FAILED =
  "@suppliers/GET_SUPPLIER_DOCUMENT_LIST_FAILED";

export const ADD_NEW_SUPPLIER = "@suppliers/ADD_NEW_SUPPLIER";
export const ADD_NEW_SUPPLIER_SUCCESS = "@suppliers/ADD_NEW_SUPPLIER_SUCCESS";
export const ADD_NEW_SUPPLIER_FAILED = "@suppliers/ADD_NEW_SUPPLIER_FAILED";

export const UPDATE_SUPPLIER = "@suppliers/UPDATE_SUPPLIER";
export const UPDATE_SUPPLIER_SUCCESS = "@suppliers/UPDATE_SUPPLIER_SUCCESS";
export const UPDATE_SUPPLIER_FAILED = "@suppliers/UPDATE_SUPPLIER_FAILED";

export const DELETE_SUPPLIER = "@suppliers/DELETE_SUPPLIER";
export const DELETE_ADDRESS = "@suppliers/DELETE_ADDRESS";
export const DELETE_CONTACT = "@suppliers/DELETE_CONTACT";
export const DELETE_NOTE = "@suppliers/DELETE_NOTE";

export const DELETE_DOCUMENT = "@suppliers/DELETE_DOCUMENT";
export const DELETE_SUPPLIER_SUCCESS = "@suppliers/DELETE_SUPPLIER_SUCCESS";
export const DELETE_SUPPLIER_FAILED = "@suppliers/DELETE_SUPPLIER_FAILED";

export const GET_SUPPLIERS_BI = "@suppliers/GET_SUPPLIERS_BI";
export const GET_SUPPLIERS_BI_SUCCESS = "@suppliers/GET_SUPPLIERS_BI_SUCCESS";
export const GET_SUPPLIERS_BI_FAILED = "@suppliers/GET_SUPPLIERS_BI_FAILED";

export const CLEAR_SUPPLIER_SAVE_MESSAGE =
  "@suppliers/CLEAR_SUPPLIER_SAVE_MESSAGE";
