import { call, put, takeLatest } from "redux-saga/effects";

import {
  GET_SUPPLIER_LIST,
  GET_SUPPLIER_LIST_SUCCESS,
  GET_SUPPLIER_LIST_FAILED,
  ADD_NEW_SUPPLIER,
  ADD_NEW_SUPPLIER_SUCCESS,
  ADD_NEW_SUPPLIER_FAILED,
  UPDATE_SUPPLIER,
  UPDATE_SUPPLIER_SUCCESS,
  UPDATE_SUPPLIER_FAILED,
  DELETE_SUPPLIER,
  DELETE_SUPPLIER_SUCCESS,
  DELETE_SUPPLIER_FAILED,
  GET_SUPPLIER_LIST_ALL,
  DELETE_ADDRESS,
  DELETE_CONTACT,
  DELETE_NOTE,
  GET_SUPPLIER_DOCUMENT_LIST,
  GET_SUPPLIER_DOCUMENT_LIST_SUCCESS,
  GET_SUPPLIER_DOCUMENT_LIST_FAILED,
  DELETE_DOCUMENT,
  GET_SUPPLIERS_BI,
  GET_SUPPLIERS_BI_SUCCESS,
  GET_SUPPLIERS_BI_FAILED,
} from "./types";

import supplierServices from "../../api/services/suppliers";

function* fnGetSupplierListAll() {
  try {
    const data = yield call(supplierServices.api.fnGetSupplierListAll);

    yield put({
      type: GET_SUPPLIER_LIST_SUCCESS,
      payload: [...data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_SUPPLIER_LIST_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetSupplierList(payload) {
  const id = payload.payload.id;

  try {
    const data = yield call(supplierServices.api.fnGetSupplierList, id);

    yield put({
      type: GET_SUPPLIER_LIST_SUCCESS,
      payload: [data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_SUPPLIER_LIST_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetSupplierDocumentList(payload) {
  const id = payload.payload.id;

  try {
    const data = yield call(supplierServices.api.fnGetSupplierDocumentList, id);

    yield put({
      type: GET_SUPPLIER_DOCUMENT_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: GET_SUPPLIER_DOCUMENT_LIST_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnAddNewSupplier({ payload }) {
  const { formData } = payload;
  try {
    yield call(supplierServices.api.fnAddNewSupplier, payload);

    yield put({
      type: ADD_NEW_SUPPLIER_SUCCESS,
    });
    // yield put({
    //   type: GET_SUPPLIER_LIST,
    // });
  } catch (error) {
    yield put({
      type: ADD_NEW_SUPPLIER_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnUpdateSupplier({ payload }) {
  try {
    console.log("payload", payload);
    const data = yield call(supplierServices.api.fnUpdateSupplier, payload);
    if (data.status == 200) {
      yield put({
        type: UPDATE_SUPPLIER_SUCCESS,
        payload: { message: data?.data.message, status: "success" },
      });
      yield put({
        type: GET_SUPPLIER_LIST,
        payload: { id: payload.id },
      });
    } else {
      yield put({
        type: UPDATE_SUPPLIER_FAILED,
        payload: { message: data?.data.message, status: "error" },
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_SUPPLIER_FAILED,
      payload: error.payload.data ? error.payload.data.message : "",
    });
  }
}

function* fnDeleteSupplier({ payload }) {
  try {
    const { id } = payload;

    yield call(supplierServices.api.fnDeleteSupplier, id);
    yield put({
      type: DELETE_SUPPLIER_SUCCESS,
    });
    yield put({
      type: GET_SUPPLIER_LIST_ALL,
    });
  } catch (error) {
    yield put({
      type: DELETE_SUPPLIER_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnDeleteAddress({ payload }) {
  try {
    const { id } = payload;

    yield call(supplierServices.api.fnDeleteAddress, id);
    yield put({
      type: DELETE_SUPPLIER_SUCCESS,
    });
    // yield put({
    //   type: GET_SUPPLIER_LIST_ALL,
    // });
  } catch (error) {
    yield put({
      type: DELETE_SUPPLIER_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnDeleteContact({ payload }) {
  try {
    const { id } = payload;

    yield call(supplierServices.api.fnDeleteContact, id);
    yield put({
      type: DELETE_SUPPLIER_SUCCESS,
    });
    // yield put({
    //   type: GET_SUPPLIER_LIST_ALL,
    // });
  } catch (error) {
    yield put({
      type: DELETE_SUPPLIER_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnDeleteNote({ payload }) {
  try {
    const { id } = payload;

    yield call(supplierServices.api.fnDeleteNote, id);
    yield put({
      type: DELETE_SUPPLIER_SUCCESS,
    });
    // yield put({
    //   type: GET_SUPPLIER_LIST_ALL,
    // });
  } catch (error) {
    yield put({
      type: DELETE_SUPPLIER_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnDeleteDocument({ payload }) {
  try {
    const { id } = payload;

    yield call(supplierServices.api.fnDeleteDocument, id);
    yield put({
      type: DELETE_SUPPLIER_SUCCESS,
    });
    // yield put({
    //   type: GET_SUPPLIER_LIST_ALL,
    // });
  } catch (error) {
    yield put({
      type: DELETE_SUPPLIER_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetSuppliersBI({ payload }) {
  try {
    const response = yield call(supplierServices.api.fnGetSuppliersBI, payload);
    yield put({
      type: GET_SUPPLIERS_BI_SUCCESS,
      payload: { data: response.data.result },
    });
  } catch (error) {
    yield put({
      type: GET_SUPPLIERS_BI_FAILED,
      payload: error.response?.data ? error.response.data.message : error,
    });
  }
}

export default function* watcher() {
  yield takeLatest(GET_SUPPLIER_LIST_ALL, fnGetSupplierListAll);
  yield takeLatest(GET_SUPPLIER_LIST, fnGetSupplierList);
  yield takeLatest(GET_SUPPLIER_DOCUMENT_LIST, fnGetSupplierDocumentList);
  yield takeLatest(ADD_NEW_SUPPLIER, fnAddNewSupplier);
  yield takeLatest(UPDATE_SUPPLIER, fnUpdateSupplier);
  yield takeLatest(DELETE_SUPPLIER, fnDeleteSupplier);
  yield takeLatest(DELETE_ADDRESS, fnDeleteAddress);
  yield takeLatest(DELETE_CONTACT, fnDeleteContact);
  yield takeLatest(DELETE_NOTE, fnDeleteNote);
  yield takeLatest(DELETE_DOCUMENT, fnDeleteDocument);
  yield takeLatest(GET_SUPPLIERS_BI, fnGetSuppliersBI);
}
