import { emsApi } from "../../api";
import { USER_SERVICE } from "../../api/constants";

class Authentication {
   async fnLogin (values) {
    const payload = {
      username: values.username,
      password: values.password,
    };

    return await emsApi.post(USER_SERVICE.LOGIN, payload);
  };
}

Authentication.api = new Authentication();
export default Authentication;
