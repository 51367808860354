import React from 'react';
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import CountUp from 'react-countup';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
} from 'reactstrap';
import { connect } from 'react-redux';
import { actionCreator, types } from '../../../store';

import Breadcrumb from '../../../components/Common/Breadcrumb';

/** import Mini Widget data */
import LineApexChart from './LineApexChart';
import SpineArea from './SpineArea';
import BarChart from './BarChart';

const options = {
  chart: {
    height: 50,
    type: 'line',
    toolbar: { show: false },
    sparkline: {
      enabled: true,
    },
  },
  colors: ['#5156be'],
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: false,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return '';
        },
      },
    },
    marker: {
      show: false,
    },
  },
};

const SuppliersBI = ({ clientfields, suppliers, ...props }) => {
  const currentYear = `${moment().year()}`;
  const previousYear = `${moment().subtract(1, 'year').year()}`;
  const { clients, clientSuppliers } = clientfields;
  const [totalCostData, setTotalCostData] = React.useState([]);
  const [workerPerSupplierData, setWorkerPerSupplierData] = React.useState([]);
  const [activeWorkersData, setActiveWorkersData] = React.useState([]);
  const [filter, setFilter] = React.useState({
    filterByClient: '',
    filterBySupplier: '',
    filterByYear1: currentYear,
    filterByYear2: previousYear,
  });
  const [widgetsData, setWidgetsData] = React.useState([]);

  React.useEffect(() => {
    filter.filterByClient && fetchSuppliers();
    clients.data.length > 0 && fetchSuppliersBI();
  }, [filter, clients]);

  React.useEffect(() => {
    fetchClients();
  }, []);

  React.useEffect(() => {
    totalCostHandler(suppliers.suppliersBIData.supplierTotalCost);
    workersPerSupplierHandler(suppliers.suppliersBIData.supplierWorkers);
    widgetsDataHandler(suppliers.suppliersBIData.supplierCostTracking);
    activeWorkersHandler(suppliers.suppliersBIData.supplierActiveWorkers);
  }, [suppliers.suppliersBIData]);

  const fetchSuppliersBI = async () => {
    let clientIds = [];
    // filter is 'ALL'
    if (!filter.filterByClient) {
      for (let client of clients.data) {
        clientIds.push(client.client_id);
      }
    }

    await props.actionCreator({
      type: types.GET_SUPPLIERS_BI,
      payload: {
        client_id: filter.filterByClient ? filter.filterByClient : clientIds,
        supplier_id: filter.filterBySupplier,
        year1: filter.filterByYear1,
        year2: filter.filterByYear2,
      },
    });
  };

  const fetchClients = async () => {
    await props.actionCreator({
      type: types.GET_CLIENTS,
    });
  };

  const fetchSuppliers = async () => {
    await props.actionCreator({
      type: types.GET_CLIENT_SUPPLIERS,
      payload: {
        client_id: filter.filterByClient,
      },
    });
  };

  const handleFilter = (filter, event) => {
    if (filter === 'client') {
      setFilter((prevFilter) => {
        const newFilter = {
          ...prevFilter,
          filterByClient: parseInt(event.target.value),
        };

        return newFilter;
      });
    }

    if (filter === 'supplier') {
      setFilter((prevFilter) => {
        const newFilter = {
          ...prevFilter,
          filterBySupplier: event.target.value,
        };

        return newFilter;
      });
    }

    if (filter === 'Year1') {
      setFilter((prevFilter) => {
        const newFilter = {
          ...prevFilter,
          filterByYear1: event.target.value,
        };

        return newFilter;
      });
    }

    if (filter === 'Year2') {
      setFilter((prevFilter) => {
        const newFilter = {
          ...prevFilter,
          filterByYear2: event.target.value,
        };

        return newFilter;
      });
    }
  };

  const totalCostHandler = (supplierTotalCost) => {
    let data = [];

    if (supplierTotalCost) {
      for (let cost of supplierTotalCost) {
        // Generate a series of 12 random numbers between 1 and 100
        const randomSeries = Array.from(
          { length: 12 },
          () => Math.floor(Math.random() * 100) + 1,
        );

        data.push({
          id: 1,
          title: `Total Cost for Year ${cost.year}`,
          totalNumber: cost?.total_amount || 0,
          series: randomSeries,
        });
      }
    }

    setTotalCostData(data);
  };

  const workersPerSupplierHandler = (supplierWorkers) => {
    const series = [];
    const data = [];
    const categories = [];

    if (supplierWorkers) {
      for (let supplier of supplierWorkers) {
        data.push(supplier.total_workers);
        categories.push(supplier.supplier_name);
      }

      series.push({
        data,
      });
    }

    const maxTotalAmount = supplierWorkers?.reduce((max, obj) => {
      // Use 0 if total_workers is null, otherwise use the actual value
      const amount = obj.total_workers !== null ? obj.total_workers : 0;
      return Math.max(max, amount);
    }, 0);

    setWorkerPerSupplierData({
      series,
      categories,
      max: maxTotalAmount,
    });
  };

  const widgetsDataHandler = (supplierCostTracking) => {
    let mainData = [];

    if (supplierCostTracking) {
      for (let supplier of supplierCostTracking) {
        if (supplier.item_name) {
          let month1 = [];
          let month2 = [];
          for (let i = 1; i <= 12; i++) {
            const year1 = supplierCostTracking?.find(
              (item) =>
                parseInt(item.month) === i &&
                parseInt(item.year) === parseInt(filter.filterByYear1) &&
                item.item_name === supplier.item_name,
            );

            const year2 = supplierCostTracking?.find(
              (item) =>
                parseInt(item.month) === i &&
                parseInt(item.year) === parseInt(filter.filterByYear2) &&
                item.item_name === supplier.item_name,
            );

            month1.push(
              year1 ? (year1.total_amount ? year1.total_amount : 0) : 0,
            );
            month2.push(
              year2 ? (year2.total_amount ? year2.total_amount : 0) : 0,
            );
          }

          let costTrackingSeries = [];

          costTrackingSeries.push({
            name: filter.filterByYear1,
            data: month1,
          });
          costTrackingSeries.push({
            name: filter.filterByYear2,
            data: month2,
          });

          const maxTotal = supplierCostTracking?.reduce((max, obj) => {
            // Use 0 if total_amount is null, otherwise use the actual value
            const amount =
              obj.item_name === supplier.item_name
                ? obj.total_amount !== null
                  ? obj.total_amount
                  : 0
                : 0;
            return Math.max(max, amount);
          }, 0);

          const isTitleExist = mainData?.find(
            (item) => item.title === supplier.item_name,
          );

          !isTitleExist &&
            mainData.push({
              title: supplier.item_name,
              series: costTrackingSeries,
              max: maxTotal,
            });
        }
      }
    }

    setWidgetsData(mainData);
  };

  const activeWorkersHandler = (activeWorkers) => {
    let month1 = [];
    let month2 = [];
    for (let i = 1; i <= 12; i++) {
      const year1 = activeWorkers?.find(
        (item) =>
          parseInt(item.month) === i &&
          parseInt(item.year) === parseInt(filter.filterByYear1),
      );

      const year2 = activeWorkers?.find(
        (item) =>
          parseInt(item.month) === i &&
          parseInt(item.year) === parseInt(filter.filterByYear2),
      );

      month1.push(
        year1
          ? year1.total_active_workers
            ? year1.total_active_workers
            : 0
          : 0,
      );
      month2.push(
        year2
          ? year2.total_active_workers
            ? year2.total_active_workers
            : 0
          : 0,
      );
    }

    let newActiveWorkers = [];

    newActiveWorkers.push({
      name: filter.filterByYear1,
      data: month1,
    });
    newActiveWorkers.push({
      name: filter.filterByYear2,
      data: month2,
    });

    const maxTotalActiveWorker = activeWorkers?.reduce((max, obj) => {
      // Use 0 if total_active_workers is null, otherwise use the actual value
      const amount =
        obj.total_active_workers !== null ? obj.total_active_workers : 0;
      return Math.max(max, amount);
    }, 0);

    setActiveWorkersData({
      series: newActiveWorkers,
      max: maxTotalActiveWorker,
    });
  };

  const resetFilter = () => {
    setFilter({
      filterByClient: '',
      filterBySupplier: '',
      filterByYear1: currentYear,
      filterByYear2: previousYear,
    });
  };

  //meta title
  document.title = 'Supplier BI';

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Dashboard"
            titleLink="/dashboard"
            breadcrumbItem="Supplier BI"
          />
          <Row>
            <Col lg={3}>
              <div className="mb-3">
                <Label className="form-label">Client</Label>
                <Input
                  type="select"
                  className="form-select"
                  multiple={false}
                  onChange={(e) => handleFilter('client', e)}
                  value={filter.filterByClient}
                >
                  <option value={''}>All</option>
                  {clients.data.map((item, index) => (
                    <option key={index} value={item.client_id}>
                      {item.client_name}
                    </option>
                  ))}
                </Input>
              </div>
            </Col>
            <Col lg={3}>
              <div className="mb-3">
                <Label className="form-label">Supplier</Label>
                <Input
                  type="select"
                  className="form-select"
                  multiple={false}
                  onChange={(e) => handleFilter('supplier', e)}
                  value={filter.filterBySupplier}
                >
                  <option value={''}>All</option>
                  {clientSuppliers.data.map((item, index) => (
                    <option key={index} value={item.supplier_id}>
                      {item.supplier_name}
                    </option>
                  ))}
                </Input>
              </div>
            </Col>
            {(filter.filterByClient ||
              filter.filterBySupplier ||
              filter.filterByDepartment) && (
              <Col lg={2}>
                <div className="mb-3">
                  <button
                    type="button"
                    className="btn"
                    onClick={resetFilter}
                    style={{
                      color: '#4549a2',
                      textDecoration: 'underline',
                      fontWeight: 700,
                    }}
                  >
                    Reset Filter
                  </button>
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <Col lg={3}>
              <div className="mb-3">
                <Label className="form-label">Year 1</Label>
                <Input
                  type="number"
                  className="form-control"
                  onChange={(e) => handleFilter('Year1', e)}
                  value={filter.filterByYear1}
                  placeholder="YYYY"
                  min="1900"
                  max={currentYear} // Set the maximum year
                  step="1"
                />
              </div>
            </Col>
            <Col lg={3}>
              <div className="mb-3">
                <Label className="form-label">Year 2</Label>
                <Input
                  type="number"
                  className="form-control"
                  onChange={(e) => handleFilter('Year2', e)}
                  value={filter.filterByYear2}
                  placeholder="YYYY"
                  min="1900"
                  max={currentYear} // Set the maximum year
                  step="1"
                />
              </div>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: 50,
            }}
          >
            {(totalCostData || []).map((totalCost, key) => (
              <Col xl={4} md={6} key={key}>
                <Card className="card-h-100">
                  <CardBody>
                    <Row className="align-items-center">
                      <Col xs={6}>
                        <span className="text-muted mb-3 lh-1 d-block text-truncate">
                          {totalCost.title}
                        </span>
                        <h4 className="mb-3">
                          <span className="counter-value">
                            <CountUp
                              start={0}
                              end={totalCost.totalNumber}
                              duration={2}
                              decimals={2}
                              separator=","
                            />
                          </span>
                        </h4>
                      </Col>
                      <Col xs={6}>
                        <ReactApexChart
                          options={options}
                          series={[{ data: [...totalCost['series']] }]}
                          type="line"
                          className="apex-charts mb-2"
                          dir="ltr"
                          height={50}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <Row>
            <Col xl={6}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Workers in each Supplier</h4>
                </CardHeader>
                <CardBody>
                  {workerPerSupplierData?.series && (
                    <BarChart
                      // series={[
                      //   {
                      //     data: [
                      //       380, 430, 450, 475, 550, 584, 780, 1100, 1220, 1365,
                      //     ],
                      //   },
                      // ]}
                      series={workerPerSupplierData.series}
                      // categories={[
                      //   "Supplier J",
                      //   "Supplier I",
                      //   "Supplier H",
                      //   "Supplier G",
                      //   "Supplier F",
                      //   "Supplier E",
                      //   "Supplier D",
                      //   "Supplier C",
                      //   "Supplier B",
                      //   "Supplier A",
                      // ]}
                      categories={workerPerSupplierData.categories}
                      yaxis={{
                        min: 0,
                        max: workerPerSupplierData.max,
                      }}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
            {(widgetsData || []).map((widget, key) => (
              <Col xl={6} key={key}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title mb-0">{widget.title}</h4>
                  </CardHeader>
                  <CardBody>
                    <SpineArea
                      // series={[
                      //   {
                      //     name: "2023",
                      //     data: [340, 400, 280, 520, 420, 1090, 1000],
                      //   },
                      //   {
                      //     name: "2024",
                      //     data: [320, 600, 340, 460, 340, 520, 410],
                      //   },
                      // ]}
                      series={widget.series}
                      categories={[
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sept',
                        'Oct',
                        'Nov',
                        'Dec',
                      ]}
                      yaxis={{
                        min: 0,
                        max: widget.max,
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            ))}
            <Col xl={6}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">Active Workers</h4>
                </CardHeader>
                <CardBody>
                  {activeWorkersData?.series && (
                    <LineApexChart
                      title={'Active Workers'}
                      // series={[
                      //   {
                      //     name: "2023",
                      //     data: [21, 16, 29, 36, 30, 28, 34],
                      //   },
                      //   {
                      //     name: "2024",
                      //     data: [26, 9, 36, 12, 40, 20, 17],
                      //   },
                      // ]}
                      series={activeWorkersData.series}
                      categories={[
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sept',
                        'Oct',
                        'Nov',
                        'Dec',
                      ]}
                      yaxis={{
                        min: 0,
                        max: activeWorkersData.max,
                        currency: false,
                      }}
                      stroke={{
                        width: [3, 3],
                        curve: 'straight',
                      }}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ clientfields, suppliers }) => ({
  clientfields,
  suppliers,
});

export default connect(mapStateToProps, { actionCreator })(SuppliersBI);
