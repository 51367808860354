import { call, put, takeLatest, all, fork } from "redux-saga/effects";

import {
  GET_JOB_ORDER_LIST,
  GET_JOB_ORDER_LIST_SUCCESS,
  GET_JOB_ORDER_LIST_FAILED,
  POST_UPDATE_JOB_ORDER,
  POST_UPDATE_JOB_ORDER_SUCCESS,
  POST_UPDATE_JOB_ORDER_FAILED,
  GET_CANDIDATE_LIST,
  GET_CANDIDATE_LIST_SUCCESS,
  GET_CANDIDATE_LIST_FAILED,
  POST_ADD_CANDIDATE,
  POST_ADD_CANDIDATE_SUCCESS,
  POST_ADD_CANDIDATE_FAILED,
  GET_CANDIDATE,
  GET_CANDIDATE_SUCCESS,
  GET_CANDIDATE_FAILED,
  POST_UPDATE_CANDIDATE_STATUS,
  POST_UPDATE_CANDIDATE_STATUS_SUCCESS,
  POST_UPDATE_CANDIDATE_STATUS_FAILED,
  POST_ADD_CANDIDATE_INTERVIEW,
  POST_ADD_CANDIDATE_INTERVIEW_SUCCESS,
  POST_ADD_CANDIDATE_INTERVIEW_FAILED,
  POST_UPDATE_CANDIDATE_INTERVIEW,
  POST_UPDATE_CANDIDATE_INTERVIEW_SUCCESS,
  POST_UPDATE_CANDIDATE_INTERVIEW_FAILED,
  POST_ADD_CANDIDATE_OFFER,
  POST_ADD_CANDIDATE_OFFER_SUCCESS,
  POST_ADD_CANDIDATE_OFFER_FAILED,
  POST_ACCEPT_CANDIDATE,
  POST_ACCEPT_CANDIDATE_SUCCESS,
  POST_ACCEPT_CANDIDATE_FAILED,
  POST_CONVERT_WORKER,
  POST_CONVERT_WORKER_SUCCESS,
  POST_CONVERT_WORKER_FAILED,
} from "./types";

import jobOrderServices from "../../api/services/job-order";

function* fnGetJobOrderList({ payload }) {
  try {
    const response = yield call(
      jobOrderServices.api.fnGetJobOrderList,
      payload
    );
    yield put({
      type: GET_JOB_ORDER_LIST_SUCCESS,
      payload: {
        data: [...response.data.result],
        message: response.data.message,
      },
    });
  } catch (error) {
    yield put({
      type: GET_JOB_ORDER_LIST_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnPostUpdateJobOrder({ payload }) {
  const { formData } = payload;
  try {
    const response = yield call(jobOrderServices.api.fnPostUpdateJobOrder, {
      formData,
    });

    if (response) {
      yield put({
        type: POST_UPDATE_JOB_ORDER_SUCCESS,
        payload: response.data.message,
      });

      yield put({
        type: GET_JOB_ORDER_LIST,
        payload: {
          department: "",
          client_id: "",
        },
      });
    }
  } catch (error) {
    yield put({
      type: POST_UPDATE_JOB_ORDER_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetJOCandidates({ payload }) {
  try {
    const response = yield call(
      jobOrderServices.api.fnGetJOCandidates,
      payload
    );
    yield put({
      type: GET_CANDIDATE_LIST_SUCCESS,
      payload: {
        data: [...response.data.result],
        message: response.data.message,
      },
    });
  } catch (error) {
    yield put({
      type: GET_CANDIDATE_LIST_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnPostAddJobOrderNewCandidate({ payload }) {
  const { formData } = payload;
  try {
    const response = yield call(
      jobOrderServices.api.fnPostAddJobOrderNewCandidate,
      {
        formData,
      }
    );

    if (response) {
      yield put({
        type: POST_ADD_CANDIDATE_SUCCESS,
        payload: response.data.message,
      });

      yield put({
        type: GET_JOB_ORDER_LIST,
        payload: {
          department: "",
          client_id: "",
        },
      });
    }
  } catch (error) {
    yield put({
      type: POST_ADD_CANDIDATE_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetCandidate({ payload }) {
  try {
    const response = yield call(jobOrderServices.api.fnGetCandidate, payload);
    yield put({
      type: GET_CANDIDATE_SUCCESS,
      payload: {
        data: [...response.data.result],
        message: response.data.message,
      },
    });
  } catch (error) {
    yield put({
      type: GET_CANDIDATE_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnPostUpdateCandidateStatus({ payload }) {
  try {
    const response = yield call(
      jobOrderServices.api.fnPostUpdateCandidateStatus,
      payload
    );
    yield put({
      type: POST_UPDATE_CANDIDATE_STATUS_SUCCESS,
      payload: {
        messages: response.data.message,
      },
    });
    yield put({
      type: GET_CANDIDATE,
      payload: {
        id: payload.joCandidateId,
      },
    });
  } catch (error) {
    yield put({
      type: POST_UPDATE_CANDIDATE_STATUS_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnPostAddCandidateInterview({ payload }) {
  try {
    const response = yield call(
      jobOrderServices.api.fnPostAddCandidateInterview,
      payload
    );
    yield put({
      type: POST_ADD_CANDIDATE_INTERVIEW_SUCCESS,
      payload: {
        messages: response.data.message,
      },
    });
    yield put({
      type: GET_CANDIDATE,
      payload: {
        id: payload.jo_candidate_id,
      },
    });
  } catch (error) {
    yield put({
      type: POST_ADD_CANDIDATE_INTERVIEW_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnPostUpdateCandidateInterview({ payload }) {
  try {
    const response = yield call(
      jobOrderServices.api.fnPostUpdateCandidateInterview,
      payload
    );
    yield put({
      type: POST_UPDATE_CANDIDATE_INTERVIEW_SUCCESS,
      payload: {
        messages: response.data.message,
      },
    });
    yield put({
      type: GET_CANDIDATE,
      payload: {
        id: payload.jo_candidate_id,
      },
    });
  } catch (error) {
    yield put({
      type: POST_UPDATE_CANDIDATE_INTERVIEW_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnPostUpdateCandidateOffer({ payload }) {
  try {
    const response = yield call(
      jobOrderServices.api.fnPostUpdateCandidateOffer,
      payload
    );
    yield put({
      type: POST_ADD_CANDIDATE_OFFER_SUCCESS,
      payload: {
        messages: response.data.message,
      },
    });
    yield put({
      type: GET_CANDIDATE,
      payload: {
        id: payload.jo_candidate_id,
      },
    });
  } catch (error) {
    yield put({
      type: POST_ADD_CANDIDATE_OFFER_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnPostAcceptCandidate({ payload }) {
  try {
    const response = yield call(
      jobOrderServices.api.fnPostAcceptCandidate,
      payload
    );
    yield put({
      type: POST_ACCEPT_CANDIDATE_SUCCESS,
      payload: {
        messages: response.data.message,
      },
    });
    yield put({
      type: GET_CANDIDATE,
      payload: {
        id: payload.jo_candidate_id,
      },
    });
  } catch (error) {
    yield put({
      type: POST_ACCEPT_CANDIDATE_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnPostConvertCandidateIntoWorker({ payload }) {
  try {
    const response = yield call(
      jobOrderServices.api.fnPostConvertCandidateIntoWorker,
      payload
    );
    yield put({
      type: POST_CONVERT_WORKER_SUCCESS,
      payload: {
        messages: response.data.message,
        result: response.data.result,
      },
    });
    yield put({
      type: GET_CANDIDATE,
      payload: {
        id: payload.jo_candidate_id,
      },
    });
  } catch (error) {
    yield put({
      type: POST_CONVERT_WORKER_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

export function* watchGetSerialLogsData() {
  yield takeLatest(GET_JOB_ORDER_LIST, fnGetJobOrderList);
  yield takeLatest(GET_CANDIDATE_LIST, fnGetJOCandidates);
  yield takeLatest(GET_CANDIDATE, fnGetCandidate);
  yield takeLatest(POST_UPDATE_JOB_ORDER, fnPostUpdateJobOrder);
  yield takeLatest(POST_ADD_CANDIDATE, fnPostAddJobOrderNewCandidate);
  yield takeLatest(POST_UPDATE_CANDIDATE_STATUS, fnPostUpdateCandidateStatus);
  yield takeLatest(POST_ADD_CANDIDATE_INTERVIEW, fnPostAddCandidateInterview);
  yield takeLatest(
    POST_UPDATE_CANDIDATE_INTERVIEW,
    fnPostUpdateCandidateInterview
  );
  yield takeLatest(POST_ADD_CANDIDATE_OFFER, fnPostUpdateCandidateOffer);
  yield takeLatest(POST_ACCEPT_CANDIDATE, fnPostAcceptCandidate);
  yield takeLatest(POST_CONVERT_WORKER, fnPostConvertCandidateIntoWorker);
}

function* jobOrderSaga() {
  yield all([fork(watchGetSerialLogsData)]);
}

export default jobOrderSaga;
