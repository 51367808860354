import React from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import Dropzone from "react-dropzone";
import * as Yup from "yup";
import { useFormik } from "formik";
import { actionCreator, types } from "../../../store";
import { Name, Navigate, ResumeIcons, Tags } from "./column";
import TableContainer from "../../../components/Common/TableContainer";
import { USER_GROUPS } from "../../../constants/app";

const JobOrderCandidate = ({
  jobOrderDetails,
  joborder,
  clientfields,
  authentication,
  setCandidateCount,
  ...props
}) => {
  const { user_group_id } = authentication.data.data;
  const { candidatesData } = joborder;
  const [selectAll, setSelectAll] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [attachmentModal, setAttachmentModal] = React.useState(null);
  const [selectedFiles, setselectedFiles] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [customHiddenColumns, setCustomHiddenColumns] = React.useState([
    "supplier_name",
  ]);

  React.useEffect(() => {
    candidatesData && setCandidateCount(candidatesData.length);
  }, [candidatesData]);

  React.useEffect(() => {
    jobOrderDetails && fetchJobOrderCandidates();
  }, [jobOrderDetails]);

  const toggleSelectRow = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      // Add the selected value to the array
      setSelected((prevSelected) => {
        const newSelected = [...prevSelected, parseInt(value)];

        return newSelected;
      });
    } else {
      // Remove the deselected value from the array
      setSelected(selected.filter((v) => v !== parseInt(value)));
    }
  };

  const toggleSelectAll = () => {
    let newSelected = [];

    if (!selectAll) {
      for (let candidate of candidatesData) {
        newSelected.push(candidate.id);
      }
    }

    setSelected(newSelected);
    setSelectAll(!selectAll);
  };

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      job_order_id: (jobOrderDetails && jobOrderDetails.id) || null,
      candidate_name: "",
      personal_email: "",
    },
    validationSchema: Yup.object({
      candidate_name: Yup.string()
        .matches(
          /^[^0-9]*$/,
          "Candidate name should not contain alphanumeric characters."
        )
        .required("Please enter a Candidate name."),
      personal_email: Yup.string()
        .email("Please enter a valid email address.")
        .required("Please enter a Personal email."),
    }),
    onSubmit: async (values) => {
      let newCandidate = new FormData();

      if (selectedFiles.length > 0) {
        for (let i = 0; i < selectedFiles.length; i++) {
          newCandidate.append("files", selectedFiles[i], selectedFiles[i].name);
        }
      }

      newCandidate.append("job_order_id", values["job_order_id"]);
      newCandidate.append("candidate_name", values["candidate_name"]);
      newCandidate.append("personal_email", values["personal_email"]);

      // save new job request
      props.actionCreator({
        type: types.POST_ADD_CANDIDATE,
        payload: {
          formData: newCandidate,
        },
      });

      validation.resetForm();
      setselectedFiles([]);
      toggleAttachmentModal();
    },
  });

  const columns = [
    {
      id: "checkbox",
      Cell: (checkboxCol) => {
        return (
          <Input
            type="checkbox"
            className="checkbox"
            style={{
              borderColor: "#ADB5BD",
              borderRadius: 0,
              height: 20,
              width: 20,
            }}
            value={checkboxCol.row.original.id}
            checked={selected.includes(parseInt(checkboxCol.row.original.id))}
            onChange={toggleSelectRow}
          />
        );
      },
      Header: (x) => {
        return (
          <Input
            type="checkbox"
            className="checkbox"
            checked={selectAll}
            onChange={() => toggleSelectAll()}
            style={{
              borderColor: "#ADB5BD",
              borderRadius: 0,
              height: 20,
              width: 20,
            }}
          />
        );
      },
    },
    {
      Header: "Candidate",
      accessor: "candidate_name",
      filterable: false,
      disableFilters: true,
      Cell: (cellProps) => {
        return (
          <>
            <Navigate {...cellProps} />{" "}
          </>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status_id",
      filterable: false,
      disableFilters: true,
      Cell: (cellProps) => {
        return (
          <>
            <Tags {...cellProps} />{" "}
          </>
        );
      },
    },
    {
      Header: "Date Added",
      accessor: "date_added_str",
      filterable: false,
      disableFilters: true,
      Cell: (cellProps) => {
        return (
          <>
            <Name {...cellProps} />{" "}
          </>
        );
      },
    },
    {
      Header: "Added by Supplier",
      accessor: "supplier_name",
      filterable: false,
      disableFilters: true,
      Cell: (cellProps) => {
        return (
          <>
            <Name {...cellProps} />{" "}
          </>
        );
      },
    },
    {
      Header: "Client",
      accessor: "client_name",
      filterable: false,
      disableFilters: true,
      Cell: (cellProps) => {
        return (
          <>
            <Name {...cellProps} />{" "}
          </>
        );
      },
    },
    {
      Header: "Resume",
      accessor: "resumes",
      filterable: false,
      disableFilters: true,
      Cell: (cellProps) => {
        return (
          <>
            <ResumeIcons {...cellProps} />{" "}
          </>
        );
      },
    },
  ];

  const fetchJobOrderCandidates = async () => {
    await props.actionCreator({
      type: types.GET_CANDIDATE_LIST,
      payload: {
        id: jobOrderDetails.id,
      },
    });
  };

  const toggleAttachmentModal = () => {
    setAttachmentModal(!attachmentModal);
  };

  return (
    <React.Fragment>
      <Row>
        <Col
          lg={12}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 25,
            marginBottom: 25,
          }}
        >
          <div className="text-end">
            {user_group_id === USER_GROUPS.SUPPLIER_OPS && (
              <button
                type="submit"
                className="btn btn-info text-white"
                style={{
                  backgroundColor: "#5156BE",
                  width: 191,
                  height: 48,
                }}
                onClick={toggleAttachmentModal}
              >
                Submit CV
              </button>
            )}
          </div>
        </Col>
        <Col sm={12}>
          <Card>
            <CardHeader>
              <CardTitle className="h4">Candidates</CardTitle>
            </CardHeader>
            <CardBody>
              <TableContainer
                columns={columns}
                data={candidatesData}
                isGlobalFilter={true}
                customPageSize={10}
                className="table align-middle datatable dt-responsive table-check nowrap"
                customHiddenColumns={
                  user_group_id === USER_GROUPS.CLIENT_HIRING_MANAGER
                    ? customHiddenColumns
                    : []
                }
              />
              <Modal
                isOpen={attachmentModal}
                toggle={toggleAttachmentModal}
                style={{ maxWidth: "700px", width: "80%" }}
              >
                <ModalHeader toggle={toggleAttachmentModal} tag="h4">
                  CV Submission
                </ModalHeader>
                <ModalBody style={{ margin: 15 }}>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col xs={12}>
                        <Dropzone
                          onDrop={(acceptedFiles, fileRejections) => {
                            setselectedFiles(acceptedFiles);
                            setErrorMessages(
                              fileRejections.map(
                                ({ file, errors }) =>
                                  `${file.name}: ${errors.map((e) => e.message).join(", ")} `
                              )
                            );
                          }}
                          accept={{
                            "application/pdf": [".pdf"],
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bx-cloud-upload" />
                                </div>
                                <h4>
                                  Drop CV here in .pdf or click to upload.
                                </h4>
                                {errorMessages.length > 0 && (
                                  <div
                                    className="error-messages"
                                    style={{
                                      fontSize: 12,
                                    }}
                                  >
                                    {errorMessages.map((message, index) => (
                                      <p key={index} className="text-danger">
                                        {message}
                                      </p>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                style={{
                                  marginLeft: 5,
                                  marginRight: 5,
                                  width: "50%",
                                }}
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col
                                      className="col-auto text-primary"
                                      style={{
                                        marginBottom: 10,
                                      }}
                                    >
                                      <i
                                        className="mdi mdi-file-document-outline font-size-30"
                                        id="edittooltip"
                                      ></i>
                                    </Col>
                                    <Col>
                                      <p className="mb-0">{f.name}</p>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 40 }}>
                      <Col xs={5}>
                        <div className="mb-3">
                          <Label className="form-label">Name as per NRIC</Label>
                          <Input
                            type="text"
                            name="candidate_name"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.candidate_name || ""}
                            invalid={
                              validation.touched.candidate_name &&
                              validation.errors.candidate_name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.candidate_name &&
                          validation.errors.candidate_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.candidate_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xs={5}>
                        <div className="mb-3">
                          <Label className="form-label">Personal Email</Label>
                          <Input
                            type="text"
                            name="personal_email"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.personal_email || ""}
                            invalid={
                              validation.touched.personal_email &&
                              validation.errors.personal_email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.personal_email &&
                          validation.errors.personal_email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.personal_email}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: 80,
                      }}
                    >
                      <Col
                        lg={12}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div className="text-end">
                          <button
                            type="submit"
                            className="btn btn-info text-white"
                            style={{
                              backgroundColor: "#5156BE",
                              width: 142,
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = ({ joborder, clientfields, authentication }) => ({
  joborder,
  clientfields,
  authentication,
});

export default connect(mapStateToProps, { actionCreator })(JobOrderCandidate);
