import React from "react";
import ReactApexChart from "react-apexcharts";
import { connect } from "react-redux";
import { Card, CardBody, Row, Col } from "reactstrap";
import { actionCreator } from "../../../store";

const PieChart = ({ dashboard }) => {
  const [pieChartData, setPieChartData] = React.useState([]);
  const piechartColors = ["#777aca", "#5156be", "#a8aada"];

  React.useEffect(() => {
    dashboard.pieCharts.data?.workersPerSuppliers &&
      workerPerSupplierHandler(dashboard.pieCharts.data);
  }, [dashboard.pieCharts.data]);

  const workerPerSupplierHandler = (workerPiechartData) => {
    const colorList = ["text-success", "text-info", "text-primary"];
    let elements = [];
    let percentage = [];
    let colors = [];

    if (workerPiechartData?.workersPerSuppliers) {
      for (let chartData of workerPiechartData.workersPerSuppliers) {
        elements.push(chartData.supplier_name);
        percentage.push(chartData.total_workers);

        // Get a random index from colorList
        const randomIndex = Math.floor(Math.random() * colorList.length);

        // Push the random color to the colors array
        colors.push(colorList[randomIndex]);
      }

      setPieChartData({
        elements,
        colors,
        percentage,
      });
    } else {
      setPieChartData([]);
    }
  };

  return (
    <React.Fragment>
      <Col xl={5}>
        <Card className="card-h-100">
          <CardBody>
            <div className="d-flex flex-wrap align-items-center mb-4">
              <h5 className="card-title me-2">No. of Workers per Supplier</h5>
            </div>
            <Row className="align-items-center">
              <div className="col-sm">
                <div id="wallet-balance" className="apex-charts">
                  <ReactApexChart
                    options={{
                      chart: {
                        width: 227,
                        height: 227,
                        type: "pie",
                      },
                      labels: pieChartData?.elements || [],
                      colors: piechartColors,
                      stroke: {
                        width: 0,
                      },
                      legend: {
                        show: false,
                      },
                      responsive: [
                        {
                          breakpoint: 480,
                          options: {
                            chart: {
                              width: 200,
                            },
                          },
                        },
                      ],
                    }}
                    series={pieChartData?.percentage || []}
                    type="pie"
                    height="227"
                  />
                </div>
              </div>
              <div className="col-sm align-self-center">
                <div className="mt-4 mt-sm-0">
                  {pieChartData?.elements &&
                    pieChartData.elements.map((item, index) => (
                      <div key={index}>
                        <p className="mb-2">
                          <i
                            className={`mdi mdi-circle align-middle font-size-10 me-2 ${pieChartData?.colors[index]}`}
                          ></i>{" "}
                          {item}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

const mapStateToProps = ({ dashboard }) => ({
  dashboard,
});

export default connect(mapStateToProps, { actionCreator })(PieChart);
