import React from "react";
import {
  Row,
  Col,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { candidateStatusColors } from "../../../../helpers/utility_helper";

const StatusActions = ({
  candidateData,
  candidateStatusHandler,
  isSupplierOps,
  isClientHiringManager,
  isPMOSupport,
}) => {
  const [currentActions, setCurrentActions] = React.useState([]);

  React.useEffect(() => {
    renderActionsPerGroups();
  }, [candidateData]);

  const renderActionsPerGroups = () => {
    if (candidateData.length > 0) {
      // status not 'reject' & 'converted'
      if (![8, 11].includes(candidateData[0].status_id)) {
        if (isSupplierOps) {
          if ([6].includes(candidateData[0].status_id)) {
            // status 'offered'
            setCurrentActions([
              {
                id: 7,
                actionStatus: "Accept",
              },
            ]);
          } else if ([7].includes(candidateData[0].status_id)) {
            // status 'accepted'
            setCurrentActions([
              {
                id: 11,
                actionStatus: "Convert to Worker",
              },
            ]);
          } else {
            setCurrentActions([]);
          }
        }

        if (isClientHiringManager) {
          if ([2].includes(candidateData[0].status_id)) {
            // status 'shorlisted'
            setCurrentActions([
              {
                id: 3,
                actionStatus: "Schedule Interview",
              },
              {
                id: 8,
                actionStatus: "Reject",
              },
            ]);
          } else if ([10].includes(candidateData[0].status_id)) {
            // status 'Interview Accepted by Candidate'
            setCurrentActions([
              {
                id: 6,
                actionStatus: "Offer",
              },
              {
                id: 8,
                actionStatus: "Reject",
              },
            ]);
          } else {
            setCurrentActions([]);
          }
        }

        if (isPMOSupport) {
          if ([1].includes(candidateData[0].status_id)) {
            // status 'Submitted'
            setCurrentActions([
              {
                id: 2,
                actionStatus: "Shortlist",
              },
              {
                id: 8,
                actionStatus: "Reject",
              },
            ]);
          } else {
            setCurrentActions([]);
          }
        }
      } else {
        setCurrentActions([]);
      }
    }
  };

  const renderCandidateStatus = (status_id) => {
    const status = candidateStatusColors.find((item) => item.id === status_id);

    return (
      <p
        style={{
          backgroundColor: status.color || "none",
          maxWidth: 250,
          width: "100%",
          textAlign: "center",
          padding: 2,
          borderRadius: 8,
          color: "#FFFFFF",
          padding: 5,
        }}
      >
        {candidateData[0].status_name}
      </p>
    );
  };

  return (
    <React.Fragment>
      <Row>
        <Col xs={2} md={3} lg={2}>
          <Label>Status</Label>
          {candidateData.length > 0 &&
            renderCandidateStatus(candidateData[0].status_id)}
        </Col>
        <Col xs={3} md={3} lg={2}>
          {!isClientHiringManager && (
            <>
              <Label>Candidate ID</Label>
              <p
                style={{
                  color: "#495057",
                  fontWeight: 0,
                }}
              >
                {candidateData.length > 0 && candidateData[0].id}
              </p>
            </>
          )}
        </Col>
        <Col xs={3} md={3} lg={3}>
          <Label>Supplier</Label>
          <p
            style={{
              color: "#495057",
            }}
          >
            {candidateData.length > 0 && candidateData[0].supplier_name}
          </p>
        </Col>
        <Col
          xs={4}
          md={3}
          lg={5}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            height: 40,
          }}
        >
          {currentActions.length > 0 && (
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn btn-primary waves-light waves-effect dropdown-toggle"
                tag="div"
              >
                Action <i className="mdi mdi-dots-vertical ms-2" />
              </DropdownToggle>
              <DropdownMenu>
                {currentActions.map((item, index) => (
                  <DropdownItem
                    key={index}
                    onClick={() => {
                      candidateStatusHandler(item.id);
                    }}
                  >
                    {item.actionStatus}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default StatusActions;
