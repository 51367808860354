import { emsApi } from "../";
import { DASHBOARD_SERVICE } from "../../api/constants";

class Dashboard {
  async fnGetApexChartsData(payload) {
    return await emsApi.post(DASHBOARD_SERVICE.GET_APEX_CHARTS_DATA, payload);
  }

  async fnGetPieChartsData(payload) {
    return await emsApi.post(DASHBOARD_SERVICE.GET_PIE_CHARTS_DATA, payload);
  }

  async fnGetBarChartsData(payload) {
    return await emsApi.post(DASHBOARD_SERVICE.GET_BAR_CHARTS_DATA, payload);
  }

  async fnGetTopChartsData(payload) {
    return await emsApi.post(DASHBOARD_SERVICE.GET_TOP_CHARTS_DATA, payload);
  }

  async fnGetRecentRequestsActivityData(payload) {
    return await emsApi.post(
      DASHBOARD_SERVICE.GET_RECENT_ACTIVITY_DATA,
      payload
    );
  }
}

Dashboard.api = new Dashboard();
export default Dashboard;
