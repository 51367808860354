export const GET_DEPARTMENT_OPTIONS = "@clientfields/GET_DEPARTMENT_OPTIONS";
export const GET_DEPARTMENT_OPTIONS_SUCCESS =
  "@clientfields/GET_DEPARTMENT_OPTIONS_SUCCESS";
export const GET_DEPARTMENT_OPTIONS_FAILED =
  "@clientfields/GET_DEPARTMENT_OPTIONS_FAILED";

export const GET_COST_CENTER_OPTIONS = "@clientfields/GET_COST_CENTER_OPTIONS";
export const GET_COST_CENTER_OPTIONS_SUCCESS =
  "@clientfields/GET_COST_CENTER_OPTIONS_SUCCESS";
export const GET_COST_CENTER_OPTIONS_FAILED =
  "@clientfields/GET_COST_CENTER_OPTIONS_FAILED";

export const GET_CLIENTS = "@clientfields/GET_CLIENTS";
export const GET_CLIENTS_SUCCESS = "@clientfields/GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_FAILED = "@clientfields/GET_CLIENTS_FAILED";

export const GET_CLIENT_SUPPLIERS = "@clientfields/GET_CLIENT_SUPPLIERS";
export const GET_CLIENT_SUPPLIERS_SUCCESS =
  "@clientfields/GET_CLIENT_SUPPLIERS_SUCCESS";
export const GET_CLIENT_SUPPLIERS_FAILED =
  "@clientfields/GET_CLIENT_SUPPLIERS_FAILED";

export const GET_CURRENCY_OPTIONS = "@clientfields/GET_CURRENCY_OPTIONS";
export const GET_CURRENCY_OPTIONS_SUCCESS =
  "@clientfields/GET_CURRENCY_OPTIONS_SUCCESS";
export const GET_CURRENCY_OPTIONS_FAILED =
  "@clientfields/GET_CURRENCY_OPTIONS_FAILED";

export const GET_COST_TRACKING_OPTIONS = "@clientfields/GET_COST_TRACKING_OPTIONS";
export const GET_COST_TRACKING_OPTIONS_SUCCESS =
  "@clientfields/GET_COST_TRACKING_OPTIONS_SUCCESS";
export const GET_COST_TRACKING_OPTIONS_FAILED =
  "@clientfields/GET_COST_TRACKING_OPTIONS_FAILED";
