import { call, put, takeLatest, all, fork } from "redux-saga/effects";

import {
  GET_APEX_CHARTS_DATA,
  GET_APEX_CHARTS_DATA_SUCCESS,
  GET_APEX_CHARTS_DATA_FAILED,
  GET_PIE_CHARTS_DATA,
  GET_PIE_CHARTS_DATA_SUCCESS,
  GET_PIE_CHARTS_DATA_FAILED,
  GET_BAR_CHARTS_DATA,
  GET_BAR_CHARTS_DATA_SUCCESS,
  GET_BAR_CHARTS_DATA_FAILED,
  GET_TOP_CHARTS_DATA,
  GET_TOP_CHARTS_DATA_SUCCESS,
  GET_TOP_CHARTS_DATA_FAILED,
  GET_RECENT_ACTIVITY_DATA,
  GET_RECENT_ACTIVITY_DATA_SUCCESS,
  GET_RECENT_ACTIVITY_DATA_FAILED,
} from "./types";

import dashboardServices from "../../api/services/dashboard";

function* fnGetApexChartsData({ payload }) {
  try {
    const response = yield call(
      dashboardServices.api.fnGetApexChartsData,
      payload
    );

    yield put({
      type: GET_APEX_CHARTS_DATA_SUCCESS,
      payload: response.data.result,
    });
  } catch (error) {
    yield put({
      type: GET_APEX_CHARTS_DATA_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetPieChartsData({ payload }) {
  try {
    const response = yield call(
      dashboardServices.api.fnGetPieChartsData,
      payload
    );

    yield put({
      type: GET_PIE_CHARTS_DATA_SUCCESS,
      payload: response.data.result,
    });
  } catch (error) {
    yield put({
      type: GET_PIE_CHARTS_DATA_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetBarChartsData({ payload }) {
  try {
    const response = yield call(
      dashboardServices.api.fnGetBarChartsData,
      payload
    );

    yield put({
      type: GET_BAR_CHARTS_DATA_SUCCESS,
      payload: response.data.result,
    });
  } catch (error) {
    yield put({
      type: GET_BAR_CHARTS_DATA_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetTopChartsData({ payload }) {
  try {
    const response = yield call(
      dashboardServices.api.fnGetTopChartsData,
      payload
    );

    yield put({
      type: GET_TOP_CHARTS_DATA_SUCCESS,
      payload: response.data.result,
    });
  } catch (error) {
    yield put({
      type: GET_TOP_CHARTS_DATA_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetRecentRequestsActivityData({ payload }) {
  try {
    const response = yield call(
      dashboardServices.api.fnGetRecentRequestsActivityData,
      payload
    );

    yield put({
      type: GET_RECENT_ACTIVITY_DATA_SUCCESS,
      payload: response.data.result,
    });
  } catch (error) {
    yield put({
      type: GET_RECENT_ACTIVITY_DATA_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

export function* watchGetSerialLogsData() {
  yield takeLatest(GET_APEX_CHARTS_DATA, fnGetApexChartsData);
  yield takeLatest(GET_PIE_CHARTS_DATA, fnGetPieChartsData);
  yield takeLatest(GET_BAR_CHARTS_DATA, fnGetBarChartsData);
  yield takeLatest(GET_TOP_CHARTS_DATA, fnGetTopChartsData);
  yield takeLatest(GET_RECENT_ACTIVITY_DATA, fnGetRecentRequestsActivityData);
}

function* dashboardSaga() {
  yield all([fork(watchGetSerialLogsData)]);
}

export default dashboardSaga;
