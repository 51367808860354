import { emsApi } from "../../api";
import { APP_SERVICE } from "../../api/constants";
class App {
  fnGetMenus = async () => {
    return await emsApi.get(APP_SERVICE.MENU);
  };

  fnGetSelectedWorkshop = async () => {
    return await emsApi.get(APP_SERVICE.SELECTED_WORKSHOP);
  };
}

App.api = new App();

export default App;
