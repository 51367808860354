import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TableContainer from '../../components/Common/TableContainer';
import { actionCreator, types } from '../../store';
import { Col, Container, Row } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Name, Docs } from './column';
import * as Yup from 'yup';
import Siren from '../../assets/images/siren-green.png';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import ViewDetailsModal from './ViewDetailsModal';

const PmoWorkItems = ({ app, requisition, clientfields, ...props }) => {
  document.title = 'PMO Work Items | MSP';

  const [modal, setModal] = React.useState(false);
  const [groupModal, setGroupModal] = React.useState(false);
  const [selectedRequisitionData, setSelectedRequisitionData] = React.useState(
    [],
  );
  const [filters, setFilters] = React.useState({
    client: 'All',
    supplier: 'All',
    status: 'All',
  });
  const [requisitionData, setRequisitionData] = React.useState(null);
  const [confirmMoveModal, setConfirmMoveModal] = React.useState(false);
  const toggleConfirmMoveModal = () => {
    setConfirmMoveModal(!confirmMoveModal);
  };
  const { id } = useParams();

  //NOTIFICATION
  const handleNotification = async () => {
    toast(requisition.messages, {
      position: 'top-right',
      hideProgressBar: true,
      className: 'bg-success text-white',
    });
  };

  React.useEffect(() => {
    if (id) {
      requisition.data && showSelectedRequest(requisition.data);
    }
  }, [requisition.data]);

  React.useEffect(() => {
    requisition.isSubmitted && handleNotification();
  }, [requisition]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      remarks: (requisitionData && requisitionData.remarks) || '',
    },
    validationSchema: Yup.object({
      remarks: Yup.string().required('Please Enter Remarks'),
    }),
    onSubmit: (values, { setSubmitting }, event) => {
      if (values.action === 'reject') {
        props.actionCreator({
          type: types.REJECT_REQUISITION,
          payload: {
            id: selectedRequisitionData.id,
            remarks: values.remarks,
          },
        });
      } else if (values.action === 'approve') {
        toggleConfirmMoveModal();
        props.actionCreator({
          type: types.APPROVE_REQUISITION,
          payload: {
            id: selectedRequisitionData.id,
            remarks: values.remarks,
          },
        });
      }
      setRequisitionData(values);
      validation.resetForm();
      toggleGroup();
    },
  });

  const columns = [
    {
      Header: 'Request No.',
      accessor: 'id',
      filterable: false,
      disableFilters: true,
      Cell: (cellProps) => {
        return (
          <Link
            to="#"
            className="text-primary"
            style={{
              fontWeight: 700,
              fontFamily: 'IBM Plex Sans',
            }}
            onClick={() => {
              onClickView(cellProps.row.original);
            }}
          >
            {cellProps.value}
          </Link>
        );
      },
    },
    {
      Header: 'Date Requested',
      accessor: 'date_added_str',
      filterable: false,
      disableFilters: true,
      Cell: (cellProps) => {
        return (
          <>
            <Name {...cellProps} />{' '}
          </>
        );
      },
    },
    {
      Header: 'Request Type',
      accessor: 'request_type_name',
      filterable: false,
      disableFilters: true,
      Cell: (cellProps) => {
        return (
          <>
            <Name {...cellProps} />{' '}
          </>
        );
      },
    },
    {
      Header: 'Client',
      accessor: 'client',
      filterable: false,
      disableFilters: true,
      Cell: (cellProps) => {
        return (
          <>
            <Name {...cellProps} />{' '}
          </>
        );
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      filterable: false,
      disableFilters: true,
      Cell: (cellProps) => {
        return (
          <>
            <Link
              to="#"
              className={`badge font-size-11 m-1 ${
                cellProps.value.toLowerCase().includes('pending')
                  ? 'text-warning bg-warning-subtle'
                  : cellProps.value.toLowerCase().includes('cancel') ||
                      cellProps.value.toLowerCase().includes('reject')
                    ? 'text-danger bg-danger-subtle'
                    : 'text-success bg-success-subtle'
              }`}
            >
              <Name {...cellProps} />
            </Link>
          </>
        );
      },
    },
    {
      Header: 'SLA',
      accessor: 'urgency_level_id',
      filterable: false,
      disableFilters: true,
      Cell: (cellProps) => {
        return (
          <div className="d-flex gap-3">
            <img src={Siren} height={24} />
          </div>
        );
      },
    },
    {
      Header: 'Attached File',
      accessor: 'documents',
      filterable: false,
      disableFilters: true,
      width: 80,
      Cell: (cellProps) => {
        return (
          <>
            <Docs {...cellProps} />{' '}
          </>
        );
      },
    },
  ];

  React.useEffect(() => {
    props.actionCreator({
      type: types.GET_TOTAL,
    });
    fetchRequisitionList();
    props.actionCreator({
      type: types.GET_OPTIONS,
    });

    props.actionCreator({
      type: types.GET_DEPARTMENT_OPTIONS,
      payload: {
        type: 'department',
        client_id: null,
      },
    });

    props.actionCreator({
      type: types.GET_COST_CENTER_OPTIONS,
      payload: {
        type: 'cost center',
        client_id: null,
      },
    });
  }, []);

  React.useEffect(() => {
    fetchRequisitionList();
  }, [filters]);

  const fetchRequisitionList = async () => {
    let payload = {};

    if (filters.client !== 'All') {
      payload.filter_by_client = filters.client;
    }

    if (filters.supplier !== 'All') {
      payload.filter_by_supplier = filters.supplier;
    }

    if (filters.status !== 'All') {
      payload.filter_by_status = filters.status;
    }

    await props.actionCreator({
      type: types.GET_REQUEST_LIST_FILTERED,
      payload,
    });
  };

  const showSelectedRequest = (args) => {
    const items = args.find((item) => item.id === parseInt(id));

    if (items) {
      setSelectedRequisitionData({
        id: items.id,
        type: items.request_type_name,
        status: items.status,
        status_id: items.status_id,
        request_date: items.date_added_str,
        requested_by: items.requested_by,
        email: items.email,
        urgency_level_name: items.urgency_level_name,
        content: items.request_content,
        documents: items.documents,
        type_id: items.type_id,
      });

      setRequisitionData(null);
      setGroupModal(true);
    }
  };

  const onClickView = (arg) => {
    const items = arg;
    setSelectedRequisitionData({
      id: items.id,
      type: items.request_type_name,
      status: items.status,
      status_id: items.status_id,
      request_date: items.date_added_str,
      requested_by: items.requested_by,
      email: items.email,
      urgency_level_name: items.urgency_level_name,
      content: items.request_content,
      documents: items.documents,
      type_id: items.type_id,
    });
    setRequisitionData(null);

    toggleGroup();
  };

  const handleFilterClientSelect = (client) => {
    setFilters({ ...filters, client });
  };

  const handleFilterSupplierSelect = (supplier) => {
    setFilters({ ...filters, supplier });
  };

  const handleFilterStatusSelect = (status) => {
    setFilters({ ...filters, status });
  };

  const toggle = () => {
    setModal(!modal);
  };

  const toggleGroup = () => {
    setGroupModal(!groupModal);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Dashboard"
            titleLink="/dashboard"
            breadcrumbItem="PMO Work Items"
          />
          <Row>
            <Col lg="12">
              <Row>
                <Col xl="12">
                  <TableContainer
                    columns={columns}
                    data={requisition.data}
                    isGlobalFilter={true}
                    customPageSize={10}
                    isWorkItems={true}
                    className="table align-middle datatable dt-responsive table-check nowrap"
                    handleFilterClientSelect={handleFilterClientSelect}
                    handleFilterSupplierSelect={handleFilterSupplierSelect}
                    handleFilterStatusSelect={handleFilterStatusSelect}
                    requisition={requisition}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <ViewDetailsModal
            groupModal={groupModal}
            toggleGroup={toggleGroup}
            selectedRequisitionData={selectedRequisitionData}
            validation={validation}
            confirmMoveModal={confirmMoveModal}
            toggleConfirmMoveModal={toggleConfirmMoveModal}
            requisitionData={requisitionData}
            clientfields={clientfields}
          />
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

const mapStateToProps = ({ requisition, clientfields }) => ({
  requisition,
  clientfields,
});

export default connect(mapStateToProps, { actionCreator })(PmoWorkItems);
