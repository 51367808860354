import { call, put, takeLatest } from "redux-saga/effects";

import {
  GET_USER_LIST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAILED,
  ADD_NEW_USER,
  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAILED,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  GET_USER_RULES,
  GET_USER_RULES_SUCCESS,
  GET_USER_RULES_FAILED,
} from "./types";

import userServices from "../../api/services/user";

function* fnGetUserList() {
  try {
    const data = yield call(userServices.api.fnGetUserList);
    yield put({
      type: GET_USER_LIST_SUCCESS,
      payload: [...data.data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_USER_LIST_FAILED,
      payload: error.response?.data ? error.response?.data.message : "",
    });
  }
}

function* fnAddNewUser({ payload }) {
  try {
    const result = yield call(userServices.api.fnAddNewUser, payload);

    if (result) {
      yield put({
        type: ADD_NEW_USER_SUCCESS,
        payload: { message: result.data.message },
      });
      yield put({
        type: GET_USER_LIST,
      });
    }
  } catch (error) {
    yield put({
      type: ADD_NEW_USER_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnUpdateUser({ payload }) {
  try {
    const result = yield call(userServices.api.fnUpdateUser, payload);

    if (result) {
      yield put({
        type: UPDATE_USER_SUCCESS,
        payload: { message: result.data.message },
      });
      yield put({
        type: GET_USER_LIST,
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_USER_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnDeleteUser({ payload }) {
  try {
    const { id } = payload;

    const result = yield call(userServices.api.fnDeleteUser, id);

    yield put({
      type: DELETE_USER_SUCCESS,
      payload: { message: result.data.message },
    });

    yield put({
      type: GET_USER_LIST,
    });
  } catch (error) {
    yield put({
      type: DELETE_USER_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetUserRules({ payload }) {
  try {
    const { id } = payload;

    const result = yield call(userServices.api.fnGetUserRules, id);
    yield put({
      type: GET_USER_RULES_SUCCESS,
      payload: result.data.result,
    });

  } catch (error) {
    yield put({
      type: GET_USER_RULES_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

export default function* watcher() {
  yield takeLatest(GET_USER_LIST, fnGetUserList);
  yield takeLatest(ADD_NEW_USER, fnAddNewUser);
  yield takeLatest(UPDATE_USER, fnUpdateUser);
  yield takeLatest(DELETE_USER, fnDeleteUser);
  yield takeLatest(GET_USER_RULES, fnGetUserRules);
}
