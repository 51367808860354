import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TableContainer from '../../components/Common/TableContainer';
import { actionCreator, types } from '../../store';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import {
  ID,
  Name,
  Client,
  Supplier,
  ReportingManager,
  Department,
  ContractStartDate,
  TerminationDate,
} from './column';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const WorkersList = ({ app, workers, ...props }) => {
  document.title = 'Workers List';
  const location = useLocation();

  const [workerList, setWorkerList] = React.useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Worker ID',
        accessor: 'id',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const workerData = cellProps.row.original;
          return (
            <>
              <Link
                to={`/workers/edit/${workerData.id}`}
                style={{
                  fontWeight: 700,
                  fontFamily: 'IBM Plex Sans',
                }}
              >
                <ID {...cellProps} />
              </Link>{' '}
            </>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const workerData = cellProps.row.original;
          return (
            <>
              <Link
                to={`/workers/edit/${workerData.id}`}
                style={{
                  fontWeight: 700,
                  fontFamily: 'IBM Plex Sans',
                }}
              >
                <Name {...cellProps} />{' '}
              </Link>{' '}
            </>
          );
        },
      },
      {
        Header: 'Client',
        accessor: 'client_name',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Client {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'Supplier',
        accessor: 'supplier_name',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Supplier {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'Reporting Manager',
        accessor: 'reporting_manager_name',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <ReportingManager {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'Department',
        accessor: 'department_name',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Department {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'Contract Start Date',
        accessor: 'contract_start_date_format',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <ContractStartDate {...cellProps} />{' '}
            </>
          );
        },
      },
      {
        Header: 'Termination Date',
        accessor: 'contract_end_date_format',
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <TerminationDate {...cellProps} />{' '}
            </>
          );
        },
      },
    ],
    [],
  );

  React.useEffect(() => {
    props.actionCreator({
      type: types.GET_WORKER_LIST_ALL,
    });
  }, []);

  React.useEffect(() => {
    setWorkerList(workers?.listData || []);
  }, [workers]);

  React.useEffect(() => {
    if (location.state?.reload) {
      props.actionCreator({
        type: types.GET_WORKER_LIST_ALL,
      });
    }
  }, [location.state]);

  var node = React.useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const handleDeleteClient = () => {
    props.actionCreator({
      type: types.DELETE_WORKER,
      payload: {
        id: selectedClient.id,
      },
    });
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Dashboard"
            titleLink="/dashboard"
            breadcrumbItem="Workers"
          />
          <Row className="align-items-center">
            <Col md={6}>
              <div className="mb-3">
                <h5 className="card-title">
                  Workers List{' '}
                  <span className="text-muted fw-normal ms-2">
                    ({workerList?.length})
                  </span>
                </h5>
              </div>
            </Col>
          </Row>
          <Row hidden>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">Client</Label>
                <Input
                  type="select"
                  className="form-select"
                  onChange={(e) => {
                    const value = e.target.value;
                  }}
                >
                  <option value={''}>All</option>
                </Input>
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">Supplier</Label>
                <Input
                  type="select"
                  className="form-select"
                  onChange={(e) => {
                    const value = e.target.value;
                  }}
                >
                  <option value={''}>All</option>
                </Input>
              </div>
            </Col>{' '}
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">Status</Label>
                <Input
                  type="select"
                  className="form-select"
                  onChange={(e) => {
                    const value = e.target.value;
                  }}
                >
                  <option value={''}>All</option>
                </Input>
              </div>
            </Col>
          </Row>
          <Row hidden>
            <Col lg="3">
              <div className="mb-3">
                <Label className="form-label">Department</Label>
                <Input
                  type="select"
                  className="form-select"
                  onChange={(e) => {
                    const value = e.target.value;
                  }}
                >
                  <option value={''}>All</option>
                </Input>
              </div>
            </Col>
          </Row>{' '}
          <Row>
            <Col lg="12">
              <Row className="align-items-center">
                <Col md={12}>
                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                    <div>
                      <Link to={`/workers/add`} className="btn btn-primary">
                        <i className="bx bx-plus me-1"></i> Add New
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <TableContainer
                    columns={columns}
                    data={workerList || []}
                    isGlobalFilter={true}
                    customPageSize={10}
                    className="table align-middle datatable dt-responsive table-check nowrap"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ app, workers }) => ({
  app,
  workers,
});

export default connect(mapStateToProps, { actionCreator })(WorkersList);
