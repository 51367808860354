import { call, put, takeLatest, all, fork } from "redux-saga/effects";

import {
  GET_REQUEST_LIST,
  GET_REQUEST_LIST_SUCCESS,
  GET_REQUEST_LIST_FAILED,
  GET_REQUEST_LIST_FILTERED,
  GET_REQUEST_LIST_FILTERED_SUCCESS,
  GET_REQUEST_LIST_FILTERED_FAILED,
  GET_TOTAL,
  GET_TOTAL_SUCCESS,
  GET_TOTAL_FAILED,
  GET_OPTIONS,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_FAILED,
  APPROVE_REQUISITION,
  APPROVE_REQUISITION_SUCCESS,
  APPROVE_REQUISITION_FAILED,
  PROCESS_REQUISITION,
  PROCESS_REQUISITION_SUCCESS,
  PROCESS_REQUISITION_FAILED,
  COMPLETE_REQUISITION,
  COMPLETE_REQUISITION_SUCCESS,
  COMPLETE_REQUISITION_FAILED,
  REJECT_REQUISITION,
  REJECT_REQUISITION_SUCCESS,
  REJECT_REQUISITION_FAILED,
  ADD_REQUISITION,
  ADD_REQUISITION_SUCCESS,
  ADD_REQUISITION_FAILED,
  GET_REQUISITION_WORKER,
  GET_REQUISITION_WORKER_SUCCESS,
  GET_REQUISITION_WORKER_FAILED,
} from "./types";

import requisitionServices from "../../api/services/requisition";

function* fnGetRequests({ payload }) {
  try {
    const response = yield call(requisitionServices.api.fnGetRequests, payload);
    yield put({
      type: GET_REQUEST_LIST_SUCCESS,
      payload: [...response.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_REQUEST_LIST_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnPostAddRequest({ payload }) {
  const { formData } = payload;
  try {
    const response = yield call(requisitionServices.api.fnPostAddRequest, {
      formData,
    });

    if (response) {
      yield put({
        type: ADD_REQUISITION_SUCCESS,
        payload: response.data.message,
      });
      yield put({
        type: GET_REQUEST_LIST,
      });
    }
  } catch (error) {
    yield put({
      type: ADD_REQUISITION_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetRequestsFiltered({ payload }) {
  try {
    const data = yield call(
      requisitionServices.api.fnGetRequestsFiltered,
      payload
    );

    yield put({
      type: GET_REQUEST_LIST_FILTERED_SUCCESS,
      payload: [...data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_REQUEST_LIST_FILTERED_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetTotal({ payload }) {
  try {
    const data = yield call(requisitionServices.api.fnGetTotal, payload);
    yield put({
      type: GET_TOTAL_SUCCESS,
      payload: [data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_TOTAL_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetOptions({ payload }) {
  try {
    const data = yield call(requisitionServices.api.fnGetOptions, payload);
    yield put({
      type: GET_OPTIONS_SUCCESS,
      payload: [data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_OPTIONS_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnApprove({ payload }) {
  try {
    const response = yield call(requisitionServices.api.fnApprove, payload);

    if (response) {
      yield put({
        type: APPROVE_REQUISITION_SUCCESS,
        payload: { message: response.data.message, status: "success" },
      });
      yield put({
        type: GET_REQUEST_LIST_FILTERED,
      });
    }
  } catch (error) {
    yield put({
      type: APPROVE_REQUISITION_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnProcess({ payload }) {
  try {
    const response = yield call(requisitionServices.api.fnProcess, payload);
    if (response) {
      yield put({
        type: PROCESS_REQUISITION_SUCCESS,
        payload: { message: response.data.message, status: "success" },
      });
      yield put({
        type: GET_REQUEST_LIST_FILTERED,
      });
    }
  } catch (error) {
    yield put({
      type: PROCESS_REQUISITION_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnReject({ payload }) {
  try {
    const response = yield call(requisitionServices.api.fnReject, payload);
    if (response) {
      yield put({
        type: REJECT_REQUISITION_SUCCESS,
        payload: { message: response.data.message, status: "success" },
      });
      yield put({
        type: GET_REQUEST_LIST_FILTERED,
      });
    }
  } catch (error) {
    yield put({
      type: REJECT_REQUISITION_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnRequisitionWorkers() {
  try {
    const response = yield call(requisitionServices.api.fnRequisitionWorkers);
    if (response) {
      yield put({
        type: GET_REQUISITION_WORKER_SUCCESS,
        payload: {
          data: response.data.result,
        },
      });
    }
  } catch (error) {
    yield put({
      type: GET_REQUISITION_WORKER_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnComplete({ payload }) {
  try {
    const response = yield call(requisitionServices.api.fnComplete, payload);
    if (response) {
      yield put({
        type: COMPLETE_REQUISITION_SUCCESS,
        payload: { message: response.data.message, status: "success" },
      });
      yield put({
        type: GET_REQUEST_LIST_FILTERED,
      });
    }
  } catch (error) {
    yield put({
      type: COMPLETE_REQUISITION_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

export function* watchGetRequisitionData() {
  yield takeLatest(GET_REQUEST_LIST, fnGetRequests);
  yield takeLatest(ADD_REQUISITION, fnPostAddRequest);
  yield takeLatest(GET_REQUEST_LIST_FILTERED, fnGetRequestsFiltered);
  yield takeLatest(GET_TOTAL, fnGetTotal);
  yield takeLatest(GET_OPTIONS, fnGetOptions);
  yield takeLatest(APPROVE_REQUISITION, fnApprove);
  yield takeLatest(PROCESS_REQUISITION, fnProcess);
  yield takeLatest(REJECT_REQUISITION, fnReject);
  yield takeLatest(GET_REQUISITION_WORKER, fnRequisitionWorkers);
  yield takeLatest(COMPLETE_REQUISITION, fnComplete);
}

function* requisitionSaga() {
  yield all([fork(watchGetRequisitionData)]);
}

export default requisitionSaga;
