import React from 'react';
import { connect } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { actionCreator, types } from '../../../store';
import { useFormik } from 'formik';
import {
  Container,
  Row,
  Card,
  Col,
  Form,
  FormFeedback,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import * as Yup from 'yup';
import Dropzone from 'react-dropzone';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import defaultAvatar from '../../../assets/images/users/avatar-1.jpg';
import user1 from '../../../assets/images/users/user-dummy-img.jpg';

const EarlyTermination = ({ requisition, ...props }) => {
  const { id } = useParams();
  let navigate = useNavigate();

  const editorRef = React.useRef();
  const [editor, setEditor] = React.useState(false);
  const [attachmentModal, setAttachmentModal] = React.useState(null);
  const [serviceDetails, setServiceDetails] = React.useState('');
  const [selectedFiles, setselectedFiles] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState([]);
  const { CKEditor, ClassicEditor } = editorRef.current || {};
  const [filterWorkers, setFilterWorkers] = React.useState([]);
  const [urgencyOptions, setUrgencyOptions] = React.useState([]);

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      workers: [],
      urgency_level_id: '',
      termination_date: '',
      service_details: '',
    },
    validationSchema: Yup.object({
      workers: Yup.array()
        .of(Yup.string().required('An worker is required'))
        .min(1, 'Please select at least one worker.'),
      termination_date: Yup.string().required(
        'Please Select a Termination Date',
      ),
      urgency_level_id: Yup.string().required('Please Select a Urgency Level'),
      service_details: Yup.string().required(
        'Please enter any service details.',
      ),
    }),
    onSubmit: async (values) => {
      let forEvaluationWorkers = new FormData();

      if (selectedFiles.length > 0) {
        for (let i = 0; i < selectedFiles.length; i++) {
          forEvaluationWorkers.append(
            'files',
            selectedFiles[i],
            selectedFiles[i].name,
          );
        }
      }

      let newWorkersData = [];
      if (values['workers'].length > 0) {
        for (let workerID of values['workers']) {
          const filterWorker = filterWorkers.find(
            (item) => item.id === workerID,
          );

          newWorkersData.push({
            id: workerID,
            name: filterWorker?.name || '',
          });
        }
      }

      let request_content = [];

      request_content.push({
        label: 'Workers',
        column: 'worker_id',
        values: JSON.stringify(newWorkersData),
      });

      request_content.push({
        label: 'Termination Date',
        column: 'date_terminated',
        values: JSON.stringify([
          {
            id: '',
            name: values['termination_date'],
          },
        ]),
      });

      request_content.push({
        label: 'Details of the Service/ Justification/ Notes',
        column: '',
        values: JSON.stringify([
          {
            id: '',
            name: serviceDetails,
          },
        ]),
      });

      forEvaluationWorkers.append(
        'urgency_level_id',
        values['urgency_level_id'],
      );
      forEvaluationWorkers.append('request_type_id', id);
      forEvaluationWorkers.append(
        'request_content',
        JSON.stringify(request_content),
      );

      // save new Early Termination of Contract
      props.actionCreator({
        type: types.ADD_REQUISITION,
        payload: {
          formData: forEvaluationWorkers,
        },
      });

      validation.resetForm();
      setselectedFiles([]);
      setServiceDetails('');
      navigate('/requisition');
    },
  });

  React.useEffect(() => {
    editorRef.current = {
      CKEditor: require('@ckeditor/ckeditor5-react').CKEditor,
      ClassicEditor: require('@ckeditor/ckeditor5-build-classic'),
    };
    setEditor(true);
  }, []);

  React.useEffect(() => {
    if (requisition.workersAndUrgencyData) {
      setFilterWorkers(requisition.workersAndUrgencyData?.client_workers || []);
      setUrgencyOptions(
        requisition.workersAndUrgencyData?.urgency_levels || [],
      );
    }
  }, [requisition.workersAndUrgencyData]);

  React.useEffect(() => {
    fetchWorkersAndUrgency();
  }, []);

  const fetchWorkersAndUrgency = async () => {
    await props.actionCreator({
      type: types.GET_REQUISITION_WORKER,
    });
  };

  const selectWorkersHandler = (e) => {
    const { value, checked } = e.target;
    const { workers } = validation.values;

    if (checked) {
      validation.setFieldValue('workers', [...workers, parseInt(value)]);
    } else {
      validation.setFieldValue(
        'workers',
        workers.filter((v) => v !== parseInt(value)),
      );
    }
  };

  const handleSearchWorkers = (e) => {
    const searchValue = e.target.value.toLowerCase();

    if (searchValue) {
      const filteredWorkers =
        requisition.workersAndUrgencyData?.client_workers.filter((item) =>
          item.name.toLowerCase().includes(searchValue),
        );
      setFilterWorkers(filteredWorkers);
    } else {
      setFilterWorkers(requisition.workersAndUrgencyData?.client_workers || []);
    }
  };

  const toggleAttachmentModal = () => {
    setAttachmentModal(!attachmentModal);
  };

  //meta title
  document.title = 'Early Termination of Contract';

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Requisitions"
            titleLink="/requisition"
            breadcrumbItem="Early Termination of Contract"
          />
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                  <Label className="form-label">Worker(s)</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Search for worker"
                    onChange={handleSearchWorkers}
                    style={{
                      fontStyle: 'italic',
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      minHeight: 100,
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      marginTop: 10,
                      border: validation.errors.workers
                        ? '1px solid #fd625e'
                        : 'none',
                    }}
                  >
                    {filterWorkers.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginRight: 40,
                          marginTop: 10,
                        }}
                      >
                        <Input
                          type="checkbox"
                          name="workers"
                          style={{
                            marginRight: 10,
                            marginTop: 10,
                            borderColor: '#ADB5BD',
                            borderRadius: 4,
                            height: 18,
                            width: 18,
                          }}
                          value={item.id}
                          checked={validation.values.workers.includes(
                            parseInt(item.id),
                          )}
                          onChange={selectWorkersHandler}
                        />
                        <img
                          className="rounded-circle"
                          style={{
                            marginRight: 10,
                            backgroundColor: 'none',
                            height: 36,
                            width: 36,
                          }}
                          src={user1}
                          alt="Header Avatar"
                        />
                        <Label
                          style={{
                            marginTop: 10,
                          }}
                        >
                          {' '}
                          {item.name}{' '}
                        </Label>
                      </div>
                    ))}
                  </div>
                  {validation.errors.workers ? (
                    <p
                      style={{
                        display: 'block',
                        width: '100%',
                        fontSize: 12,
                        marginTop: '0.25rem',
                        color: '#fd625e',
                      }}
                    >
                      {validation.errors.workers}
                    </p>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col lg={3}>
                <div className="mb-3">
                  <Label className="form-label">Termination Date</Label>
                  <Input
                    type="date"
                    name="termination_date"
                    className="form-control"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.termination_date || ''}
                    invalid={
                      validation.touched.termination_date &&
                      validation.errors.termination_date
                        ? true
                        : false
                    }
                  />
                  {validation.touched.termination_date &&
                  validation.errors.termination_date ? (
                    <FormFeedback type="invalid">
                      {validation.errors.termination_date}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={3}>
                <div className="mb-3">
                  <Label className="form-label">Urgency Level</Label>
                  <Input
                    type="select"
                    name="urgency_level_id"
                    className="form-select"
                    multiple={false}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.urgency_level_id || '0'}
                    invalid={
                      validation.touched.urgency_level_id &&
                      validation.errors.urgency_level_id
                        ? true
                        : false
                    }
                  >
                    <option value={''}> </option>
                    {urgencyOptions.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Input>
                  {validation.touched.urgency_level_id &&
                  validation.errors.urgency_level_id ? (
                    <FormFeedback type="invalid">
                      {validation.errors.urgency_level_id}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                  <Label className="form-label">
                    Details of the Service/ Justification/ Notes
                  </Label>
                  {editor ? (
                    <CKEditor
                      editor={ClassicEditor}
                      data={serviceDetails}
                      onReady={(editor) => {
                        //console.log("Editor is ready to use!", editor);
                        // You can store the "editor" and use when it is needed.
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();

                        validation.setFieldValue('service_details', data);
                        setServiceDetails(data);
                      }}
                    />
                  ) : (
                    <p>ckeditor5</p>
                  )}
                  {validation.errors.service_details ? (
                    <p
                      style={{
                        display: 'block',
                        width: '100%',
                        fontSize: 12,
                        marginTop: '0.25rem',
                        color: '#fd625e',
                      }}
                    >
                      {validation.errors.service_details}
                    </p>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row
              style={{
                marginTop: 25,
              }}
            >
              <Col lg={2}>
                <div className="mb-3">
                  <Label className="form-label">
                    Supporting Documents (optional)
                  </Label>
                  <button
                    type="button"
                    className="btn btn-light"
                    style={{
                      width: 95,
                      height: 90,
                    }}
                    onClick={toggleAttachmentModal}
                  >
                    <i className="mdi mdi-upload display-6"> </i>
                    <p>Upload</p>
                  </button>
                </div>
              </Col>
              <Col lg={10}>
                <div
                  className="dropzone-previews mt-3"
                  id="file-previews"
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  {selectedFiles.map((f, i) => {
                    return (
                      <Card
                        style={{
                          marginLeft: 5,
                          marginRight: 5,
                          width: '50%',
                        }}
                        key={i + '-file'}
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col
                              className="col-auto"
                              style={{
                                marginBottom: 10,
                              }}
                            >
                              <i
                                className="mdi mdi-file-document-outline font-size-30"
                                id="edittooltip"
                              >
                                {f.name}
                              </i>
                            </Col>
                            <Col>
                              <Link
                                to="#"
                                className="text-muted font-weight-bold"
                              >
                                {f.name}
                              </Link>
                              <p className="mb-0">
                                <strong>{f.formattedSize}</strong>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    );
                  })}
                </div>
              </Col>
              <Modal
                isOpen={attachmentModal}
                toggle={toggleAttachmentModal}
                style={{ maxWidth: '700px', width: '80%' }}
              >
                <ModalHeader toggle={toggleAttachmentModal} tag="h4">
                  Attach Files
                </ModalHeader>
                <ModalBody>
                  <Row>
                    <Col xs={12}>
                      <Dropzone
                        onDrop={(acceptedFiles, fileRejections) => {
                          setselectedFiles(acceptedFiles);
                          setErrorMessages(
                            fileRejections.map(
                              ({ file, errors }) =>
                                `${file.name}: ${errors.map((e) => e.message).join(', ')} `,
                            ),
                          );

                          if (!fileRejections.length) {
                            toggleAttachmentModal();
                          }
                        }}
                        accept={{
                          'application/pdf': ['.pdf'],
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick mt-2"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bx-cloud-upload" />
                              </div>
                              <h4>Drop files here or click to attach files.</h4>
                              {errorMessages.length > 0 && (
                                <div
                                  className="error-messages"
                                  style={{
                                    fontSize: 12,
                                  }}
                                >
                                  {errorMessages.map((message, index) => (
                                    <p key={index} className="text-danger">
                                      {message}
                                    </p>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </Row>
            <Row
              style={{
                margin: 40,
              }}
            >
              <Col
                lg={12}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-info text-white"
                    style={{
                      backgroundColor: '#5156BE',
                      width: 175,
                    }}
                  >
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ requisition }) => ({
  requisition,
});

export default connect(mapStateToProps, { actionCreator })(EarlyTermination);
