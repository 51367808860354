import { call, put, takeLatest, all, fork } from "redux-saga/effects";

import {
  GET_OPERATION_CLIENTS,
  GET_OPERATION_CLIENTS_SUCCESS,
  GET_OPERATION_CLIENTS_FAILED,
  SAVE_MONTHLY_OPERATION,
  SAVE_MONTHLY_OPERATION_SUCCESS,
  SAVE_MONTHLY_OPERATION_FAILED,
  GET_OPERATION_LIST,
  GET_OPERATION_LIST_SUCCESS,
  GET_OPERATION_LIST_FAILED,
} from "./types";

import operationServices from "../../api/services/operation";

function* fnGetOperationClients({ payload }) {
  try {
    const data = yield call(operationServices.api.fnGetOperationClients, payload);
    yield put({
      type: GET_OPERATION_CLIENTS_SUCCESS,
      payload: [...data.data.result],
    });
  } catch (error) {
    yield put({
      type: GET_OPERATION_CLIENTS_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnSave({ payload }) {
  try {
    const response = yield call(operationServices.api.fnSave,payload);
    if (response) {
      yield put({
        type: SAVE_MONTHLY_OPERATION_SUCCESS,
        payload: { message: response.data.message, status: "success" },
      });
    }
  } catch (error) {
    yield put({
      type: SAVE_MONTHLY_OPERATION_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

function* fnGetOperationList({ payload }) {
  try {
    const data = yield call(operationServices.api.fnGetOperationList, payload);

    yield put({
      type: GET_OPERATION_LIST_SUCCESS,
      payload: {
        monthly_operation_id: data.data.result.id,
        fields: [...data.data.result.fields]
      },
    });
  } catch (error) {
    yield put({
      type: GET_OPERATION_LIST_FAILED,
      payload: error.response.data ? error.response.data.message : "",
    });
  }
}

export function* watchGetOperationData() {
  yield takeLatest(GET_OPERATION_CLIENTS, fnGetOperationClients);
  yield takeLatest(SAVE_MONTHLY_OPERATION, fnSave);
  yield takeLatest(GET_OPERATION_LIST, fnGetOperationList);
}

function* operationSaga() {
  yield all([fork(watchGetOperationData)]);
}

export default operationSaga;
