export const GET_CLIENT_LIST_ALL = "@clients/GET_CLIENT_LIST_ALL";
export const GET_CLIENT_LIST_ALL_SUCCESS =
  "@clients/GET_CLIENT_LIST_ALL_SUCCESS";
export const GET_CLIENT_LIST_ALL_FAILED = "@clients/GET_CLIENT_LIST_ALL_FAILED";

export const GET_CLIENT_LIST = "@clients/GET_CLIENT_LIST";
export const GET_CLIENT_LIST_SUCCESS = "@clients/GET_CLIENT_LIST_SUCCESS";
export const GET_CLIENT_LIST_FAILED = "@clients/GET_CLIENT_LIST_FAILED";

export const GET_CLIENTS_LIST_SUPPLIERS_REPORTINGMANAGER =
  "@clients/GET_CLIENTS_LIST_SUPPLIERS_REPORTINGMANAGER";
export const GET_CLIENTS_LIST_SUPPLIERS_REPORTINGMANAGER_SUCCESS =
  "@clients/GET_CLIENTS_LIST_SUPPLIERS_REPORTINGMANAGER_SUCCESS";
export const GET_CLIENTS_LIST_SUPPLIERS_REPORTINGMANAGER_FAILED =
  "@clients/GET_CLIENTS_LIST_SUPPLIERS_REPORTINGMANAGER_FAILED";

export const ADD_NEW_CLIENT = "@clients/ADD_NEW_CLIENT";
export const ADD_NEW_CLIENT_SUCCESS = "@clients/ADD_NEW_CLIENT_SUCCESS";
export const ADD_NEW_CLIENT_FAILED = "@clients/ADD_NEW_CLIENT_FAILED";

export const UPDATE_CLIENT = "@clients/UPDATE_CLIENT";
export const UPDATE_CLIENT_SUCCESS = "@clients/UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAILED = "@clients/UPDATE_CLIENT_FAILED";

export const UPDATE_CLIENT_CONTACTS = "@clients/UPDATE_CLIENT_CONTACTS";
export const UPDATE_CLIENT_CONTACTS_SUCCESS =
  "@clients/UPDATE_CLIENT_CONTACTS_SUCCESS";
export const UPDATE_CLIENT_CONTACTS_FAILED =
  "@clients/UPDATE_CLIENT_CONTACTS_FAILED";

export const DELETE_CLIENT = "@clients/DELETE_CLIENT";
export const DELETE_CLIENT_SUCCESS = "@clients/DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAILED = "@clients/DELETE_CLIENT_FAILED";

export const CLEAR_CLIENT_SAVE_MESSAGE = "@documents/CLEAR_CLIENT_SAVE_MESSAGE";
