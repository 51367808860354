import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import TableContainer from "../../components/Common/TableContainer";
import { actionCreator, types } from "../../store";
import withRouter from "../../components/Common/withRouter";

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavLink,
  FormGroup,
} from "reactstrap";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";

import { Name, Status, Date, Id } from "./column";
import InputCheckbox from "../../components/Common/Form/InputCheckbox";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";
import ErrorModal from "../../components/Common/ErrorModal";

import { isEmpty } from "lodash";

const UserGroup = ({ usergroup, authentication, app, ...props }) => {
  const [selectedUserGroup, setSelectedUserGroup] = React.useState();
  const [modal, setModal] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [dynamicOptionsValue, setDynamicOptionsValue] = React.useState([]);
  //delete user modal
  const [deleteModal, setDeleteModal] = React.useState(false);
  //error user modal
  const [errorModal, setErrorModal] = React.useState(false);
  let list = [];

  const handleNotification = async () => {
    await props.actionCreator({ type: types.CLEAR_USERGROUP });
    toast(usergroup.message, {
      position: "top-right",
      hideProgressBar: true,
      className: "bg-success text-white",
    });
  };

  const handleErrorNotification = async () => {
    await props.actionCreator({ type: types.CLEAR_USERGROUP_ERROR });
    toast(usergroup.errorMessage, {
      position: "top-right",
      hideProgressBar: true,
      className: "bg-danger text-white",
    });
  };

  const userGroupForm = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: (selectedUserGroup && selectedUserGroup.name) || "",
      status: (selectedUserGroup && selectedUserGroup.status) || "",
      ...dynamicOptionsValue,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      status: Yup.string().required("Please Select Status"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateUserGroup = {
          id: selectedUserGroup.id,
          name: values.name,
          status: parseInt(values.status),
          access: getAccessId(app.data, values),
        };
        // update user
        props.actionCreator({
          type: types.UPDATE_USERGROUP,
          payload: updateUserGroup,
        });
        setIsEdit(false);
      } else {
        const newUserGroup = {
          name: values["name"],
          status: parseInt(values["status"]),
          access: getAccessId(app.data, values),
        };
        // save new user
        props.actionCreator({
          type: types.ADD_NEW_USERGROUP,
          payload: newUserGroup,
        });
      }
      toggle();
    },
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "User Group ID",
        accessor: "id",
        width: "20%",
        filterable: false,
        disableFilters: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Id {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        disableFilters: true,
        width: "20%",
        Cell: (cellProps) => {
          return (
            <>
              <Status {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Date Modified",
        accessor: "date_modified",
        filterable: false,
        disableFilters: true,
        width: "20%",
        Cell: (cellProps) => {
          return (
            <>
              <Date {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    handleUserClickEdit(userData);
                  }}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    onClickDelete(userData);
                  }}
                ></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  React.useEffect(() => {
    props.actionCreator({
      type: types.GET_USERGROUP_LIST,
    });
    setIsEdit(false);
  }, []);

  React.useEffect(() => {
    let menu = _.sortBy(app.data, ["sort"]);
    let data = [];
    for (let x of menu) {
      data[x.name.replace(/\s/g, "").toLowerCase()] =
        selectedUserGroup === undefined
          ? false
          : selectedUserGroup[x.name.replace(/\s/g, "").toLowerCase()];
      if (x.children) {
        for (let xChild of x.children) {
          data[xChild.name.replace(/\s/g, "").toLowerCase()] =
            selectedUserGroup === undefined ||
            selectedUserGroup[xChild.name.replace(/\s/g, "").toLowerCase()] ===
              undefined
              ? false
              : selectedUserGroup[xChild.name.replace(/\s/g, "").toLowerCase()];
        }
      }
    }
    setDynamicOptionsValue(data);
  }, [app, selectedUserGroup]);

  React.useEffect(() => {
    usergroup.isUserGroupSubmitted && handleNotification();
    usergroup.error &&
      usergroup.errorMessage &&
      usergroup.status != 500 &&
      usergroup.status != 403 &&
      handleErrorNotification();
  }, [usergroup]);

  const toggle = () => {
    setModal(!modal);
  };

  const getAccessId = (collection, selected) => {
    _.each(collection, function (model) {
      if (model.name && selected[navNameToLowerCase(model.name)] === true) {
        list.push(model.id);
      }
      if (model.children && model.children.length > 0) {
        getAccessId(model.children, selected);
      }
    });
    return list;
  };

  const handleUserClickEdit = (arg) => {
    const menuList = _.cloneDeep(app.data);
    const userCheckedItems = {};
    menuList.forEach((item, index) => {
      if (
        JSON.parse(arg.access).includes(item.id) &&
        item.parent === 0 &&
        item.url
      ) {
        userCheckedItems[navNameToLowerCase(item.name)] = true;
      } else if (!item.url && item.parent === 0) {
        let flg = false;
        menuList[index]["children"].forEach((subItem, subIndex) => {
          if (JSON.parse(arg.access).includes(subItem.id)) {
            flg = true;
            userCheckedItems[navNameToLowerCase(subItem.name)] = true;
          }

          if (flg) {
            userCheckedItems[navNameToLowerCase(item.name)] = true;
          } else {
            userCheckedItems[navNameToLowerCase(item.name)] = false;
          }
        });
      } else {
        userCheckedItems[navNameToLowerCase(item.name)] = false;
      }
    });

    setSelectedUserGroup({
      id: arg.id,
      name: arg.name,
      status: parseInt(arg.status),
      ...userCheckedItems,
    });
    setIsEdit(true);
    toggle();
  };

  var node = React.useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const onClickDelete = (user) => {
    setSelectedUserGroup(user);
    setDeleteModal(true);
  };

  const handleDeleteUserGroup = () => {
    props.actionCreator({
      type: types.DELETE_USERGROUP,
      payload: {
        id: selectedUserGroup.id,
      },
    });
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    const menuList = _.cloneDeep(app.data);
    const userCheckedItems = {};
    menuList.forEach((item, index) => {
      if (item.parent === 0 && item.url) {
        userCheckedItems[navNameToLowerCase(item.name)] = false;
      } else if (!item.url && item.parent === 0) {
        userCheckedItems[navNameToLowerCase(item.name)] = false;
        menuList[index]["children"].forEach((subItem, subIndex) => {
          userCheckedItems[navNameToLowerCase(subItem.name)] = false;
        });
      }
    });

    setSelectedUserGroup({
      id: "",
      name: "",
      status: "",
      ...userCheckedItems,
    });
    setIsEdit(false);
    toggle();
  };

  const navNameToLowerCase = (string) => {
    return string.replace(/\s/g, "").toLowerCase();
  };

  //meta title
  document.title = "User Groups | MPS";
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUserGroup}
        onCloseClick={() => setDeleteModal(false)}
      />
      <ErrorModal
        component={usergroup}
        statusCode={usergroup.list.status}
        show={errorModal}
        onCloseClick={() => setErrorModal(false)}
        message={usergroup.errorMessage}
        setErrorModal={setErrorModal}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="User Groups" breadcrumbItem="User Groups" />
          <Row>
            <Col lg="12">
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="mb-3">
                    <h5 className="card-title">
                      Users Group List{" "}
                      <span className="text-muted fw-normal ms-2">
                        ({usergroup?.list?.data.length})
                      </span>
                    </h5>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                    <div>
                      <Link
                        to="#"
                        className="btn btn-primary"
                        onClick={handleUserClicks}
                      >
                        <i className="bx bx-plus me-1"></i> Add New
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <TableContainer
                    columns={columns}
                    data={usergroup?.list?.data}
                    isGlobalFilter={true}
                    customPageSize={10}
                    className="table align-middle datatable dt-responsive table-check nowrap"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit User Group" : "New User Group"}
                    </ModalHeader>
                    <ModalBody>
                      <FormikProvider value={userGroupForm}>
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            userGroupForm.handleSubmit();
                            return false;
                          }}
                        >
                          <Row>
                            <Col xs={12}>
                              <div className="mb-3">
                                <Label className="form-label">Name</Label>
                                <Input
                                  name="name"
                                  label="name"
                                  type="text"
                                  onChange={userGroupForm.handleChange}
                                  onBlur={userGroupForm.handleBlur}
                                  value={userGroupForm.values.name || ""}
                                  invalid={
                                    userGroupForm.touched.name &&
                                    userGroupForm.errors.name
                                      ? true
                                      : false
                                  }
                                />
                                {userGroupForm.touched.name &&
                                userGroupForm.errors.name ? (
                                  <FormFeedback type="invalid">
                                    {userGroupForm.errors.name}
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="mb-3">
                                <Label className="form-label">Access</Label>
                                {app.data.length > 0 &&
                                  app.data.map((item, index) => {
                                    const name = item.name
                                      .replace(/\s/g, "")
                                      .toLowerCase();
                                    if (!item.children) {
                                      return (
                                        <InputCheckbox
                                          key={index}
                                          name={name}
                                          label={item.name}
                                          value={userGroupForm.values[name]}
                                        />
                                      );
                                    } else {
                                      return (
                                        <Fragment key={index}>
                                          <InputCheckbox
                                            key={"parent_" + index}
                                            name={name}
                                            label={item.name}
                                            value={userGroupForm.values[name]}
                                            children={item.children}
                                          />
                                          <div
                                            style={{ marginLeft: "20px" }}
                                            key={"child_" + index}
                                          >
                                            {item.children &&
                                              item.children.map(
                                                (subitem, idx) => {
                                                  const childName = subitem.name
                                                    .replace(/\s/g, "")
                                                    .toLowerCase();
                                                  return (
                                                    <InputCheckbox
                                                      key={"child_" + idx}
                                                      name={childName}
                                                      label={subitem.name}
                                                      value={
                                                        userGroupForm.values[
                                                          childName
                                                        ]
                                                      }
                                                      parent={name}
                                                      siblings={item.children}
                                                    />
                                                  );
                                                }
                                              )}
                                          </div>
                                        </Fragment>
                                      );
                                    }
                                  })}
                              </div>

                              <div className="mb-3">
                                <Label className="form-label">Status</Label>
                                <Input
                                  type="select"
                                  name="status"
                                  className="form-select"
                                  multiple={false}
                                  onChange={userGroupForm.handleChange}
                                  onBlur={userGroupForm.handleBlur}
                                  value={userGroupForm.values.status || "0"}
                                  invalid={
                                    userGroupForm.touched.status &&
                                    userGroupForm.errors.status
                                      ? true
                                      : false
                                  }
                                >
                                  <option value={""}>Select Status</option>
                                  <option value={1}>Active</option>
                                  <option value={2}>In-Active</option>
                                </Input>
                                {userGroupForm.touched.status &&
                                userGroupForm.errors.status ? (
                                  <FormFeedback type="invalid">
                                    {userGroupForm.errors.status}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end">
                                <button
                                  type="submit"
                                  className="btn btn-success save-user"
                                >
                                  Save
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </FormikProvider>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ usergroup, authentication, app }) => ({
  usergroup,
  authentication,
  app,
});

export default withRouter(
  connect(mapStateToProps, { actionCreator })(UserGroup)
);
