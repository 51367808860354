import {
  SAVE_CONFIGURATION,
  SAVE_CONFIGURATION_SUCCESS,
  SAVE_CONFIGURATION_FAILED,
} from "./types";

const INIT_STATE = {
  loading: true,
  data: [],
  error: false,
  errorMessage: null,
  status: null,
  isDataSubmitted: false,
  isSubmitted: false,
};

const user = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_CONFIGURATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: false,
        errorMessage: null,
        status: null,
        isSubmitted: true,
        messages: action.payload.message,
      };

    case SAVE_CONFIGURATION_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        status: action.status,
        isSubmitted: false,
      };

    default:
      return state;
  }
};

export default user;
